import React, { useEffect, useState } from "react";

import copy from "copy-text-to-clipboard";

import Typography from "app/uikit-V2/Typography";
import toastify from "app/utils/toastify";
import Alert from "app/uikit/alert";
import Button from "app/uikit-V2/Button";
import { API } from "app/api";
import { ApiUrls } from "app/api/apiUrls";
import { DeleteIcon } from "app/icons";

import { ICompany } from "../../../types";

import styles from "./style.module.scss";
import { IconCopy, IconLinkedin, IconShare, IconTime } from "./icons";
import ContinueTrackingModal, { IInitialValues } from "./ContinueTrackingModal";

interface Props {
	name: string;
	email: string;
	linkedInUrl?: string;
	position?: string;
	lastUpdate?: string;
	shouldRemove?: boolean;
	positionCurrent?: string;
	companyCurrent?: string;
	onDelete?: () => void;
	onSubmitSettings?: (values: IInitialValues) => void;
	apiUrl?: string;
	webSite?: string;
	ids: {
		trackingId: string;
		companyId: string;
		personId: string;
		userId?: string;
	};
}

const DetailsInfo: React.FC<Props> = ({
	name,
	linkedInUrl,
	position,
	lastUpdate,
	email,
	webSite,
	onDelete,
	positionCurrent,
	companyCurrent,
	shouldRemove,
	onSubmitSettings,
	apiUrl = ApiUrls.account.leadObservation.companiesSets.path,
	ids
}) => {
	const { trackingId } = ids;

	const [modalOpen, setModalOpen] = useState(false);
	const [companies, setCompanies] = useState<{ name: string; id: string }[]>([]);

	const onCloseModal = () => {
		setModalOpen(false);
	};

	const onOpenModal = () => {
		setModalOpen(true);
	};

	useEffect(() => {
		(async () => {
			try {
				const response = await API.get<{ count: number; items: ICompany[] }>(
					apiUrl
				);

				const listCompanies = response.data.items.map(item => ({
					name: item.name,
					id: item.id
				}));
				setCompanies(listCompanies);
			} catch (err) {
				console.log(err);
			}
		})();
	}, [trackingId, apiUrl]);

	return (
		<div className={styles.box}>
			<div className={styles.wrap_info}>
				<div className={styles.info}>
					<Typography marginBottom="0" className={styles.title} component="h3">
						{name}
						{linkedInUrl && (
							<a href={linkedInUrl} target="_blank" rel="noreferrer">
								<IconLinkedin />
							</a>
						)}
					</Typography>
					{position && (
						<Typography className={styles.position}>{position}</Typography>
					)}

					{webSite && (
						<Typography className={styles.position}>
							<a href={webSite} target="_blank" rel="noreferrer">
								{webSite}
							</a>
						</Typography>
					)}

					{email && (
						<div className={styles.wrapEmail}>
							<a
								href={`mailto:${email}`}
								className={styles.email}
								target="_blank"
								rel="noreferrer"
							>
								{email}
							</a>
							<button
								onClick={() => {
									copy(email);
									toastify.info("The Email copied");
								}}
								className={styles.copy}
							>
								<IconCopy />
							</button>
						</div>
					)}
				</div>

				<div className={styles.content}>
					{shouldRemove && (
						<>
							<Alert
								className={styles.alert}
								text="The person is no longer employed at this company"
								type="error"
							/>

							<Button
								onClick={onOpenModal}
								className={styles.btnContinue}
								size="small"
							>
								Set up settings
							</Button>

							{onDelete && (
								<Button
									variant="secondary"
									className={styles.iconShare}
									onClick={onDelete}
								>
									<DeleteIcon color="#939BAB" />
								</Button>
							)}
						</>
					)}

					<Button
						variant="secondary"
						className={styles.iconShare}
						onClick={() => {
							copy(window.location.href);
							toastify.info("The url copied");
						}}
					>
						<IconShare />
					</Button>
				</div>
			</div>

			{lastUpdate && (
				<p className={styles.time}>
					<IconTime />
					<span>Last update: {lastUpdate}</span>
				</p>
			)}
			<ContinueTrackingModal
				ids={ids}
				companiesList={companies}
				details={{
					position_name: positionCurrent,
					company_name: companyCurrent
				}}
				open={modalOpen}
				onSubmit={onSubmitSettings}
				onClose={onCloseModal}
			/>
		</div>
	);
};

export default DetailsInfo;
