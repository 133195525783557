import { AxiosResponse } from "axios";

import { API } from "app/api";
import { ApiUrls } from "app/api/apiUrls";
import { globalHandlerError } from "app/helper/handlerErrors";
import { IFile } from "app/types";

import { IMatchedData, TypeRequestFile } from "./type";

export const getMatchesColumns = async (data: {
	file: IFile;
}): Promise<IMatchedData> => {
	try {
		const response: AxiosResponse<IMatchedData> = await API.post(
			ApiUrls.account.leadObservation.companiesSets.match,
			data
		);

		return response.data;
	} catch (err) {
		globalHandlerError(err);
		throw new Error(err);
	}
};

export const sendMatchedColumns = async (
	data: TypeRequestFile
): Promise<IMatchedData> => {
	try {
		const response: AxiosResponse<IMatchedData> = await API.post(
			ApiUrls.account.leadObservation.companiesSets.path,
			data
		);
		return response.data;
	} catch (err) {
		globalHandlerError(err);
		throw new Error(err);
	}
};

export const getMatchesAdditionalColumns = async (
	id: string | number,
	data: {
		file: IFile;
	}
): Promise<IMatchedData> => {
	try {
		const response: AxiosResponse<IMatchedData> = await API.post(
			ApiUrls.admin.users.leadObservation.companiesSets.match(id),
			data
		);

		return response.data;
	} catch (err) {
		globalHandlerError(err);
		throw new Error(err);
	}
};

export const sendMatchedAdditionalColumns = async (
	id: string | number,
	data: TypeRequestFile
): Promise<IMatchedData> => {
	try {
		const response: AxiosResponse<IMatchedData> = await API.post(
			ApiUrls.admin.users.leadObservation.companiesSets.path(id),
			data
		);
		return response.data;
	} catch (err) {
		globalHandlerError(err);
		throw new Error(err);
	}
};
