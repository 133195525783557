// @ts-nocheck

import types from "./types";

export const initialState = {
	settings: {},
	error: "",
	loading: false,
	updating: false
};

const reducer = function (state = initialState, action) {
	switch (action.type) {
		case types.GET_GENERAL_SETTINGS_REQUEST:
			return {
				...state,
				loading: true
			};
		case types.GET_GENERAL_SETTINGS_SUCCESS:
			return {
				...state,
				loading: false,
				settings: action.data
			};
		case types.GET_GENERAL_SETTINGS_FAILURE:
			return {
				...state,
				loading: false
			};
		case types.UPDATE_GENERAL_SETTINGS_REQUEST:
			return {
				...state,
				updating: true,
				error: ""
			};
		case types.UPDATE_GENERAL_SETTINGS_SUCCESS:
			return {
				...state,
				updating: false,
				error: ""
			};
		case types.UPDATE_GENERAL_SETTINGS_FAILURE:
			return {
				...state,
				updating: false,
				error: action.error
			};
		default:
			return state;
	}
};

export default reducer;
