// @ts-nocheck
import { FormControl as FormControlBase } from "@material-ui/core";
import cl from "classnames";

import useStyles from "./style";

export default function FormControl({
	fullWidth,
	children,
	className,
	...rest
}) {
	const classes = useStyles({ fullWidth });
	return (
		<FormControlBase className={cl(classes.formControl, className)} {...rest}>
			{children}
		</FormControlBase>
	);
}
