import Loadable from "app/utils/Loadable";
import Layout from "app/components/includes/layout";

const LoginContent = Loadable({
	loader: () => import("./login")
});

const PageLogin = () => {
	return (
		<Layout>
			<LoginContent />
		</Layout>
	);
};

export default PageLogin;
