import { globalHandlerError } from "app/helper/handlerErrors";
import { ApiUrls } from "app/api/apiUrls";
import { API } from "app/api";

export const fetchActiveSubscription = async (success_url?: string) => {
	try {
		const response = await API.get(ApiUrls.account.onboard.subscriptionLink, {
			params: { success_url }
		});
		window.location.href = response?.data?.link;
	} catch (err) {
		globalHandlerError(err);
		throw new Error(err);
	}
};
