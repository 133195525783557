import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	label: {
		transform: "none",
		position: "static",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		fontSize: "14px",
		lineHeight: "22px",
		color: "#56637B",
		marginBottom: "7px",
		"& + .MuiInput-formControl": {
			margin: 0
		},
		"&.Mui-focused": {
			color: "#38465D"
		}
	},
	input: {
		border: "1px solid #E8EAEF",
		borderRadius: 6,
		minHeight: 50,
		padding: "6px 16px",
		transition: "0.2s",
		color: "#3E4555",
		fontSize: "16px",
		"&.Mui-focused": {
			borderColor: "#CFD3DE"
		},
		"&.MuiInput-underline:before,&.MuiInput-underline:after": {
			content: "none"
		},
		"& .MuiInput-input": {
			padding: 0,
			color: "#3E4555"
		},
		"& .MuiInputBase-inputMultiline": {
			paddingTop: 6,
			paddingBottom: 6,
			resize: "vertical",
			lineHeight: "26px"
		}
	},
	input_error: {
		borderColor: "#EF5E39"
	},
	warning: {
		"&.Mui-error": {
			color: "#56637B",
			fontSize: "16px"
		}
	},
	warning__icon: {
		verticalAlign: "middle",
		display: "inline-block",
		marginRight: 10,
		lineHeight: 0
	},
	warning__text: {
		color: "#56637B",
		verticalAlign: "middle"
	},
	iconStyles: {
		height: "auto",
		maxHeight: "100%"
	},
	iconClickable: {
		cursor: "pointer"
	},
	currencyAdornment: {
		height: "100%",
		borderLeft: "1px solid #E8EAEF",
		marginTop: "-27px",
		marginBottom: "-27px",
		marginRight: "-16px",
		padding: "14px 10px"
	}
});

export default useStyles;
