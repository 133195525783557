import { useMemo } from "react";

import cn from "classnames";

import { ITriggerStyle } from "app/types";

import styles from "./style.module.scss";
import {
	getRenderByType,
	mapDataByType,
	viewType
} from "./PersonDetailsItem.utils";
import { KeywordsViewItemProps } from "./KeywordsViewItem";
import { CommonViewItemProps } from "./CommonViewItem";

interface Props {
	data?: any[];
	renderItem?: (data: any) => JSX.Element;
	viewType?: viewType;
	markerPositionUp?: boolean;
	triggerStyle?: ITriggerStyle;
}

const PersonDetailsItem: React.FC<Props> = ({
	data,
	renderItem,
	viewType = "position",
	triggerStyle,
	markerPositionUp
}) => {
	const ComponentItem = getRenderByType(viewType);

	const mappedData:
		| CommonViewItemProps[]
		| KeywordsViewItemProps[] = useMemo(() => mapDataByType(data, viewType), [
		data,
		viewType
	]);

	const isKeywords = viewType === "keywords";

	return (
		<div className={styles.wrapper}>
			{mappedData?.map((item, i) => {
				const isNewItem = mappedData?.[0]?.time === item?.time;

				return (
					<div
						key={i}
						className={cn(styles.item, {
							[styles.item_markers_up]: markerPositionUp || isKeywords,
							[styles.isActive]: isNewItem
						})}
					>
						<div className={styles.item__content}>
							<div className={styles.item__content_inner}>
								{renderItem ? (
									renderItem(item)
								) : (
									//@ts-ignore
									<ComponentItem
										triggerStyle={isKeywords ? triggerStyle : undefined}
										{...item}
									/>
								)}
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default PersonDetailsItem;
