export default {
	GET_EMPLOYEES_REQUEST: "GET_EMPLOYEES_REQUEST",
	GET_EMPLOYEES_SUCCESS: "GET_EMPLOYEES_SUCCESS",
	GET_EMPLOYEES_FAILURE: "GET_EMPLOYEES_FAILURE",

	DELETE_EMPLOYEES_REQUEST: "DELETE_EMPLOYEES_REQUEST",
	DELETE_EMPLOYEES_SUCCESS: "DELETE_EMPLOYEES_SUCCESS",
	DELETE_EMPLOYEES_FAILURE: "DELETE_EMPLOYEES_FAILURE",

	FETCH_NEW_EMPLOYEE_REQUEST: "FETCH_NEW_EMPLOYEE_REQUEST",
	FETCH_NEW_EMPLOYEE_SUCCESS: "FETCH_NEW_EMPLOYEE_SUCCESS",
	FETCH_NEW_EMPLOYEE_FAILURE: "FETCH_NEW_EMPLOYEE_FAILURE",

	FETCH_EDIT_EMPLOYEE_REQUEST: "FETCH_EDIT_EMPLOYEE_REQUEST",
	FETCH_EDIT_EMPLOYEE_SUCCESS: "FETCH_EDIT_EMPLOYEE_SUCCESS",
	FETCH_EDIT_EMPLOYEE_FAILURE: "FETCH_EDIT_EMPLOYEE_FAILURE",

	FETCH_PAGINATION_EMPLOYEE_REQUEST: "FETCH_PAGINATION_EMPLOYEE_REQUEST",
	FETCH_PAGINATION_EMPLOYEE_SUCCESS: "FETCH_PAGINATION_EMPLOYEE_SUCCESS",
};
