// @ts-nocheck
import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import Loadable from "app/utils/Loadable";
import Layout from "app/components/includes/layout";
import action from "app/store/auth/actions";

const Success = Loadable({
	loader: () => import("app/components/auth/recoverySuccess")
});

const Password = Loadable({
	loader: () => import("app/components/auth/new-password")
});

const NewPassword = () => {
	const { passwordChanged } = useSelector(state => state.auth);
	const dispatch = useDispatch();
	useEffect(() => {
		return () => {
			dispatch(action.fetchResetActionState());
		};
	}, [dispatch]);

	return <Layout>{(passwordChanged && <Success />) || <Password />}</Layout>;
};

export default NewPassword;
