import classNames from "classnames/bind";

import style from "./style.module.scss";

const HeaderLight = (props: any) => {
	const cx = classNames.bind(style);
	return <main className={cx("main", props.className)}>{props.children}</main>;
};

export default HeaderLight;
