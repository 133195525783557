import Loadable from "app/utils/Loadable";
import Layout from "app/components/includes/layout";

const PasswordRecovery = Loadable({
	loader: () => import("./PasswordRecovery")
});

const PagePasswordRecovery = () => {
	return (
		<Layout>
			<PasswordRecovery />
		</Layout>
	);
};

export default PagePasswordRecovery;
