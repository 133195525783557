import { ITrendValuePrepare } from "app/screens/cabinet/trends/reports/components/createOrEdit/types";
import { IFilterParams, IPagination } from "app/types";

import types from "./types";

export const ACTIONS_NAMES = {
	trends: "trends",
	trendDetails: "trendDetails",
	trendCreate: "trendCreate",
	trendUpdate: "trendUpdate",
	trendDelete: "trendDelete"
};

export type ITrandsActionNames = keyof typeof ACTIONS_NAMES;

const actions = {
	getTrends: (isAdmin: boolean = false) => ({
		type: types.GET_TRENDS_REQUEST,
		actionName: ACTIONS_NAMES.trends,
		isAdmin
	}),
	getTrendsSuccess: (actionName: ITrandsActionNames, data: any) => ({
		type: types.GET_TRENDS_SUCCESS,
		actionName,
		data
	}),
	getTrendsFailure: (actionName: ITrandsActionNames, error: any) => ({
		type: types.GET_TRENDS_FAILURE,
		actionName,
		error
	}),

	getTrend: (id: number, cb?: () => void) => ({
		type: types.GET_TREND_REQUEST,
		id,
		cb,
		actionName: ACTIONS_NAMES.trendDetails
	}),
	getTrendSuccess: (actionName: ITrandsActionNames, data: any) => ({
		type: types.GET_TREND_SUCCESS,
		actionName,
		data
	}),
	getTrendFailure: (actionName: ITrandsActionNames, error: any) => ({
		type: types.GET_TREND_FAILURE,
		actionName,
		error
	}),

	fetchCreateTrend: (data: ITrendValuePrepare, cb?: () => void) => ({
		type: types.FETCH_TREND_CREATE_REQUEST,
		data,
		cb,
		actionName: ACTIONS_NAMES.trendCreate
	}),
	fetchCreateTrendSuccess: (actionName: ITrandsActionNames, data: any) => ({
		type: types.FETCH_TREND_CREATE_SUCCESS,
		actionName,
		data
	}),
	fetchCreateTrendFailure: (actionName: ITrandsActionNames, error: any) => ({
		type: types.FETCH_TREND_CREATE_FAILURE,
		actionName,
		error
	}),

	fetchUpdateTrend: (id: number, data: ITrendValuePrepare, cb?: () => void) => ({
		type: types.FETCH_TREND_EDIT_REQUEST,
		id,
		data,
		cb,
		actionName: ACTIONS_NAMES.trendUpdate
	}),
	fetchUpdateTrendSuccess: (actionName: ITrandsActionNames, data: any) => ({
		type: types.FETCH_TREND_EDIT_SUCCESS,
		actionName,
		data
	}),
	fetchUpdateTrendFailure: (actionName: ITrandsActionNames, error: any) => ({
		type: types.FETCH_TREND_EDIT_FAILURE,
		actionName,
		error
	}),

	fetchDeleteTrend: (id: number, cb?: () => void) => ({
		type: types.FETCH_TREND_DELETE_REQUEST,
		id,
		cb,
		actionName: ACTIONS_NAMES.trendDelete
	}),

	fetchDeleteTrendSuccess: (actionName: ITrandsActionNames, data: any) => ({
		type: types.FETCH_TREND_DELETE_SUCCESS,
		actionName,
		data
	}),
	fetchDeleteTrendFailure: (actionName: ITrandsActionNames, error: any) => ({
		type: types.FETCH_TREND_DELETE_FAILURE,
		actionName,
		error
	}),

	fetchPagination: (data: IPagination, actionName?: ITrandsActionNames) => ({
		type: types.FETCH_PAGINATION_TRENDS_REQUEST,
		actionName: actionName || ACTIONS_NAMES.trends,
		data
	}),

	fetchPaginationSuccess: (
		data: IPagination,
		actionName: ITrandsActionNames
	) => ({
		type: types.FETCH_PAGINATION_TRENDS_SUCCESS,
		data,
		actionName
	}),

	fetchFilter: (data: IFilterParams, actionName?: ITrandsActionNames) => ({
		type: types.FETCH_FILTER_TRENDS_REQUEST,
		data,
		actionName: ACTIONS_NAMES.trends || actionName
	}),
	fetchFilterSuccess: (data: IFilterParams, actionName: ITrandsActionNames) => ({
		type: types.FETCH_FILTER_TRENDS_SUCCESS,
		data,
		actionName
	}),

	resetTrendDetails: () => ({
		type: types.RESET_TRENDS_DETAILS
	}),

	resetTrends: () => ({
		type: types.RESET_TRENDS
	})
};

export default actions;
