import { useCallback, useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import UserProfileWrapper from "app/components/UserProfileLayout";
import Button from "app/uikit-V2/Button";
import PATHS from "PATHS";
import TriggerDetails from "app/screens/cabinet/tracking/triggerDetails";
import { IPerson } from "app/screens/cabinet/tracking/types";
import { asyncConfirm } from "app/components/modal/asyncConfirm";
import { Nullable } from "app/types";
import { API } from "app/api";
import { ApiUrls } from "app/api/apiUrls";
import toastify from "app/utils/toastify";
import { IInitialValues } from "app/screens/cabinet/tracking/triggerDetails/components/DetailsInfo/ContinueTrackingModal";
import useQuery from "app/hooks/useQuery";

import styles from "./style.module.scss";
import { getPersonDetails } from "./TrackingPersonDetails.utils";

type UserLeadsParams = {
	id: string;
	companyId: string;
	trackingId: string;
	personId: string;
};

const UserTrackingPersonDetails = () => {
	const { id, trackingId, personId, companyId } = useParams<UserLeadsParams>();
	const history = useHistory();
	const [details, setDetails] = useState<Nullable<IPerson>>(null);

	const query = useQuery();
	const prevTrackingList = query.get("prev-tracking-list");

	useEffect(() => {
		getPersonDetails(id, trackingId, companyId, personId, setDetails);
	}, [id, companyId, personId, trackingId, setDetails]);

	const onBack = useCallback(() => {
		history.push(
			PATHS.adminPanel.users.tracking.details.url(
				id,
				prevTrackingList || trackingId
			)
		);
	}, [history, id, trackingId, prevTrackingList]);

	const onDeletePerson = useCallback(async () => {
		const confirm = await asyncConfirm({
			title: "Delete this person?",
			message: "Are you sure you want to delete this person?",
			declineText: "No",
			acceptText: "Yes"
		});

		if (confirm) {
			try {
				const res = await API.delete(
					ApiUrls.admin.users.leadObservation.companies.person.details(
						id,
						trackingId,
						companyId,
						personId
					)
				);
				toastify.success(res?.data?.message || res?.data?.messages);
				onBack();
			} catch (err) {
				toastify.error(err);
			}
		}
	}, [onBack, id, companyId, trackingId, personId]);

	const onSubmitSettings = useCallback(
		(values: IInitialValues) =>
			API.put(
				ApiUrls.admin.users.leadObservation.companies.person.move(
					id,
					trackingId,
					companyId,
					personId
				),
				{
					...values,
					companies_set_id: Number((values.companies_set_id as any).id)
				}
			),
		[id, trackingId, companyId, personId]
	);

	return (
		<UserProfileWrapper>
			<div className={styles.controls}>
				<Button size="medium" variant="secondary" onClick={onBack}>
					Go to tracking
				</Button>
			</div>

			<TriggerDetails
				userId={id}
				trackingId={trackingId}
				personId={personId}
				companyId={companyId}
				details={details}
				onDelete={onDeletePerson}
				onSubmitSettings={onSubmitSettings}
			/>
		</UserProfileWrapper>
	);
};

export default UserTrackingPersonDetails;
