import {  useFormik } from "formik";

import Select from "app/uikit-V2/Form/Select";
// import FormField from "app/uikit-V2/Form/FormField";
// import FormikField from "app/uikit-V2/Formik/FormikField/FormikField";

import styles from "./style.module.scss";

const TestSelectChip = () => {
	const formik = useFormik({
		initialValues: {
			select: []
		},
		onSubmit: values => {}
	});

	return (
		<div className={styles.container}>
			<div className={styles.title}>Select</div>
			<div className={styles.col}>
				{/* <Select fullWidth options={[{ label: "2", value: "2" }]} /> */}
			</div>
			<div className={styles.col}>
				<Select
					fullWidth
					multiple
					form={formik}
					name="select"
					options={[{ label: "2", value: "2" }]}
				/>
			</div>

			{/* 
			<div className={styles.col}>
				<FormField
					type="selectChip"
					error="eeee"
					info="22222"
					label="23"
					value={[]}
				/>
			</div>

			<div className={styles.col}>
				<FormField
					type="selectChip"
					info="22222"
					label="23"
					form={formik}
					name="chipForm"
				/>
			</div>
			<div className={styles.col}>
				<Formik initialValues={{ chip: [] }} onSubmit={() => {}}>
					{() => (
						<form>
							<FormikField
								type="selectChip"
								name="chip"
								error="eeee"
								info="22222"
								label="23"
							/>
						</form>
					)}
				</Formik>
			</div>*/}
		</div>
	);
};

export default TestSelectChip;
