export const getPropsForm = (
	{
		name,
		onChange,
		label,
		value,
		onBlur,
		form,
		error,
		checked
	}: {
		name?: string;
		onChange?: any;
		onBlur?: any;
		label?: string | React.ReactNode | JSX.Element;
		value?: any;
		form?: any;
		error?: string | boolean;
		checked?: boolean;
	},
	formProps?: {}
) => {
	let propsForm = {
		name,
		onChange,
		checked,
		onBlur,
		label,
		value,
		error
	};

	if (form) {
		const error = form.errors[name];
		propsForm.checked = form.values[name] === value;
		propsForm.value = form.values[name];
		propsForm.error = form.touched[name] && Boolean(error) ? error : "";
		propsForm.onChange = form.handleChange;
		propsForm.onBlur = form.handleBlur;

		if (formProps) {
			propsForm = { ...propsForm, ...formProps };
		}
	}

	return propsForm;
};

export const getPropsInput = <
	T extends { form?: any; validateField?: boolean }
>(
	props: T
): Omit<T, "form" | "validateField"> => {
	const { form, validateField, ...rest } = props;
	return rest;
};
