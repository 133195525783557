import ReactGA from "react-ga4";

import { removeEmptyValues } from "app/helper";

import { isDev } from "../isDev";

import { ANALYTICS_CATEGORY } from "./analyticsTracker.constants";
import COMMON_EVENTS from "./categories/common";
import { prepareActions } from "./prepareActions";

interface IEventTrackProps {
	category: string;
	action: string;
	label: string;
	value?: string;
	nonInteraction?: boolean;
}

export const eventTrack = ({
	category,
	action,
	label,
	value,
	nonInteraction = false
}: IEventTrackProps) => {
	const options = removeEmptyValues({
		category,
		action,
		label,
		value,
		nonInteraction
	});
	if (isDev) {
		console.table({
			TRACK: "EVENT",
			_____: "________________________",
			...options
		});
	}

	if (!isDev && category && action) {
		ReactGA.event(options);
	}
};

export const CommonEvents = prepareActions(
	ANALYTICS_CATEGORY.common,
	COMMON_EVENTS
);
