export const isDev = process.env.NODE_ENV === "development";
window.isDev = isDev;

export const isDevelopmentEnv =
	window.location.hostname === "newtest.signum.website";
window.isDevelopmentEnv = isDevelopmentEnv;

declare global {
	interface Window {
		isDev?: any;
		isDevelopmentEnv?: any;
	}
}
