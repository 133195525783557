import { AxiosResponse } from "axios";

import { globalHandlerError } from "app/helper/handlerErrors";
import { ApiUrls } from "app/api/apiUrls";
import { API } from "app/api";

import { IPerson, IResponsePersonDetails } from "../types";

export const getPersonDetails = async (
	id: string,
	companyId: string,
	personUuid: string,
	cb?: React.Dispatch<React.SetStateAction<IPerson>>
): Promise<IResponsePersonDetails | IPerson> => {
	try {
		if (!id) return;
		const response: AxiosResponse<IResponsePersonDetails> = await API.get(
			ApiUrls.account.leadObservation.companiesSets.companies.person.details(
				id,
				companyId,
				personUuid
			)
		);
		if (cb) {
			cb(response?.data?.person);
		}

		return response.data;
	} catch (err) {
		globalHandlerError(err);
	}
};
