import React, { useState } from "react";

import { useSelector } from "react-redux";

import { ProspectsUpload } from "app/components/Tracking/TrackingNewLists";
import { Nullable } from "app/types";
import {
	IMatchedData,
	TypeProspectData,
	TypeRequestFile
} from "app/components/Tracking/TrackingNewLists/ProspectsUpload/type";
import { configSelect } from "app/store/select";

import PriceStep from "../PriceStep";
import { IPriceData } from "../PriceStep/types";
import { TAddProspects } from "../types";

import { PropsTrackingAddNewListFlow } from "./types";

interface Props extends PropsTrackingAddNewListFlow {
	step: number;
	withNameField?: boolean;
	onCancel: () => void;
	submitBtnContent?: string;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	prospects?: number;
	onSubmit: (data: TAddProspects) => void;
}

const TrackingAddProspectsStepper: React.FC<Props> = ({
	fetchMatchesColumns,
	onCancel,
	withNameField = true,
	step,
	setStep,
	onSubmit
}) => {
	const {
		user: { is_created_by_admin }
	} = useSelector(configSelect.selectUserDetails);
	const [firstStepData, setFirstStepData] = useState<Nullable<TypeProspectData>>(
		null
	);
	const [lastStepData, setLastStepData] = useState<Nullable<IPriceData>>();

	const onNextStep = (nextStep?: number) => {
		if (typeof nextStep === "number") {
			setStep(nextStep);
		} else {
			setStep(state => state + 1);
		}
	};

	const onPrev = (nextStep?: number) => {
		if (typeof nextStep === "number") {
			setStep(nextStep);
		} else {
			setStep(state => state - 1);
		}
	};

	const onSubmitFile = async (
		values: TypeRequestFile,
		file: File[],
		matched: IMatchedData
	) => {
		try {
			setFirstStepData({
				columns: values.columns,
				name: values.name,
				file,
				base64File: values.file,
				file_id: matched?.file_id
			});
			if (matched.price) {
				const {
					next_people_count,
					next_tariff_plan,
					is_change_tariff_plan
				} = matched.price;

				setLastStepData({
					newProspects: matched.matched_rows_count,
					totalProspects: next_people_count,
					currencyCode: next_tariff_plan.currency_code,
					price: next_tariff_plan.price,
					is_change_tariff_plan
				});
			}

			onNextStep();
		} catch (err) {
			console.error(err);
		}
	};

	const onSubmitAddNewProspects = async () => {
		try {
			onSubmit({
				columns: firstStepData.columns,
				file_id: firstStepData.file_id,
				prospects: lastStepData.newProspects
			});
		} catch (err) {
			console.error(err);
		}
	};

	const handleCancel = () => {
		onCancel();
	};

	switch (step) {
		case 0:
			return (
				<ProspectsUpload
					withNameField={withNameField}
					data={firstStepData}
					onSubmit={onSubmitFile}
					onPrev={handleCancel}
					onNext={onNextStep}
					fetchMatchesColumns={fetchMatchesColumns}
					title="Prospects uploading"
					subTitle="Upload a CSV sheet that will contain the following data: linkedin URLs and company websites of prospects you want to track"
					prevBtnContent="Cancel"
				/>
			);
		case 1:
			return (
				<PriceStep
					submitBtnContent="Add new prospects"
					onSubmit={onSubmitAddNewProspects}
					data={lastStepData}
					onPrev={onPrev}
					showTariffPlan={!is_created_by_admin}
				/>
			);
		default:
			return null;
	}
};

export default TrackingAddProspectsStepper;
