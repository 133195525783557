import React from "react";

import Typography from "app/uikit-V2/Typography";
import Button from "app/uikit-V2/Button";

import styles from "./style.module.scss";
import { IconLock } from "./Icons";

interface Props {
	title: string;
	link?: string;
}

const ComingSoon: React.FC<Props> = ({ title, link }) => (
	<div className={styles.box}>
		<Typography marginBottom="30px" component="h3">
			{title}
		</Typography>
		<div className={styles.body}>
			<IconLock />
			<Typography>Unavailable for your plan</Typography>
			{!!link && (
				<Button target="_blank" rel="noreferrer" size="small" href={link}>
					Schedule a call to upgrade
				</Button>
			)}
		</div>
	</div>
);

export default ComingSoon;
