import { useMemo, useState } from "react";

import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import queryString from "query-string";

import Modal from "app/components/modal";
import Button from "app/uikit-V2/Button";
import FormField from "app/uikit-V2/Form/FormField";
import Autocomplete from "app/uikit/autocomplete";
import { API } from "app/api";
import { ApiUrls } from "app/api/apiUrls";
import toastify from "app/utils/toastify";
import PATHS from "PATHS";

import styles from "./style.module.scss";
import validationSchema from "./validationSchema";

interface Props {
	open: boolean;
	onClose: () => void;
	companiesList?: { name: string; id: string }[];
	details?: {
		company_name?: string;
		website_url?: string;
		position_name?: string;
	};
	ids: {
		trackingId: string;
		companyId: string;
		personId: string;
		userId?: string;
	};
	onSubmit?: (values: IInitialValues) => any;
}

export interface IInitialValues {
	companies_set_id: Record<string, string> | string;
	company_name: string;
	website_url: string;
	position_name: string;
}

const ContinueTrackingModal: React.FC<Props> = ({
	ids,
	details,
	companiesList,
	open,
	onClose,
	onSubmit
}) => {
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const { trackingId, companyId, personId, userId } = ids;

	const handleSubmit = async (values: IInitialValues) => {
		setIsLoading(true);
		try {
			let response: any = {};
			if (onSubmit) {
				response = await onSubmit(values);
			} else {
				response = await API.put(
					ApiUrls.account.leadObservation.companiesSets.companies.person.move(
						trackingId,
						companyId,
						personId
					),
					{
						...values,
						companies_set_id: Number((values.companies_set_id as any).id)
					}
				);
			}

			const { new_companies_set_id, new_company_id, new_person_id } =
				response?.data || {};

			const redirectLink = userId
				? PATHS.adminPanel.userLeadsPersonDetails.url(
						userId,
						new_companies_set_id,
						new_company_id,
						new_person_id
				  )
				: PATHS.cabinet.tracking.company.person.url(
						new_companies_set_id,
						new_company_id,
						new_person_id
				  );

			history.replace(
				queryString.stringifyUrl({
					url: redirectLink,
					query: {
						"prev-tracking-list": trackingId
					}
				})
			);

			toastify.success(response?.data?.message || response?.data?.messages);
			onClose();
		} catch (err) {
			console.log(err);
		} finally {
			setIsLoading(false);
		}
	};

	const initialCompany = useMemo(
		() => companiesList?.find(item => Number(item.id) === Number(trackingId)),
		[companiesList, trackingId]
	);

	const formik = useFormik<IInitialValues>({
		enableReinitialize: true,
		initialValues: {
			companies_set_id: initialCompany || "",
			company_name: "",
			website_url: "",
			position_name: "",
			...details
		},
		validationSchema: validationSchema,
		onSubmit: handleSubmit
	});

	return (
		<Modal
			showCloseIcon
			title="Set new tracking parameters for the person"
			onClose={onClose}
			resetForm={formik.resetForm}
			open={open}
		>
			<form onSubmit={formik.handleSubmit}>
				<Autocomplete
					fullWidth
					name="companies_set_id"
					form={formik}
					label="Which sheet to move the person to"
					required
					options={companiesList}
				/>
				<FormField
					fullWidth
					name="company_name"
					form={formik}
					label="Set a new company name"
					required
				/>
				<FormField
					fullWidth
					name="website_url"
					form={formik}
					label="Set the website for the new company"
					required
				/>
				<FormField
					fullWidth
					name="position_name"
					form={formik}
					label="Specify the person's position"
					required
				/>

				<div className={styles.footer}>
					<Button loading={isLoading} size="medium" type="submit">
						Apply settings
					</Button>
				</div>
			</form>
		</Modal>
	);
};

export default ContinueTrackingModal;
