export const IconTime = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
	>
		<path
			d="M9.99167 1.66666C5.39167 1.66666 1.66667 5.39999 1.66667 9.99999C1.66667 14.6 5.39167 18.3333 9.99167 18.3333C14.6 18.3333 18.3333 14.6 18.3333 9.99999C18.3333 5.39999 14.6 1.66666 9.99167 1.66666ZM10 16.6667C6.31667 16.6667 3.33334 13.6833 3.33334 9.99999C3.33334 6.31666 6.31667 3.33332 10 3.33332C13.6833 3.33332 16.6667 6.31666 16.6667 9.99999C16.6667 13.6833 13.6833 16.6667 10 16.6667ZM9.81667 5.83332H9.76667C9.43334 5.83332 9.16667 6.09999 9.16667 6.43332V10.3667C9.16667 10.6583 9.31667 10.9333 9.575 11.0833L13.0333 13.1583C13.3167 13.325 13.6833 13.2417 13.85 12.9583C14.025 12.675 13.9333 12.3 13.6417 12.1333L10.4167 10.2167V6.43332C10.4167 6.09999 10.15 5.83332 9.81667 5.83332Z"
			fill="#D4D7DF"
		/>
	</svg>
);

export const IconLink = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="13"
		height="13"
		viewBox="0 0 13 13"
		fill="none"
	>
		<g clip-path="url(#clip0_1_935)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5 0H2H0V2V11V13H2H11H13V11V8H11V11H2V2H5V0Z"
				fill="#D4D7DF"
			/>
			<path d="M13 0L13 5L8 7.17929e-08L13 0Z" fill="#D4D7DF" />
			<path d="M11 2L5 8" stroke="#D4D7DF" stroke-width="2" />
		</g>
		<defs>
			<clipPath id="clip0_1_935">
				<rect width="13" height="13" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
