// @ts-nocheck
import { takeEvery, put, all, call } from "redux-saga/effects";

import toastify from "app/utils/toastify";

import { errorsHandler } from "../helper";

import services from "./services";
import types from "./types";
import actions from "./actions";

function* supportRequest({ data, cb, actionName }: any) {
	try {
		const response = yield call(services.fetchSupportRequest, data);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.fetchSupportSuccess(actionName));
			toastify.success(response?.data?.message);
			cb && cb();
		}
	} catch (error) {
		yield errorsHandler(actions.fetchSupportFailure, error, actionName);
	}
}

export default function* root() {
	yield all([takeEvery(types.UPLOAD_SUPPORT_REQUEST, supportRequest)]);
}
