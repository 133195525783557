import { Link } from "react-router-dom";

import { Edit, Download } from "app/icons";
import PATHS from "PATHS";

const menuHeader = (logOut: any) => {
	return [
		{
			key: "account",
			id: "account",
			render: () => (
				<Link to={PATHS.account.profile} className="with-icon link-in-menu">
					<Edit /> Account
				</Link>
			)
		},
		{
			key: "Log-out",
			id: "Log-out",
			onChange: logOut,
			render: () => (
				<div className="with-icon">
					<Download /> Log out
				</div>
			)
		}
	];
};

export default menuHeader;
