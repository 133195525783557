class Storage {
	_token: string;
	_info: string;
	constructor() {
		this._token = "token";
		this._info = "info";
	}
	getAccessToken(): string {
		return this._getItem(this._token);
	}
	getInfo(): {} {
		return this._getItem(this._info);
	}
	setInfo(data: any) {
		this._setItem(this._info, data);
	}
	setToken(data: any) {
		this._setItem(this._token, data);
	}
	clear() {
		localStorage.removeItem(this._info);
		localStorage.removeItem(this._token);
	}
	_getItem(name: any) {
		try {
			let itemLocal = localStorage.getItem(name);
			if (name !== this._token) {
				itemLocal = JSON.parse(localStorage.getItem(name));
			}
			return itemLocal;
		} catch (e) {
			return null;
		}
	}
	_setItem(name: string, data: any) {
		if (name === this._token) {
			localStorage.setItem(name, data);
		} else {
			localStorage.setItem(name, JSON.stringify(data));
		}
	}
}
export default new Storage();
