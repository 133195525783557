import { makeStyles } from "@material-ui/core/styles";

import { PALETTE } from "app/theme";

const useStyles = makeStyles({
	input: {
		border: `1px solid ${PALETTE.athensGray}`,
		borderRadius: 6,
		minHeight: 50,
		transition: "0.2s",
		color: PALETTE.pickledBluewood,
		fontSize: "16px",
		width: "auto",

		"&.Mui-focused": {
			borderColor: PALETTE.mischka,
			border: `1px solid ${PALETTE.pickledBluewood}`
		},
		"&.MuiInput-underline:before,&.MuiInput-underline:after": {
			content: "none"
		},
		"& .MuiSelect-nativeInput": {
			padding: 0
		},
		"& .MuiSelect-root": {
			padding: "12px 40px 12px 12px"
		},
		"& [data-info]": {
			display: "none !important"
		}
	},
	error: {
		borderColor: PALETTE.error
	},
	iconElem: {
		width: "25px",
		textAlign: "center",
		lineHeight: 0,
		marginRight: "4px"
	},
	menuItem: {
		display: "flex",
		width: "100%",
		alignItems: "center",
		justifyContent: "space-between"
	},
	menuItem__inner: {
		maxWidth: "100%",

		display: "flex",
		alignItems: "center",
		flexGrow: 1
	},
	menuItem__text: {
		maxWidth: "100%",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden"
	},

	icon: {
		transition: "0.3s",
		position: "absolute",
		top: "50%",
		right: "15px",
		width: "18px",
		height: "18px",
		transform: "translateY(-50%) scaleY(1)",
		pointerEvents: "none"
	},

	icon_rotate: {
		transform: "translateY(-50%) scaleY(-1)"
	},
	placeholder: {
		color: PALETTE.black,
		opacity: 0.5
	}
});

export default useStyles;
