import { IPropsFormComponent } from "./FormComponents";

export const getFomComponentProps = ({
	fullWidth,
	wrapperClassName,
	name,
	info,
	labelContent,
	infoPlacement,
	required,
	label,
	labelClassName,
	error
}: IPropsFormComponent) => ({
	fullWidth,
	wrapperClassName,
	name,
	info,
	labelContent,
	infoPlacement,
	required,
	label,
	labelClassName,
	error
});
