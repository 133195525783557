import api from "app/api";

const API = api();

const getHandBooks = async () => {
	try {
		return await API.get("/handbooks/all");
	} catch (error) {
		return error;
	}
};

export default {
	getHandBooks
};
