import { ChangeEvent, memo } from "react";

import isEqual from "react-fast-compare";
import cn from "classnames/bind";
import { FormControlLabel, Box } from "@material-ui/core";

import { FormError } from "app/uikit-V2/Form/FormComponents";
import Info from "app/components/info";

import Radio from "./RadioBase";
import { useStylesRadioWithLabel } from "./styles";
import { IRadioProps } from "./types";

const RadioWithLabel: React.FC<IRadioProps> = ({
	label,
	error,
	labelClassName,
	labelPlacement,
	wrapperClassName,
	info,
	infoPlacement,
	noGutters,
	onChange,
	identifier,
	value,
	...rest
}) => {
	const classes = useStylesRadioWithLabel();
	const isError: boolean = !!error;

	return (
		<div
			className={cn(classes.wrapper, wrapperClassName, {
				[classes.noGutters]: noGutters,
				checked: rest.checked
			})}
		>
			<div className={classes.container}>
				<FormControlLabel
					value={value}
					className={cn(classes.root, labelClassName, {
						[classes.error]: isError
					})}
					labelPlacement={labelPlacement}
					control={<Radio classNameIcon={cn({ [classes.errorRadio]: isError })} />}
					label={
						<Box component="span" fontSize={12}>
							{label}
						</Box>
					}
					onChange={e => {
						if (onChange) {
							const event = e as ChangeEvent<HTMLInputElement>;
							onChange(event, event.target.checked, identifier);
						}
					}}
					{...rest}
				/>

				{!!info && <Info info={info} placement={infoPlacement} />}
			</div>
			<FormError error={error} />
		</div>
	);
};

RadioWithLabel.defaultProps = {
	error: "",
	label: "",
	labelPlacement: "end"
};

export default memo(RadioWithLabel, isEqual);
