// @ts-nocheck
import { useEffect, useState } from "react";

import cl from "classnames";
import { Box, Switch as MuiSwitch } from "@material-ui/core";

import { FormControl, InputLabel } from "../form-components";

import useStyles from "./style";

const getStyleByLabelPlacement = (placement = "top") => {
	switch (placement) {
		case "right":
			return {
				display: "flex",
				"flex-direction": "row-reverse",
				gap: "10px"
			};
		case "left":
			return {
				display: "flex",
				gap: "10px"
			};
		case "top":
		default:
			return {};
	}
};

const Switch = ({
	active,
	onChange: handleChange,
	name,
	form,
	info,
	labelContent,
	labelPlacement = "top",
	infoPlacement,
	required,
	label,
	labelClassName,
	wrapperClassName,
	className,
	disabled,
	...rest
}: any) => {
	const [isActive, setIsActive] = useState(false);
	const classes = useStyles();

	useEffect(() => {
		const isActive = form && form.values[name];
		setIsActive(isActive ?? active);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [active, form]);

	const onChange = e => {
		if (form) {
			const checked = e.target.checked;
			return form.setFieldValue(name, checked);
		}
		if (handleChange) {
			handleChange(e);
		}
		if (active === undefined) {
			setIsActive(!isActive);
		}
	};

	return (
		<FormControl className={cl(classes.wrapper, wrapperClassName)}>
			<Box style={{ ...getStyleByLabelPlacement(labelPlacement) }}>
				<InputLabel
					name={name}
					info={info}
					labelContent={labelContent}
					infoPlacement={infoPlacement}
					required={required}
					label={label}
					className={labelClassName}
				/>

				<MuiSwitch
					checked={isActive}
					onChange={onChange}
					className={cl("switch", className, { switch_disabled: disabled })}
					{...rest}
				/>
			</Box>
		</FormControl>
	);
};

export default Switch;
