import { path, host } from "app/api";

export const download = (url: string, fromHost?: string | null) => {
	if (process?.env?.NODE_ENV === "development") {
		console.log(url, "_____||||||_____", "url-export");
	}
	const link = document.createElement("a");
	link.setAttribute("target", "_top");
	if (fromHost !== null) {
		link.href = (fromHost ? host : path) + url;
	} else {
		link.href = url;
	}
	link.click();
};

export default download;
