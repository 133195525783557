// @ts-nocheck

import types from "./types";

const actions = {
	fetchSignInRequest: (credentials, history) => ({
		type: types.FETCH_SIGN_IN_REQUEST,
		credentials,
		history
	}),
	fetchSignInSuccess: data => ({
		type: types.FETCH_SIGN_IN_SUCCESS,
		data
	}),
	fetchSignInFailure: error => ({
		type: types.FETCH_SIGN_IN_FAILURE,
		error
	}),

	fetchSignUpRequest: credentials => ({
		type: types.FETCH_SIGN_UP_REQUEST,
		credentials
	}),
	fetchSignUpSuccess: data => ({
		type: types.FETCH_SIGN_UP_SUCCESS,
		data
	}),
	fetchSignUpFailure: error => ({
		type: types.FETCH_SIGN_UP_FAILURE,
		error
	}),

	fetchPasswordRecoveryRequest: (credentials, history, resetForm) => ({
		type: types.FETCH_PASSWORD_RECOVERY_REQUEST,
		credentials,
		history,
		resetForm
	}),
	fetchPasswordRecoverySuccess: data => ({
		type: types.FETCH_PASSWORD_RECOVERY_SUCCESS,
		data
	}),
	fetchPasswordRecoveryFailure: error => ({
		type: types.FETCH_PASSWORD_RECOVERY_FAILURE,
		error
	}),

	fetchNewPasswordRequest: credentials => ({
		type: types.FETCH_NEW_PASSWORD_REQUEST,
		credentials
	}),
	fetchNewPasswordSuccess: data => ({
		type: types.FETCH_NEW_PASSWORD_SUCCESS,
		data
	}),
	fetchNewPasswordFailure: error => ({
		type: types.FETCH_NEW_PASSWORD_FAILURE,
		error
	}),

	fetchResetActionState: () => ({
		type: types.FETCH_RESET_ACTION_STATE
	}),

	fetchLogOutRequest: (withoutRequest?: boolean) => ({
		type: types.FETCH_LOGOUT_REQUEST,
		withoutRequest
	}),
	fetchLogOutSuccess: () => ({
		type: types.FETCH_LOGOUT_SUCCESS
	}),
	fetchLogOutFailure: () => ({
		type: types.FETCH_LOGOUT_FAILURE
	})
};
export default actions;
