import { toast, ToastPosition } from "react-toastify";

import CustomNotif from "../components/notification";

const autoCloseTimeout = 5000;

class Toastify {
	success = (
		title: string,
		txt?: string,
		autoClose: number = autoCloseTimeout,
		delay: number = 0,
		position: ToastPosition = toast.POSITION.TOP_RIGHT
	) => {
		toast.success(
			<CustomNotif variant="success" title={title}>
				{txt}
			</CustomNotif>,
			{
				autoClose,
				position,
				delay
			}
		);
	};

	error = (
		title: string,
		txt?: string,
		autoClose: number = autoCloseTimeout,
		delay: number = 0,
		position: ToastPosition = toast.POSITION.TOP_RIGHT
	) => {
		toast.error(
			<CustomNotif variant="error" title={title}>
				{txt}
			</CustomNotif>,
			{
				autoClose,
				position,
				delay
			}
		);
	};

	warn = (
		title: string,
		txt?: string,
		autoClose: number = autoCloseTimeout,
		delay: number = 0,
		position: ToastPosition = toast.POSITION.TOP_RIGHT
	) => {
		toast.warn(
			<CustomNotif variant="warn" title={title}>
				{txt}
			</CustomNotif>,
			{
				autoClose,
				position,
				delay
			}
		);
	};

	info = (
		title: string,
		txt?: string,
		autoClose: number = autoCloseTimeout,
		delay: number = 0,
		position: ToastPosition = toast.POSITION.TOP_RIGHT
	) => {
		toast.info(
			<CustomNotif variant="info" title={title}>
				{txt}
			</CustomNotif>,
			{
				autoClose,
				position,
				delay
			}
		);
	};
}

export default new Toastify();
