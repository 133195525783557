import { memo, useEffect, useRef, useState } from "react";

import isEqual from "react-fast-compare";
import cn from "classnames";

import Chip from "app/uikit-V2/Chip/Chip";

import { FormControl, FormError, InputLabel } from "../FormComponents";

import useStyles from "./style";
import { ISelectChipProps } from "./types";

const ChipSelect: React.FC<ISelectChipProps> = ({
	fullWidth,
	name,
	error,
	info,
	labelContent,
	infoPlacement,
	required,
	label,
	labelClassName,
	classNameField,
	value,
	onChange,
	autoFocus,
	placeholder
}) => {
	const ref = useRef(null);
	const classes = useStyles({ fullWidth });
	const [inputValue, setInputValue] = useState("");
	const [values, setValues] = useState<string[]>([]);

	useEffect(() => {
		setValues(value || []);
	}, [value]);

	const onDelete = (index: string | number) => {
		const newValue = [...values];
		newValue.splice(Number(index), 1);

		if (onChange) {
			onChange(newValue, name);
		} else {
			setValues(newValue);
		}
	};

	const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		const target = e.target;
		const value = target.value;
		setInputValue(value);
	};

	const handleChange = () => {
		if (inputValue?.trim() === "") return;
		const newValue = [...values, inputValue];
		if (onChange) {
			onChange(newValue, name);
		} else {
			setValues(newValue);
		}
		setInputValue("");
		return;
	};

	const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.keyCode !== 13) return;
		e.preventDefault();
		handleChange();
	};

	const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		e.preventDefault();
		handleChange();
	};

	const inputValueLen = inputValue?.length;

	return (
		<FormControl
			fullWidth={fullWidth}
			onClick={() => {
				ref?.current?.focus();
			}}
		>
			<InputLabel
				name={name}
				info={info}
				labelContent={labelContent}
				infoPlacement={infoPlacement}
				required={required}
				label={label}
				className={labelClassName}
			/>
			<div
				className={cn(classes.block, classNameField, { [classes.error]: !!error })}
			>
				{values?.map((el, i) => (
					<div key={el + i} className={classes.chip}>
						<Chip id={i} label={el} onDelete={onDelete} />
					</div>
				))}

				<input
					ref={ref}
					style={{
						maxWidth: "100%",
						width: inputValueLen ? `${(inputValueLen + 1) * 10}px` : "50px"
					}}
					onBlur={onBlur}
					onKeyDown={onKeyDown}
					onChange={onChangeInput}
					className={classes.input_wrapper}
					value={inputValue}
					name={name}
					id={name}
					autoFocus={autoFocus}
					type="text"
					placeholder={!values?.length && placeholder}
				/>
			</div>

			<FormError error={error} />
		</FormControl>
	);
};
export default memo(ChipSelect, isEqual);
