import { WarningIcon, ErrorIcon } from "app/icons";

export const getIcon = (type?: string): JSX.Element => {
	switch (type) {
		case "error":
			return <ErrorIcon />;
		case "warning":
		default:
			return <WarningIcon />;
	}
};

export const getAlertClass = (type?: string): string => {
	switch (type) {
		case "error":
			return "alert--error";
		default:
			return "";
	}
};
