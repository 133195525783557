// @ts-nocheck
import Select from "./select";
export default function SelectBase(props: any) {
	const { name, form, handleChange, showError, validateField, ...rest } = props;
	if (form) {
		const onHandleChange = async (value, e, eLement) => {
			if (handleChange) {
				await handleChange(value, name, e, eLement);
			} else {
				await form.setFieldValue(name, value);
			}
			if (validateField) {
				await form.validateField(name);
			}
			form.setStatus({
				...form.status,
				dirtyFields: {
					...form.status?.dirtyFields,
					[name]: true
				}
			});
		};
		return (
			<Select
				name={name}
				value={form.values[name]}
				onChange={onHandleChange}
				error={(form.touched[name] || showError) && Boolean(form.errors[name])}
				errorText={(form.touched[name] || showError) && form.errors[name]}
				{...rest}
			/>
		);
	}

	return <Select name={name || ""} {...rest} />;
}

SelectBase.defaultProps = {
	list: [],
	label: ""
};
