import { memo } from "react";

import isEqual from "react-fast-compare";

import { IChildren } from "app/types";

import style from "./style.module.scss";

const ContentConfiguration: React.FC<{ children?: IChildren }> = ({
	children
}) => (
	<main className={style.main}>
		<div className={style.container}>{children}</div>
	</main>
);

export default memo(ContentConfiguration, isEqual);
