import React, { useState } from "react";

import { Box } from "@material-ui/core";

import Button from "app/uikit-V2/Button";
import Typography from "app/uikit-V2/Typography";

import styles from "./style.module.scss";
import { ISubscriptionPrice } from "./type";
import { IconCheck } from "./Icon";
import BinanceImg from "./assets/binance.png";
import PavilionImg from "./assets/pavilion.png";
import AsdImg from "./assets/asd.png";
import DriftImg from "./assets/drift.png";
import ReviewsImg from "./assets/reviews.png";

interface Props {
	data: ISubscriptionPrice;
	onSubmit: () => Promise<void>;
	title?: string;
	subTitle?: string;
}

const PriceListReports: React.FC<Props> = ({
	data,
	onSubmit,
	title = "Add a payment method",
	subTitle = "Link your card to get started"
}) => {
	const [loadingSession, setLoadingSession] = useState(false);

	const handleSubmit = async () => {
		try {
			setLoadingSession(true);
			await onSubmit();
		} catch (err) {
			console.log(err);
		} finally {
			setLoadingSession(false);
		}
	};

	return (
		<section className={styles.section}>
			<div className={styles.section__item}>
				<div>
					<h1 className={styles.title}>{title} </h1>
					{subTitle && <p className={styles.text}>{subTitle}</p>}

					<div className={styles.body}>
						<div className={styles.item}>
							<p className={styles.item__text}>What you will get</p>
							<div className={styles.item__info}>
								<ul className={styles.item__list}>
									<li>Weekly trend reports</li>
									<li>Access to trend database</li>
									<li>
										Tracking signals and insights for target companies and individuals
									</li>
								</ul>
							</div>
						</div>
						<div className={styles.body__wrap_btn}>
							<Button loading={loadingSession} onClick={handleSubmit}>
								Try it free
							</Button>
						</div>

						<ul className={styles.body__list}>
							<li>
								<IconCheck />
								<div>
									After your trial, you will be charged{" "}
									<span>${data?.tariff_plan?.price || 0} per month</span>
								</div>
							</li>
							<li>
								<IconCheck />
								<div>Cancel anytime</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className={styles.section__item}>
				<Box textAlign="center">
					<Typography
						style={{
							fontWeight: 500,
							fontSize: "22px"
						}}
					>
						Trusted by
					</Typography>
					<Box
						display="flex"
						alignItems="center"
						gridGap="40px"
						justifyContent="center"
						flexWrap="wrap"
						marginTop={2}
					>
						<div>
							<img width={165} src={BinanceImg} alt="" />
						</div>

						<div>
							<img width={158} src={PavilionImg} alt="" />
						</div>

						<div>
							<img width={39} src={AsdImg} alt="" />
						</div>

						<div>
							<img width={99} src={DriftImg} alt="" />
						</div>
					</Box>

					<Box marginTop={5}>
						<img width={622} src={ReviewsImg} alt="" />
					</Box>
				</Box>
			</div>
		</section>
	);
};

export default PriceListReports;
