// @ts-nocheck

import Loadable from "app/utils/Loadable";
import Layout from "app/components/includes/layout";

const ErrorSendPwEmail = Loadable({
	loader: () => import("./ErrorSendPwResetEmail")
});

const PageErrorSendPwEmail = () => {
	return (
		<Layout>
			<ErrorSendPwEmail />
		</Layout>
	);
};

export default PageErrorSendPwEmail;
