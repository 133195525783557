import React from "react";

import Loadable from "app/utils/Loadable";
import UserLayout from "app/components/includes/UserLayout";
import Layout from "app/components/includes/layout";
import localStore from "app/helper/local-store";

const PageNotFound = Loadable({
	loader: () => import("./404")
});

const Page404: React.FC = () => {
	const token = localStore.getAccessToken();
	const Wrapper = (token && UserLayout) || Layout;
	return (
		<Wrapper>
			<PageNotFound />
		</Wrapper>
	);
};

export default Page404;
