export default {
	GET_TRENDS_REQUEST: "GET_TRENDS_REQUEST",
	GET_TRENDS_SUCCESS: "GET_TRENDS_SUCCESS",
	GET_TRENDS_FAILURE: "GET_TRENDS_FAILURE",

	GET_TREND_REQUEST: "GET_TREND_REQUEST",
	GET_TREND_SUCCESS: "GET_TREND_SUCCESS",
	GET_TREND_FAILURE: "GET_TREND_FAILURE",

	FETCH_PAGINATION_TRENDS_REQUEST: "FETCH_PAGINATION_TRENDS_REQUEST",
	FETCH_PAGINATION_TRENDS_SUCCESS: "FETCH_PAGINATION_TRENDS_SUCCESS",

	FETCH_FILTER_TRENDS_REQUEST: "FETCH_FILTER_TRENDS_REQUEST",
	FETCH_FILTER_TRENDS_SUCCESS: "FETCH_FILTER_TRENDS_SUCCESS",

	RESET_TRENDS: "RESET_TRENDS",
	RESET_TRENDS_DETAILS: "RESET_TRENDS_DETAILS",

	FETCH_TREND_CREATE_REQUEST: "FETCH_TREND_CREATE_REQUEST",
	FETCH_TREND_CREATE_SUCCESS: "FETCH_TREND_CREATE_SUCCESS",
	FETCH_TREND_CREATE_FAILURE: "FETCH_TREND_CREATE_FAILURE",

	FETCH_TREND_EDIT_REQUEST: "FETCH_TREND_EDIT_REQUEST",
	FETCH_TREND_EDIT_SUCCESS: "FETCH_TREND_EDIT_SUCCESS",
	FETCH_TREND_EDIT_FAILURE: "FETCH_TREND_EDIT_FAILURE",

	FETCH_TREND_DELETE_REQUEST: "FETCH_TREND_DELETE_REQUEST",
	FETCH_TREND_DELETE_SUCCESS: "FETCH_TREND_DELETE_SUCCESS",
	FETCH_TREND_DELETE_FAILURE: "FETCH_TREND_DELETE_FAILURE"
};
