export default {
	en: {
		required: "This field is required",
		requiredFile: "This field is required",
		minLength: "The number of characters must be at least #{number}",
		maxLength: "The number of characters must be no more than #{number}",
		email: "Please enter correct email",
		passwordLength: "Password should be of minimum #{number} characters length",
		password:
			"Password must contain at least #{number} characters and at least one capital letter",
		passwordMatch: "Passwords must match",
		leastOneRadio: "A radio option is required",
		fileLarge: "File size must be less than",
		webSite: "The URL you entered doesn't direct to a website",
		webSiteLength: "The URL you entered doesn't direct to a website",

		serverNotWorking: "Sorry, the server is temporarily unavailable.",
		deleteIntegrationAccount: "An error has occurred, try again later",
		downLoadFile: "Download will start soon, please wait.",
		selectCreative: "Select a creative",

		minAge: '"Minimum age" must be lower or equal to "Maximum age".',
		maxAge: '"Maximum age" must be higher or equal to "Minimum age".',

		minNumberEmployees:
			'"Number of Employees (min)" must be lower or equal to "Number of Employees (max)".',
		maxNumberEmployees:
			'"Number of Employees (max)" must be higher or equal to "Number of Employees (min)".',
		selectTrigger: "Please select at least one trigger."
	}
};
