export default {
	GET_STATISTIC_REGISTRATIONS_REQUEST: "GET_STATISTIC_REGISTRATIONS_REQUEST",
	GET_STATISTIC_REGISTRATIONS_SUCCESS: "GET_STATISTIC_REGISTRATIONS_SUCCESS",
	GET_STATISTIC_REGISTRATIONS_FAILURE: "GET_STATISTIC_REGISTRATIONS_FAILURE",

	GET_STATISTIC_USER_REGISTRATIONS_REQUEST:
		"GET_STATISTIC_USER_REGISTRATIONS_REQUEST",
	GET_STATISTIC_USER_REGISTRATIONS_SUCCESS:
		"GET_STATISTIC_USER_REGISTRATIONS_SUCCESS",
	GET_STATISTIC_USER_REGISTRATIONS_FAILURE:
		"GET_STATISTIC_USER_REGISTRATIONS_FAILURE",

	GET_STATISTIC_USER_TRIALS_REQUEST: "GET_STATISTIC_USER_TRIALS_REQUEST",
	GET_STATISTIC_USER_TRIALS_SUCCESS: "GET_STATISTIC_USER_TRIALS_SUCCESS",
	GET_STATISTIC_USER_TRIALS_FAILURE: "GET_STATISTIC_USER_TRIALS_FAILURE",

	GET_STATISTIC_USER_ACTIVE_SUBSCRIPTIONS_REQUEST:
		"GET_STATISTIC_USER_ACTIVE_SUBSCRIPTIONS_REQUEST",
	GET_STATISTIC_USER_ACTIVE_SUBSCRIPTIONS_SUCCESS:
		"GET_STATISTIC_USER_ACTIVE_SUBSCRIPTIONS_SUCCESS",
	GET_STATISTIC_USER_ACTIVE_SUBSCRIPTIONS_FAILURE:
		"GET_STATISTIC_USER_ACTIVE_SUBSCRIPTIONS_FAILURE",

	RESET_STATISTICS: "RESET_STATISTICS"
};
