import Loadable from "app/utils/Loadable";
import Layout from "app/components/includes/layout";

const EmailVerificationExpired = Loadable({
	loader: () => import("./EmailVerificationExpired")
});

const PageEmailVerificationExpired = () => {
	return (
		<Layout>
			<EmailVerificationExpired />
		</Layout>
	);
};

export default PageEmailVerificationExpired;
