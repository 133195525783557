// @ts-nocheck

import { Input, FormHelperText, InputAdornment } from "@material-ui/core";
import classNames from "classnames";

import Alert from "app/uikit/alert";

import { FormControl, InputLabel, FormError } from "../form-components";

import useStyles from "./style";

const InpurDefaul = (props: any) => {
	const {
		className,
		wrapperClassName,
		labelClassName,
		info,
		label,
		type = "text",
		variant,
		form,
		error,
		errorText,
		infoPlacement,
		name,
		icon,
		iconClickable,
		iconPosition,
		currencyAdornment,
		adornmentActions,
		fullWidth,
		required,
		warning,
		labelContent,
		reference,
		...rest
	} = props;
	const classes = useStyles();

	return (
		<FormControl
			variant={variant}
			fullWidth={fullWidth}
			className={wrapperClassName}
		>
			<InputLabel
				name={name}
				info={info}
				labelContent={labelContent}
				infoPlacement={infoPlacement}
				required={required}
				label={label}
				className={labelClassName}
			/>

			<Input
				id={name}
				type={type}
				name={name}
				className={classNames(
					classes.input,
					error && classes.input_error,
					className
				)}
				ref={reference}
				endAdornment={
					iconPosition === "end" && (
						<InputAdornment
							className={classNames(
								classes.iconStyles,
								currencyAdornment ? classes.currencyAdornment : "",
								{
									[classes.iconClickable]: iconClickable
								}
							)}
							position={iconPosition}
							{...adornmentActions}
						>
							{icon}
						</InputAdornment>
					)
				}
				startAdornment={
					iconPosition === "start" && (
						<InputAdornment
							{...adornmentActions}
							className={classNames(classes.iconStyles, {
								[classes.iconClickable]: iconClickable
							})}
							position={iconPosition}
						>
							{icon}
						</InputAdornment>
					)
				}
				{...rest}
			/>

			<FormError error={error} text={errorText} />
			{!(error && Boolean(error)) && warning && (
				<FormHelperText error={true} className={classes.warning} component="div">
					{!(error && Boolean(error)) && warning && (
						<>
							<Alert type="warning" text={warning} />
						</>
					)}
				</FormHelperText>
			)}
		</FormControl>
	);
};
export default InpurDefaul;
