import { InputLabel as InputLabelBase } from "@material-ui/core";
import cn from "classnames";

import Info from "app/components/info";
import { ITooltipPlacement } from "app/uikit-V2/types";

import useStyles from "./style";

export interface IPropsInputLabel {
	label?: string | React.ReactNode | JSX.Element;
	labelContent?: string | React.ReactNode | JSX.Element;
	required?: boolean;
	name?: string;
	info?: string;
	className?: string;
	fullWidth?: boolean;
	infoPlacement?: ITooltipPlacement;
}

const InputLabel: React.FC<IPropsInputLabel> = ({
	label,
	labelContent,
	required,
	name,
	info,
	infoPlacement,
	className,
	fullWidth = false
}) => {
	const classes = useStyles({ fullWidth });
	if (!label) return null;

	return (
		<InputLabelBase
			id={name}
			htmlFor={name}
			className={cn(classes.inputLabel, className)}
		>
			{
				<>
					{label}
					{required && <span className={classes.required}>&nbsp;*</span>}
				</>
			}
			{labelContent}

			{info && <Info info={info} placement={infoPlacement} />}
		</InputLabelBase>
	);
};

export default InputLabel;
