import api from "app/api";
import { ApiUrls } from "app/api/apiUrls";

const API = api();

const getInfo = async () => {
	try {
		return await API.get(ApiUrls.account.user.path);
	} catch (error) {
		return error;
	}
};

const changeInfo = async (data: any) => {
	try {
		return await API.put(ApiUrls.account.user.path, data);
	} catch (error) {
		return error;
	}
};

const createPayment = async (params: Record<string, string>) => {
	try {
		return await API.post("/account/payments/sessions", params);
	} catch (error) {
		return error;
	}
};

const cancelSubscription = async () => {
	try {
		return await API.delete(ApiUrls.account.user.cancelSubscription);
	} catch (error) {
		return error;
	}
};

const fetchAppSumoKey = async (data: { appsumo_licence_key: string }) => {
	try {
		return await API.patch(ApiUrls.account.user.appsumo, data);
	} catch (error) {
		return error;
	}
};

export default {
	getInfo,
	changeInfo,
	createPayment,
	cancelSubscription,
	fetchAppSumoKey
};
