/* eslint-disable import/order */
import { PERMISSIONS } from "app/enum/permissions";
import PATHS from "PATHS";

//auth
import Login from "app/screens/auth/login";
import Registration from "app/screens/auth/registration";
import EmailVerification from "app/screens/auth/emailVerification";
import EmailVerificationExpired from "app/screens/auth/emailVerificationExpired";
import EmailVerificationConfirmed from "app/screens/auth/emailVerificationConfirmed";
import NewPassword from "app/screens/auth/newPassword";
import ErrorSendPwEmail from "app/screens/auth/errorSendPwResetEmail";
import PasswordRecovery from "app/screens/auth/passwordRecovery";
import VerificationCode from "app/screens/auth/verificationCode";
import ErrorServerUnavailable from "app/screens/auth/errorServerUnavailable";

//account
import Profile from "app/screens/account/profile";

//cabinet
//trends
import Report from "app/screens/cabinet/trends/reports";
import ReportDetails from "app/screens/cabinet/trends/reports/TrendDetails";
import TrendsDatabase from "app/screens/cabinet/trends/trendsDatabase";
import TrendsDatabaseDetails from "app/screens/cabinet/trends/trendsDatabase/TrendDatabaseDetails";

//Tracking
import Tracking from "app/screens/cabinet/tracking";
import TrackingDetails from "app/screens/cabinet/tracking/details";
import TrackingEditList from "app/screens/cabinet/tracking/editList";
import TrackingPersonDetails from "app/screens/cabinet/tracking/personDetails";
import TrackingCompanyDetails from "app/screens/cabinet/tracking/companyDetails";
import TrackingAddNewList from "app/screens/cabinet/tracking/addNewList";

//admin
import Employees from "app/screens/admin/employees";
import ScreenUsers from "app/screens/admin/users";
import ProfileUser from "app/screens/admin/users/account-profile";
import AppSettings from "app/screens/admin/settings";
import InfoDashboard from "app/screens/admin/info-dashboard";
import TrendCreate from "app/screens/cabinet/trends/reports/create";
import TrendEdit from "app/screens/cabinet/trends/reports/edit";
import TrendDatabaseCreate from "app/screens/cabinet/trends/trendsDatabase/create";
import TrendDatabaseEdit from "app/screens/cabinet/trends/trendsDatabase/edit";
//users
import LeadsUser from "app/screens/admin/users/account-leads";
import LeadsUserDetails from "app/screens/admin/users/account-leads/TrackingDetails";
import LeadsUserDetailsEdit from "app/screens/admin/users/account-leads/EditList";
import LeadsUserPersonDetails from "app/screens/admin/users/account-leads/TrackingPersonDetails";
import LeadsUserCompanyDetails from "app/screens/admin/users/account-leads/TrackingCompanyDetails";
import LeadsUserAddNewList from "app/screens/admin/users/account-leads/AddNewList";

//Test
import Test from "app/screens/test";

import { IRouter } from "./types";
import { isDev } from "app/utils/isDev";
import { isSubscriptionActive } from "app/hooks/useSubscribe";

const authRouts: IRouter[] = [
	{
		active: true,
		path: PATHS.auth.login,
		component: Login
	},
	{
		active: true,
		path: PATHS.auth.registration,
		component: Registration
	},
	{
		active: true,
		path: PATHS.auth.emailVerification,
		component: EmailVerification
	},

	{
		active: true,
		path: PATHS.auth.emailVerificationExpired,
		component: EmailVerificationExpired
	},
	{
		active: true,
		path: PATHS.auth.emailVerificationConfirmed,
		component: EmailVerificationConfirmed
	},

	{
		active: true,
		path: PATHS.auth.passwordRecovery,
		component: PasswordRecovery
	},
	{
		active: true,
		path: PATHS.auth.verificationCode,
		component: VerificationCode
	},
	{
		active: true,
		path: PATHS.auth.newPassword,
		component: NewPassword
	},
	{
		active: true,
		path: PATHS.auth.errorSendPwEmail,
		component: ErrorSendPwEmail
	},
	{
		active: true,
		path: PATHS.auth.errorServerUnavailable,
		component: ErrorServerUnavailable
	}
];

const cabinetRouts: IRouter[] = [
	{
		active: true,
		isPrivate: true,
		permission: true,
		path: PATHS.account.profile,
		component: Profile
	},
	{
		active: true,
		isPrivate: true,
		permission: true,
		path: PATHS.cabinet.trends.database.path,
		component: TrendsDatabase
	},
	{
		active: true,
		isPrivate: true,
		permission: true,
		path: PATHS.cabinet.trends.database.details.path,
		component: TrendsDatabaseDetails
	},
	{
		active: true,
		isPrivate: true,
		permission: true,
		path: PATHS.cabinet.trends.reports.path,
		component: Report
	},
	{
		active: true,
		isPrivate: true,
		permission: true,
		path: PATHS.cabinet.trends.reports.details.path,
		component: ReportDetails
	},
	{
		active: true,
		isPrivate: true,
		permission: true,
		path: PATHS.cabinet.tracking.path,
		component: Tracking
	},
	{
		active: true,
		isPrivate: true,
		permission: true,
		path: PATHS.cabinet.tracking.create,
		component: TrackingAddNewList,
		conditionsActive: ({ user }) => {
			if (isDev) {
				return true;
			}
			return isSubscriptionActive(user);
		}
	},
	{
		active: true,
		isPrivate: true,
		permission: true,
		path: PATHS.cabinet.tracking.details.path,
		component: TrackingDetails
	},
	{
		active: true,
		isPrivate: true,
		permission: true,
		path: PATHS.cabinet.tracking.details.edit.path,
		component: TrackingEditList
	},
	{
		active: true,
		isPrivate: true,
		permission: true,
		path: PATHS.cabinet.tracking.company.path,
		component: TrackingCompanyDetails
	},
	{
		active: true,
		isPrivate: true,
		permission: true,
		path: PATHS.cabinet.tracking.company.person.path,
		component: TrackingPersonDetails
	}
];

const adminRouts: IRouter[] = [
	{
		active: true,
		isPrivate: true,
		permission: PERMISSIONS.admin_users,
		path: PATHS.adminPanel.users.path,
		component: ScreenUsers
	},
	{
		active: true,
		isPrivate: true,
		permission: PERMISSIONS.admin_users,
		path: PATHS.adminPanel.users.account,
		component: ProfileUser
	},
	{
		active: true,
		isPrivate: true,
		permission: PERMISSIONS.admin_users,
		path: PATHS.adminPanel.users.tracking.path,
		component: LeadsUser
	},
	{
		active: true,
		isPrivate: true,
		permission: PERMISSIONS.admin_users,
		path: PATHS.adminPanel.users.tracking.details.path,
		component: LeadsUserDetails
	},
	{
		active: true,
		isPrivate: true,
		permission: PERMISSIONS.admin_users,
		path: PATHS.adminPanel.users.tracking.details.edit.path,
		component: LeadsUserDetailsEdit
	},
	{
		active: true,
		isPrivate: true,
		permission: PERMISSIONS.admin_users,
		path: PATHS.adminPanel.userLeadsPersonDetails.path,
		component: LeadsUserPersonDetails
	},
	{
		active: true,
		isPrivate: true,
		permission: PERMISSIONS.admin_users,
		path: PATHS.adminPanel.userLeadsAddNewList,
		component: LeadsUserAddNewList
	},
	{
		active: true,
		isPrivate: true,
		permission: PERMISSIONS.admin_users,
		path: PATHS.adminPanel.userLeadsCompanyDetails.path,
		component: LeadsUserCompanyDetails
	},
	{
		active: true,
		isPrivate: true,
		permission: PERMISSIONS.admin_sheets,
		path: PATHS.adminPanel.employees,
		component: Employees
	},
	{
		active: true,
		isPrivate: true,
		permission: PERMISSIONS.admin_sheets,
		path: PATHS.adminPanel.settings,
		component: AppSettings
	},
	{
		active: true,
		isPrivate: true,
		permission: PERMISSIONS.admin_statistic,
		path: PATHS.adminPanel.infoDashboard,
		component: InfoDashboard
	},
	{
		active: true,
		isPrivate: true,
		permission: PERMISSIONS.admin_trend_reports,
		path: PATHS.adminPanel.trendCreate,
		component: TrendCreate
	},
	{
		active: true,
		isPrivate: true,
		permission: PERMISSIONS.admin_trend_reports,
		path: PATHS.adminPanel.trendEdit,
		component: TrendEdit
	},
	{
		active: true,
		isPrivate: true,
		permission: PERMISSIONS.admin_trend_reports,
		path: PATHS.adminPanel.trends.database.create,
		component: TrendDatabaseCreate
	},
	{
		active: true,
		isPrivate: true,
		permission: PERMISSIONS.admin_trend_reports,
		path: PATHS.adminPanel.trends.database.edit.path,
		component: TrendDatabaseEdit
	}
];

const test = {
	active: isDev,
	isPrivate: true,
	permission: true,
	path: PATHS.test,
	component: Test
};

export const ROUTERS: IRouter[] = [
	test,
	...authRouts,
	...cabinetRouts,
	...adminRouts
];
