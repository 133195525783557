import { createMuiTheme } from "@material-ui/core";

export const PALETTE = {
	primary: "#EB7D68",
	primaryGradient: "linear-gradient(98.22deg, #F3B078 0%, #EB7D68 100%)",
	primaryHoverGradient: "linear-gradient(98.22deg, #FFBC84 0%, #F2826D 100%)",
	primaryActiveGradient: "linear-gradient(98.22deg, #EEA567 0%, #E66F59 100%)",
	primaryDisabledGradient:
		"linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),linear-gradient(98.22deg, #F3B078 0%, #EB7D68 100%)",
	error: "#EF5E39",
	errorHover: "#DD4B26",
	errorText: "#EC0910",
	attention: "#FFA630",
	success: "#34BD41",
	oxfordBlue: "#323B4F",

	white: "#ffffff",

	indigo: "#5F74BE",
	sail: "#A5CBFA",
	pattensBlue: "#DCEEFF",

	black: "#3E4555",
	darkGray: "#56637B",
	gray: "#9298A8",
	textGray: "#B9B9BE",
	dark: "#1B1E3F",

	cinder: "#15151F",
	pickledBluewood: "#38465D",
	lynch: "#64738C",
	manatee: "#939BAB",
	heather: "#B3BECB",
	mischka: "#CFD3DE",
	athensGray: "#E8EAEF",
	athensGrayLight: "#F7F8FA"
};

const theme = createMuiTheme({
	typography: {
		fontSize: 16,
		fontFamily: "Rubik, sans-serif",
		h1: {
			fontSize: 38,
			fontWeight: 500
		},
		h2: {
			fontSize: 30,
			fontWeight: 500,
			marginBottom: "0.53em"
		},
		h3: {
			fontSize: 24,
			fontWeight: 500
		},
		h4: {
			fontSize: 20,
			fontWeight: 500
		},
		subtitle1: {
			fontSize: 18,
			fontWeight: 500,
			lineHeight: 1.66
		},
		body1: {
			fontSize: 16,
			lineHeight: 1.625
		},
		button: {
			fontFamily: "Rubik, sans-serif"
		}
	},
	props: {
		MuiButtonBase: {
			disableRipple: true
		}
	},
	overrides: {
		MuiTypography: {
			gutterBottom: {
				marginBottom: "0.8em"
			}
		}
	}
});

export default theme;
