import React, { memo } from "react";

import { Checkbox as CheckboxMUI } from "@material-ui/core";
import classNames from "classnames";
import isEqual from "react-fast-compare";

import { useStylesCheckboxBase } from "./styles";
import { ICheckboxProps } from "./types";

const CheckboxBase: React.FC<ICheckboxProps> = ({
	className,
	classNameIcon,
	onChange,
	name,
	identifier,
	value,
	...rest
}) => {
	const classes = useStylesCheckboxBase();

	return (
		<CheckboxMUI
			color="default"
			className={classNames(classes.root, className)}
			icon={<span className={classNames(classes.icon, classNameIcon)} />}
			checkedIcon={
				<span className={classNames(classes.icon, classes.checkedIcon)} />
			}
			onChange={e => {
				if (onChange) {
					onChange(e, e.target.checked, identifier);
				}
			}}
			{...rest}
		/>
	);
};

const Checkbox = memo(CheckboxBase, isEqual);

export default Checkbox;
