import React, { memo } from "react";

import cn from "classnames";

import { useSubscribe } from "app/hooks/useSubscribe";

import style from "./style.module.scss";

interface props {
	data?: JSX.Element | JSX.Element[];
	classNameContent?: string;
	className?: string;
}

const HideContent: React.FC<props> = ({
	data,
	classNameContent,
	className
}) => {
	const subscription = useSubscribe();

	if (subscription.isActive) return null;

	return (
		<div className={cn(style.hide_content_container, className)}>
			{data}
			<div className={cn(style.hide_content, classNameContent)}>
				<svg
					width="140"
					height="140"
					viewBox="0 0 140 140"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="70" cy="70" r="70" fill="#A6D4FF" fillOpacity="0.4" />

					<path
						d="M85.75 59.3333H83.125V54.0952C83.125 46.8667 77.245 41 70 41C62.755 41 56.875 46.8667 56.875 54.0952V59.3333H54.25C51.3625 59.3333 49 61.6905 49 64.5714V90.7619C49 93.6429 51.3625 96 54.25 96H85.75C88.6375 96 91 93.6429 91 90.7619V64.5714C91 61.6905 88.6375 59.3333 85.75 59.3333ZM62.125 59.3333V54.0952C62.125 49.7476 65.6425 46.2381 70 46.2381C74.3575 46.2381 77.875 49.7476 77.875 54.0952V59.3333H62.125Z"
						fill="#6F8BB6"
					/>

					<circle cx="87" cy="86" r="15" fill="#EB7D68" />

					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M92.0903 89.2308L93.8002 90.9673V91.8154H80.2002V90.9673L81.9101 89.2308V84.95C81.9101 83.6038 82.2481 82.4327 82.9242 81.4365C83.6002 80.4404 84.5347 79.7942 85.7277 79.4981V78.8923C85.7277 78.5423 85.847 78.2394 86.0856 77.9837C86.3242 77.7279 86.629 77.6 87.0002 77.6C87.3713 77.6 87.6762 77.7279 87.9148 77.9837C88.1534 78.2394 88.2727 78.5423 88.2727 78.8923V79.4981C89.4657 79.7942 90.4002 80.4404 91.0762 81.4365C91.7522 82.4327 92.0903 83.6038 92.0903 84.95V89.2308ZM87.0002 94.4C86.523 94.4 86.1187 94.2317 85.7873 93.8952C85.4559 93.5587 85.2903 93.1615 85.2903 92.7039H88.7101C88.7101 93.1615 88.5378 93.5587 88.1932 93.8952C87.8485 94.2317 87.4509 94.4 87.0002 94.4Z"
						fill="white"
					/>
				</svg>

				<p className={style.hide_content_container_title}>
					Activate a subscription
				</p>
			</div>
		</div>
	);
};

export default memo(HideContent);
