import { memo } from "react";

import isEqual from "react-fast-compare";

import { FormError } from "app/uikit-V2/Form/FormComponents";

import type { ICheckboxProps } from "./types";

import Checkbox from "./index";

const CheckboxGroup: React.FC<ICheckboxProps> = ({
	list,
	error,
	onChange,
	value,
	canDisplayError,
	wrapperClassName,
	labelClassName,
	...rest
}) => (
	<div>
		{list.map(
			({
				value: valueItem,
				name,
				label,
				className,
				onChangedTrigger,
				labelClassName: labelClassNameItem
			}) => {
				const isChecked = !!(value as Record<string, boolean>)?.[valueItem];

				return (
					<Checkbox
						onChange={(e, ...rest) => {
							if (onChange) {
								onChange(e, ...rest);
							}
							if (onChangedTrigger) {
								onChangedTrigger(name, e.target.checked);
							}
						}}
						{...rest}
						name={name || rest.name}
						wrapperClassName={className || wrapperClassName}
						labelClassName={labelClassNameItem || labelClassName}
						key={name}
						checked={isChecked}
						identifier={name}
						label={label}
					/>
				);
			}
		)}

		{canDisplayError && <FormError error={error} />}
	</div>
);

export default memo(CheckboxGroup, isEqual);

CheckboxGroup.defaultProps = {
	error: "",
	label: ""
};
