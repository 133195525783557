import Modal from "app/components/modal";
import Icon from "assets/img/icons/bdbc9a7582fb780d576397f176ebaad2.png";

import { IMatchedData } from "../type";

import MatchColumns from "./match-columns";
import styles from "./style.module.scss";

interface props {
	onClose: () => void;
	open: boolean;
	onSubmit: (values: any) => void;
	file: File;
	loading?: boolean;
	data?: IMatchedData;
}

const MatchModal: React.FC<props> = ({
	onClose,
	open,
	file,
	data,
	loading,
	onSubmit
}) => {
	return (
		<Modal
			showCloseIcon
			title="Set up imported custom variables"
			onClose={onClose}
			open={open}
		>
			<div className={styles.notic}>
				<img src={Icon} alt="" width="26" />
				<p>
					Please match the required fields: <strong>Person LinkedIn URLs </strong>
					<br />
					and <strong>Company Websites</strong>
				</p>
			</div>
			<MatchColumns
				showTitle={false}
				sortByEmpty={false}
				submitButtonsText="Confirm"
				keyColumnTypeId="column_id"
				data={{
					...data,
					canCreate: data?.is_can_upload_companies_set,
					audienceTemporaryData: { file },
					loading: !!loading,
					errors: ""
				}}
				onSubmit={onSubmit}
				onBack={onClose}
			/>
		</Modal>
	);
};

export default MatchModal;
