import Lazy from "react-loadable";

import LoadingPage from "app/components/LoadingPage";

const Loadable = <T, O extends object = any>(options: any) =>
	Lazy<T, O>({
		loading: LoadingPage,
		timeout: 10000,
		...options
	});

export default Loadable;
