import * as Sentry from "@sentry/react";

export const DSN = process?.env?.REACT_APP_SENTRY_DSN;

export const initSentry = () => {
	if (!DSN) return;

	Sentry.init({
		dsn: DSN,
		integrations: [
			new Sentry.BrowserTracing({
				// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
				tracePropagationTargets: [
					"localhost",
					"https://my.signum.ai/",
					/^https:\/\/box\.signum\.ai:30890\/api/
				]
			}),
			new Sentry.Replay()
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
};
