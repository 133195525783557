import { memo, useState, useCallback } from "react";

import { useSelector } from "react-redux";
import debounce from "lodash/debounce";

import { RootState } from "app/store/rootReducer";
import { CustomSelect } from "app/uikit/customSelect";
import Button from "app/uikit/button";
import { IOption } from "app/types";
import Checkbox from "app/uikit/checkbox";
import Chip from "app/uikit/chip";
import { useDidUpdateEffect } from "app/hooks/useDidUpdateEffect";
import { getTriggerColors } from "app/helper/tracking.helper";

import style from "./style.module.scss";

interface props {
	onChangeFilter: (values: IOption[]) => void;
}

const FilterSignals: React.FC<props> = ({ onChangeFilter }) => {
	const [selected, setSelected] = useState<IOption[]>([]);
	const { lead_observation_events } = useSelector(
		(state: RootState) => state.handBooks
	);

	const delayed = useCallback(
		debounce((items: IOption[]) => {
			onChangeFilter(items);
		}, 200),
		[]
	);

	useDidUpdateEffect(() => {
		delayed(selected);
	}, [selected]);

	const onAddFilter = (option: IOption) => {
		const newValue = [...selected];
		const index = newValue.findIndex((item: IOption) => item.id === option.id);
		if (index !== -1) {
			newValue.splice(index, 1);
		} else {
			newValue.push(option);
		}
		setSelected(newValue);
	};

	const onDelete = (index: number) => {
		const newValue = [...selected];
		newValue.splice(index, 1);
		setSelected(newValue);
	};

	return (
		<div className={style.filter}>
			<div className={style.filter__body}>
				<div className={style.filter__inner}>
					<Chip
						className={style.filter__chips}
						classNameItem={style["filter__chips-item"]}
						getStyleItem={(item: IOption) => getTriggerColors(Number(item.id))}
						list={selected}
						onDelete={onDelete}
					/>

					<CustomSelect
						value={selected}
						disableCloseOnSelect
						name="filter"
						renderComponent={
							<Button className={style["btn-add-filter"]}>
								<svg
									width="14"
									height="14"
									viewBox="0 0 14 14"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M6 8V14H8V8H14V6H8V0H6V6H0V8H6Z"
										fill="#EB7D68"
									/>
								</svg>
								<span>Add a filter</span>
							</Button>
						}
						onChange={(item: IOption) => {
							onAddFilter(item);
						}}
						options={lead_observation_events}
						renderItem={(item, value) => {
							const isActive =
								Array.isArray(value) &&
								value.find((option: IOption) => option.id === item.id);
							return (
								<div className={style["filter-item__multiple"]}>
									<Checkbox checked={!!isActive} />
									{item.name}
								</div>
							);
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default memo(FilterSignals);
