import React, { memo } from "react";

import { Radio as RadioMUI } from "@material-ui/core";
import cn from "classnames";
import isEqual from "react-fast-compare";

import { useStylesRadioBase } from "./styles";
import { IRadioProps } from "./types";

const RadioBase: React.FC<IRadioProps> = ({
	className,
	classNameIcon,
	onChange,
	name,
	identifier,
	value,
	...rest
}) => {
	const classes = useStylesRadioBase();

	return (
		<RadioMUI
			className={cn(classes.root, className)}
			icon={<span className={cn(classes.icon, classNameIcon)} />}
			checkedIcon={<span className={cn(classes.icon, classes.checkedIcon)} />}
			color="secondary"
			onChange={e => {
				if (onChange) {
					onChange(e, e.target.checked, identifier);
				}
			}}
			name={name}
			value={value}
			{...rest}
		/>
	);
};

const Checkbox = memo(RadioBase, isEqual);

export default Checkbox;
