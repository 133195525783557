import { IHandbookState } from "./handBooks/reducer";
import { RootState } from "./rootReducer";
import { IUser, IUserState } from "./user/reducer";
import { IAuth } from "./auth/reducer";
import { ITrendsState } from "./trends/type";
import { ITrendsDatabaseState } from "./trendsDatabase/type";

export const configSelect = {
	handBooks: (state: RootState): IHandbookState => state.handBooks,
	selectUser: (state: RootState): IUser => state.user?.user,
	selectUserDetails: (state: RootState): IUserState => state.user,
	auth: (state: RootState): IAuth => state.auth,
	users: (state: RootState): any => state.users,
	trends: (state: RootState): ITrendsState => state.trends,
	trendsDatabase: (state: RootState): ITrendsDatabaseState =>
		state.trendsDatabase
};
