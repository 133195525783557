import { useEffect } from "react";

import { useDispatch } from "react-redux";

import useQuery from "app/hooks/useQuery";
import Loadable from "app/utils/Loadable";
import useGetAppSumoKey from "app/components/auth/useGetAppSumoKey";
import actions from "app/store/user/actions";

import { SuccessType } from "./type";

const SubscriptionSuccess = Loadable({
	loader: () => import("./SubscriptionSuccess")
});

const SubscriptionSuccessOnboarding = Loadable({
	loader: () => import("./SubscriptionSuccessOnboarding")
});

const SubscriptionSuccessAppSumo = Loadable({
	loader: () => import("./SubscriptionSuccessAppSumo")
});

let timer: NodeJS.Timeout | null | ReturnType<typeof setTimeout> = null;

const ScreenSubscriptionSuccess = () => {
	const dispatch = useDispatch();
	const query = useQuery();
	const isOnboarding = query.get("type")?.includes(SuccessType.onboarding);
	const appsumoKey = useGetAppSumoKey();

	useEffect(() => {
		dispatch(actions.getInfo());

		if (isOnboarding) {
			timer = setInterval(() => {
				dispatch(actions.getInfo());
			}, 1500);
		}

		return () => {
			clearInterval(timer);
		};
	}, [dispatch, isOnboarding]);

	if (appsumoKey) {
		return <SubscriptionSuccessAppSumo />;
	}

	if (isOnboarding) {
		return <SubscriptionSuccessOnboarding />;
	}

	return <SubscriptionSuccess />;
};

export default ScreenSubscriptionSuccess;
