import { memo } from "react";

import { Field, FieldProps } from "formik";
import isEqual from "react-fast-compare";

import { getPropsForm, getPropsInput } from "../Form.utils";

import InputBase from "./InputBase";
import { IInputProps } from "./type";

const Input: React.FC<IInputProps> = props => {
	const propsForm = getPropsForm(props);
	const propsInput = getPropsInput(props);
	return <InputBase {...propsInput} {...propsForm} />;
};

const FormikInputBase: React.FC<IInputProps> = ({ name, ...rest }) => (
	<Field name={name}>
		{({ field, meta }: FieldProps) => {
			const error = meta.touched ? meta.error : "";
			return (
				<Input
					value={field.value}
					name={name}
					{...rest}
					onChange={field.onChange}
					onBlur={field.onBlur}
					error={error}
				/>
			);
		}}
	</Field>
);

export const FormikFormInput = memo(FormikInputBase, isEqual);

export default memo(Input, isEqual);
