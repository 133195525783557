export const EmailIcon = () => (
	<svg
		width="12"
		height="10"
		viewBox="0 0 12 10"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect y="0.5" width="12" height="9" rx="2" fill="#D4D7DF" />
		<path d="M1.5 2L6 5.5L10.5 2" stroke="white" strokeWidth="1.5" />
	</svg>
);

export const LocationIcon = () => (
	<svg
		width="10"
		height="12"
		viewBox="0 0 10 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M4.99999 12C4.0978 11.2819 3.26156 10.4946 2.49999 9.64646C1.35714 8.37271 6.2778e-07 6.47574 6.2778e-07 4.6681C-0.000495013 3.74492 0.292506 2.84235 0.841922 2.07462C1.39134 1.30689 2.17247 0.708507 3.08647 0.355202C4.00047 0.00189744 5.00624 -0.0904489 5.9765 0.0898503C6.94676 0.270149 7.83789 0.714989 8.53712 1.36807C9.00266 1.80056 9.37165 2.31491 9.62275 2.88136C9.87385 3.44781 10.0021 4.0551 9.99998 4.6681C9.99998 6.47574 8.64284 8.37271 7.49998 9.64646C6.73841 10.4946 5.90217 11.2819 4.99999 12ZM4.99999 2.66849C4.43167 2.66849 3.88663 2.87916 3.48476 3.25416C3.0829 3.62916 2.85714 4.13777 2.85714 4.6681C2.85714 5.19843 3.0829 5.70704 3.48476 6.08203C3.88663 6.45703 4.43167 6.66771 4.99999 6.66771C5.56831 6.66771 6.11335 6.45703 6.51521 6.08203C6.91708 5.70704 7.14284 5.19843 7.14284 4.6681C7.14284 4.13777 6.91708 3.62916 6.51521 3.25416C6.11335 2.87916 5.56831 2.66849 4.99999 2.66849Z"
			fill="#D4D7DF"
		/>
	</svg>
);

export const LinkedinIcon = () => (
	<svg
		width="18"
		height="18"
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1.63395 2.00389C1.97335 1.68442 2.4164 1.52454 2.96318 1.52454C3.51 1.52454 3.9483 1.68429 4.2776 2.00389C4.60693 2.32342 4.77505 2.72687 4.78163 3.21449C4.78817 3.69547 4.62185 4.09736 4.28258 4.42011C3.94332 4.74304 3.49688 4.90438 2.94338 4.90438H2.92362C2.38337 4.90438 1.94847 4.7429 1.61913 4.42011C1.2897 4.09736 1.125 3.69534 1.125 3.21449C1.125 2.72683 1.29458 2.32342 1.63395 2.00389ZM15.2772 7.1333C14.5922 6.38547 13.6861 6.01149 12.5595 6.01149C12.1444 6.01149 11.7671 6.06254 11.4278 6.16473C11.0885 6.26684 10.8019 6.41012 10.568 6.59462C10.3342 6.77912 10.1479 6.95039 10.0097 7.10845C9.8782 7.25858 9.74964 7.43345 9.6242 7.63128V6.23863H6.3726L6.38257 6.71307C6.38925 7.02942 6.39254 8.00447 6.39254 9.63835C6.39254 11.2724 6.38596 13.4037 6.37274 16.0326H9.6242V10.5673C9.6242 10.2313 9.66023 9.96453 9.73285 9.7667C9.87131 9.43052 10.0803 9.14906 10.3605 8.92184C10.6406 8.69435 10.988 8.58071 11.4033 8.58071C11.9697 8.58071 12.3866 8.7767 12.6535 9.16872C12.9202 9.56071 13.0536 10.1026 13.0536 10.7945V16.0323H16.3051V10.4192C16.3049 8.97612 15.9625 7.88089 15.2772 7.1333ZM1.31275 6.2387H4.57407V16.0325H1.31275V6.2387Z"
			fill="#D4D7DF"
		/>
	</svg>
);

export const TwitterIcon = () => (
	<svg
		width="18"
		height="18"
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M2.01098 2.70761C3.30876 4.43851 5.63234 6.0718 8.75086 6.24739C8.57887 5.49581 8.56231 3.45583 9.92202 2.60802C10.5302 2.22879 11.1059 2 11.7841 2C12.6362 2 13.3356 2.30682 14.0796 3.05325C14.6851 2.96064 15.8163 2.45003 16.0847 2.24946C15.91 2.95327 15.2109 3.85698 14.7058 4.07154C15.1696 4.07004 16.1893 3.77911 16.5017 3.6072C16.0682 4.33893 15.3113 5.03988 14.8971 5.33081C15.3767 9.83995 11.5503 15.4454 6.05492 15.451C4.48559 15.4526 2.78014 15.1026 1 14.0349C2.75606 14.2509 4.63759 13.6535 5.71792 12.619C4.35546 12.5867 2.97552 11.6223 2.34798 10.1412C3.03062 10.3645 3.65999 10.4776 4.03295 10.1412C3.39716 10.0662 1.33699 8.88111 1.33699 6.58587C1.81043 6.99733 2.49209 7.42248 3.02196 7.30933C2.15492 6.77005 0.894858 4.71627 2.01098 2.70761Z"
			fill="#3DBDF8"
		/>
	</svg>
);

export const FaceBookIcon = () => (
	<svg
		width="18"
		height="18"
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M13.0258 1.12828L10.9008 1.125C8.51347 1.125 6.97068 2.64637 6.97068 5.0011V6.78824H4.83413C4.64951 6.78824 4.5 6.9321 4.5 7.10955V9.69891C4.5 9.87637 4.64968 10.0201 4.83413 10.0201H6.97068V16.5538C6.97068 16.7313 7.12019 16.875 7.30481 16.875H10.0924C10.277 16.875 10.4265 16.7311 10.4265 16.5538V10.0201H12.9247C13.1093 10.0201 13.2588 9.87637 13.2588 9.69891L13.2598 7.10955C13.2598 7.02435 13.2245 6.94275 13.162 6.88245C13.0994 6.82215 13.0142 6.78824 12.9255 6.78824H10.4265V5.27326C10.4265 4.54509 10.6071 4.17544 11.5939 4.17544L13.0254 4.17495C13.2099 4.17495 13.3594 4.03109 13.3594 3.8538V1.44943C13.3594 1.2723 13.21 1.1286 13.0258 1.12828Z"
			fill="#5165A0"
		/>
	</svg>
);
