import { useSelector } from "react-redux";

import { ISubscription } from "app/store/user/reducer";
import { IUser } from "app/store/user/reducer";
import { configSelect } from "app/store/select";

type ISubscribe = {
	isActive: boolean;
	subscription: ISubscription;
};

export const isSubscriptionActive = (user: IUser) => {
	const { is_subscription_required, subscription } = user;
	return !is_subscription_required || subscription?.is_active_subscription;
};

export const useSubscribe = (): ISubscribe => {
	const user = useSelector(configSelect.selectUser);
	const { subscription } = user;

	return {
		isActive: isSubscriptionActive(user),
		subscription
	};
};
