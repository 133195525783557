import { AnyAction } from "redux";

import config from "app/config";

import {
	stateRequest,
	getDefaultPagination,
	getInitialValueActionName,
	getFilter,
	updateTotalPagination,
	getPagination
} from "../helper";

import types from "./types";
import { ACTIONS_NAMES } from "./actions";
import { ITrendsState } from "./type";

export const initialState: ITrendsState = {
	trends: {
		items: [],
		count: 0
	},
	trendDetails: {
		trend_report: {
			body: "",
			datetime: "",
			file: null,
			id: null,
			language: null,
			name: "",
			short_description: ""
		}
	},

	pagination: {
		[ACTIONS_NAMES.trends]: {
			total: 0,
			limit: config.ui.pagination.size,
			page: 1
		}
	},

	filter: {
		[ACTIONS_NAMES.trends]: {}
	},

	error: getInitialValueActionName(ACTIONS_NAMES, ""),
	isLoading: getInitialValueActionName(ACTIONS_NAMES)
};

const reducer = function (state = initialState, action: AnyAction) {
	const actionName = action.actionName;

	switch (action.type) {
		case types.GET_TRENDS_REQUEST:
		case types.GET_TREND_REQUEST:
		case types.FETCH_TREND_CREATE_REQUEST:
		case types.FETCH_TREND_EDIT_REQUEST:
		case types.FETCH_TREND_DELETE_REQUEST:
			return {
				...state,
				...stateRequest(state, action, "request")
			};
		case types.GET_TRENDS_FAILURE:
		case types.GET_TREND_FAILURE:
		case types.FETCH_TREND_CREATE_FAILURE:
		case types.FETCH_TREND_EDIT_FAILURE:
		case types.FETCH_TREND_DELETE_FAILURE:
			return {
				...state,
				...stateRequest(state, action, "failure")
			};
		case types.GET_TRENDS_SUCCESS:
		case types.GET_TREND_SUCCESS:
		case types.FETCH_TREND_CREATE_SUCCESS:
		case types.FETCH_TREND_EDIT_SUCCESS:
		case types.FETCH_TREND_DELETE_SUCCESS:
			let tempState = {
				...state,
				...stateRequest(state, action, "success")
			};
			switch (actionName) {
				case ACTIONS_NAMES.trends:
					tempState.trends = action.data;
					tempState.pagination = updateTotalPagination(
						state,
						action.data,
						actionName
					);
					break;
				case ACTIONS_NAMES.trendDetails:
					tempState.trendDetails = action.data;
					break;
				default:
					break;
			}
			return tempState;
		case types.FETCH_PAGINATION_TRENDS_SUCCESS:
			return {
				...state,
				pagination: getPagination(state, action.data, actionName)
			};

		case types.FETCH_FILTER_TRENDS_SUCCESS:
			return {
				...state,
				...stateRequest(state, action, "success"),
				pagination: getDefaultPagination(state, actionName),
				filter: getFilter(state, action.data, actionName)
			};
		case types.RESET_TRENDS_DETAILS:
			return {
				...state,
				trendDetails: initialState.trendDetails,
				error: {
					...state.error,
					trendDetails: initialState.error.trendDetails
				},
				isLoading: {
					...state.isLoading,
					trendDetails: initialState.isLoading.trendDetails
				}
			};
		case types.RESET_TRENDS:
			return {
				...state,
				trends: initialState.trends,
				pagination: { ...state.pagination, trends: initialState.pagination.trends },
				filter: { ...state.filter, trends: initialState.filter.trends },
				error: { ...state.error, trends: initialState.error.trends },
				isLoading: {
					...state.isLoading,
					trends: initialState.isLoading.trends
				}
			};
		default:
			return state;
	}
};

export default reducer;
