export enum PERMISSIONS {
	admin_users = "admin_users",
	admin_sheets = "admin_sheets",
	account_sheets = "account_sheets",
	account_integrations = "account_integrations",
	account_creatives = "account_creatives",
	account_campains = "account_campains",
	account_reports = "account_reports",
	account_content_generation = "account_content_generation",
	admin_general_settings = "admin_general_settings",
	admin_contact_categories = "admin_contact_categories",
	admin_statistic = "admin_statistic",
	admin_audience = "admin_audience",
	admin_data_vendors = "admin_data_vendors",
	admin_employees = "admin_employees",
	admin_leads = "admin_leads",
	admin_leads_observation = "admin_leads_observation",
	admin_marketplace_audience = "admin_marketplace_audience",
	admin_marketplace_contacts = "admin_marketplace_contacts",
	admin_trend_reports = "admin_trend_reports"
}

export type IPermissionsTypeof = typeof PERMISSIONS;

export type IPermissions = keyof typeof PERMISSIONS;

export type IPermissionsTypes = Record<IPermissions, boolean>;
