import { MixedSchema } from "yup/lib/mixed";
import { AnyObject } from "yup/lib/types";

import yup from "app/yup";
import { ErrorFileRequired, ErrorName } from "app/errors-shcema";

const validationSchema = (withName?: boolean) => {
	let obj: {
		file: MixedSchema<any, AnyObject, any>;
		name?: MixedSchema<any, AnyObject, any>;
	} = {
		file: ErrorFileRequired
	};

	if (withName) {
		obj.name = ErrorName;
	}

	return yup.object(obj);
};

export default validationSchema;
