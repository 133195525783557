import { memo, useMemo, useState } from "react";

import isEqual from "react-fast-compare";
import { Select as MUISelect, MenuItem } from "@material-ui/core";
import cn from "classnames";

import { DownArrow } from "app/icons";

import { getFomComponentProps } from "../FormComponents/FormComponents.utils";
import FormComponent from "../FormComponents";
import Checkbox from "../Checkbox";

import useStyles from "./style";
import { ISelectProps } from "./type";

const Select: React.FC<ISelectProps> = props => {
	const {
		className,
		useNone,
		name,
		reference,
		options,
		placeholder,
		value,
		anchorOrigin,
		MenuProps,
		error,
		multiple,
		noneValue = null,
		...rest
	} = props;
	const [open, setOpen] = useState(false);

	const classes = useStyles();

	const onClose = () => {
		setOpen(false);
	};

	const onOpen = () => {
		setOpen(true);
	};

	const currentValue = value;

	const renderValue =
		Array.isArray(currentValue) || (placeholder && !currentValue)
			? (() => {
					if (
						placeholder &&
						((Array.isArray(currentValue) && !currentValue?.length) || !currentValue)
					) {
						return (value: string[]) => (
							<div className={classes.placeholder}>{placeholder}</div>
						);
					}
					if (multiple && currentValue.length) {
						return (value: string[]) =>
							((value as unknown) as string[])
								.map(item => options.find(elem => elem.value === item)?.label)
								.join(", ");
					}
			  })()
			: undefined;

	const fomComponentProps = getFomComponentProps(props);

	const formattedList = useMemo(
		() =>
			options?.map(({ label, icon, value: optionValue, disabled }, i) => {
				if (multiple) {
					return (
						<MenuItem
							value={optionValue}
							key={`${optionValue}_${i}`}
							disabled={disabled}
						>
							<div className={classes.menuItem}>
								<div className={classes.menuItem__inner}>
									<Checkbox
										checked={
											!!((value as unknown) as string[]).find(item => item === optionValue)
										}
									/>
									{icon && <div className={classes.iconElem}>{icon}</div>}
									<div className={classes.menuItem__text}>{label}</div>
								</div>
							</div>
						</MenuItem>
					);
				}

				return (
					<MenuItem
						value={optionValue}
						key={`${optionValue}_${i}`}
						disabled={disabled}
					>
						<div className={classes.menuItem}>
							<div className={classes.menuItem__inner}>
								{icon && <div className={classes.iconElem}>{icon}</div>}
								<div className={classes.menuItem__text}>{label}</div>
							</div>
						</div>
					</MenuItem>
				);
			}),
		[
			classes.iconElem,
			classes.menuItem,
			classes.menuItem__inner,
			classes.menuItem__text,
			multiple,
			options,
			value
		]
	);

	return (
		<FormComponent {...fomComponentProps}>
			<MUISelect
				displayEmpty
				MenuProps={{
					anchorOrigin: {
						vertical: "bottom",
						horizontal: "left",
						...anchorOrigin
					},
					getContentAnchorEl: null,
					...MenuProps
				}}
				name={name}
				ref={reference}
				className={cn(className, classes.input, { [classes.error]: error })}
				IconComponent={() => (
					<div className={cn(classes.icon, { [classes.icon_rotate]: open })}>
						<DownArrow />
					</div>
				)}
				renderValue={renderValue}
				onClose={onClose}
				onOpen={onOpen}
				inputProps={{ id: name }}
				value={currentValue}
				multiple={multiple}
				{...rest}
			>
				{!!useNone && (
					<MenuItem className={classes.menuItem} value={noneValue}>
						None
					</MenuItem>
				)}

				{(formattedList?.length && formattedList) || (
					<MenuItem className={classes.menuItem} value="null" disabled={true}>
						<em>No available options</em>
					</MenuItem>
				)}
			</MUISelect>
		</FormComponent>
	);
};

export default memo(Select, isEqual);
