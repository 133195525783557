import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	root: {
		background: "#EB7D68",
		borderRadius: 4,
		paddingLeft: 8,
		paddingRight: 8,
		maxWidth: "100%",
		margin: 4,
		color: "#fff",
		height: 30,
		flexShrink: 0,
		position: "relative",

		"& .MuiChip-label": {
			padding: 0,
			lineHeight: 2,
			maxWidth: "100%"
		},
		"&.MuiChip-deletable:focus": {
			background: "#EB7D68"
		},
		"& .MuiChip-deleteIcon": {
			height: 11,
			width: 10,
			position: "absolute",
			right: 8,
			top: "50%",
			transform: "translateY(-50%)",
			cursor: "pointer",
			margin: 0,

			"&:hover": {
				opacity: 0.7,
				transition: "opacity 0.2s"
			}
		}
	},
	with_remove: {
		paddingRight: "26px !important"
	},
	container_ghost: {
		width: "100%",
		display: "flex",
		flexWrap: "wrap",
		alignItems: "center",
		color: "#3E4555",
		fontSize: "16px",
		"&.Mui-focused": {
			borderColor: "#CFD3DE"
		},
		"&.MuiInput-underline:before,&.MuiInput-underline:after": {
			content: "none"
		},
		"& .MuiInput-input": {
			padding: 0,
			color: "#3E4555"
		},
		"& .MuiInputBase-inputMultiline": {
			paddingTop: 6,
			paddingBottom: 6,
			resize: "vertical",
			lineHeight: "26px"
		}
	},
	container: {
		border: "1px solid #E8EAEF",
		width: "100%",
		display: "flex",
		flexWrap: "wrap",
		alignItems: "center",
		borderRadius: 6,
		minHeight: 50,
		padding: "6px 8px",
		transition: "0.2s",
		color: "#3E4555",
		fontSize: "16px",
		"&.Mui-focused": {
			borderColor: "#CFD3DE"
		},
		"&.MuiInput-underline:before,&.MuiInput-underline:after": {
			content: "none"
		},
		"& .MuiInput-input": {
			padding: 0,
			color: "#3E4555"
		},
		"& .MuiInputBase-inputMultiline": {
			paddingTop: 6,
			paddingBottom: 6,
			resize: "vertical",
			lineHeight: "26px"
		}
	},
	reset: {
		fontWeight: 500,
		fontSize: 14,
		borderRadius: "4px",
		margin: 4,
		lineHeight: 22,
		color: "#38465D",
		height: 30,
		display: "flex",
		paddingLeft: 10,
		alignItems: "center",
		"& svg": {
			margin: "0 10px"
		},
		"&:hover": {
			backgroundColor: "#F7F8FA",
			borderColor: "#F7F8FA"
		}
	}
});

export default useStyles;
