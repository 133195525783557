import { memo } from "react";

import isEqual from "react-fast-compare";

import { FormControl, FormError, InputLabel } from "../FormComponents";

import { IPropsFormError } from "./FormError";
import { IPropsInputLabel } from "./InputLabel";

export type IPropsFormComponent = IPropsFormError &
	IPropsInputLabel & {
		wrapperClassName?: string;
		labelClassName?: string;
		children?: any;
	};

const FormComponent: React.FC<IPropsFormComponent> = ({
	wrapperClassName,
	labelClassName,
	info,
	label,
	error,
	infoPlacement,
	name,
	fullWidth,
	required,
	labelContent,
	children
}) => (
	<FormControl fullWidth={fullWidth} className={wrapperClassName}>
		<InputLabel
			name={name}
			info={info}
			labelContent={labelContent}
			infoPlacement={infoPlacement}
			required={required}
			label={label}
			className={labelClassName}
		/>

		{children}
		<FormError error={error} />
	</FormControl>
);

export default memo(FormComponent, isEqual);
