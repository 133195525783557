import React, { useCallback, useState } from "react";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import cl from "classnames";

import localStore from "app/helper/local-store";
import config from "app/config";
import ModalSupport from "app/components/modal/support";
import { Logo } from "app/icons";
import { RootState } from "app/store/rootReducer";
import { IUserState } from "app/store/user/reducer";
import { BOTTOM_ITEMS, MENU_ITEMS } from "app/routers/AsideMenu";

import { AsideMenu } from "./menu";
import style from "./style.module.scss";

export const Aside: React.FC = () => {
	const {
		user: { permissions }
	} = useSelector<RootState, IUserState>(state => state.user);
	const token: string = localStore.getAccessToken();
	const [modalSupportOpen, setModalSupportOpen] = useState<boolean>(false);

	const onOpenModalSupport = () => {
		setModalSupportOpen(true);
	};

	const onCloseModalSupport = useCallback(() => {
		setModalSupportOpen(false);
	}, []);

	const onCheckAction = (action?: string) => {
		switch (action) {
			case "support":
				onOpenModalSupport();
				break;
			default:
				return;
		}
	};

	return (
		<>
			<aside className={style.aside}>
				<div className={style.logo}>
					<Link className={style.logo_link} to={config.defaultPage.link}>
						<Logo />
					</Link>
				</div>

				<AsideMenu
					onCheckAction={onCheckAction}
					permissions={permissions}
					list={MENU_ITEMS}
					token={token}
				/>

				<ul className={cl(style.aside_list)}>
					{BOTTOM_ITEMS.map(item => (
						<li key={item.caption}>
							{(item?.action && (
								<button
									className={style.aside_list__item}
									onClick={() => {
										onCheckAction(item.action);
									}}
								>
									{item.icon}
									{item.caption}
								</button>
							)) || (
								<Link to="#" className={style.aside_list__item}>
									{item.icon}
									{item.caption}
								</Link>
							)}
						</li>
					))}
				</ul>
			</aside>
			<ModalSupport onClose={onCloseModalSupport} open={modalSupportOpen} />
		</>
	);
};
