import classNames from "classnames/bind";

import style from "./style.module.scss";

const HeaderLight = (props: any) => {
	const cx = classNames.bind(style);
	return (
		<div className={cx("wrapper", props.className, { gray: props.gray })}>
			{props.children}
		</div>
	);
};

export default HeaderLight;
