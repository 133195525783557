import { useEffect, useCallback } from "react";

import { useParams } from "react-router";
import { useDispatch } from "react-redux";

import actions from "app/store/users/actions";

type UserLeadsParams = {
	id: string;
};

const useGetUserDetails = (id?: number | string) => {
	const { id: paramId } = useParams<UserLeadsParams>();
	const dispatch = useDispatch();

	const fetchUserData = useCallback(() => {
		dispatch(actions.getUser(id || paramId));
	}, [id, paramId, dispatch]);

	useEffect(() => {
		fetchUserData();
	}, [fetchUserData]);
};

export default useGetUserDetails;
