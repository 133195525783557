import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { configSelect } from "app/store/select";
import { API } from "app/api";
import { ApiUrls } from "app/api/apiUrls";
import actions from "app/store/user/actions";
import Loader from "app/components/loaderV2";
import { ISubscriptionPrice } from "app/components/Tracking/TrackingNewLists/PriceList/type";
import { Nullable } from "app/types";
import { getSubscriptionPrice } from "app/components/Tracking/TrackingNewLists/PriceList/PriceList.utils";
import PATHS from "PATHS";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Content from "./components/Content";
import styles from "./style.module.scss";
import ConfigurationStepper from "./components/Content/Price";

const Configuration = () => {
	const [data, setData] = useState<Nullable<ISubscriptionPrice>>(null);
	const dispatch = useDispatch();
	const history = useHistory();

	const onGetPrice = async () => {
		const price = await getSubscriptionPrice();
		setData(price);
	};

	const {
		user: { is_created_by_admin }
	} = useSelector(configSelect.selectUserDetails);

	const onSkipOnboarding = useCallback(async () => {
		try {
			const response = await API.patch(ApiUrls.account.onboard.skip);
			await new Promise(res => {
				dispatch(
					actions.getInfo({
						cb: () => {
							history.replace(PATHS.cabinet.trends.database.path);
							res(null);
						}
					})
				);
			});
			return response.data;
		} catch (err) {
			console.log(err);
		}
	}, [dispatch, history]);

	useEffect(() => {
		if (is_created_by_admin) {
			onSkipOnboarding();
		} else {
			onGetPrice();
		}
	}, [is_created_by_admin, onSkipOnboarding]);

	return (
		<Loader variant="center-absolute" loading={!data}>
			<div className={styles.wrapper}>
				<Header />
				<Content>
					<ConfigurationStepper price={data} />
				</Content>
				<Footer />
			</div>
		</Loader>
	);
};

export default Configuration;
