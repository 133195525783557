import React, { ComponentType } from "react";

import Loadable from "app/utils/Loadable";

import { PropsTrackingAddNewListFlow } from "./type";

const TrackingAddNewListContent: ComponentType<PropsTrackingAddNewListFlow> = Loadable(
	{
		loader: () => import("./AddNewList")
	}
);

const TrackingAddNewListFlow: React.FC<PropsTrackingAddNewListFlow> = props => (
	<TrackingAddNewListContent {...props} />
);

export default TrackingAddNewListFlow;
