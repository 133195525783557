import Loadable from "app/utils/Loadable";
import Layout from "app/components/includes/layout";

const EmailVerificationConfirmed = Loadable({
	loader: () => import("./EmailVerificationConfirmed")
});

const PageEmailVerificationConfirmed = () => {
	return (
		<Layout>
			<EmailVerificationConfirmed />
		</Layout>
	);
};

export default PageEmailVerificationConfirmed;
