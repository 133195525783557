import { NavLink } from "react-router-dom";
import cl from "classnames/bind";

import style from "./style.module.scss";
import { matchPathByNames } from "./helper";
import { ICheckAction, IMenuItem } from "./types";

interface props {
	data: IMenuItem;
	isSubItem?: boolean;
	nesting?: number;
	onCheckAction: ICheckAction;
}

export const MenuItem: React.FC<props> = ({
	data,
	isSubItem = false,
	nesting,
	onCheckAction
}) => {
	const cs = cl.bind(style);
	const { caption, icon, link, matchPaths, to, action } = data;

	if (!link && !to) {
		return (
			<li key={caption}>
				<button
					className={style.aside_list__item}
					onClick={() => {
						onCheckAction(action);
					}}
				>
					{icon}
					{caption}
				</button>
			</li>
		);
	}

	if (link) {
		return (
			<li key={caption}>
				<a
					href={link}
					target="_blank"
					className={style.aside_list__item}
					rel="noreferrer"
				>
					{icon}
					{caption}
				</a>
			</li>
		);
	}

	return (
		<li key={caption}>
			<NavLink
				to={to}
				isActive={(match, location) => {
					return matchPathByNames(match, location, matchPaths);
				}}
				className={cs("aside_list__item", `aside_list__item--nesting-${nesting}`, {
					"aside_list__item--sub": isSubItem
				})}
			>
				<>{icon}</>
				{caption}
			</NavLink>
		</li>
	);
};
