import React, { useState } from "react";

import cn from "classnames";
import { useHistory } from "react-router-dom";

import { DownArrow } from "app/icons";
import Button from "app/uikit-V2/Button";
import { ICompany, ICompanyPerson } from "app/screens/cabinet/tracking/types";
import { parseUrl } from "app/helper";
import PATHS from "PATHS";
import { getTriggerColors } from "app/helper/tracking.helper";
import CardPerson from "app/screens/cabinet/tracking/details/components/CardPerson";

import styles from "./style.module.scss";

interface Props {
	data: ICompany;
	trackingListId?: string;
	userId?: string;
	onRemovePerson?: (
		id: string,
		companyId: string,
		name?: string
	) => Promise<void>;
}

const CompanyCard: React.FC<Props> = ({
	data: {
		name,
		website_url,
		events,
		persons,
		is_changed_from_external_source,
		id
	} = {},
	trackingListId,
	userId,
	onRemovePerson
}) => {
	const [open, setOpen] = useState(false);
	const history = useHistory();

	const url = parseUrl(website_url);

	const toggleOpen = () => {
		setOpen(state => !state);
	};

	const goToCompanyDetails = () => {
		history.push(
			PATHS.adminPanel.userLeadsCompanyDetails.url(userId, trackingListId, id)
		);
	};

	return (
		<div className={styles.card}>
			<div className={styles.card__content}>
				<div className={styles.card__info}>
					<p className={styles.card__info_name}>{name}</p>
					<p className={styles.card__info_site}>
						<a href={website_url} rel="noreferrer">
							{url.resource || url.href}
						</a>
					</p>
				</div>

				<div className={styles.card__trigger}>
					{events && (
						<ul className={styles.card__trigger_list}>
							{is_changed_from_external_source && <li>New update on Company</li>}
							{events?.map(item => (
								<li style={getTriggerColors(item.id)} key={item.id}>
									{item.name}
								</li>
							))}
						</ul>
					)}
				</div>
				<Button
					size="medium"
					variant="text"
					className={cn(styles["btn-toggle"], styles.card__btn_info)}
					onClick={toggleOpen}
				>
					{(open && (
						<>
							Hide prospects
							<DownArrow
								color="currentColor"
								className={styles["arrow-up"]}
								width={14}
								height={14}
							/>
						</>
					)) || (
						<>
							Show prospects <DownArrow color="currentColor" width={14} height={14} />
						</>
					)}
				</Button>
				<Button size="medium" onClick={goToCompanyDetails}>
					Company details
				</Button>
			</div>
			{open && (
				<div className={styles.card__additional}>
					<p className={styles.card__additional_title}>Prospects</p>
					<div className={styles.card__additional_content}>
						{persons.map((item: ICompanyPerson, i) => {
							const viewLink =
								trackingListId &&
								userId &&
								PATHS.adminPanel.userLeadsPersonDetails.url(
									userId,
									trackingListId,
									id,
									item.id
								);
							const name =
								!item.first_name && !item.last_name
									? "Name is not provided"
									: `${item.first_name} ${item.last_name}`;

							const onDelete = () => {
								onRemovePerson(item.id, id, name);
							};

							return (
								<CardPerson
									key={i}
									onDelete={onDelete}
									name={name}
									position={item.position_name}
									viewLink={viewLink}
									email={item.email}
									events={item.events}
									linkedInUrl={item.linked_in_url}
								/>
							);
						})}
					</div>
					<div className={styles.card__additional_footer}>
						<Button
							size="medium"
							variant="text"
							className={styles["btn-toggle"]}
							onClick={toggleOpen}
						>
							Hide prospects
							<DownArrow
								color="currentColor"
								className={styles["arrow-up"]}
								width={14}
								height={14}
							/>
						</Button>
					</div>
				</div>
			)}
		</div>
	);
};

export default CompanyCard;
