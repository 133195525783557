import { Tooltip, TooltipProps } from "@material-ui/core";

import { Info as IconInfo } from "app/icons";

import style from "./style.module.scss";

interface props extends Omit<TooltipProps, "children" | "title"> {
	info?: NonNullable<React.ReactNode> | string;
}

const Info: React.FC<props> = ({ info, placement = "right", ...rest }) => (
	<Tooltip title={info} placement={placement} {...rest}>
		<div className={style.info}>
			<IconInfo />
		</div>
	</Tooltip>
);
export default Info;
