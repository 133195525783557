import React, { useMemo } from "react";

import { IPermissionsTypes } from "app/enum/permissions";

import { filterMenuList } from "./helper";
import { AsideMenuList } from "./menuLiist";
import { ICheckAction, IMenuItem } from "./types";

interface props {
	permissions: IPermissionsTypes;
	list: IMenuItem[];
	token: string;
	onCheckAction: ICheckAction;
}

export const AsideMenu: React.FC<props> = ({
	permissions,
	list,
	token,
	onCheckAction
}) => {
	const formatedList: IMenuItem[] = useMemo(() => {
		return filterMenuList(list, token, permissions);
	}, [list, token, permissions]);

	return <AsideMenuList list={formatedList} onCheckAction={onCheckAction} />;
};
