// @ts-nocheck

import { put } from "redux-saga/effects";

import directory from "app/directory/errors";
import toastify from "app/utils/toastify";

const dir = directory.en;

export const stateRequest = (state: any, action: any, requestName: any) => {
	let loading = false;
	let error = "";
	const property = action.propertyName;
	switch (requestName) {
		case "request":
			loading = true;
			break;
		case "failure":
			error = action.error;
			break;
		default:
			break;
	}
	const defaultLoading = { ...state.isLoading, [action.actionName]: loading };
	return {
		isLoading:
			(property &&
				(((property === true || property === "id") && {
					...state.isLoading,
					[action.actionName]: {
						...state.isLoading[action.actionName],
						[action.id]: loading
					}
				}) || {
					...state.isLoading,
					[action.actionName]: {
						...state.isLoading[action.actionName],
						[property]: loading
					}
				})) ||
			defaultLoading,
		error: { ...state.error, [action.actionName]: error }
	};
};

export const getPaginationAfterDelete = (
	state: any,
	data: any,
	itemName: any,
	paginationName: any,
	property: any
) => {
	let newPagination = state.pagination;
	if (
		(property && data >= state[itemName][property].items.length) ??
		data >= state[itemName].items.length
	) {
		if (property) {
			newPagination[property].page = 1;
			newPagination[property].offset = null;
		} else if (paginationName) {
			newPagination[paginationName].page = 1;
			newPagination[paginationName].offset = null;
		} else {
			newPagination.page = 1;
			newPagination.offset = null;
		}
	}
	return newPagination;
};

export const countOffset = (page: number, limit: number) => {
	if (page < 1) {
		return null;
	}

	return limit * (page - 1);
};

export const mappedOffset = (
	data?: { limit?: number; page: number },
	pg?: any = {}
) => {
	if (data?.limit || data?.page === 1) {
		return null;
	} else if (data.page) {
		return (data.page - 1) * (pg.limit || data?.limit);
	} else {
		return pg.offset;
	}
};

export const getRecalkPagination = (
	state: any,
	pagination: any,
	data: any,
	target: any
) => {
	let newPagination = pagination;

	if (data >= state[target].items.length) {
		newPagination.page = 1;
		newPagination.offset = null;
	}
	return newPagination;
};

export const updateTotalPagination = (
	state: any,
	data: any,
	actionName: any
) => {
	let value = { ...state.pagination };
	if (actionName) {
		value[actionName] = {
			...state.pagination[actionName],
			total: data.count || 0
		};
	} else {
		value = {
			...state.pagination,
			total: data.count || 0
		};
	}
	return value;
};

export const getPagination = (state: any, data: any, actionName: any) => {
	let value = { ...state.pagination };
	if (actionName) {
		value[actionName] = {
			...state.pagination[actionName],
			...data,
			offset: mappedOffset(data, state.pagination[actionName])
		};
	} else {
		value = {
			...state.pagination,
			...data,
			offset: mappedOffset(data, state.pagination)
		};
	}
	return value;
};

export const getDefaultPagination = (state: any, actionName: any) => {
	let value = { ...state.pagination };
	if (actionName) {
		value[actionName] = {
			...state.pagination[actionName],
			page: 1,
			offset: null
		};
	} else {
		value.page = 1;
		value.offset = null;
	}
	return value;
};

export const getFilter = (state: any, data: any, actionName: any) => {
	let value = { ...state.filter };
	if (actionName) {
		value[actionName] = {
			...state.filter[actionName],
			...data
		};
	} else {
		value = { ...state.filter, ...data };
	}
	return value;
};

export const getInitialValueActionName = <T, K extends string | boolean>(
	data: T,
	value: k = false
): Record<keyof typeof T, K> => {
	return Object.keys(data).reduce((acc, val) => ({ ...acc, [val]: value }), {});
};

export const errorsHandler = (
	fn: (a: string, b: any, c?: string) => void,
	error: any,
	actionName?: string,
	propertyName?: any
) => {
	if (process?.env?.NODE_ENV === "development") {
		console.log(error);
		console.error(error);
	}

	if (error?.response?.status === 422) {
		return put(fn(actionName, error?.response?.data?.errors || "", propertyName));
	} else if (error?.response?.status === 403) {
		return put(fn(actionName, error?.response?.data?.message, propertyName));
	} else {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		return put(fn(actionName, "", propertyName));
	}
};
