// @ts-nocheck
import { stateRequest, getInitialValueActionName } from "../helper";

import types from "./types";
import { ACTIONS_NAMES } from "./actions";

export const initialState = {
	error: getInitialValueActionName(ACTIONS_NAMES, ""),
	isLoading: getInitialValueActionName(ACTIONS_NAMES)
};

const reducer = function (state = initialState, action) {
	switch (action.type) {
		case types.UPLOAD_SUPPORT_REQUEST:
			return {
				...state,
				...stateRequest(state, action, "request")
			};
		case types.UPLOAD_SUPPORT_FAILURE:
			return {
				...state,
				...stateRequest(state, action, "failure")
			};
		case types.UPLOAD_SUPPORT_SUCCESS:
			return {
				...state,
				...stateRequest(state, action, "success")
			};

		default:
			return state;
	}
};

export default reducer;
