// @ts-nocheck
import React from "react";

import Popper from "@material-ui/core/Popper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ButtonBase from "@material-ui/core/ButtonBase";
import InputBase from "@material-ui/core/InputBase";
import { InputAdornment } from "@material-ui/core";
import cl from "classnames/bind";

import Input from "app/uikit/input";

import { DownArrow, Search } from "../../icons";

import useStyles from "./style";

const AutocompleteBase = ({
	name,
	error,
	placeholder,
	value,
	onChange,
	options,
	noOptionsText,
	disablePortal = true,
	flip = false
}) => {
	const classes = useStyles();
	const cx = cl.bind(classes);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [pendingValue, setPendingValue] = React.useState([]);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleChange = (event, newValue) => {
		setPendingValue("");
		if (newValue !== null) {
			onChange(newValue);
		}
	};
	const handleClose = (event, reason) => {
		if (reason === "toggleInput") {
			return;
		}
		if (anchorEl) {
			(anchorEl as any).focus();
		}
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<>
			<ButtonBase
				disableRipple
				className={cx("button", { error: error })}
				onClick={handleClick}
			>
				<Input
					name={name}
					id={name}
					readOnly={true}
					value={value?.name || ""}
					fullWidth
					iconPosition="end"
					icon={<DownArrow />}
				/>
			</ButtonBase>

			<Popper
				id={`${name}-popper`}
				open={open}
				style={{ minWidth: (anchorEl as any)?.clientWidth }}
				anchorEl={anchorEl}
				modifiers={{
					flip: {
						enabled: true
					},
					preventOverflow: {
						enabled: false,
						boundariesElement: "scrollParent"
					}
				}}
				placement="bottom"
				className={classes.popper}
			>
				<Autocomplete
					open
					disablePortal
					onClose={handleClose}
					value={pendingValue}
					onChange={handleChange}
					renderTags={() => null}
					noOptionsText={noOptionsText || "No items have been found by your request"}
					classes={{
						paper: classes.paper,
						option: classes.option,
						popperDisablePortal: classes.popperDisablePortal
					}}
					renderOption={option => (
						<React.Fragment>
							<div className={classes.text}>
								<span className="main-text main-text_bold">{(option as any).name}</span>

								<br />

								{(option as any).name && (
									<span className="small-text">{(option as any).description}</span>
								)}
							</div>
						</React.Fragment>
					)}
					options={options}
					getOptionLabel={option => (option as any).name || ""}
					renderInput={params => (
						<InputBase
							ref={params.InputProps.ref}
							inputProps={params.inputProps}
							autoFocus
							className={cl(classes.inputSearch, classes.inputBase)}
							placeholder={placeholder}
							fullWidth
							startAdornment={
								<InputAdornment position="start">
									<Search />
								</InputAdornment>
							}
						/>
					)}
				/>
			</Popper>
		</>
	);
};
export default AutocompleteBase;
