import { memo } from "react";

import isEqual from "react-fast-compare";

import { FormError } from "app/uikit-V2/Form/FormComponents";

import type { IRadioProps } from "./types";

import Radio from "./index";

const RadioGroup: React.FC<IRadioProps> = ({
	list,
	error,
	onChange,
	value,
	canDisplayError,
	wrapperClassName,
	labelClassName,
	checkedValue,
	name,
	...rest
}) => (
	<div>
		{list.map(
			({
				value,
				label,
				value: radioValue,
				className,
				onChangedTrigger,
				labelClassName: labelClassNameItem
			}) => {
				const isChecked = checkedValue === radioValue;

				return (
					<Radio
						onChange={(e, ...rest) => {
							onChange(e, ...rest);
							if (onChangedTrigger) {
								onChangedTrigger(name, e.target.checked);
							}
						}}
						{...rest}
						name={name}
						wrapperClassName={className || wrapperClassName}
						labelClassName={labelClassNameItem || labelClassName}
						key={value}
						value={radioValue}
						checked={isChecked}
						label={label}
					/>
				);
			}
		)}

		{canDisplayError && <FormError error={error} />}
	</div>
);

export default memo(RadioGroup, isEqual);

RadioGroup.defaultProps = {
	error: "",
	label: ""
};
