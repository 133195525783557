import { makeStyles } from "@material-ui/core/styles";

import { IStyleProps } from "./type";

export const useStyles = makeStyles({
	wrapper: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		position: ({ position }: IStyleProps) => position,
		height: ({ size, height }: IStyleProps) => height || size,
		width: ({ size, width }: IStyleProps) => width || size,
		backgroundColor: ({ colorBg }: IStyleProps) => colorBg || "transparent",
		animation: ({ delay }: IStyleProps) =>
			`fade-in 0s linear backwards infinite ${delay}s`,
		"& .MuiCircularProgress-indeterminate": {
			animation: ({ delay }: IStyleProps) =>
				`MuiCircularProgress-keyframes-circular-rotate 1.4s linear infinite ${delay}s`
		},
		"& .MuiCircularProgress-svg": {
			animation: ({ delay }: IStyleProps) =>
				`fade-in 0s linear backwards infinite ${delay}s`
		}
	},

	circle: {
		color: ({ color }: IStyleProps) => color || "#fff"
	},

	center: {
		marginLeft: "auto",
		marginRight: "auto"
	},

	centerAbsolute: {
		position: "absolute !important" as any,
		zIndex: 500,
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)"
	}
});
