import React, { memo } from "react";

import isEqual from "react-fast-compare";

// import { FormikAutocomplete } from '@components/shared/Form/Autocomplete';
// import { FormikSwitch } from '@components/shared/Form/Switch';
// import { FormikRadio } from '@components/shared/Form/Radio';
import Checkbox from "app/uikit-V2/Form/Checkbox";
import Radio from "app/uikit-V2/Form/Radio";
import SelectChip from "app/uikit-V2/Form/SelectChip";
import Input from "app/uikit-V2/Form/Input";
import Select from "app/uikit-V2/Form/Select";
// import { FormikTimeInput } from '@components/shared/Form/TimeInput';
// import { FormikDateInput } from '@components/shared/Form/DateInput/DateInput';
// import { FormikFormInput } from '@components/shared/Form/Input';
// import { FormikFilePicker } from '@components/shared/Form/FilePicker';
// import { FormikFormTag } from '@components/shared/Form/Tag/Tag';
import { FormFieldProps, FieldTypes } from "app/uikit-V2/types";

const fieldTypeComponents = {
	// [FieldTypes.autocomplete]: FormikAutocomplete,
	[FieldTypes.radio]: Radio,
	[FieldTypes.checkbox]: Checkbox,
	[FieldTypes.selectChip]: SelectChip,
	[FieldTypes.select]: Select
	// [FieldTypes.switch]: FormikSwitch,
	// [FieldTypes.radio]: FormikRadio,
	// [FieldTypes.file]: FormikFilePicker,
	// [FieldTypes.date]: FormikDateInput,
	// [FieldTypes.time]: FormikTimeInput,
	// [FieldTypes.tag]: FormikFormTag
};

const FormField: React.FC<FormFieldProps> = ({ type, ...props }) => {
	//@ts-ignore
	const Component = fieldTypeComponents[type] || Input;
	return <Component type={type} {...props} />;
};

FormField.defaultProps = {
	type: FieldTypes.text
};

export default memo(FormField, isEqual);
