import CircularProgress from "app/uikit-V2/CircularProgress";

import styles from "./style.module.scss";

const LoadingPage = () => (
	<div className={styles.container}>
		<CircularProgress
			color="#000"
			colorBg="rgba(0,0,0,0.03)"
			size={50}
			variant="center-absolute"
			height="100%"
			width="100%"
			delay={0.5}
		/>
	</div>
);

export default LoadingPage;
