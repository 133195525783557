import React, { memo } from "react";

import { Formik } from "formik";
import isEqual from "react-fast-compare";

import Modal from "app/components/modal";
import FormikField from "app/uikit-V2/Formik/FormikField/FormikField";
import Button from "app/uikit-V2/Button/Button";

import styles from "./style.module.scss";
import validationSchema from "./validationSchema";

interface Props {
	open: boolean;
	setKeyWords: (keywords: string[]) => void;
	onClose: (save?: boolean) => void;
	keyWords?: [] | string[];
}

const KeyWorksModal: React.FC<Props> = ({
	open,
	keyWords,
	setKeyWords,
	onClose
}) => {
	return (
		<Modal
			title={"Specify keywords for tracking"}
			headerContent="Press Enter after each keyword entry"
			classNameHeader={styles.header}
			onClose={onClose}
			open={open}
		>
			<Formik
				initialValues={{
					keywords: keyWords || []
				}}
				validationSchema={validationSchema}
				onSubmit={values => {
					setKeyWords(values.keywords);
					onClose(true);
				}}
			>
				{({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<FormikField
							classNameField={styles.input}
							fullWidth
							type="selectChip"
							name="keywords"
							autoFocus
						/>
						<div className={styles.footer}>
							<Button type="submit">Apply</Button>
						</div>
					</form>
				)}
			</Formik>
		</Modal>
	);
};

export default memo(KeyWorksModal, isEqual);
