import React, { useLayoutEffect } from "react";

import { useDispatch } from "react-redux";
import { useLastLocation } from "react-router-last-location";

import UserLayout from "app/components/includes/UserLayout";
import actions from "app/store/trends/actions";
import { checkSamePath } from "app/helper";
import PATHS from "PATHS";
import { useIsAdmin } from "app/hooks/useIsAdmin";

import Reports from "./Reports";

const PageScreenReports: React.FC = () => {
	const dispatch = useDispatch();
	const { isAdmin, loading } = useIsAdmin();
	const lastLocation = useLastLocation();

	useLayoutEffect(() => {
		if (checkSamePath(PATHS.cabinet.trends.reports.path, lastLocation)) {
			dispatch(actions.resetTrends());
		}

		if (!loading) {
			dispatch(actions.getTrends(isAdmin));
		}
	}, [dispatch, loading, isAdmin, lastLocation]);

	return (
		<UserLayout>
			<Reports />
		</UserLayout>
	);
};

export default PageScreenReports;
