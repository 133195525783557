// @ts-nocheck
/* eslint-disable no-useless-escape */
import moment from "moment";
import arseUrl from "parse-url";

import localStore from "app/helper/local-store";
import { IFile, IPagination, ISelectOption } from "app/types";
import { countOffset } from "app/store/helper";
import { IHandBookItem } from "app/store/handBooks/reducer";

import { getBase64Content } from "./helper.files";
import { globalHandlerError } from "./handlerErrors";

export function parseQuery(queryString: any) {
	let query = {};
	let pairs = (queryString[0] === "?"
		? queryString.substr(1)
		: queryString
	).split("&");
	for (let i = 0; i < pairs.length; i++) {
		let pair = pairs[i].split("=");
		query[pair[0]] = decodeURIComponent(pair[1] || "");
	}
	return query;
}
export function urlEncodeQueryParams(data) {
	const params = Object.keys(data).map(key =>
		data[key] ? `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}` : ""
	);
	return params.filter(value => !!value).join("&");
}

export const setInfo = user => {
	localStore.setInfo(user);
};

export const isFormValid = form =>
	!form.dirty || !!Object.keys(form.errors).length;

export const getCheckedItem = checked => {
	const ids = { ...checked };
	delete ids["id-all"];
	for (let mark in ids) {
		if (!ids[mark]) {
			delete ids[mark];
		}
	}
	const mapperIds = Object.keys(ids)
		.map(el => el.slice(3))
		.join(",");
	return mapperIds;
};

export const mappedParams = params => {
	if (!params) {
		return {};
	}
	let newParams = { ...params, limit: params.limit };
	delete newParams.total;
	delete newParams.page;
	for (let mark in newParams) {
		if (!newParams[mark]) {
			delete newParams[mark];
		}
	}
	return newParams;
};

interface IPrepareParamsProps {
	pagination?: IPagination;
	filter?: Record<string, any>;
}

const deleteEmptyElem = obj => {
	for (let mark in obj) {
		if (!obj[mark]) {
			delete obj[mark];
		}
	}
};

export const prepareParams = (
	props: IPrepareParamsProps
): {
	pagination: {
		limit?: number;
		offset?: number;
		total?: number;
		page: number;
	};
	filter: {
		[x: string]: any;
	};
} => {
	if (!props) {
		return {};
	}

	let newParams = {
		pagination: { ...props.pagination },
		filter: { ...props.filter }
	};

	if (newParams?.pagination?.page && newParams?.pagination?.limit) {
		newParams.pagination.offset = countOffset(
			newParams.pagination.page,
			newParams.pagination.limit
		);
	}

	delete newParams?.pagination?.total;
	delete newParams?.pagination?.page;

	for (let mark in newParams) {
		deleteEmptyElem(newParams[mark]);
	}

	for (let key in newParams?.filter) {
		newParams.filter[`filter[${key}]`] = newParams.filter[key];
		delete newParams.filter[key];
	}

	return { ...newParams.pagination, ...newParams.filter };
};

export const domainFromUrl = url => {
	let result;
	let match;
	if (
		(match = url.match(
			/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im
		))
	) {
		result = match[1];
		if ((match = result.match(/^[^\.]+\.(.+\..+)$/))) {
			result = match[1];
		}
	}
	return result;
};

export const getTableItemsById = (ids, data) => {
	if (ids && Number.isInteger(ids)) {
		return data.find(el => el.id === ids);
	}
	const elems = (Array.isArray(ids) && ids) || (ids && ids.split(",")) || [];
	return elems.map(elem => data.find(el => el.id === +elem));
};

export const checkSamePath = (path, lastLocation) =>
	lastLocation && lastLocation.pathname.indexOf(path) === -1;

export const formateDate = (value = "") => moment(value).format("DD MMM, YYYY");

export const compare = function (obj1, obj2) {
	for (let p in obj1) {
		if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;
		switch (typeof obj1[p]) {
			case "object":
				if (!(Object as any).compare(obj1[p], obj2[p])) return false;
				break;
			case "function":
				if (
					typeof obj2[p] == "undefined" ||
					(p !== "compare" && obj1[p].toString() !== obj2[p].toString())
				)
					return false;
				break;
			default:
				if (obj1[p] !== obj2[p]) return false;
		}
	}
	for (let p in obj2) {
		if (typeof obj1[p] == "undefined") return false;
	}
	return true;
};

export const hasAnyAdAcounts = adAccounts => {
	let obj = {
		hasActiveAccounts: false,
		canCreateCampaign: false
	};

	for (let mark in adAccounts) {
		if (adAccounts[mark]?.ad_accounts?.length) {
			obj.hasActiveAccounts = true;
		}
	}

	for (let mark in adAccounts) {
		adAccounts[mark]?.ad_accounts?.forEach(el => {
			if (el.is_can_create_campaigns) {
				obj.canCreateCampaign = true;
			}
		});
	}

	return obj;
};

export const getListWithCategory = (namesList, lists) => {
	return namesList?.map((elem, i) => ({
		category: {
			name: elem.name,
			value: elem.id
		},
		list:
			lists[i]?.map(elem => ({
				text: elem.name,
				value: elem.id,
				info: elem.description
			})) || []
	}));
};

enum IMapFilterMethods {
	stringIds = "stringIds",
	stringNames = "stringNames"
}

export const mapFilter = (
	value: any[],
	method: keyof typeof IMapFilterMethods
) => {
	switch (method) {
		case IMapFilterMethods.stringIds:
			return value.map(el => el.id).join(",");
		case IMapFilterMethods.stringNames:
			return value.map(el => el.name).join(",");
	}
};

export const serializedData = values => {
	const obj = {};
	if (!values) return obj;
	for (let i in values) {
		if (Array.isArray(values[i])) {
			const filtered = values[i].filter(item => item);
			if (filtered.length) {
				obj[`filter[${i}]`] = filtered.map(el => el?.id || el).join(",");
			}
		} else if (typeof values[i] === "object") {
			if (values[i]) {
				obj[`filter[${i}]`] = values[i].id;
			}
		} else {
			if (values[i]) {
				obj[`filter[${i}]`] = values[i];
			}
		}
	}
	return obj;
};

export const checkByNumber = value => {
	if (value.match(/^(?=\d*[0-9])\d+$/) || value === "") {
		return true;
	}
	return false;
};

export const extractFieldFromObjectsArray = (arr = [], fieldKey) =>
	arr.map(item => item[fieldKey]);

export const getBooleanValues = value => {
	if (value === "true") {
		return true;
	} else if (value === "false") {
		return false;
	}
	return value;
};

export const prepareFile = async (file: File): IFile => ({
	name: file.name,
	content: await getBase64Content(file),
	mime: file.type
});

export const parseUrl = (url): { resource: string; href: string } => {
	try {
		return arseUrl(url);
	} catch (err) {
		globalHandlerError(err, false);
		return { resource: url, href: url };
	}
};

export const isEqualArrays = (a: any[], b: any[]): boolean => {
	if (a instanceof Array && b instanceof Array) {
		if (a.length !== b.length) {
			return false;
		}

		for (var i = 0; i < a.length; i++) {
			if (!isEqualArrays(a[i], b[i])) {
				return false;
			}
		}

		return true;
	}

	return a === b;
};

export const removeEmptyValues = obj => {
	const copiedObj = { ...obj };
	Object.entries(copiedObj).forEach(([key, val]) => {
		if (val === undefined) {
			delete copiedObj[key];
		}
	});

	return copiedObj;
};

export const prepareHandBookOptions = (
	options: IHandBookItem[]
): ISelectOption[] =>
	options?.map(({ id, name }) => ({ label: name, value: id })) || [];
