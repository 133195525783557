import { AxiosResponse } from "axios";

import { globalHandlerError } from "app/helper/handlerErrors";
import { ApiUrls } from "app/api/apiUrls";
import { API } from "app/api";
import {
	IPerson,
	IResponsePersonDetails
} from "app/screens/cabinet/tracking/types";

export const getPersonDetails = async (
	userId: string,
	trackingId: string,
	companyId: string,
	personUuid: string,
	cb?: React.Dispatch<React.SetStateAction<IPerson>>
): Promise<IResponsePersonDetails | IPerson> => {
	try {
		if (!userId) return;
		const response: AxiosResponse<IResponsePersonDetails> = await API.get(
			ApiUrls.admin.users.leadObservation.companies.person.details(
				userId,
				trackingId,
				companyId,
				personUuid
			)
		);
		if (cb) {
			cb(response?.data?.person);
		}

		return response.data;
	} catch (err) {
		globalHandlerError(err);
	}
};
