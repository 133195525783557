import React, { memo } from "react";

import moment from "moment";
import { Link } from "react-router-dom";

import notfoundImg from "assets/img/notfound.png";
import { ITrendItem } from "app/store/trends/type";
import { DeleteIcon, Edit } from "app/icons";
import Button from "app/uikit/button";
import PATHS from "PATHS";

import style from "./style.module.scss";

interface props {
	data: ITrendItem;
	onDelete?: (info: ITrendItem) => void;
	isAdmin?: boolean;
}

const TrendCard: React.FC<props> = ({ data, isAdmin, onDelete }) => (
	<div className={style.card}>
		<div className={style.card__img}>
			<img src={data.file?.url || notfoundImg} alt={data.name} />
		</div>

		<div className={style.card__content}>
			{isAdmin && (
				<div className={style.card__tool_bar}>
					<Link
						className={style.card__tool_bar_item}
						to={PATHS.adminPanel.trendEdit.replace(":id", String(data.id))}
					>
						<Edit />
					</Link>
					<Button
						className={style.card__tool_bar_item}
						onClick={() => onDelete && onDelete(data)}
					>
						<DeleteIcon />
					</Button>
				</div>
			)}
			<h3 className={style.card__title}>{data.name}</h3>

			<p className={style.card__date}>
				<svg
					width="14"
					height="14"
					viewBox="0 0 14 14"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<rect
						x="1"
						y="3"
						width="12"
						height="10"
						stroke="#D4D7DF"
						strokeWidth="2"
					/>

					<rect x="2" width="3" height="4" fill="#D4D7DF" />

					<rect x="9" width="3" height="4" fill="#D4D7DF" />

					<rect y="2" width="14" height="4" fill="#D4D7DF" />
				</svg>{" "}
				{moment(data.datetime).format("MMMM DD, YYYY")}
			</p>

			<p className={style.card__desc}>{data.short_description}</p>

			<Link
				to={PATHS.cabinet.trends.reports.details.url(data.id)}
				className="button button_gradient button_small"
			>
				Read report
			</Link>
		</div>
	</div>
);

export default memo(TrendCard);
