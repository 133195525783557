import { useCallback, useMemo } from "react";

import { Location } from "history";
import { useLastLocation } from "react-router-last-location";

import { Nullable } from "app/types";

let data: Record<string, any> = {};

const useSaveParamsByLocation = (
	key: string,
	{
		initialValues,
		condition
	}: {
		initialValues: any;
		condition: (lastLocation: Nullable<Location<unknown>>) => boolean;
	}
) => {
	const lastLocation = useLastLocation();

	const value = useMemo(() => {
		if (condition(lastLocation)) {
			return data[key] || initialValues;
		}
		return initialValues;
	}, [condition, lastLocation, key, initialValues]);

	const setValue = useCallback(
		(value: any) => {
			data[key] = value;
		},
		[key]
	);

	return {
		value,
		setValue
	};
};

export default useSaveParamsByLocation;
