import yup from "app/yup";
import directory from "app/directory/errors";
import { ErrorWebSite } from "app/errors-shcema";

const dir = directory.en;

const validationSchema = yup.object({
	companies_set_id: yup.mixed().nullable().required(dir.required),
	company_name: yup.string().max(256).required(dir.required),
	website_url: ErrorWebSite,
	position_name: yup.string().max(256).required(dir.required)
});

export default validationSchema;
