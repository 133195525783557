import Button from "app/uikit-V2/Button";

import { PrevIcon } from "./Icon";
import style from "./style.module.scss";

interface Props {
	onNext?: () => void;
	onPrev?: () => void;
	prevBtnContent?: React.ReactNode;
	nextBtnContent?: React.ReactNode;
	loadingNextBtn?: boolean;
}

const ButtonsControls: React.FC<Props> = ({
	onNext,
	onPrev,
	nextBtnContent = "Continue",
	prevBtnContent,
	loadingNextBtn
}) => (
	<div className={style.wrapAction}>
		{onPrev && (
			<Button onClick={onPrev} className={style.prevBtn} variant="secondary">
				{prevBtnContent ?? (
					<div className={style.prevBtnIcon}>
						<PrevIcon />
					</div>
				)}
			</Button>
		)}
		{onNext && (
			<Button className={style.nextBtn} onClick={onNext} loading={loadingNextBtn}>
				{nextBtnContent}
			</Button>
		)}
	</div>
);

export default ButtonsControls;
