import { makeStyles } from "@material-ui/core/styles";

export const useStylesRadioBase = makeStyles({
	root: {
		"& MuiIconButton-label": {
			position: "relative"
		},
		"& input": {
			position: "absolute",
			left: 0,
			top: 0,
			width: "100%",
			height: "100%"
		}
	},
	icon: {
		width: 18,
		height: 18,
		borderRadius: "50%",
		border: "1px solid #CFD3DE"
	},
	iconDisabled: {
		borderColor: "#CFD3DE !important"
	},
	checkedIcon: {
		border: "6px solid #EB7D68"
	}
});

export const useStylesRadioWithLabel = makeStyles(theme => ({
	root: {
		margin: -9,
		marginRight: 0,
		gap: 15,
		position: "relative",

		"&.MuiFormControlLabel-labelPlacementBottom, &.MuiFormControlLabel-labelPlacementTop, &.MuiFormControlLabel-labelPlacementStart": {
			gap: 5,
			marginLeft: 0
		},
		"& .MuiBox-root": {
			color: "#3E4555",
			fontSize: "16px",
			lineHeight: "26px",
			display: "inline-block"
		}
	},
	container: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center"
	},
	error: {
		"& .MuiTypography-root": {
			color: theme.palette.error.main
		}
	},
	errorRadio: {
		borderColor: theme.palette.error.main
	},
	wrapper: {
		marginBottom: 16,
		marginTop: 8

		// "& .MuiFormControlLabel-label": {
		// 	marginLeft: 15
		// }
	},
	noGutters: {
		margin: 0
	}
}));
