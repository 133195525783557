import { AnyAction } from "redux";

import localStore from "app/helper/local-store";

import types from "./types";

const tokenStorage = localStore.getAccessToken();

export type IAuth = {
	token: string;
	passwordChanged: boolean;
	countRegistered: boolean;
	error: string;
	isLoading: boolean;
};

export const initialState = {
	token: tokenStorage,
	passwordChanged: false,
	countRegistered: false,
	error: "",
	isLoading: false
};

const reducer = function (state: IAuth = initialState, action: AnyAction) {
	switch (action.type) {
		case types.FETCH_SIGN_IN_REQUEST:
		case types.FETCH_SIGN_UP_REQUEST:
		case types.FETCH_PASSWORD_RECOVERY_REQUEST:
		case types.FETCH_NEW_PASSWORD_REQUEST:
			return {
				...state,
				isLoading: true,
				passwordChanged: false,
				error: ""
			};
		case types.FETCH_SIGN_IN_SUCCESS:
			return {
				...state,
				...action.data,
				isLoading: false
			};
		case types.FETCH_SIGN_UP_SUCCESS:
			return {
				...state,
				isLoading: false,
				error: "",
				countRegistered: true
			};
		case types.FETCH_PASSWORD_RECOVERY_SUCCESS:
		case types.FETCH_NEW_PASSWORD_SUCCESS:
			return {
				...state,
				isLoading: false,
				passwordChanged: true,
				error: ""
			};
		case types.FETCH_SIGN_IN_FAILURE:
		case types.FETCH_SIGN_UP_FAILURE:
		case types.FETCH_NEW_PASSWORD_FAILURE:
		case types.FETCH_PASSWORD_RECOVERY_FAILURE:
			return {
				...state,
				isLoading: false,
				passwordChanged: false,
				countRegistered: false,
				error: action.error
			};
		case types.FETCH_RESET_ACTION_STATE:
			return {
				...state,
				isLoading: false,
				passwordChanged: false,
				countRegistered: false,
				error: ""
			};
		default:
			return state;
	}
};

export default reducer;
