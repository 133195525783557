export const IconArrow: React.FC<{ open?: boolean }> = ({ open }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="10"
		viewBox="0 0 16 10"
		fill="none"
		style={{
			transition: "0.3s",
			transform: `rotate(${open ? 180 : 0}deg)`
		}}
	>
		<path d="M1 1L8 8L15 1" stroke="#38465D" strokeWidth="1.5" />
	</svg>
);
