import React, { memo } from "react";

import { Container, Grid } from "@material-ui/core";

import TermsAndPrivacy from "../TermsAndPrivacy";

import style from "./style.module.scss";
import Header from "./header";
import Wrapper from "./wrapper";
import Main from "./main";
import Footer from "./footer";

const Layout = (props: any) => (
	<Wrapper gray={props.gray}>
		<Header className={props.header} />
		<Main>{props.children}</Main>
		<Footer>
			<Container maxWidth={false}>
				<Grid container justify="space-between" alignItems="center">
					<Grid item>
						<span className={style.text}>
							&copy; {new Date().getFullYear()} Tenoris Inc.
						</span>
					</Grid>
					<Grid item>
						<TermsAndPrivacy />
					</Grid>
				</Grid>
			</Container>
		</Footer>
	</Wrapper>
);

export default memo(Layout);
