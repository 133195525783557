import Loadable from "app/utils/Loadable";
import Layout from "app/components/includes/layout";

const EmailVerification = Loadable({
	loader: () => import("./EmailVerification")
});

const PageEmailVerification = () => {
	return (
		<Layout>
			<EmailVerification />
		</Layout>
	);
};

export default PageEmailVerification;
