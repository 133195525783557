import { memo, ChangeEvent } from "react";

import isEqual from "react-fast-compare";
import { Field, FieldProps } from "formik";

import { getPropsForm, getPropsInput } from "../Form.utils";

import RadioBase from "./RadioBase";
import RadioLabel from "./RadioWithLabel";
import RadioGroup from "./RadioGroup";
import { IRadioProps } from "./types";

const Radio: React.FC<IRadioProps> = props => {
	const { label, name, form, list, onChange, validateField } = props;

	const handleChangeField = async (e: ChangeEvent<HTMLInputElement>) => {
		const valueInput = e.target.value;

		if (onChange) {
			await onChange(e, valueInput, name);
		} else if (form) {
			await form.setFieldValue(name, valueInput);
		}

		if (form && validateField) {
			await form.validateField(name);
		}
	};

	const propsForm = getPropsForm(props, {
		onChange: handleChangeField,
		checkedValue: form?.values?.[name]
	});

	const propsInput = getPropsInput(props);

	if (list) {
		return <RadioGroup list={list} {...propsForm} {...propsInput} />;
	}

	if (label) {
		return <RadioLabel {...propsForm} {...propsInput} />;
	}

	return <RadioBase {...propsForm} {...propsInput} />;
};

const FormikRadioBase: React.FC<IRadioProps> = ({ name, ...rest }) => (
	<Field name={name}>
		{({ field, meta }: FieldProps) => {
			const error = meta.touched ? meta.error : "";
			return <Radio {...field} {...rest} error={error} />;
		}}
	</Field>
);

Radio.defaultProps = {
	validateField: true
};

export const FormikRadio = memo(FormikRadioBase, isEqual);

export default memo(Radio, isEqual);
