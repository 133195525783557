import { useLayoutEffect, useState, useCallback, memo } from "react";

import debounce from "lodash/debounce";
import isEqual from "react-fast-compare";

import { IChildren } from "app/types";
import CircularProgress, {
	PropsCircularProgress
} from "app/uikit-V2/CircularProgress";

interface Props extends PropsCircularProgress {
	loading: boolean;
	children?: IChildren;
	delay?: number;
	isBlackout?: boolean;
	backgroundColor?: string;
}

const Loader: React.FC<Props> = ({
	loading: propsLoading,
	children,
	delay,
	isBlackout,
	backgroundColor,
	...rest
}) => {
	const [loading, setLoading] = useState(true);
	const delayed = useCallback(
		debounce(v => setLoading(v), (Number.isInteger(delay) && delay) || 500),
		[]
	);
	useLayoutEffect(() => {
		if (delay) {
			delayed(propsLoading);
		} else {
			setLoading(propsLoading);
		}
	}, [propsLoading, delayed, setLoading, delay]);

	if (loading) {
		return (
			<CircularProgress
				colorBg={backgroundColor || isBlackout ? "rgba(0,0,0,0.03)" : "transparent"}
				{...rest}
			/>
		);
	}
	return children;
};

Loader.defaultProps = {
	color: "#000",
	size: 50,
	variant: "center",
	height: "100%"
};

export default memo(Loader, isEqual);
