import { useMemo } from "react";

import { useSelector } from "react-redux";

import { RootState } from "app/store/rootReducer";
import { IUserState } from "app/store/user/reducer";

const ROLES = {
	admin: {
		id: 2,
		name: "Admin"
	}
};

export const checkIsAdmin = (roles: { id: number; name: string }[]) => {
	for (let i = 0; i < roles.length; i++) {
		if (roles[i].id === ROLES.admin.id) {
			return true;
		}
	}
};

export const useIsAdmin = () => {
	const { user } = useSelector<RootState, IUserState>(state => state.user);

	const data = useMemo(
		() => ({
			loading: !user.id,
			isAdmin: checkIsAdmin(user?.roles || [])
		}),
		[user.id]
	);
	return data;
};
