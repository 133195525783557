// @ts-nocheck
import InputBase from "./input";

const Input = (props: any) => {
	const {
		name,
		form,
		showError,
		validateField,
		changeType,
		handleChange,
		...rest
	} = props;
	if (form && Object.keys(form).length) {
		const handleChangeField = async e => {
			const value = e.target.value;

			if (changeType === "number") {
				if (value.match(/^(?=\d*[1-9])\d+$/) || value === "") {
					return form.setFieldValue(name, +value);
				}
				return new Promise(rej => rej());
			}

			if (handleChange) {
				await handleChange(value, name, e);
			} else {
				await form.setFieldValue(name, value);
			}
			if (validateField) {
				await form.validateField(name);
			}

			if (form.setStatus) {
				form.setStatus({
					...form.status,
					dirtyFields: {
						...form.status?.dirtyFields,
						[name]: true
					}
				});
			}
		};

		return (
			<InputBase
				value={form.values[name]}
				onChange={handleChangeField}
				error={(form.touched[name] || showError) && Boolean(form.errors[name])}
				errorText={(form.touched[name] || showError) && form.errors[name]}
				name={name}
				form={form}
				{...rest}
			/>
		);
	} else {
		return <InputBase fullWidth name={name} {...props} />;
	}
};
export default Input;
