type Iid = string | number;

export const ApiUrls = {
	account: {
		leadObservation: {
			notificationSettings: "/account/user/lead-observation/notification-settings",
			companiesSets: {
				path: "/account/user/lead-observation/companies-sets",
				details: (id: Iid): string =>
					`/account/user/lead-observation/companies-sets/${id}`,
				detailsMatch: (id: Iid): string =>
					`/account/user/lead-observation/companies-sets/${id}/match`,
				enable: (id: Iid): string =>
					`/account/user/lead-observation/companies-sets/${id}/is-enabled`,
				export: (id: Iid): string =>
					`/account/user/lead-observation/companies-sets/${id}/export`,
				match: "/account/user/lead-observation/companies-sets/match",
				companies: {
					list: (id: Iid): string =>
						`/account/user/lead-observation/companies-sets/${id}/companies`,
					details: (id: Iid, companyId: Iid): string =>
						`/account/user/lead-observation/companies-sets/${id}/companies/${companyId}`,
					person: {
						details: (id: Iid, companyId: Iid, personUuid: Iid): string =>
							`/account/user/lead-observation/companies-sets/${id}/companies/${companyId}/persons/${personUuid}`,
						move: (id: Iid, companyId: Iid, personUuid: Iid): string =>
							`/account/user/lead-observation/companies-sets/${id}/companies/${companyId}/persons/${personUuid}/move`
					}
				}
			}
		},
		onboard: {
			subscriptionPrice: "/account/onboard/subscription-price",
			subscriptionLink: "/account/onboard/subscription-link",
			skip: "/account/onboard/skip"
		},
		user: {
			path: "account/user",
			resumeSubscription: "/account/user/resume-subscription",
			cancelSubscription: "/account/user/cancel-subscription",
			subscription: {
				tariffPlan: {
					canChange: "/account/user/subscription/tariff-plan/can-change",
					change: "/account/user/subscription/tariff-plan/change"
				}
			},
			appsumo: "/account/user/appsumo"
		},
		trends: {
			path: "/account/trends",
			details: (id: Iid): string => `/account/trends/${id}`
		}
	},

	admin: {
		users: {
			getLeadObservationCompanies: (id: Iid): string =>
				`/admin/users/${id}/lead-observation/companies`,
			getLeadObservationEnabled: (id: Iid): string =>
				`/admin/users/${id}/lead-observation-enabled`,
			leadObservation: {
				companiesSets: {
					path: (id: Iid): string =>
						`/admin/users/${id}/lead-observation/companies-sets`,
					detailsMatch: (userId: Iid, id: Iid): string =>
						`/admin/users/${userId}/lead-observation/companies-sets/${id}/match`,
					match: (id: Iid): string =>
						`/admin/users/${id}/lead-observation/companies-sets/match`,
					enable: (userId: Iid, id: Iid): string =>
						`/admin/users/${userId}/lead-observation/companies-sets/${id}/is-enabled`,
					details: (userId: Iid, id: Iid): string =>
						`/admin/users/${userId}/lead-observation/companies-sets/${id}`,
					export: (userId: Iid, id: Iid): string =>
						`/admin/users/${userId}/lead-observation/companies-sets/${id}/export`
				},

				companies: {
					list: (userId: Iid, trackingId: Iid): string =>
						`/admin/users/${userId}/lead-observation/companies-sets/${trackingId}/companies`,
					details: (userId: Iid, trackingId: Iid, companyId: Iid): string =>
						`/admin/users/${userId}/lead-observation/companies-sets/${trackingId}/companies/${companyId}`,
					person: {
						details: (
							userId: Iid,
							trackingId: Iid,
							companyId: Iid,
							personUuid: Iid
						): string =>
							`/admin/users/${userId}/lead-observation/companies-sets/${trackingId}/companies/${companyId}/persons/${personUuid}`,
						move: (
							userId: Iid,
							trackingId: Iid,
							companyId: Iid,
							personUuid: Iid
						): string =>
							`/admin/users/${userId}/lead-observation/companies-sets/${trackingId}/companies/${companyId}/persons/${personUuid}/move`
					}
				}
			}
		},
		trends: {
			path: "/admin/trends",
			details: (id: Iid): string => `/admin/trends/${id}`,
			delete: "/admin/trends/delete-multiple"
		}
	}
};
