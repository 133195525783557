import { useLayoutEffect, useState, useCallback, memo } from "react";

import debounce from "lodash/debounce";
import isEqual from "react-fast-compare";

import CircularProgress, {
	PropsCircularProgress
} from "app/uikit/CircularProgress";
import { IChildren } from "app/types";

interface Props extends PropsCircularProgress {
	loading: boolean;
	children?: IChildren;
	delay?: number;
	isTransparent?: boolean;
}

const Loader: React.FC<Props> = ({
	loading: propsLoading,
	children,
	delay,
	isTransparent,
	...rest
}) => {
	const [loading, setLoading] = useState(true);
	const delayed = useCallback(
		debounce(v => setLoading(v), (Number.isInteger(delay) && delay) || 500),
		[]
	);
	useLayoutEffect(() => {
		if (delay) {
			delayed(propsLoading);
		} else {
			setLoading(propsLoading);
		}
	}, [propsLoading, delayed, setLoading, delay]);

	if (loading) {
		return (
			<CircularProgress
				color="#000"
				size={50}
				position="center"
				colorBg={isTransparent ? "transparent" : "rgba(0,0,0,0.03)"}
				height="100%"
				{...rest}
			/>
		);
	}
	return children;
};

export default memo(Loader, isEqual);
