export default {
	GET_TRENDS_DATABASE_REQUEST: "GET_TRENDS_DATABASE_REQUEST",
	GET_TRENDS_DATABASE_SUCCESS: "GET_TRENDS_DATABASE_SUCCESS",
	GET_TRENDS_DATABASE_FAILURE: "GET_TRENDS_DATABASE_FAILURE",

	GET_TREND_DATABASE_REQUEST: "GET_TREND_DATABASE_REQUEST",
	GET_TREND_DATABASE_SUCCESS: "GET_TREND_DATABASE_SUCCESS",
	GET_TREND_DATABASE_FAILURE: "GET_TREND_DATABASE_FAILURE",

	FETCH_PAGINATION_TRENDS_DATABASE_REQUEST:
		"FETCH_PAGINATION_TRENDS_DATABASE_REQUEST",
	FETCH_PAGINATION_TRENDS_DATABASE_SUCCESS:
		"FETCH_PAGINATION_TRENDS_DATABASE_SUCCESS",

	FETCH_FILTER_TRENDS_DATABASE_REQUEST: "FETCH_FILTER_TRENDS_DATABASE_REQUEST",
	FETCH_FILTER_TRENDS_DATABASE_SUCCESS: "FETCH_FILTER_TRENDS_DATABASE_SUCCESS",

	RESET_TRENDS_DATABASE: "RESET_TRENDS_DATABASE",
	RESET_TRENDS_DATABASE_DETAILS: "RESET_TRENDS_DATABASE_DETAILS",

	FETCH_TREND_CREATE_DATABASE_REQUEST: "FETCH_TREND_CREATE_DATABASE_REQUEST",
	FETCH_TREND_CREATE_DATABASE_SUCCESS: "FETCH_TREND_CREATE_DATABASE_SUCCESS",
	FETCH_TREND_CREATE_DATABASE_FAILURE: "FETCH_TREND_CREATE_DATABASE_FAILURE",

	FETCH_TREND_EDIT_DATABASE_REQUEST: "FETCH_TREND_EDIT_DATABASE_REQUEST",
	FETCH_TREND_EDIT_DATABASE_SUCCESS: "FETCH_TREND_EDIT_DATABASE_SUCCESS",
	FETCH_TREND_EDIT_DATABASE_FAILURE: "FETCH_TREND_EDIT_DATABASE_FAILURE",

	FETCH_TREND_DELETE_DATABASE_REQUEST: "FETCH_TREND_DELETE_DATABASE_REQUEST",
	FETCH_TREND_DELETE_DATABASE_SUCCESS: "FETCH_TREND_DELETE_DATABASE_SUCCESS",
	FETCH_TREND_DELETE_DATABASE_FAILURE: "FETCH_TREND_DELETE_DATABASE_FAILURE"
};
