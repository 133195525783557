// @ts-nocheck

import { useState, useEffect, useMemo } from "react";

import cl from "classnames/bind";
import { withRouter } from "react-router";

import styleModule from "./style.module.scss";
import { isActiveSubItemPage } from "./helper";
import { AsideMenuList } from "./menuLiist";
// import { IListSubItem } from "./types";

const MenuSubItemComponent = ({ data, nesting, history, location }: any) => {
	const [isOpen, setIsOpen] = useState(false);
	const cs = cl.bind(styleModule);
	const { icon, caption, subItems } = data;

	const goToFirstSubItem = subItems => {
		if (!isOpen) {
			history.push(subItems[0]?.link);
		}
		setIsOpen(state => !state);
	};

	const isActive = useMemo(
		() => isActiveSubItemPage(subItems, location.pathname),
		[location.pathname, subItems]
	);

	useEffect(() => {
		if (isActive) {
			setIsOpen(true);
		}
	}, [isActive]);

	const btnMenuClasses = cs(
		"aside_list__item",
		"aside_list__item_btn",
		"aside_list__item--sub",
		`aside_list__item--nesting-${nesting}`,
		{
			"aside_list__item_btn--open": isOpen,
			"aside_list__item_btn--active": isActive
		}
	);

	return (
		<li>
			<button
				onClick={() => {
					goToFirstSubItem(subItems);
				}}
				className={btnMenuClasses}
			>
				<>{icon}</>
				{caption}
			</button>

			{isOpen && (
				<AsideMenuList
					isSubItem
					history={history}
					location={location}
					nesting={nesting}
					list={data.subItems}
				/>
			)}
		</li>
	);
};

export const MenuSubItem = withRouter(MenuSubItemComponent);

MenuSubItem.defaultProps = {
	data: {},
	nesting: 0
};
