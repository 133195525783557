// @ts-nocheck
import { useState, useMemo } from "react";

import { Select, ListSubheader, MenuItem } from "@material-ui/core";
import cl from "classnames/bind";

import Info from "app/components/info";

import { DownArrow } from "../../icons";

import style from "./style.module.scss";
import useStyle from "./style";
export default function SelectBase({
	list,
	hasNone,
	anchorOrigin,
	MenuProps,
	name,
	value,
	hasAllSelect,
	onChange,
	error,
	placeholder,
	className,
	staticWidth,
	...rest
}) {
	const [open, setOpen] = useState(false);

	const cx = cl.bind(style);
	const classes = useStyle({ staticWidth });
	const onClose = () => {
		setOpen(false);
	};
	const onOpen = () => {
		setOpen(true);
	};

	const newValue =
		(hasAllSelect &&
			(value === undefined || value === null || value === "") &&
			"all") ||
		value;

	const handleChange = (e, element) => {
		const value = e.target.value;
		if (onChange) {
			onChange(value === "all" ? null : value, e, element);
		}
	};

	const formattedList = useMemo(
		() =>
			list
				.map(elem => {
					if (elem.category && elem.list) {
						const subTitle = elem.category?.name;
						return [
							<ListSubheader key={subTitle} className={classes.subHeader}>
								{subTitle}
							</ListSubheader>,
							...elem.list?.map(elems => (
								<MenuItem
									key={elems.value}
									data-category={elem.category?.value}
									value={(elems.value === "" && "all") || elems.value}
									disabled={elems.disabled}
								>
									<div className={style.menuItem}>
										<div className={style.menuItem__inner}>
											{elems.icon && <div className={style.iconElem}>{elems.icon}</div>}

											<div className={style.menuItem__text}>{elems.text}</div>
										</div>
										{elems.info && (
											<Info
												className={style.info}
												data-info="true"
												info={elems.info}
												placement="right"
											/>
										)}
									</div>
								</MenuItem>
							))
						];
					} else {
						return (
							<MenuItem
								key={elem.value}
								value={(elem.value === "" && "all") || elem.value}
								disabled={elem.disabled}
							>
								<div className={style.menuItem}>
									<div className={style.menuItem__inner}>
										{elem.icon && <div className={style.iconElem}>{elem.icon}</div>}

										<div className={style.menuItem__text}>{elem.text}</div>
									</div>
									{elem.info && (
										<Info className={style.info} data-info="true" placement="right" />
									)}
								</div>
							</MenuItem>
						);
					}
				})
				.flat(),
		[list]
	);

	return (
		<Select
			displayEmpty
			MenuProps={{
				anchorOrigin: {
					vertical: "bottom",
					horizontal: "left",
					...anchorOrigin
				},
				getContentAnchorEl: null,
				...MenuProps
			}}
			IconComponent={() => (
				<div className={cx("icon", { "icon-rotate": open })}>
					<DownArrow />
				</div>
			)}
			renderValue={
				(placeholder &&
					(newValue !== ""
						? undefined
						: () => <div className={style.placeholder}>{placeholder}</div>)) ||
				undefined
			}
			onClose={onClose}
			onOpen={onOpen}
			value={newValue || ""}
			onChange={handleChange}
			inputProps={{ id: name }}
			className={cl(className, classes.input, error && classes.error)}
			name={name}
			{...rest}
		>
			{hasNone && (
				<MenuItem value="null">
					<em>None</em>
				</MenuItem>
			)}

			{(formattedList.length && formattedList) || (
				<MenuItem value="null" disabled={true}>
					<em>No available options</em>
				</MenuItem>
			)}
		</Select>
	);
}

SelectBase.defaultProps = {
	list: []
};
