import React from "react";

import Nodata from "app/components/NoData";

interface Props {
	loading?: boolean;
	content?: React.ReactNode;
	text?: string;
	minHeight: number | string;
}

const EmptyData: React.FC<Props> = ({
	loading,
	content,
	text,
	minHeight = 415
}) => {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
				textAlign: "center",
				paddingTop: "50px",
				paddingBottom: "50px",
				minHeight: minHeight
			}}
		>
			{!loading && (content || <Nodata text={text} />)}
		</div>
	);
};

export default EmptyData;
