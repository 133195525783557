import api from "app/api";
import { mappedParams } from "app/helper";
import { ITrendValuePrepare } from "app/screens/cabinet/trends/reports/components/createOrEdit/types";
import { IGetParams } from "app/types";

const API = api();

const fetchTrends = async (params: IGetParams) => {
	try {
		return await API.get("/account/trend-reports", {
			params: { ...mappedParams(params) }
		});
	} catch (error) {
		return error;
	}
};

const fetchTrendsAdmin = async (params: IGetParams) => {
	try {
		return await API.get("/admin/trend-reports", {
			params: { ...mappedParams(params) }
		});
	} catch (error) {
		return error;
	}
};

const fetchTrendDetails = async (id: number) => {
	try {
		return await API.get(`/account/trend-reports/${id}`);
	} catch (error) {
		return error;
	}
};

const fetchTrendCreate = async (data: ITrendValuePrepare) => {
	try {
		return await API.post(`/admin/trend-reports`, data);
	} catch (error) {
		return error;
	}
};

const fetchTrendUpdate = async (id: number, data: ITrendValuePrepare) => {
	try {
		return await API.put(`/admin/trend-reports/${id}`, data);
	} catch (error) {
		return error;
	}
};

const fetchTrendDelete = async (id: number) => {
	try {
		return await API.delete(`/admin/trend-reports/delete-multiple`, {
			params: {
				ids: id
			}
		});
	} catch (error) {
		return error;
	}
};

export default {
	fetchTrends,
	fetchTrendDetails,
	fetchTrendCreate,
	fetchTrendsAdmin,
	fetchTrendUpdate,
	fetchTrendDelete
};
