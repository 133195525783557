// @ts-nocheck
import api from "app/api";

const API = api();

const fetchSignIn = async (credentials: any) => {
	try {
		return await API.post("/auth/login", credentials);
	} catch (error) {
		return error;
	}
};

const fetchSignUp = async credentials => {
	try {
		return await API.post("/auth/register", credentials);
	} catch (error) {
		return error;
	}
};

const fetchPasswordRecovery = async email => {
	try {
		return await API.post("/auth/recover", email);
	} catch (error) {
		return error;
	}
};

const fetchNewPassword = async credentials => {
	try {
		return await API.post("/auth/new-password", credentials);
	} catch (error) {
		return error;
	}
};

const fetchLogOut = async () => {
	try {
		return await API.get("/auth/logout");
	} catch (error) {
		return error;
	}
};

export default {
	fetchSignIn,
	fetchSignUp,
	fetchPasswordRecovery,
	fetchNewPassword,
	fetchLogOut
};
