import { makeStyles } from "@material-ui/core/styles";

import { PALETTE } from "app/theme";

import { I_TYPES_BUTTON_VARIANT, I_TYPES_BUTTON_SIZE } from "./types";
import styles from "./style.module.scss";

export const country = "Austria";

const ClassesVariant: Record<string, string> = {
	primary: styles.primary,
	secondary: styles.secondary,
	borderLess: styles.borderLess,
	secondaryBordered: styles.secondaryBordered,
	text: styles.text,
	blue: styles.blue,
	danger: styles.danger
};

const ClassesSize: Record<string, string> = {
	large: styles.large,
	medium: styles.medium,
	small: styles.small
};

const ColorLoaderByVariant: Record<string, string> = {
	borderLess: PALETTE.black,
	text: PALETTE.black,
	danger: PALETTE.errorText
};

export const getButtonVariant = (variant: I_TYPES_BUTTON_VARIANT): string =>
	ClassesVariant[variant] || ClassesVariant.primary;

export const getButtonSize = (variant: I_TYPES_BUTTON_SIZE): string =>
	ClassesSize[variant] || ClassesSize.large;

export const getColorLoaderByVariant = (
	variant: I_TYPES_BUTTON_VARIANT
): string | undefined => ColorLoaderByVariant[variant];

export const useStyle = makeStyles({
	color: {
		color: (props: { color?: string }) =>
			props?.color ? `${props?.color} !important` : undefined
	}
});
