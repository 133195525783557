// @ts-nocheck
import { AnyAction, combineReducers } from "redux";
import { connectRouter } from "connected-react-router/immutable";

import localStore from "app/helper/local-store";

import types from "./auth/types";
import { authReducer, authInitialState } from "./auth";
import { userReducer, userInitialState } from "./user";
import { handBooksReducer, handBooksInitialState } from "./handBooks";
import { employeesReducer, employeesInitialState } from "./employees";
import { usersReducer, usersInitialState } from "./users";
import { uploadAuciencesReducer, uploadInitialState } from "./uploadAuciences";
import { appSettingsReducer, appSettingsInitialState } from "./appSettings";
import { generalReducer, generalInitialState } from "./general";
import { dashboardReducer, dashboardInitialState } from "./dashboard";
import { trendsReducer, trendsInitialState } from "./trends";
import {
	trendsDatabaseReducer,
	trendsDatabaseInitialState
} from "./trendsDatabase";

const initialState = {
	auth: authInitialState,
	user: userInitialState,
	handBooks: handBooksInitialState,
	general: generalInitialState,
	employees: employeesInitialState,
	users: usersInitialState,
	uploadAuciences: uploadInitialState,
	appSettings: appSettingsInitialState,
	dashboard: dashboardInitialState,
	trends: trendsInitialState,
	trendsDatabase: trendsDatabaseInitialState
};

const createAppReducer = history =>
	combineReducers({
		router: connectRouter(history),
		auth: authReducer,
		user: userReducer,
		handBooks: handBooksReducer,
		general: generalReducer,
		employees: employeesReducer,
		users: usersReducer,
		uploadAuciences: uploadAuciencesReducer,
		appSettings: appSettingsReducer,
		dashboard: dashboardReducer,
		trends: trendsReducer,
		trendsDatabase: trendsDatabaseReducer
	});

const createRootReducer = history => (state, action) => {
	if (action.type === types.FETCH_LOGOUT_SUCCESS) {
		localStore.clear();
		state = initialState;
	}
	return createAppReducer(history)(state, action);
};

const appReducer = (
	state: ReturnType<typeof createAppReducer>,
	action: AnyAction
) => createAppReducer(state, action);

export type AppState = ReturnType<typeof appReducer>;
export type RootState = ReturnType<typeof store.getState>;

export default createRootReducer;
