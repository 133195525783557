// @ts-nocheck

import types from "./types";

const actions = {
	getGeneralSettings: () => ({
		type: types.GET_GENERAL_SETTINGS_REQUEST
	}),
	getGeneralSettingsSuccess: data => ({
		type: types.GET_GENERAL_SETTINGS_SUCCESS,
		data
	}),
	getGeneralSettingsFailure: error => ({
		type: types.GET_GENERAL_SETTINGS_FAILURE,
		error
	}),
	updateGeneralSettings: data => ({
		type: types.UPDATE_GENERAL_SETTINGS_REQUEST,
		data
	}),
	updateGeneralSettingsSuccess: data => ({
		type: types.UPDATE_GENERAL_SETTINGS_SUCCESS,
		data
	}),
	updateGeneralSettingsFailure: error => ({
		type: types.UPDATE_GENERAL_SETTINGS_FAILURE,
		error
	})
};
export default actions;
