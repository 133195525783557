import React, { memo } from "react";

import isEqual from "react-fast-compare";

import { ITraking } from "./types";
import Header from "./header";
import Item from "./item";

interface Props {
	list: ITraking[];
	onDeleteItem: (id: number) => void;
	onOpenDetails: (id: number) => void;
	onOpenEditDetails: (id: number) => void;
	onEnableTracking: (
		id: number,
		enable: boolean,
		cb: (enable: boolean) => void
	) => void;
}

const TrackingList: React.FC<Props> = ({ list, ...rest }) => {
	return (
		<>
			<Header />
			{list?.map((item: ITraking) => (
				<Item key={item.id} data={item} {...rest} />
			))}
		</>
	);
};

export default memo(TrackingList, isEqual);
