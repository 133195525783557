import React from "react";

import Loadable from "app/utils/Loadable";
import UserLayout from "app/components/includes/UserLayout";
import PATHS from "PATHS";

const TrendEdit = Loadable({
	loader: () => import("./TrendEdit")
});

const PageCreateTrend: React.FC = () => {
	return (
		<UserLayout
			headerBackLinkTitle="Reports"
			headerBackLink={PATHS.cabinet.trends.reports.path}
		>
			<TrendEdit />
		</UserLayout>
	);
};

export default PageCreateTrend;
