import React, { memo } from "react";

import isEqual from "react-fast-compare";
import { Link } from "react-router-dom";
import cn from "classnames";

import {
	ITrendDatabaseItem,
	ITrendsDatabase
} from "app/store/trendsDatabase/type";
import HideContent from "app/components/hideContent";
import EmptyData from "app/uikit/table/EmptyData";
import CircularProgress from "app/uikit-V2/CircularProgress";
import { PALETTE } from "app/theme";
import { DeleteIcon, Edit } from "app/icons";
import Button from "app/uikit-V2/Button";
import { useIsAdmin } from "app/hooks/useIsAdmin";
import PATHS from "PATHS";

import styles from "./style.module.scss";

interface Props {
	data?: ITrendsDatabase;
	isLoading?: boolean;
	onDelete?: (data: ITrendDatabaseItem) => void;
	showOnlyFirstLine?: boolean;
}

const TrendsDatabaseContent: React.FC<Props> = ({
	isLoading,
	data,
	onDelete,
	showOnlyFirstLine
}) => {
	const { isAdmin } = useIsAdmin();
	const hasItems = !!data?.count;

	return (
		<div>
			{hasItems && (
				<div className={cn(styles.container, { [styles.hide]: showOnlyFirstLine })}>
					{data?.items?.map(item => {
						const growingPercent = item.growing_percent;
						const growIsUp = growingPercent < 0;
						return (
							<Link
								key={item.id}
								to={PATHS.cabinet.trends.database.details.url(item.id)}
								className={styles.card}
							>
								<div className={styles.card__header}>
									<div className={styles.card__title}>{item.name}</div>
									<div className={styles.card__header_ctrl}>
										<div
											className={cn(styles.card__grow, {
												[styles.card__grow_down]: growIsUp
											})}
										>
											{growingPercent} %
											{growingPercent && (
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="11"
													height="11"
													viewBox="0 0 11 11"
													fill="none"
													style={growIsUp ? { transform: "rotate(180deg)" } : {}}
												>
													<path
														d="M5.5 2L10.2631 8.75H0.73686L5.5 2Z"
														fill={growIsUp ? PALETTE.error : PALETTE.success}
													/>
												</svg>
											)}
										</div>

										{isAdmin && (
											<>
												<Link
													style={{ lineHeight: 0, fontSize: 0 }}
													to={PATHS.adminPanel.trends.database.edit.url(item.id)}
												>
													<Edit color={PALETTE.primary} />
												</Link>
												<Button
													variant="text"
													onClick={e => {
														e.preventDefault();
														e.stopPropagation();
														onDelete && onDelete(item);
													}}
												>
													<DeleteIcon color={PALETTE.primary} />
												</Button>
											</>
										)}
									</div>
								</div>
								<div className={styles.card__body}>
									<img src={item?.preview_file?.url} alt="" />
								</div>
							</Link>
						);
					})}
				</div>
			)}
			{hasItems && <HideContent />}
			{!hasItems && <EmptyData minHeight="70vh" loading={isLoading} />}
			{isLoading && (
				<CircularProgress
					color="#000"
					size={50}
					width="100%"
					position="absolute"
					variant="center"
					colorBg="rgba(0,0,0,0.05)"
					height="100%"
				/>
			)}
		</div>
	);
};

export default memo(TrendsDatabaseContent, isEqual);
