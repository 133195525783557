// @ts-nocheck
import Alert from "app/uikit/alert";
import { MIME_FILES } from "app/enum";
import { CsvIcon, XlsIcon, ImgIcon } from "app/icons";

import style from "./style.module.scss";
import {
	ValidateFileSheet,
	ValidateFileImg,
	ValidateFileCSv
} from "./validate";

import { DROPZONE_TYPE } from "./index";

export const getAccept = (type: any) => {
	switch (type) {
		case DROPZONE_TYPE.sheet:
			return ".csv, .xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
		case DROPZONE_TYPE.csv:
			return ".csv";
		case DROPZONE_TYPE.img:
			return "image/png, image/jpeg, image/jpg";
		case DROPZONE_TYPE.imgs:
			return "image/png, image/jpeg, image/jpg, image/svg+xml";
		default:
			return null;
	}
};

export const getValidator = (type, size, accept, text) => {
	switch (type) {
		case DROPZONE_TYPE.sheet:
			return ValidateFileSheet(size, accept, text);
		case DROPZONE_TYPE.csv:
			return ValidateFileCSv(size, accept, text);
		case DROPZONE_TYPE.img:
			return ValidateFileImg(size, accept, text);
		case DROPZONE_TYPE.imgs:
			return ValidateFileImg(size, MIME_FILES.imgs, "png | jpeg | jpg | svg");
		default:
			return null;
	}
};

export const getTextFiles = type => {
	switch (type) {
		case DROPZONE_TYPE.sheet:
			return ".csv .xls.";
		case DROPZONE_TYPE.csv:
			return ".csv";
		case DROPZONE_TYPE.img:
			return ".png .jpeg .jpg.";
		case DROPZONE_TYPE.imgs:
			return ".png .jpeg .jpg. .svg";
		default:
			return null;
	}
};

export const getFiles = (Files, removeAll) =>
	Files.map((file, i) => {
		const { name } = file;
		return (
			<span className={style.file} key={name}>
				<span className={`small-text ${style.file_text}`}>
					{name.endsWith(".csv") && <CsvIcon />}
					{(name.endsWith(".xls") || name.endsWith(".xlsx")) && <XlsIcon />}
					{(name.endsWith(".jpg") ||
						name.endsWith(".jpeg") ||
						name.endsWith(".svg") ||
						name.endsWith(".png")) && <ImgIcon />}
					<span className={style.file_name}>{name}</span>
				</span>
				<span className={`sub-text ${style.size}`}>{file.size / 1000} kb</span>
				<span className={style.close} onClick={removeAll} />
			</span>
		);
	});

export const getRejectionsFiles = FilesRejections =>
	FilesRejections.map(({ errors }, i) => (
		<div key={i}>
			{errors
				.filter(el => el.code.slice(0, 6) === "custom")
				.map(e => (
					<Alert key={e.code} type="error" text={e.message} />
				))}
		</div>
	));
