// @ts-nocheck
import classNames from "classnames/bind";
import { FormHelperText, FormControlLabel, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Info from "app/components/info";

import Checkbox from "./checkbox-base";

const useStyles = makeStyles(theme => ({
	root: {
		marginRight: 0,
		marginLeft: -9,

		"& .MuiFormControlLabel-label": {
			marginLeft: 15
		},

		"&.MuiFormControlLabel-labelPlacementStart": {
			marginLeft: 0,
			"& .MuiFormControlLabel-label": {
				margin: 0,
				marginRight: 15
			}
		},
		"&.MuiFormControlLabel-labelPlacementTop": {
			"& .MuiFormControlLabel-label": {
				margin: 0,
				marginBottom: 15
			}
		},
		"&.MuiFormControlLabel-labelPlacementBottom": {
			"& .MuiFormControlLabel-label": {
				margin: 0,
				marginTop: 15
			}
		},

		"& .MuiBox-root": {
			color: "#3E4555",
			fontSize: "16px",
			lineHeight: "26px"
		}
	},
	container: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center"
	},
	error: {
		"& .MuiTypography-root": {
			color: theme.palette.error.main
		}
	},
	errorChecbox: {
		borderColor: theme.palette.error.main
	},
	wrapper: {
		margin: "0px"
	}
}));

const CheckboxWithLabel = ({
	label = "",
	helperText,
	error,
	labelClass,
	labelPlacement = "end",
	wrapperClassName,
	info,
	...rest
}) => {
	const classes = useStyles();
	const cx = classNames.bind(classes);

	return (
		<div
			className={classNames(classes.wrapper, wrapperClassName, {
				checked: rest.checked ?? rest.value
			})}
		>
			<div className={classes.container}>
				<FormControlLabel
					className={cx("root", labelClass, { error: error })}
					labelPlacement={labelPlacement}
					control={
						<Checkbox classNameIcon={cx({ errorChecbox: error })} {...rest} />
					}
					label={
						<Box component="span" fontSize={12}>
							{label}
						</Box>
					}
					{...rest}
				/>

				{info && <Info info={info} placement={infoPlacement} />}
			</div>

			<FormHelperText error={error}>{helperText}</FormHelperText>
		</div>
	);
};

export default CheckboxWithLabel;

CheckboxWithLabel.defaultProps = {
	helperText: "",
	error: false,
	label: ""
};
