import { confirmable, createConfirmation } from "react-confirm";

import ConfirmModal from "./confirm-modal";

interface props {
	title?: string;
	message?: string;
	acceptText?: string;
	declineText?: string;
	shouldAcceptOnClose?: boolean;
	icon?: Element;
}

interface IAsyncConfirmDialogProps extends props {
	show: boolean;
	proceed: (close: boolean) => void;
}

const AsyncConfirmDialog = confirmable(
	({
		show,
		proceed,
		title,
		message,
		acceptText,
		declineText,
		icon,
		shouldAcceptOnClose
	}: IAsyncConfirmDialogProps) => (
		<ConfirmModal
			open={show}
			title={title || "Confirm"}
			icon={icon}
			onAgree={() => {
				proceed(true);
			}}
			text={message || "Are you sure?"}
			textAgree={acceptText || "Yes, reset"}
			textClose={declineText || "No, decline"}
			onClose={() => (shouldAcceptOnClose ? proceed(true) : proceed(false))}
		/>
	)
);

const confirm = createConfirmation(AsyncConfirmDialog);

/**
 * Util that show confirmation dialog with promised result - comfortable for logout / remove confirmation / etc
 * @param confirmation - string
 * @param options - dialog options
 * @returns Promise
 *
 * Example of usage:
 * const results = await asyncConfirm('Are you sure?')
 * if(result) {
 *   ...
 * } else {
 *   ...
 * }
 */
export function asyncConfirm({
	title,
	message,
	acceptText,
	declineText,
	icon,
	shouldAcceptOnClose
}: props) {
	return confirm({
		title,
		message,
		icon,
		acceptText,
		declineText,
		shouldAcceptOnClose
	});
}
