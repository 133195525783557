import { useEffect, useState, useCallback } from "react";

import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import { AxiosResponse } from "axios";
import { useHistory } from "react-router-dom";

import actions from "app/store/users/actions";
import Loader from "app/components/loader";
import Button from "app/uikit-V2/Button";
import UserProfileWrapper from "app/components/UserProfileLayout";
import Switch from "app/uikit/switch";
import services from "app/store/users/services";
import toastify from "app/utils/toastify";
import { globalHandlerError } from "app/helper/handlerErrors";
import { asyncConfirm } from "app/components/modal/asyncConfirm";
import { ApiUrls } from "app/api/apiUrls";
import { API } from "app/api";
import { IData } from "app/types";
import PATHS from "PATHS";
import { configSelect } from "app/store/select";

import TrackingList from "./components/TrackingList";

type UserLeadsParams = {
	id: string;
};

const UserLeads = () => {
	const { id } = useParams<UserLeadsParams>();
	const {
		user: { is_lead_observation_enabled, lead_observation_status },
		profileLoading
	} = useSelector(configSelect.users);
	const [automationStatus, setAutomationStatus] = useState({
		loading: false,
		checked: false
	});
	const [resetting, setResetting] = useState(false);
	const history = useHistory();
	const [trackingList, setTrackingList] = useState<{
		data: IData;
		loading: boolean;
	}>({
		data: { items: [], count: 0 } as IData,
		loading: true
	});
	const dispatch = useDispatch();

	const fetchUserData = () => {
		dispatch(actions.getUser(id));
	};

	const onGetTrackingList = useCallback(async () => {
		try {
			setTrackingList(state => ({ ...state, loading: true }));
			const response: AxiosResponse = await API.get(
				ApiUrls.admin.users.leadObservation.companiesSets.path(id)
			);
			setTrackingList(state => ({
				...state,
				data: response?.data,
				loading: false
			}));
		} catch (err) {
			console.log(err);
			globalHandlerError(err);
		} finally {
			setTrackingList(state => ({ ...state, loading: false }));
		}
	}, [id]);

	const onEnableTracking = useCallback(
		async (idList: number, enable: boolean, cb: (enable: boolean) => void) => {
			try {
				const response: AxiosResponse = await API.patch(
					ApiUrls.admin.users.leadObservation.companiesSets.enable(id, idList),
					{
						is_enabled: enable
					}
				);
				toastify.success(response?.data?.message);
				onGetTrackingList();
			} catch (err) {
				globalHandlerError(err);
				cb(!enable);
			}
		},
		[id, onGetTrackingList]
	);

	const onDeleteTrackingItem = useCallback(
		async (idList: number) => {
			try {
				const response = await API.delete(
					ApiUrls.admin.users.leadObservation.companiesSets.details(id, idList)
				);
				toastify.success(response?.data?.message);
				onGetTrackingList();
			} catch (err) {}
		},
		[onGetTrackingList, id]
	);

	const onOpenDetails = (trackingId: number) => {
		history.push(PATHS.adminPanel.users.tracking.details.url(id, trackingId));
	};
	const onOpenEditDetails = (trackingId: number) => {
		history.push(
			PATHS.adminPanel.users.tracking.details.edit.url(id, trackingId)
		);
	};

	useEffect(() => {
		onGetTrackingList();
	}, [onGetTrackingList]);

	useEffect(() => {
		setAutomationStatus(state => ({
			...state,
			checked: is_lead_observation_enabled
		}));
	}, [is_lead_observation_enabled]);

	const onChangedParsingAutomation = async (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		const checked = e.target.checked;
		try {
			setAutomationStatus(state => ({
				...state,
				checked,
				loading: true
			}));
			const response = await services.setLeadObservationEnabled(id, {
				is_enabled: checked
			});
			toastify.success(response?.data?.message);
			setAutomationStatus(state => ({
				...state,
				loading: false
			}));
			fetchUserData();
		} catch (err) {
			globalHandlerError(err);
			setAutomationStatus(state => ({
				...state,
				checked: !checked,
				loading: false
			}));
		}
	};

	const onHandleResetCompanies = async () => {
		try {
			const confirm = await asyncConfirm({
				title: "Reset companies",
				message: "Want to reset companies?"
			});
			if (confirm) {
				setResetting(true);
				const response = await API.delete(
					ApiUrls.admin.users.getLeadObservationCompanies(id)
				);
				toastify.success(response?.data?.message);
				onGetTrackingList();
			}
		} catch (err) {
			console.log(err);
			globalHandlerError(err);
		} finally {
			setResetting(false);
		}
	};

	return (
		<UserProfileWrapper>
			<Loader
				loading={profileLoading}
				height="50vh"
				colorBg="#fff"
				position="relative"
			>
				<Typography className="m-b--16">
					status: {lead_observation_status?.name}
				</Typography>

				<Box marginBottom={3}>
					<Switch
						labelPlacement="right"
						disabled={automationStatus.loading}
						checked={automationStatus.checked}
						label="Toggle parsing automation status"
						onChange={onChangedParsingAutomation}
					/>
				</Box>

				<Box display="flex" alignItems="center" marginBottom={4}>
					<Button
						className="m-r--16"
						size="medium"
						onClick={() => {
							history.push(PATHS.adminPanel.userLeadsAddNewList.replace(":id", id));
						}}
					>
						Upload leads
					</Button>

					<Button size="medium" onClick={onHandleResetCompanies} loading={resetting}>
						Remove companies
					</Button>
				</Box>

				<TrackingList
					list={trackingList.data.items}
					onOpenDetails={onOpenDetails}
					onOpenEditDetails={onOpenEditDetails}
					onDeleteItem={onDeleteTrackingItem}
					onEnableTracking={onEnableTracking}
				/>
			</Loader>
		</UserProfileWrapper>
	);
};

export default UserLeads;
