import React from "react";

import { ConfirmExit } from "app/icons";

import ConfirmDelete from "../modal/confirm-modal";

const CustomPrompt: React.FC = ({ message, cleanUp }: any) => {
	//   const parsedMessage = JSON.parse(message);
	// const parsedMessage = message;
	const onClose = () => cleanUp(false);
	const onAgree = () => cleanUp(true);

	return (
		<ConfirmDelete
			icon={<ConfirmExit />}
			title="Want to leave the page without saving data?"
			text={
				<>
					If you leave this page all changes will not be saved. <br />
					Do you want to leave page?
				</>
			}
			open={true}
			onClose={onClose}
			onAgree={onAgree}
			textAgree="Yes, leave"
			textClose="No, stay"
		/>
	);
};

export default CustomPrompt;
