import axios from "axios";
import * as Sentry from "@sentry/browser";

import history from "app/routers/history";
import localStore from "app/helper/local-store";
import actions from "app/store/auth/actions";
import PATHS from "PATHS";
import { showModalNotInternet } from "app/components/modal/notInternet";

export const path = process?.env?.REACT_APP_API_HOST;
export const host = process?.env?.REACT_APP_HOST;

const getAxiosInstance = (flag?: any) => {
	switch (flag) {
		case "pdf":
			return axios.create({
				baseURL: path,
				headers: {
					Authorization: localStore.getAccessToken(),
					"Content-Type": "application/pdf"
				},
				responseType: "blob"
			});
		default:
			return axios.create({
				baseURL: path,
				headers: {
					"Content-Type": "application/json",
					Authorization: localStore.getAccessToken(),
					Accept: "application/json"
				},
				responseType: "json"
			});
	}
};

const CreateInstance = () => {
	const axiosInstance = getAxiosInstance();

	axiosInstance.interceptors.request.use(request => {
		if (localStore.getAccessToken() !== null) {
			request.headers.Authorization = localStore.getAccessToken();
		}
		return request;
	});

	axiosInstance.interceptors.response.use(
		response => response,
		error => {
			if (error.message === "Network Error") {
				showModalNotInternet();
			}

			const status = error?.response?.status;
			if (status === 401 && localStore.getAccessToken()) {
				import("app/store").then(state => {
					state.default.dispatch(actions.fetchLogOutRequest(true));
				});
			}
			if (status === 404 && localStore.getAccessToken()) {
				history.push(PATHS.pageNotFound);
			}

			if (status !== 401) {
				Sentry?.captureException?.(error);
			}

			return Promise.reject(error);
		}
	);
	return axiosInstance;
};

export const API = CreateInstance();

export default CreateInstance;
