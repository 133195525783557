// @ts-nocheck
import { all, fork } from "redux-saga/effects";

import { authSagas } from "./auth";
import { employeesSagas } from "./employees";
import { handBooksSagas } from "./handBooks";
import { userSagas } from "./user";
import { usersSagas } from "./users";
import { uploadAuciencesSagas } from "./uploadAuciences";
import { appSettingsSagas } from "./appSettings";
import { generalSagas } from "./general";
import { dashboardSagas } from "./dashboard";
import { trendsSagas } from "./trends";
import { trendsDatabaseSagas } from "./trendsDatabase";

export default function* rootSaga(dispatch: any) {
	yield all([fork(authSagas, dispatch)]);
	yield all([fork(userSagas, dispatch)]);
	yield all([fork(employeesSagas, dispatch)]);
	yield all([fork(handBooksSagas, dispatch)]);
	yield all([fork(usersSagas, dispatch)]);
	yield all([fork(uploadAuciencesSagas, dispatch)]);
	yield all([fork(appSettingsSagas, dispatch)]);
	yield all([fork(generalSagas, dispatch)]);
	yield all([fork(dashboardSagas, dispatch)]);
	yield all([fork(trendsSagas, dispatch)]);
	yield all([fork(trendsDatabaseSagas, dispatch)]);
}
