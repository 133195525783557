import Loadable from "app/utils/Loadable";
import Layout from "app/components/includes/layout";

const VerificationCode = Loadable({
	loader: () => import("./VerificationCode")
});

const PageVerificationCode = () => {
	return (
		<Layout>
			<VerificationCode />
		</Layout>
	);
};

export default PageVerificationCode;
