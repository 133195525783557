//@ts-nocheck
import { useMemo, useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { cloneDeep } from "lodash";

import { handlerErrors } from "app/helper/handlerErrors";
import { getBase64Content } from "app/helper/helper.files";
import Alert from "app/uikit/alert";
import Modal from "app/components/modal";

import MatchTable from "./match-grid";

type IValidateValues = Record<string, number>;

const validate = (values: IValidateValues) => {
	const errors: {
		error?: string;
	} = {};

	const ids = Object.values(values).reduce(
		(acc, item) => ({ ...acc, [item]: item }),
		{}
	);

	if (ids["2"] && ids["16"]) {
		return errors;
	}

	errors.error = "Please select: Person LinkedIn URL and Company Website";
	return errors;
};

const defaultHelpText =
	"All columns must be matched to ensure accurate loading of the prospect list. The required fields include LinkedIn URLs for individuals and websites for companies.";

const MatchColumns = ({
	onSubmit,
	onBack,
	stepValue,
	submitButtonsText = "Create list",
	showTitle = true,
	helpText,
	sortByEmpty = true,
	options: listOptions,
	validate: validateProps,
	data: {
		columns,
		unmatched_columns_count,
		canCreate,
		message,
		missed_rows_count,
		audienceTemporaryData,
		loading,
		errors
	}
}: any) => {
	const { user_lead_observation_column_types } = useSelector(
		state => state.handBooks
	);

	const [errorsMatching, setErrorsMatching] = useState(false);
	const onChange = (value, form, e) => {
		const target = e.target;
		const name = target.name;
		const values = form.values;
		for (let mark in values) {
			if (name === mark) {
				continue;
			}
			if (values[mark] === value) {
				form.setFieldValue(mark, null, false);
			}
		}
		form.setFieldValue(name, value);
	};

	const optionsChoosed = useMemo(
		() => listOptions || user_lead_observation_column_types,
		[listOptions, user_lead_observation_column_types]
	);

	const options = useMemo(
		() => [
			...optionsChoosed?.map(el => ({ text: el.name, value: el.id })),
			{ text: "Custom variable", value: "" }
		],
		[optionsChoosed]
	);

	const handleSubmit = async values => {
		const columns = Object.keys(values)
			.map(el => {
				const id = el.split("_");
				const indexValue = values[el];
				return {
					column_id: indexValue && +indexValue,
					column_index: +id[id.length - 1]
				};
			})
			.filter(el => el.column_id);

		const requestData = {
			columns,
			file: {
				name: audienceTemporaryData?.file?.name,
				content: await getBase64Content(audienceTemporaryData?.file),
				mime: audienceTemporaryData?.file?.type
			},
			name: audienceTemporaryData?.name || audienceTemporaryData?.file?.name
		};
		onSubmit(requestData);
	};

	const initialValues = useMemo(() => {
		const obj = {};
		columns?.forEach(el => {
			obj[`column_index_${[el.column_index]}`] = el.column_id;
		});
		return obj;
	}, [columns]);

	const formik = useFormik({
		initialValues: initialValues,
		enableReinitialize: true,
		validate: validateProps || validate,
		onSubmit: handleSubmit
	});

	useEffect(() => {
		formik.validateForm();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		handlerErrors(errors, formik.setErrors);
	}, [errors, formik.setErrors]);

	const onMissedWarnings = () => {
		setTimeout(() => {
			setErrorsMatching(true);
		}, 0);
	};

	const warnings = useMemo(() => {
		let arr: JSX.Element[] = [];
		if (unmatched_columns_count) {
			arr.push(
				<span>
					There {`${unmatched_columns_count > 1 ? "are" : "is"}`}{" "}
					<strong>
						{unmatched_columns_count}{" "}
						{`${unmatched_columns_count > 1 ? "columns" : "column"}`}{" "}
					</strong>{" "}
					unmatched.
				</span>
			);
		}
		if (missed_rows_count) {
			arr.push(
				<span>
					There {`${missed_rows_count > 1 ? "are" : "is"}`}{" "}
					<strong>
						{missed_rows_count} {`${missed_rows_count > 1 ? "accounts" : "account"}`}{" "}
					</strong>{" "}
					without Website and LinkedIn URL.{" "}
					{`${missed_rows_count > 1 ? "These accounts" : "This account"}`} will not
					be uploaded.
				</span>
			);
		}

		return (arr.length && arr) || null;
	}, [unmatched_columns_count, missed_rows_count]);

	const buttonSubmit = useMemo(() => {
		if (warnings && !errorsMatching) {
			return {
				secondBtnText: "Skip issues",
				secondBtnDisabled: !canCreate,
				secondBtnAction: onMissedWarnings,
				secondBtnVariant: "ghost"
			};
		}
		return {
			secondBtnText: submitButtonsText,
			secondBtnloading: loading,
			secondBtnDisabled: !!formik.errors.error || !canCreate,
			secondBtnAction: formik.handleSubmit,
			secondBtnVariant: "gradient"
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canCreate, loading, formik.errors.error, errorsMatching, warnings]);

	const dataMapped = useMemo(
		() =>
			sortByEmpty
				? cloneDeep(columns)?.sort(el => {
						if (!el.column_id) {
							return -1;
						}
						return 0;
				  })
				: columns,
		[sortByEmpty, columns]
	);

	return (
		<div className="modal-step-three">
			{showTitle && (
				<div className="upload-next">
					<div className="upload-label main-text_bold">
						{stepValue && <span>{stepValue}</span>} Match columns with data types
					</div>
					<p className={`small-text ${stepValue && "upload-label__caption"}`}>
						To upload personal data, match them.
					</p>
				</div>
			)}

			<form onSubmit={formik.handleSubmit} noValidate>
				<div className="match-table-wrapper">
					<MatchTable
						options={options}
						onChangeSelect={onChange}
						form={formik}
						data={dataMapped}
					/>
				</div>

				{!errorsMatching && canCreate && <Alert type="warning" text={warnings} />}
				<Alert
					type="error"
					text={
						formik.errors.error ||
						errors?.columns ||
						errors?.file ||
						(!canCreate && message)
					}
				/>
				{helpText && <p className="small-text m-b--24">{helpText}</p>}
				<Modal.Footer
					footer
					spacer
					firstBtnText="Back"
					firstBtnAction={onBack}
					{...buttonSubmit}
				/>
			</form>
		</div>
	);
};

export default MatchColumns;
