// @ts-nocheck
import { takeEvery, put, all, call } from "redux-saga/effects";

import toastify from "app/utils/toastify";
import directory from "app/directory/errors";

import services from "./services";
import { types } from "./types";
import actions from "./actions";

const dir = directory.en;

function* fetchMatchAudience({ data, cb, actionName }: any) {
	try {
		let response = {};
		switch (actionName) {
			case "userAudience":
				response = yield call(services.fetchMatchUserAudience, {
					id: data.id,
					name: data.name,
					file: data.file
				});
				break;
			case "categoryAudience":
				response = yield call(services.fetchMatchCategoryAudience, {
					file: data.file
				});
				break;
			default:
				response = yield call(services.fetchMatchAudience, {
					name: data.name,
					file: data.file
				});
				break;
		}

		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			let newData = response.data;
			delete newData.success;
			yield put(actions.fetchMatchAudienceSuccess(newData));
			cb && cb();
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			return yield put(
				actions.fetchMatchAudienceFailure(error?.response?.data?.errors || "")
			);
		} else if (error?.response?.status === 403) {
			return yield put(
				actions.fetchMatchAudienceFailure(error?.response?.data?.message)
			);
		} else {
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.fetchMatchAudienceFailure(""));
		}
	}
}
function* fetchCreateAudience({ data, cb, actionName }) {
	try {
		let response = {};
		switch (actionName) {
			case "userAudience":
				response = yield call(services.fetchCreateUserAudience, data);
				break;
			case "categoryAudience":
				response = yield call(services.fetchCreateCategoryAudience, data);
				break;
			default:
				response = yield call(services.fetchCreateAudience, data);
				break;
		}
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.fetchCreateAudienceSuccess(response.data));
			yield put(actions.resetDataAudiences());
			cb && cb();
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			return yield put(
				actions.fetchCreateAudienceFailure(error?.response?.data?.errors || "")
			);
		} else if (error?.response?.status === 403) {
			return yield put(
				actions.fetchCreateAudienceFailure(error?.response?.data?.message)
			);
		} else {
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.fetchCreateAudienceFailure(""));
		}
	}
}

export default function* root() {
	yield all([takeEvery(types.FETCH_MATCH_AUDIENCE_REQUEST, fetchMatchAudience)]);
	yield all([
		takeEvery(types.FETCH_CREATE_AUDIENCE_REQUEST, fetchCreateAudience)
	]);
}
