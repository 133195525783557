import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import qs from "query-string";

import Modal from "app/components/modal";
import { configSelect } from "app/store/select";
import Typography from "app/uikit-V2/Typography";
import Button from "app/uikit-V2/Button";
import { globalHandlerError } from "app/helper/handlerErrors";
import PATHS from "PATHS";
import { API } from "app/api";
import { ApiUrls } from "app/api/apiUrls";
import actions from "app/store/user/actions";

import {
	fetchActiveSubscription,
	fetchChangeSubscriptionPlan,
	fetchResumeSubscription
} from "../Subscription.utils";
import CantSelectSubscription from "../CantSelectSubscription";

import styles from "./style.module.scss";

interface Props {
	open: boolean;
	tariffPlan?: number;
	onClose: () => void;
}

enum TypeCard {
	current = "current",
	new = "new"
}

type TCartageCard = keyof typeof TypeCard;

const SubscriptionSelectCardModal: React.FC<Props> = ({
	open,
	tariffPlan,
	onClose
}) => {
	const [cantSelectPlan, setCantSelectPlan] = useState(false);
	const [loading, setLoading] = useState(false);
	const { subscription } = useSelector(configSelect.selectUser);
	const [selectCard, setSelectCard] = useState<TCartageCard | undefined>();
	const dispatch = useDispatch();

	const onSelectCard = (value: TCartageCard) => {
		setSelectCard(value);
	};

	useEffect(() => {
		if (open) {
			setSelectCard(undefined);
		}
	}, [open]);

	const handleResumeSubscription = async () => {
		setLoading(true);
		try {
			await fetchResumeSubscription();
			await new Promise(res => {
				dispatch(
					actions.getInfo({
						cb: () => {
							res(true);
						}
					})
				);
			});
		} catch (err) {
			console.log(err);
		}
		setLoading(false);
	};

	const handleActivateSubscription = async () => {
		setLoading(true);
		try {
			const redirect: string = qs.stringifyUrl({
				url: `${window.location.origin}${PATHS.account.subscriptionSuccess}`
			});
			await fetchActiveSubscription({
				tariff_plan_id: tariffPlan,
				success_url: redirect
			});
		} catch (err) {
			console.log(err);
		}
		setLoading(false);
	};

	const handleResumeSubscriptionWithTariffPlan = async () => {
		try {
			setLoading(true);
			const isCan = await fetchChangeSubscriptionPlan(tariffPlan);
			if (!isCan) {
				setCantSelectPlan(true);
				return;
			}
			await API.put<{
				isCan: boolean;
				message: string;
			}>(ApiUrls.account.user.subscription.tariffPlan.change, {
				tariff_plan_id: tariffPlan
			});
			handleResumeSubscription();
		} catch (err) {
			console.error(err);
			globalHandlerError(err);
		} finally {
			setLoading(false);
		}
	};

	const handleCLickButton = (_: React.MouseEvent<HTMLElement>) => {
		switch (selectCard) {
			case TypeCard.current:
				if (tariffPlan !== undefined) {
					handleResumeSubscriptionWithTariffPlan();
				} else {
					handleResumeSubscription();
				}
				break;
			case TypeCard.new:
				handleActivateSubscription();
				break;
			default:
				break;
		}
	};

	return (
		<>
			<Modal
				open={open}
				size="auto"
				onClose={onClose}
				title="Pick a card for payment"
			>
				<div className={styles.container}>
					<div
						className={cn(styles.card, {
							[styles.active]: selectCard === TypeCard.current
						})}
						onClick={() => {
							onSelectCard(TypeCard.current);
						}}
					>
						<div className={styles.card__marker} />
						<Typography className={styles.card__title}>Current Card</Typography>
						<Typography className={styles.card__value}>
							**** **** **** {subscription?.bank_card?.last_four_digits}
						</Typography>
					</div>
					<div
						className={cn(styles.card_new, {
							[styles.active]: selectCard === TypeCard.new
						})}
						onClick={() => {
							onSelectCard(TypeCard.new);
						}}
					>
						<svg
							width="32"
							height="32"
							viewBox="0 0 32 32"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g clip-path="url(#clip0_5040_819)">
								<path
									d="M16 0C7.17706 0 0 7.17706 0 16C0 24.8229 7.17706 32 16 32C24.8229 32 32 24.8217 32 16C32 7.17831 24.8229 0 16 0ZM16 29.5213C8.54531 29.5213 2.47869 23.4559 2.47869 16C2.47869 8.54406 8.54531 2.47869 16 2.47869C23.4547 2.47869 29.5213 8.54406 29.5213 16C29.5213 23.4559 23.4559 29.5213 16 29.5213Z"
									fill="currentColor"
								/>
								<path
									d="M22.1968 14.6491H17.2395V9.69177C17.2395 9.00764 16.6855 8.45239 16.0001 8.45239C15.3147 8.45239 14.7607 9.00764 14.7607 9.69177V14.6491H9.80334C9.11796 14.6491 8.56396 15.2044 8.56396 15.8885C8.56396 16.5726 9.11796 17.1279 9.80334 17.1279H14.7607V22.0853C14.7607 22.7694 15.3147 23.3246 16.0001 23.3246C16.6855 23.3246 17.2395 22.7694 17.2395 22.0853V17.1279H22.1968C22.8822 17.1279 23.4362 16.5726 23.4362 15.8885C23.4362 15.2044 22.8822 14.6491 22.1968 14.6491Z"
									fill="currentColor"
								/>
							</g>
							<defs>
								<clipPath id="clip0_5040_819">
									<rect width="32" height="32" fill="white" />
								</clipPath>
							</defs>
						</svg>

						<Typography className={styles.card_new__value}>
							Select another card
						</Typography>
					</div>
				</div>
				<div className={styles.footer}>
					<Button
						disabled={!selectCard}
						loading={loading}
						onClick={handleCLickButton}
					>
						Pay now
					</Button>
				</div>
			</Modal>

			<CantSelectSubscription
				open={cantSelectPlan}
				onClose={() => {
					setCantSelectPlan(false);
				}}
			/>
		</>
	);
};

export default SubscriptionSelectCardModal;
