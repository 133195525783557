// @ts-nocheck
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";

import CustomPrompt from "app/components/CustomPrompt";

export default createBrowserHistory({
	getUserConfirmation: (message, callback) => {
		const node = document.getElementById("custom-prompt");

		const cleanUp = answer => {
			ReactDOM.unmountComponentAtNode(node);
			callback(answer);
		};

		ReactDOM.render(<CustomPrompt message={message} cleanUp={cleanUp} />, node);
	}
});
