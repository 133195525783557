import {
	Route,
	RouteProps,
	Redirect,
	RouteComponentProps
} from "react-router-dom";

import localStore from "app/helper/local-store";
import PATHS from "PATHS";
import { IPermissions, IPermissionsTypes } from "app/enum/permissions";

interface Props extends RouteProps {
	Component:
		| React.ComponentType<RouteComponentProps<any>>
		| React.ComponentType<any>
		| undefined;
	path: string;
	permission?: IPermissions | boolean;
	permissions?: IPermissionsTypes;
}

const PrivateRoute: React.FC<Props> = ({
	Component,
	permission,
	permissions,
	...rest
}) => {
	if (localStore.getAccessToken()) {
		if (permission) {
			if (!permissions || !Object.keys(permissions).length) {
				localStore.clear();

				return <Redirect to={PATHS.auth.login} />;
			}
			if (
				typeof permission === "boolean" ||
				(permissions && permission && !!permissions[permission])
			) {
				return <Route {...rest} component={Component} />;
			}
		} else {
			return <Route {...rest} component={Component} />;
		}
	}

	return <Redirect to={PATHS.auth.login} />;
};

export default PrivateRoute;
