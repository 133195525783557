import { makeStyles } from "@material-ui/core/styles";

export const useStylesCheckboxBase = makeStyles({
	root: {
		"&:hover": {
			backgroundColor: "none"
		},
		"& MuiIconButton-label": {
			position: "relative"
		},
		"& input": {
			position: "absolute",
			left: 0,
			top: 0,
			width: "100%",
			height: "100%"
		}
	},
	icon: {
		width: 18,
		height: 18,
		border: "1px solid #D4D8E2",
		borderRadius: 3,
		backgroundColor: "#fff",
		"input:hover ~ &": {
			backgroundColor: "#ebf1f5"
		},
		"input:disabled ~ &": {
			boxShadow: "none",
			background: "rgba(206,217,224,.5)"
		}
	},
	checkedIcon: {
		backgroundColor: "#EB7D68",
		borderColor: "#EB7D68",
		backgroundImage:
			"linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
		borderRadius: 3,
		"&:before": {
			display: "block",
			width: 16,
			height: 16,
			backgroundImage:
				"url(\"data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.13 7.62L10.91 0.839996L11.81 1.74L4.13 9.42L0.559998 5.85L1.46 4.95L4.13 7.62Z' fill='white'/%3E%3C/svg%3E%0A\")",
			backgroundPosition: "2px 2px",
			backgroundRepeat: "no-repeat",
			content: '""'
		},
		"input:hover ~ &": {
			backgroundColor: "#EB7D68"
		}
	}
});

export const useStylesCheckboxWithLabel = makeStyles(theme => ({
	root: {
		margin: -9,
		marginRight: 0,
		gap: 15,
		position: "relative",

		"&.MuiFormControlLabel-labelPlacementBottom, &.MuiFormControlLabel-labelPlacementTop, &.MuiFormControlLabel-labelPlacementStart": {
			gap: 5,
			marginLeft: 0
		},
		"& .MuiBox-root": {
			color: "#3E4555",
			fontSize: "16px",
			lineHeight: "26px",
			display: "inline-block"
		}
	},
	container: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center"
	},
	error: {
		"& .MuiTypography-root": {
			color: theme.palette.error.main,

			"& > .MuiBox-root": {
				color: theme.palette.error.main
			}
		}
	},
	errorCheckbox: {
		borderColor: theme.palette.error.main
	},
	wrapper: {
		marginBottom: 16,
		marginTop: 8

		// "& .MuiFormControlLabel-label": {
		// 	marginLeft: 15
		// }
	},
	noGutters: {
		margin: 0
	}
}));
