import { IPermissionsTypeof, IPermissions } from "app/enum/permissions";

import { IMenuItem } from "./types";

const filterMenuItem = (
	token: string,
	permissions: IPermissionsTypeof,
	permission?: IPermissions
): boolean => {
	return token && (!!permissions[permission] || permission === undefined);
};

// фиксануть фильтрует только напервом уровне

export const filterMenuList = (
	list: IMenuItem[],
	token: string,
	permissions: IPermissionsTypeof
): IMenuItem[] => {
	return list.filter(menuItem => {
		if (menuItem.subItems) {
			return filterMenuList(menuItem.subItems, token, permissions);
		}
		return filterMenuItem(
			token,
			permissions,
			menuItem.permission as IPermissions
		);
	});
};

export const matchPathByNames = (
	match: boolean,
	location: any,
	names: []
): boolean => {
	let matched: boolean = false;

	if (Array.isArray(names)) {
		matched = Boolean(names.some(el => location.pathname === el));
	}

	if (!match && !matched) return false;

	return true;
};

export const setMenuItemId = (
	array: IMenuItem[],
	suffix?: number | string
): IMenuItem[] | null => {
	if (!array) return null;

	return array.map((item, i) => {
		const id = (suffix && `${suffix}.${i + 1}`) || i + 1;
		return {
			...item,
			id: id,
			parentId: suffix,

			subItems: setMenuItemId(item.subItems, id)
		};
	});
};

const getArrayLinks = (array: IMenuItem[]): any[] => {
	if (!array) return [];
	return array.flatMap(el => {
		if (el.subItems) {
			return getArrayLinks(el.subItems);
		}
		const matchPaths = el?.matchPaths || [];
		return [el.link, ...matchPaths];
	});
};

export const isActiveSubItemPage = (array: [], path: string) => {
	const allLinsArray = getArrayLinks(array);
	return allLinsArray.includes(path);
};
