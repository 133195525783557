import Modal from "app/components/modal";
import Typography from "app/uikit-V2/Typography";
import Button from "app/uikit-V2/Button";

interface Props {
	open: boolean;
	onClose: () => void;
}

const CantSelectSubscription: React.FC<Props> = ({ open, onClose }) => (
	<Modal
		open={open}
		onClose={onClose}
		titleAlign="center"
		title="You cannot select this plan"
	>
		<div style={{ textAlign: "center" }}>
			<Typography variant="subtitle1" marginBottom={2}>
				You cannot select this plan because you have added more prospects for
				tracking than allowed.
			</Typography>

			<Typography variant="subtitle1" marginBottom={5}>
				Please choose another plan or go to the tracking tab and reduce the number
				of prospects
			</Typography>

			<Button onClick={onClose}>Got it</Button>
		</div>
	</Modal>
);

export default CantSelectSubscription;
