import React from "react";

import cl from "classnames";
import { Typography } from "@material-ui/core";

import { IChildren } from "app/types";

import style from "../style.module.scss";

export interface HeaderModalProps {
	iconTitle?: JSX.Element;
	title?: React.ReactNode;
	titleAlign?: "center" | "left" | "right";
	children?: IChildren;
	className?: string;
}

const HeaderModal: React.FC<HeaderModalProps> = ({
	iconTitle,
	titleAlign = "left",
	title,
	children,
	className
}) =>
	((title || children) && (
		<div className={cl(style.modal__header, className)}>
			{(title || iconTitle) && (
				<Typography
					component="h3"
					variant="subtitle1"
					className={cl(
						style.modal__title,
						style[`modal__title_align_${titleAlign}`]
					)}
				>
					{iconTitle && <div className="title-subheading-icon">{iconTitle}</div>}{" "}
					<span>{title}</span>
				</Typography>
			)}
			{children}
		</div>
	)) ||
	null;

export default HeaderModal;
