import { useEffect } from "react";

import moment from "moment";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { configSelect } from "app/store/select";
import PATHS from "PATHS";

declare global {
	interface Window {
		Intercom?: any;
	}
}

let inited = false;

const hideChat = () => {
	const tidiChatELement: HTMLDivElement = document.querySelector(
		".intercom-lightweight-app"
	);
	if (tidiChatELement) {
		tidiChatELement.style.display = "none";
	}
};

const showChat = () => {
	const tidiChatELement: HTMLDivElement = document.querySelector(
		".intercom-lightweight-app"
	);
	if (tidiChatELement) {
		tidiChatELement.style.display = "block";
	}
};

const intercomInitialize = ({
	id,
	name
}: {
	id: string | number;
	name: string;
}) => {
	if (!name) return;

	const options = {
		api_base: "https://api-iam.intercom.io",
		app_id: "m7yuvdvz",
		user_id: id,
		name,
		email: "ag@signum.ai",
		created_at: moment().unix()
	};

	if (inited) {
		return window.Intercom("update", options);
	}

	inited = true;

	const script = document.createElement("script");
	script.innerHTML = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/m7yuvdvz';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

	document.body.appendChild(script);

	const setInfo = () => {
		window.Intercom("boot", options);
	};

	const checkIntercom = () => {
		if (window.Intercom) {
			setInfo();
		} else {
			setTimeout(() => {
				checkIntercom();
			}, 250);
		}
	};

	checkIntercom();
};

export const useIntercom = () => {
	const location = useLocation();
	const userInfo = useSelector(configSelect.selectUserDetails);

	useEffect(() => {
		const excludsRoutes = Object.values(PATHS.auth);
		const foundRoute = excludsRoutes.find(item => item === location.pathname);

		if (!foundRoute) {
			intercomInitialize({
				id: userInfo?.user?.id,
				name: userInfo?.user?.name
			});
			showChat();
		}

		return () => {
			if (window.Intercom) {
				hideChat();
			}
		};
	}, [location.pathname, userInfo]);
};
