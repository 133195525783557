import type { ChangeEvent, FocusEvent } from "react";

import { InputBaseComponentProps } from "@material-ui/core";

import type { ICheckboxProps } from "./Form/Checkbox/types";
import type { ISelectChipProps } from "./Form/SelectChip/types";
import { IInputProps } from "./Form/Input/type";
import { IRadioProps } from "./Form/Radio/types";
import { ISelectProps } from "./Form/Select/type";

export type ITooltipPlacement =
	| "bottom"
	| "left"
	| "right"
	| "top"
	| "bottom-end"
	| "bottom-start"
	| "left-end"
	| "left-start"
	| "right-end"
	| "right-start"
	| "top-end"
	| "top-start";

export type ILabelPlacement = "bottom" | "top" | "end" | "start";

export type IInputTypes =
	| "button"
	| "checkbox"
	| "color"
	| "date"
	| "datetime-local"
	| "email"
	| "file"
	| "hidden"
	| "image"
	| "month"
	| "number"
	| "password"
	| "radio"
	| "range"
	| "reset"
	| "search"
	| "submit"
	| "tel"
	| "text"
	| "time"
	| "url"
	| "week";

export type IFieldProps<T extends {}> = T & {
	type?: IInputTypes;
};

export enum FieldTypes {
	// autocomplete = "autocomplete",
	checkbox = "checkbox",
	selectChip = "selectChip",
	// switch = "switch",
	radio = "radio",
	select = "select",
	// file = "file",
	// date = "date",
	// time = "time",
	// number = "number",
	// email = "email",
	// password = "password",
	text = "text"
	// tag = "tag",
	// color = "color"
}

export type FieldCommonPropsLabelProps = {
	label?: string | React.ReactNode | JSX.Element;
	labelContent?: string | React.ReactNode | JSX.Element;
	labelClassName?: string;
	labelPlacement?: ILabelPlacement;
	info?: string;
	infoPlacement?: ITooltipPlacement;
	required?: boolean;
};

export type FieldCommonPropsError = {
	error?: string | boolean;
	canDisplayError?: boolean;
};

export type FieldPropsTypes<Value> = {
	name?: string;
	form?: any;
	validateField?: boolean;
	fullWidth?: boolean;
	autoFocus?: boolean;
	onBlur?: (
		e: FocusEvent<HTMLInputElement>,
		value?: Value,
		name?: string
	) => void;
	onChange?: (e: ChangeEvent<HTMLInputElement>, name?: string) => void;
	value?: Value;
	noGutters?: boolean;
	identifier?: string;
	inputProps?: InputBaseComponentProps;
	className?: string;
	wrapperClassName?: string;
	placeholder?: string;
};

export type FieldCommonProps<Value, T = {}> = T &
	FieldCommonPropsLabelProps &
	FieldCommonPropsError &
	FieldPropsTypes<Value>;

export type FieldCheckboxInterface = ICheckboxProps & {
	type: "checkbox";
};

export type FieldRadioInterface = IRadioProps & {
	type: "radio";
};

export type FieldSelectChipInterface = ISelectChipProps & {
	type: "selectChip";
};

export type FieldInputInterface = IInputProps & {
	type?: "text";
};

export type FieldSelect = ISelectProps & {
	type?: "select";
};

export type FormFieldProps =
	| FieldCheckboxInterface
	| FieldRadioInterface
	| FieldSelectChipInterface
	| FieldSelect
	| FieldInputInterface
	| ({
			type?: keyof typeof FieldTypes | IInputTypes;
	  } & IInputProps);
