import { useLayoutEffect } from "react";

import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Loadable from "app/utils/Loadable";
import UserLayout from "app/components/includes/UserLayout";
import actions from "app/store/trendsDatabase/actions";
import PATHS from "PATHS";

const Trends = Loadable({
	loader: () => import("./components/trendDetails/Body")
});

const PageTrendDatabaseDetails = () => {
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const dispatch = useDispatch();

	useLayoutEffect(() => {
		if (isNaN(Number(id))) {
			history.push(PATHS.pageNotFound);
			return;
		}
		dispatch(actions.getTrendDatabase(id));
	}, [id, history, dispatch]);

	return (
		<UserLayout
			headerBackLinkTitle="Trend Database"
			headerBackLink={PATHS.cabinet.trends.database.path}
		>
			<Trends />
		</UserLayout>
	);
};

export default PageTrendDatabaseDetails;
