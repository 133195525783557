import yup from "app/yup";
import { ErrorName, ErrorEmail, ErrorComment } from "app/errors-shcema";

const validationSchema = yup.object({
	name: ErrorName,
	email: ErrorEmail,
	comment: ErrorComment()
});

export default validationSchema;
