export const STEP_INFO = [
	{
		value: 0,
		text: "Trigger selection"
	},
	{
		value: 1,
		text: "Prospects uploading"
	},
	{
		value: 2,
		text: "Creating a list"
	}
];
