import { AnyAction } from "redux";

import { IPermissionsTypes } from "app/enum/permissions";
import { RootState } from "app/store/rootReducer";

import types from "./types";

// const userStorage = localStore.getInfo();

export type ISubscriptionBankType = {
	brand_name: string;
	expired_date: string;
	id: number;
	last_four_digits: string;
};

export type ISubscription = {
	base_balance: number;
	expired_at: string;
	id: number;
	last_error_message: null | string;
	status: { id: number; name: string };
	tariff_balance: number;
	tariff_plan: {
		id: number;
		name: string;
		currency_code: string;
		price: number;
		appsumo_name: string;
	};
	is_active_subscription: boolean;
	bank_card: ISubscriptionBankType;
};

export interface ILeadObservationNotificationSettings {
	emails: string[];
	event_ids: number[];
	is_enabled: boolean;
	events: {};
	period: { id: number; name: string };
	period_id: number;
}

export type IUser = {
	id: string;
	email: string;
	name: string;
	avatar: string | null;
	last_logged_at: string;
	is_first_login: boolean;
	is_trial_subscription_was_activated: boolean;
	is_appsumo_user: boolean;
	lead_observation_notification_settings: ILeadObservationNotificationSettings;
	permissions: IPermissionsTypes;
	is_onboard_passed: boolean;
	subscription: ISubscription;
	roles: { id: number; name: string }[];
	bank_cards: [];
	company_resume: null | string;
	lead_observation_status: { id: number };
	is_subscription_required: boolean;
	is_created_by_admin: boolean;
	user_lead_observation_info: {
		people_count: number;
	};
};

export type IUserState = {
	user: IUser;
	isChanging: boolean;
	changeError: string;
	error: string;
	isLoading: boolean;
	cancelSubsLoading: boolean;
};

const user = {
	avatar: "",
	email: "",
	id: "",
	name: "",
	lead_observation_status: {},
	permissions: {},
	subscription: {}
};

export const selectUser = (state: RootState) => state.user?.user;
export const selectUserDetails = (state: RootState) => state.user;

export const initialState = {
	// user: userStorage || user,
	user: user,
	isChanging: false,
	changeError: "",
	error: "",
	isLoading: false,
	cancelSubsLoading: false
};

export const resetState = {
	user: user,
	isChanging: false,
	changeError: "",
	error: "",
	isLoading: false
};

const reducer = function (state = initialState, action: AnyAction) {
	switch (action.type) {
		case types.GET_INFO_USER_REQUEST:
			return {
				...state,
				error: "",
				isLoading: true
			};
		case types.GET_INFO_USER_SUCCESS:
			return {
				...state,
				user: action.data,
				error: "",
				isLoading: false
			};
		case types.GET_INFO_USER_FAILURE:
			return {
				...state,
				error: action.error,
				isLoading: false
			};

		case types.CHANGE_INFO_USER_REQUEST:
			return {
				...state,
				changeError: "",
				isChanging: true
			};
		case types.CHANGE_INFO_USER_SUCCESS:
			return {
				...state,
				user: action.data,
				changeError: "",
				isChanging: false
			};
		case types.CHANGE_INFO_USER_FAILURE:
			return {
				...state,
				changeError: action.error,
				isChanging: false
			};
		case types.RESET_INFO_USER:
			return { ...initialState, user };
		case types.TRAINING_PASSED:
		case types.FIRST_LOGIN_PASSED:
			return { ...state, user: { ...state.user, is_first_login: false } };
		case types.CANCEL_SUBSCRIPTION_REQUEST:
			return {
				...state,
				cancelSubsLoading: true
			};
		case types.CANCEL_SUBSCRIPTION_SUCCESS:
		case types.CANCEL_SUBSCRIPTION_FAILURE:
			return {
				...state,
				cancelSubsLoading: false
			};
		default:
			return state;
	}
};

export default reducer;
