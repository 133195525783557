// @ts-nocheck
import config from "app/config";

import style from "./style.module.scss";
import Pagination from "./pagination";
import MenuSize from "./menu-size";

const Footer = ({
	pagination,
	paginationChange,
	paginationSizes = config.ui.tableSize
}) => {
	const onChange = data => {
		paginationChange(data);
		document.querySelector(".page-main")?.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	};

	return (
		<>
			{(pagination && (
				<div className={style.footer}>
					<Pagination onChange={onChange} pagination={pagination} />

					<MenuSize
						sizes={paginationSizes}
						limit={pagination?.limit}
						onChange={onChange}
					/>
				</div>
			)) ||
				null}
		</>
	);
};

export default Footer;
