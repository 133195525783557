import React from "react";

import Loadable from "app/utils/Loadable";
import UserLayout from "app/components/includes/UserLayout";

const TrackingContent = Loadable({
	loader: () => import("./TrackingContent")
});

const PageScreenTracking: React.FC = () => (
	<UserLayout>
		<TrackingContent />
	</UserLayout>
);

export default PageScreenTracking;
