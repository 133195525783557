import React from "react";

import Loadable from "app/utils/Loadable";
import UserLayout from "app/components/includes/UserLayout";
import PATHS from "PATHS";

const TrackingAddNewListContent = Loadable({
	loader: () => import("app/components/Tracking/TrackingAddNewListFlow")
});

const PageScreenTrackingAddNewList: React.FC = () => (
	<UserLayout
		headerBackLink={PATHS.cabinet.tracking.path}
		headerBackLinkTitle="Tracking"
	>
		<TrackingAddNewListContent />
	</UserLayout>
);

export default PageScreenTrackingAddNewList;
