import React from "react";

import { Avatar } from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

import avatarDefault from "../../../assets/img/avatar-person.svg";

type Ichildren = {
	id: number | string;
	inactive?: any;
	name?: string;
	src?: string;
};
interface props {
	name?: any;
	id?: any;
	src?: any;
	className?: any;
	max?: any;
	isGroup?: any;
	children?: Ichildren[];
}

const useStyles = makeStyles({
	avatar_inactive: {
		position: "relative",

		"&::before": {
			content: "''",
			position: "absolute",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: "white",
			opacity: 0.5
		}
	}
});

const BaseAvatar: React.FC<props> = ({
	name,
	id,
	src,
	className,
	isGroup,
	max,
	children
}) => {
	const classes = useStyles();
	if (isGroup) {
		return (
			<AvatarGroup max={max}>
				{children?.map(item => (
					<Avatar
						className={item.inactive && classes.avatar_inactive}
						key={`user-${item.id}` || item.name}
						alt={item.name || `user-${item.id}`}
						src={item.src || avatarDefault}
					/>
				)) || null}
			</AvatarGroup>
		);
	}
	return (
		<Avatar
			className={className}
			alt={name || `user-${id}`}
			src={src || avatarDefault}
		/>
	);
};

export default BaseAvatar;
