/* eslint-disable no-useless-escape */
// @ts-nocheck
import * as yup from "yup";

function Some(metod: any, msg: any) {
	return this.test("Some", msg, values => {
		if (typeof metod == "function") {
			return metod(values);
		} else {
			return values.some(elem => {
				return elem === metod;
			});
		}
	});
}

function lenKeywords(msg, value = 15) {
	return this.test("lenKeywords", msg, values => {
		if (!values) return true;
		const split = values && values.split(",");
		if (split.length > value) {
			return false;
		} else {
			return true;
		}
	});
}

function lenKeyword(msg, value = 3) {
	return this.test("lenKeyword", msg, values => {
		if (!values) return true;
		const split = values?.split(",");
		const arr = split.map(elem => elem.trim());
		return arr.every(elem => {
			const spl = elem.split(" ").filter(elem => elem !== "");
			return spl.length <= value;
		});
	});
}

function website(msg) {
	return this.test("website", msg, values => {
		if (!values) return true;
		// eslint-disable-next-line no-useless-escape
		return !!values.match(
			/(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi
		);
	});
}

yup.addMethod(yup.array, "Some", Some);

yup.addMethod(yup.string, "lenKeywords", lenKeywords);

yup.addMethod(yup.string, "lenKeyword", lenKeyword);

yup.addMethod(yup.string, "website", website);

export default yup;
