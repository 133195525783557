import { memo, ChangeEvent } from "react";

import isEqual from "react-fast-compare";
import { Field, FieldProps } from "formik";

import { getPropsForm, getPropsInput } from "../Form.utils";

import CheckboxBase from "./CheckboxBase";
import CheckboxLabel from "./CheckboxWithLabel";
import CheckboxGroup from "./CheckboxGroup";
import { ICheckboxProps } from "./types";

const Checkbox: React.FC<ICheckboxProps> = props => {
	const { label, name, form, list, onChange, validateField, value } = props;

	const handleChangeField = async (e: ChangeEvent<HTMLInputElement>) => {
		const valueInput = e.target.checked;

		if (onChange) {
			await onChange(e, valueInput, name);
		} else if (form) {
			await form.setFieldValue(name, valueInput);
		}

		if (form && validateField) {
			await form.validateField(name);
		}
	};

	const handleChangeFieldGroup = async (
		e: ChangeEvent<HTMLInputElement>,
		_?: any,
		identifier?: string
	) => {
		const valueInput = e.target.checked;

		if (onChange) {
			await onChange(
				e,
				{ ...(value as Record<string, boolean>), [identifier]: valueInput },
				name
			);
		} else if (form) {
			await form.setFieldValue(name, {
				...form?.values[name],
				[identifier]: valueInput
			});
		}
		if (form && validateField) {
			await form.validateField(name);
		}
	};

	const propsForm = getPropsForm(props, {
		onChange: list ? handleChangeFieldGroup : handleChangeField,
		value: value ?? list ? form?.values[name] : !!form?.values[name]
	});

	const propsInput = getPropsInput(props);

	if (list) {
		return <CheckboxGroup list={list} {...propsForm} {...propsInput} />;
	}

	if (label) {
		return <CheckboxLabel {...propsForm} {...propsInput} />;
	}

	return <CheckboxBase {...propsForm} {...propsInput} />;
};

const FormikCheckboxBase: React.FC<ICheckboxProps> = ({ name, ...rest }) => (
	<Field name={name}>
		{({ field, meta }: FieldProps) => {
			const error = meta.touched ? meta.error : "";
			return <Checkbox {...field} {...rest} error={error} />;
		}}
	</Field>
);

Checkbox.defaultProps = {
	validateField: true
};

export const FormikCheckbox = memo(FormikCheckboxBase, isEqual);

export default memo(Checkbox, isEqual);
