import { Route, Redirect } from "react-router-dom";

import localStore from "app/helper/local-store";
import config from "app/config";

const PublicRoute = ({ Component, ...rest }: any) => {
	if (localStore.getAccessToken()) {
		return <Redirect to={config.defaultPage.link} />;
	}

	return <Route {...rest} component={Component} />;
};
export default PublicRoute;
