import React, { memo } from "react";

import {
	Typography as TypographyMUI,
	TypographyProps
} from "@material-ui/core";
import isEqual from "react-fast-compare";
import cn from "classnames";

import { IMarginBottom } from "./types";
import {
	getDefaultMarginBottom,
	getVariantByComponent,
	useStyles
} from "./Typography.utils";

interface Props extends TypographyProps {
	component?: React.ElementType;
	marginBottom?: IMarginBottom;
	useDefaultSpace?: boolean;
}

const Typography: React.FC<Props> = ({
	variant,
	component,
	className,
	marginBottom,
	children,
	...rest
}) => {
	const classes = useStyles({
		marginBottom: marginBottom ?? getDefaultMarginBottom(component)
	});

	const _variant = variant || getVariantByComponent(component);

	return (
		<TypographyMUI
			variant={_variant}
			component={component}
			className={cn(className, classes.root)}
			{...rest}
		>
			{children}
		</TypographyMUI>
	);
};
export default memo(Typography, isEqual);
