import { makeStyles } from "@material-ui/core";

import theme from "app/theme";

import { IStyleProps } from "./types";

export const useStyles = makeStyles({
	root: {
		marginBottom: ({ marginBottom }: IStyleProps) => {
			if (typeof marginBottom === "number") {
				return theme.spacing(marginBottom);
			}

			return marginBottom;
		}
	}
});

export const getDefaultMarginBottom = (component: React.ElementType) => {
	switch (component) {
		case "h1":
		case "h2":
		case "h3":
		case "h4":
		case "h5":
		case "h6":
			return "0.53333em";
		default:
			return undefined;
	}
};

export const getVariantByComponent = (component: React.ElementType) => {
	switch (component) {
		case "h1":
		case "h2":
		case "h3":
		case "h4":
		case "h5":
		case "h6":
			return component;
		default:
			return undefined;
	}
};
