import { ErrorCheckboxGroupRequired } from "app/errors-shcema";
import yup from "app/yup";
import directory from "app/directory/errors";

const dir = directory.en;

const validationSchema = yup.object({
	name: yup
		.string()
		.min(2, dir.minLength.replace("#{number}", "2"))
		.max(256, dir.maxLength.replace("#{number}", "256"))
		.required(),
	triggers: ErrorCheckboxGroupRequired(dir.selectTrigger)
});

export default validationSchema;
