import api from "app/api";
import { ApiUrls } from "app/api/apiUrls";
import { mappedParams } from "app/helper";
import { ITrendValuePrepare } from "app/screens/cabinet/trends/trendsDatabase/components/createOrEdit/types";
import { IGetParams } from "app/types";

const API = api();

type TID = string | number;

const fetchTrends = async (params: IGetParams) => {
	try {
		return await API.get(ApiUrls.account.trends.path, {
			params: { ...mappedParams(params) }
		});
	} catch (error) {
		return error;
	}
};

const fetchTrendsAdmin = async (params: IGetParams) => {
	try {
		return await API.get(ApiUrls.admin.trends.path, {
			params: { ...mappedParams(params) }
		});
	} catch (error) {
		return error;
	}
};

const fetchTrendDetails = async (id: TID) => {
	try {
		return await API.get(ApiUrls.account.trends.details(id));
	} catch (error) {
		return error;
	}
};

const fetchTrendCreate = async (data: ITrendValuePrepare) => {
	try {
		return await API.post(ApiUrls.admin.trends.path, data);
	} catch (error) {
		return error;
	}
};

const fetchTrendUpdate = async (id: TID, data: ITrendValuePrepare) => {
	try {
		return await API.put(ApiUrls.admin.trends.details(id), data);
	} catch (error) {
		return error;
	}
};

const fetchTrendDelete = async (id: TID) => {
	try {
		return await API.delete(ApiUrls.admin.trends.delete, {
			params: {
				ids: id
			}
		});
	} catch (error) {
		return error;
	}
};

export default {
	fetchTrends,
	fetchTrendDetails,
	fetchTrendCreate,
	fetchTrendsAdmin,
	fetchTrendUpdate,
	fetchTrendDelete
};
