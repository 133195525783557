import moment from "moment";

import CommonViewItem from "./CommonViewItem";
import KeywordsViewItem from "./KeywordsViewItem";

export type viewType = "position" | "location" | "keywords";

const VIEW_COMPONENTS = {
	position: CommonViewItem,
	location: CommonViewItem,
	keywords: KeywordsViewItem
};

export const mapDataByType = (data: any, type: viewType) => {
	switch (type) {
		case "position":
		case "location":
			return data.map((item: any) => ({
				title: item.new_value,
				time: moment(item.created_at).format("D/MM/YYYY")
			}));
		case "keywords":
			return data.map((item: any) => ({
				source: item.source_link,
				time: moment(item.created_at).format("D/MM/YYYY"),
				keywords: item.keywords
			}));
		default:
			return data;
	}
};

export const getRenderByType = (type: viewType) => VIEW_COMPONENTS[type];
