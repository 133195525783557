import React from "react";

import { FormikErrors } from "formik";
import { Alert as AlertMUI } from "@material-ui/lab";
import cn from "classnames";

import { getAlertClass, getIcon } from "./alert.utils";

interface Props {
	text?: string | string[] | FormikErrors<any>[] | React.ReactNode;
	type?: "error" | "warning";
	className?: string;
}

const Alert: React.FC<Props> = ({ text, type, className }) => {
	const alertClass = getAlertClass(type);
	if (Array.isArray(text)) {
		if (!text.length) return null;
		return (
			<div className={cn("alert alert-multiple", alertClass, className)}>
				<AlertMUI severity="warning" icon={false}>
					{text.map((elm, i) => {
						return (
							<div className="alert-multiple-item" key={i}>
								{getIcon(type)}
								<span className="small-text">{elm as string}</span>
							</div>
						);
					})}
				</AlertMUI>
			</div>
		);
	} else if (text) {
		return (
			<div className={cn("alert", alertClass, className)}>
				<AlertMUI severity="warning" icon={false}>
					{getIcon(type)}
					<span className="small-text">{text}</span>
				</AlertMUI>
			</div>
		);
	}
	return null;
};

export default Alert;
