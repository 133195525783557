import React from "react";

import cl from "classnames/bind";

import { MenuItem } from "./menuItem";
import { MenuSubItem } from "./menuSubItem";
import style from "./style.module.scss";
import { IMenuItem } from "./types";

interface props {
	list: IMenuItem[];
	nesting?: number;
	isSubItem?: boolean;
	location?: any;
	history?: any;
	onCheckAction: (action: string) => void;
}

export const AsideMenuList: React.FC<props> = ({
	list,
	nesting = 0,
	isSubItem,
	location,
	history,
	onCheckAction
}) => {
	const cs = cl.bind(style);
	const level: number = 1 + nesting;

	return (
		<ul className={cs("aside_list", { "aside_list--sub": isSubItem })}>
			{list.map((navListItem, i) => {
				if (navListItem.subItems) {
					return (
						<MenuSubItem
							location={location}
							history={history}
							key={navListItem?.id || i}
							data={navListItem}
							nesting={level}
						/>
					);
				}
				return (
					<MenuItem
						onCheckAction={onCheckAction}
						key={navListItem?.id || i}
						nesting={level}
						data={navListItem}
					/>
				);
			})}
		</ul>
	);
};
