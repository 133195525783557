import { useMemo } from "react";

import useQuery from "app/hooks/useQuery";

const KEY = "licence-key";

const useGetAppSumoKey = () => {
	const query = useQuery();
	const key = query.get(KEY);
	return key;
};

export const useGetAppSumoKeyNative = () => {
	const search = window.location.search;
	const query = useMemo(() => new URLSearchParams(search), [search]);
	const key = query.get(KEY);
	return key;
};

export default useGetAppSumoKey;
