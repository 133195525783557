import React, { memo } from "react";

import { FormHelperText } from "@material-ui/core";
import isEqual from "react-fast-compare";

interface props {
	error: boolean | string;
	text?: string;
	className?: string;
}

const FormError: React.FC<props> = ({ error, text, className }) => {
	const errorText = text ? text : typeof error === "string" ? error : "";

	if (!!error && errorText) {
		return (
			<FormHelperText className={className} error={true}>
				{errorText}
			</FormHelperText>
		);
	}

	return null;
};
export default memo(FormError, isEqual);
