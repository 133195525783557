import React, { memo, useCallback } from "react";

import { useHistory } from "react-router-dom";
import isEqual from "react-fast-compare";

import PageTitle from "app/components/PageTitle";
import { useIsAdmin } from "app/hooks/useIsAdmin";
import PATHS from "PATHS";

import TrendsNav from "../components/nav";

import TrendsContent from "./components/content";

const Reports: React.FC = () => {
	const { isAdmin } = useIsAdmin();
	const history = useHistory();

	const onOpenCreateTrend = useCallback(() => {
		history.push(PATHS.adminPanel.trendCreate);
	}, [history]);

	return (
		<>
			<PageTitle
				title="Reports"
				showButton={isAdmin}
				buttonText="Create a trend"
				buttonAction={onOpenCreateTrend}
			/>
			<TrendsNav />
			<TrendsContent />
		</>
	);
};

export default memo(Reports, isEqual);
