// @ts-nocheck

import cl from "classnames/bind";

import Alert from "app/uikit/alert";
import Button from "app/uikit/button/active-button";

import style from "../style.module.scss";

const FooterModal = ({
	justify,
	firstBtnText = "Cancel",
	firstBtnAction,
	firstBtnSize = "medium",
	firstBtnVariant = "ghost",
	firstBtnHide,
	firstBtnComponent,
	firstBtnloading,
	firstBtnDisabled,

	secondBtnText = "Send request",
	secondBtnAction,
	secondBtnSize = "medium",
	secondBtnVariant = "gradient",
	secondBtnHide,
	secondBtnComponent,
	secondBtnloading,
	secondBtnDisabled,
	secondBtnType,

	spacer,
	spacerBottom,
	disabled,
	classNameFooter,
	classNameFooterInner,
	footer = true,
	error,
	onClose,
	children
}: any) => {
	const cx = cl.bind(style);
	return (
		(footer && (
			<div
				className={cx("modal__footer", classNameFooter, {
					"modal-footer_with-warning": error,
					"modal__footer--spacer": spacer,
					"modal__footer--spacer-bottom": spacerBottom
				})}
			>
				{error && <Alert type="error" text={error} />}

				<div
					className={cl(
						classNameFooterInner,
						style.modal__footer_inner,
						justify === "center" && style["modal__footer--center"]
					)}
				>
					{!!!firstBtnHide &&
						(firstBtnComponent || (
							<Button
								classNameWrapper={style.modal__footer_btn}
								className={style.btn}
								loading={!!firstBtnloading}
								onClick={firstBtnAction || onClose}
								size={firstBtnSize}
								colorVariant={firstBtnVariant}
								disabled={!!disabled || !!firstBtnDisabled}
							>
								{firstBtnText}
							</Button>
						))}
					{!!!secondBtnHide &&
						(secondBtnComponent || (
							<Button
								type={secondBtnType}
								className={style.btn}
								classNameWrapper={style.modal__footer_btn}
								loading={!!secondBtnloading}
								onClick={secondBtnAction}
								size={secondBtnSize}
								colorVariant={secondBtnVariant}
								disabled={!!disabled || !!secondBtnDisabled}
							>
								{secondBtnText}
							</Button>
						))}
					{children}
				</div>
			</div>
		)) ||
		null
	);
};

export default FooterModal;
