import React, { memo, useMemo } from "react";

import isEqual from "react-fast-compare";
import { useFormik } from "formik";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import FormField from "app/uikit-V2/Form/FormField";
import { configSelect } from "app/store/select";
import { prepareHandBookOptions } from "app/helper";
import Button from "app/uikit-V2/Button";
import actions from "app/store/trendsDatabase/actions";

export interface ITrendDataBaseValue {
	keywords: string[];
	market_ids: string;
}

const TrendsDataBaseFilter: React.FC = () => {
	const { trend_markets } = useSelector(configSelect.handBooks);
	const { filter } = useSelector(configSelect.trendsDatabase);

	const dispatch = useDispatch();

	const marketOptions = useMemo(() => prepareHandBookOptions(trend_markets), [
		trend_markets
	]);

	const formik = useFormik<ITrendDataBaseValue>({
		enableReinitialize: true,
		initialValues: {
			keywords: filter?.trends?.keywords ?? [],
			market_ids: filter?.trends?.market_ids ?? ""
		},
		onSubmit: (values, formikActions) => {
			dispatch(actions.fetchFilter(values));
			formikActions.resetForm();
		}
	});

	return (
		<Box display="flex" alignItems="flex-start" gridGap="25px">
			<Box minWidth="300px">
				<FormField
					fullWidth
					type="select"
					name="market_ids"
					placeholder="Select a market"
					options={marketOptions}
					form={formik}
					useNone
					noneValue=""
				/>
			</Box>

			<Box minWidth="300px">
				<FormField
					placeholder="Select keywords"
					fullWidth
					type="selectChip"
					name="keywords"
					form={formik}
				/>
			</Box>
			<Button
				onClick={() => formik.handleSubmit()}
				disabled={!formik.dirty}
				size="medium"
			>
				Search
			</Button>
		</Box>
	);
};

export default memo(TrendsDataBaseFilter, isEqual);
