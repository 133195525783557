import Typography from "app/uikit-V2/Typography";

import { IconTime } from "./Icons";
import styles from "./style.module.scss";

export interface CommonViewItemProps {
	title: string;
	time: string;
}

const CommonViewItem: React.FC<CommonViewItemProps> = ({ title, time }) => (
	<div className={styles.common_view}>
		<Typography className={styles.common_view__title}>{title}</Typography>
		<div className={styles.common_view__time}>
			Date of capturing the update:{" "}
			<span>
				<IconTime /> {time}
			</span>
		</div>
	</div>
);

export default CommonViewItem;
