import React, { memo } from "react";

import isEqual from "react-fast-compare";

// import { FormikAutocomplete } from '@components/shared/Form/Autocomplete';
// import { FormikSwitch } from '@components/shared/Form/Switch';
// import { FormikRadio } from '@components/shared/Form/Radio';
import { FormikCheckbox } from "app/uikit-V2/Form/Checkbox";
import { FormikRadio } from "app/uikit-V2/Form/Radio";
import { FormikSelectChip } from "app/uikit-V2/Form/SelectChip";
import { FormikFormInput } from "app/uikit-V2/Form/Input";
import { FormikFormSelect } from "app/uikit-V2/Form/Select";
// import { FormikDateInput } from '@components/shared/Form/DateInput/DateInput';
// import { FormikFormSelect } from '@components/shared/Form/Select/Select';
// import { FormikFilePicker } from '@components/shared/Form/FilePicker';
// import { FormikTimeInput } from '@components/shared/Form/TimeInput';
// import { FormikFormTag } from '@components/shared/Form/Tag/Tag';
import { FieldTypes, FormFieldProps } from "app/uikit-V2/types";

const fieldTypeComponents = {
	// [FieldTypes.autocomplete]: FormikAutocomplete,
	[FieldTypes.radio]: FormikRadio,
	[FieldTypes.checkbox]: FormikCheckbox,
	[FieldTypes.selectChip]: FormikSelectChip,
	[FieldTypes.select]: FormikFormSelect
	// [FieldTypes.switch]: FormikSwitch,
	// [FieldTypes.radio]: FormikRadio,
	// [FieldTypes.file]: FormikFilePicker,
	// [FieldTypes.date]: FormikDateInput,
	// [FieldTypes.time]: FormikTimeInput,
	// [FieldTypes.tag]: FormikFormTag
};

// interface props {
// 	type?: keyof typeof FieldTypes;
// 	label?: string;
// 	name: string;
// }

const FormikField: React.FC<FormFieldProps> = ({ type, ...props }) => {
	//@ts-ignore
	const Component = fieldTypeComponents[type] || FormikFormInput;
	const currentType = Component ? undefined : type;
	return <Component type={currentType} {...props} />;
};

FormikField.defaultProps = {
	type: FieldTypes.text
};

export default memo(FormikField, isEqual);
