export const IconTime = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
	>
		<path
			d="M9.99166 1.66669C5.39166 1.66669 1.66666 5.40002 1.66666 10C1.66666 14.6 5.39166 18.3334 9.99166 18.3334C14.6 18.3334 18.3333 14.6 18.3333 10C18.3333 5.40002 14.6 1.66669 9.99166 1.66669ZM9.99999 16.6667C6.31666 16.6667 3.33332 13.6834 3.33332 10C3.33332 6.31669 6.31666 3.33335 9.99999 3.33335C13.6833 3.33335 16.6667 6.31669 16.6667 10C16.6667 13.6834 13.6833 16.6667 9.99999 16.6667ZM9.81666 5.83335H9.76666C9.43332 5.83335 9.16666 6.10002 9.16666 6.43335V10.3667C9.16666 10.6584 9.31666 10.9334 9.57499 11.0834L13.0333 13.1584C13.3167 13.325 13.6833 13.2417 13.85 12.9584C14.025 12.675 13.9333 12.3 13.6417 12.1334L10.4167 10.2167V6.43335C10.4167 6.10002 10.15 5.83335 9.81666 5.83335Z"
			fill="#D4D7DF"
		/>
	</svg>
);

export const IconShare = () => (
	<svg
		width="16"
		height="18"
		viewBox="0 0 16 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g id="Frame 217">
			<path
				id="Vector"
				d="M13.3333 12.7229C12.6578 12.7229 12.0533 12.994 11.5911 13.4187L5.25333 9.66867C5.29778 9.46084 5.33333 9.25301 5.33333 9.03614C5.33333 8.81928 5.29778 8.61145 5.25333 8.40361L11.52 4.68976C12 5.14157 12.6311 5.42169 13.3333 5.42169C14.8089 5.42169 16 4.21084 16 2.71084C16 1.21084 14.8089 0 13.3333 0C11.8578 0 10.6667 1.21084 10.6667 2.71084C10.6667 2.92771 10.7022 3.13554 10.7467 3.34337L4.48 7.05723C4 6.60542 3.36889 6.3253 2.66667 6.3253C1.19111 6.3253 0 7.53614 0 9.03614C0 10.5361 1.19111 11.747 2.66667 11.747C3.36889 11.747 4 11.4669 4.48 11.0151L10.8089 14.7741C10.7644 14.9639 10.7378 15.1627 10.7378 15.3614C10.7378 16.8163 11.9022 18 13.3333 18C14.7644 18 15.9289 16.8163 15.9289 15.3614C15.9289 13.9066 14.7644 12.7229 13.3333 12.7229Z"
				fill="#939BAB"
			/>
		</g>
	</svg>
);

export const IconLinkedin = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="14"
		height="14"
		viewBox="0 0 14 14"
		fill="none"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0.469384 0.439439C0.782402 0.146575 1.191 0 1.69528 0C2.19959 0 2.60382 0.146448 2.90751 0.439439C3.21124 0.732367 3.3663 1.10222 3.37236 1.54924C3.3784 1.99017 3.225 2.35859 2.91211 2.65447C2.59922 2.95051 2.18749 3.09842 1.67702 3.09842H1.65879C1.16055 3.09842 0.759449 2.95038 0.45572 2.65447C0.151896 2.35859 0 1.99004 0 1.54924C0 1.10218 0.156397 0.732367 0.469384 0.439439ZM13.0521 5.14175C12.4202 4.45619 11.5846 4.11334 10.5456 4.11334C10.1628 4.11334 9.81483 4.16015 9.50184 4.25382C9.18895 4.34744 8.92468 4.47878 8.70891 4.64792C8.49326 4.81705 8.3215 4.97407 8.194 5.11896C8.07275 5.25659 7.95418 5.4169 7.83849 5.59826V4.32157H4.83967L4.84887 4.75651C4.85503 5.04652 4.85806 5.94037 4.85806 7.43821C4.85806 8.93616 4.85199 10.89 4.8398 13.3H7.83849V8.28976C7.83849 7.98179 7.87172 7.73723 7.9387 7.55587C8.06639 7.24768 8.25912 6.98966 8.51752 6.78136C8.77588 6.57281 9.09631 6.46863 9.47924 6.46863C10.0016 6.46863 10.3861 6.64831 10.6323 7.00768C10.8783 7.36703 11.0013 7.86384 11.0013 8.49806V13.2997H14V8.15401C13.9998 6.83112 13.6841 5.82709 13.0521 5.14175ZM0.17312 4.32163H3.18091V13.2999H0.17312V4.32163Z"
			fill="#0A66C2"
		/>
	</svg>
);

export const IconCopy = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="18"
		viewBox="0 0 16 18"
		fill="none"
	>
		<path
			d="M1 6V16C1 16.5523 1.44772 17 2 17H10C10.5523 17 11 16.5523 11 16V6C11 5.44772 10.5523 5 10 5H2C1.44772 5 1 5.44772 1 6Z"
			stroke="#D4D7DF"
			strokeWidth="2"
		/>
		<path
			d="M4 1H13C14.1046 1 15 1.89543 15 3V15"
			stroke="#D4D7DF"
			strokeWidth="2"
		/>
	</svg>
);
