import React, { useMemo } from "react";

import { FormHelperText } from "@material-ui/core";
import { FormikErrors } from "formik";

interface props {
	className?: string;
	error?: string[] | string | FormikErrors<any>[];
}

const Error: React.FC<props> = ({ error, className }) => {
	const checkError = useMemo(() => {
		if (typeof error === "string" && error !== "") {
			return {
				error: Boolean(error),
				text: error
			};
		} else {
			return {
				error: false,
				text: ""
			};
		}
	}, [error]);

	if (Array.isArray(error)) {
		return (
			<>
				{error.map(item => (
					<FormHelperText className={className} error={!!item}>
						{item as string}
					</FormHelperText>
				))}
			</>
		);
	}

	return (
		<FormHelperText className={className} error={checkError.error}>
			{checkError.text}
		</FormHelperText>
	);
};

export default Error;
