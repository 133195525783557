import { useDispatch } from "react-redux";

import Logo from "app/components/logo";
import actions from "app/store/auth/actions";
import Button from "app/uikit-V2/Button/Button";

import style from "./style.module.scss";

const HeaderConfiguration: React.FC = () => {
	const dispatch = useDispatch();

	const logOut = () => {
		dispatch(actions.fetchLogOutRequest());
	};

	return (
		<header className={style.header}>
			<Logo />
			<Button className={style.logout} variant="text" onClick={logOut}>
				Logout
			</Button>
		</header>
	);
};

export default HeaderConfiguration;
