// @ts-nocheck
import { useState, useEffect } from "react";

import Popper from "@material-ui/core/Popper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ButtonBase from "@material-ui/core/ButtonBase";
import InputBase from "@material-ui/core/InputBase";
import { InputAdornment } from "@material-ui/core";
import cl from "classnames/bind";

import Checkbox from "../checkbox";
import { Search, DownArrow } from "../../icons";
import Chip from "../chip";

import useStyles from "./style";

const AutocompleteMultiple = ({
	name,
	error,
	placeholder,
	placeholderSearch,
	value,
	onChange,
	options,
	noOptionsText
}) => {
	const classes = useStyles();
	const cx = cl.bind(classes);
	const [anchorEl, setAnchorEl] = useState(null);
	const [pendingValue, setPendingValue] = useState([]);
	const [values, setValues] = useState([]);

	useEffect(() => {
		setValues(value || []);
		setPendingValue(value || []);
	}, [value]);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleChange = (event, newValue) => {
		setPendingValue(newValue);
		if (newValue !== null) {
			onChange(newValue);
		}
	};
	const handleClose = (event, reason) => {
		if (reason === "toggleInput") {
			return;
		}
		if (anchorEl) {
			(anchorEl as any).focus();
		}
		setAnchorEl(null);
	};
	const onDelete = index => {
		const mewArr = [...values];
		mewArr.splice(+index, 1);
		setPendingValue(mewArr);
		onChange(mewArr);
	};

	const open = Boolean(anchorEl);

	return (
		<>
			<ButtonBase
				disableRipple
				className={cx("button", { error: error })}
				onClick={handleClick}
			>
				<Chip
					className={classes.chipContainer}
					variant="bordered"
					list={values}
					onDelete={onDelete}
				/>

				{!values.length && placeholder && (
					<span className={classes.placeholder}> {placeholder}</span>
				)}

				<div className={classes.arrow}>
					<DownArrow />
				</div>
			</ButtonBase>

			<Popper
				id={`${name}-popper`}
				open={open}
				anchorEl={anchorEl}
				style={{ minWidth: (anchorEl as any)?.clientWidth }}
				modifiers={{
					flip: {
						enabled: true
					},
					preventOverflow: {
						enabled: true,
						boundariesElement: "viewport"
					}
				}}
				placement="bottom"
				className={classes.popper}
			>
				<Autocomplete
					open
					multiple
					disablePortal
					onClose={handleClose}
					value={pendingValue}
					onChange={handleChange}
					disableCloseOnSelect
					renderTags={() => null}
					noOptionsText={noOptionsText || "No items have been found by your request"}
					classes={{
						paper: classes.paper,
						option: classes.option,
						popperDisablePortal: classes.popperDisablePortal
					}}
					getOptionSelected={(option, value) =>
						(option as any)?.name === (value as any)?.name
					}
					renderOption={(option, { selected }) => (
						<div className={classes.text}>
							<Checkbox checked={selected} />

							<span className="main-text main-text_bold">{(option as any).name}</span>
						</div>
					)}
					options={options}
					getOptionLabel={option => (option as any).name || ""}
					renderInput={params => (
						<InputBase
							ref={params.InputProps.ref}
							inputProps={params.inputProps}
							autoFocus
							className={cl(classes.inputSearch, classes.inputBase)}
							placeholder={placeholderSearch}
							fullWidth
							startAdornment={
								<InputAdornment position="start">
									<Search />
								</InputAdornment>
							}
						/>
					)}
				/>
			</Popper>
		</>
	);
};
export default AutocompleteMultiple;
