// @ts-nocheck

import { useEffect, useRef, useState } from "react";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import cs from "classnames";

import config from "app/config";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex"
	},
	button: {
		"& .MuiButton-label": {
			display: "flex",
			textTransform: "initial",
			alignItems: "center",
			lineHeight: 1,
			fontWeight: "400"
		}
	},
	value: {
		color: "#1B1E3F",
		marginRight: 6
	},
	paper: {
		marginRight: theme.spacing(2)
	},
	menuList: {
		minWidth: 40,
		padding: 8,
		borderRadius: 5
	},
	menuItem: {
		borderRadius: 5,
		transition: "0.3s",
		backgroundColor: "transparent",
		padding: "8px 10px",
		color: "#56637B",
		"& svg": {
			fill: "currentColor"
		}
	},
	popper: {
		zIndex: 1260
	}
}));

export default function MenuListComposition(props) {
	const {
		control,
		hover,
		menuItem = [],
		placement = "bottom",
		menuItemClass
	} = props;
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [currentValue, setCurrentValue] = useState(config.ui.tableSize[0]);
	const anchorRef = useRef(null);

	const handleToggle = () => {
		setOpen(prevOpen => !prevOpen);
	};

	const handleOpen = () => {
		if (hover) {
			setOpen(true);
		}
	};

	const handleClose = event => {
		if (hover) {
			setOpen(false);
		}
	};

	const handleClickClose = event => {
		if (anchorRef.current && (anchorRef.current as any).contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const handleChangeItem = cb => {
		return event => {
			if (event) {
				handleChangeItem(event);
				setCurrentValue(event?.currentTarget?.dataset?.value || currentValue);
			}
			if (cb) {
				const target = event.currentTarget;
				const id = target?.dataset?.id;
				const key = target?.dataset?.key;
				cb(id, key, event);
			}
		};
	};

	function handleListKeyDown(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpen(false);
		}
	}

	const prevOpen = useRef(open);

	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}
		prevOpen.current = open;
	}, [open]);

	return (
		<div
			className={classes.root}
			onClick={handleToggle}
			onMouseOver={handleOpen}
			onMouseLeave={handleClose}
		>
			<div
				ref={anchorRef}
				aria-controls={open ? "menu-list-grow" : undefined}
				className={classes.button}
				aria-haspopup="true"
			>
				{control}
			</div>

			<Popper
				className={classes.popper}
				open={open}
				disablePortal={false}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				placement={placement}
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<Paper>
							<ClickAwayListener onClickAway={handleClickClose}>
								<MenuList
									className={classes.menuList}
									autoFocusItem={open}
									onKeyDown={handleListKeyDown}
								>
									{menuItem.map((elem, i) => (
										<MenuItem
											data-id={elem.id}
											className={cs(classes.menuItem, menuItemClass)}
											key={`${elem.key}-${i}`}
											data-key={elem.key}
											onClick={handleChangeItem(elem.onChange)}
										>
											{elem.render()}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	);
}
