import {
	IResponsAction,
	IResponsActionSuccess,
	IResponsActionError,
	IResponsActionType
} from "../types";

import { types } from "./types";

type Itypes = keyof typeof types;

const actions = {
	fetchMatchUserAudience: <T, C>(
		data: T,
		cb: C
	): IResponsAction<Itypes, T, C> => ({
		type: types.FETCH_MATCH_AUDIENCE_REQUEST,
		data,
		cb,
		actionName: "userAudience"
	}),
	fetchMatchCategoryAudience: <T, C>(
		data: T,
		cb: C
	): IResponsAction<Itypes, T, C> => ({
		type: types.FETCH_MATCH_AUDIENCE_REQUEST,
		data,
		cb,
		actionName: "categoryAudience"
	}),
	fetchMatchAudience: <T, C>(data: T, cb: C): IResponsAction<Itypes, T, C> => ({
		type: types.FETCH_MATCH_AUDIENCE_REQUEST,
		data,
		cb
	}),
	fetchMatchAudienceSuccess: <T>(data: T): IResponsActionSuccess<Itypes, T> => ({
		type: types.FETCH_MATCH_AUDIENCE_SUCCESS,
		data
	}),
	fetchMatchAudienceFailure: <T>(error: T): IResponsActionError<Itypes, T> => ({
		type: types.FETCH_MATCH_AUDIENCE_FAILURE,
		error
	}),
	fetchCreateUserAudience: <T, C>(
		data: T,
		cb: C
	): IResponsAction<Itypes, T, C> => ({
		type: types.FETCH_CREATE_AUDIENCE_REQUEST,
		data,
		cb,
		actionName: "userAudience"
	}),
	fetchCreateCategoryAudience: <T, C>(
		data: T,
		cb: C
	): IResponsAction<Itypes, T, C> => ({
		type: types.FETCH_CREATE_AUDIENCE_REQUEST,
		data,
		cb,
		actionName: "categoryAudience"
	}),
	fetchCreateAudience: <T, C>(data: T, cb: C): IResponsAction<Itypes, T, C> => ({
		type: types.FETCH_CREATE_AUDIENCE_REQUEST,
		data,
		cb
	}),
	fetchCreateAudienceSuccess: <T>(
		data: T
	): IResponsActionSuccess<Itypes, T> => ({
		type: types.FETCH_CREATE_AUDIENCE_SUCCESS,
		data
	}),
	fetchCreateAudienceFailure: <T>(error: T): IResponsActionError<Itypes, T> => ({
		type: types.FETCH_CREATE_AUDIENCE_FAILURE,
		error
	}),

	resetDataAudiences: (): IResponsActionType<Itypes> => ({
		type: types.RESET_DATA_AUDIENCES
	})
};
export default actions;
