import React, { memo, useCallback } from "react";

import { useDispatch } from "react-redux";

import Footer from "app/uikit/table/footer";
import CircularProgress from "app/uikit/CircularProgress";
import EmptyData from "app/uikit/table/EmptyData";
import { useSubscribe } from "app/hooks/useSubscribe";
import { IPagination } from "app/types";
import { useIsAdmin } from "app/hooks/useIsAdmin";
import {
	ICompany,
	IResponseCompanies
} from "app/screens/cabinet/tracking/types";

import CompanyCard from "./CompanyCard";
import HideContent from "./hideContent";
import style from "./style.module.scss";

const Header = () => (
	<div className={style.container__header}>
		{["Company", "Trigger", ""].map(text => (
			<div key={text}>{text}</div>
		))}
	</div>
);

interface PropsDefault {
	data: IResponseCompanies;
	loading: boolean;
	pagination: IPagination;
	displayedTrialPlaceholder?: boolean;
	trackingListId: string;
	userId: string;
	onRemovePerson?: (
		id: string,
		companyId: string,
		name?: string
	) => Promise<void>;
}
interface PropsWithActions extends PropsDefault {
	actions: any;
	actionName: string;
	onPaginationChange?: never;
}

interface PropsPaginationChange extends PropsDefault {
	actions?: never;
	actionName?: never;
	onPaginationChange: (data: IPagination) => void;
}

export const CompanyTable: React.FC<
	PropsPaginationChange | PropsWithActions
> = memo(
	({
		data,
		loading,
		pagination,
		actions,
		actionName,
		displayedTrialPlaceholder = true,
		onPaginationChange,
		trackingListId,
		userId,
		onRemovePerson
	}) => {
		const { isAdmin } = useIsAdmin();
		const subscribe = useSubscribe();

		const dispatch = useDispatch();

		const handleChangePagination = useCallback(
			(data: IPagination) => {
				if (onPaginationChange) {
					onPaginationChange(data);
					return;
				}
				dispatch(actions.fetchPagination(data, actionName));
			},
			[actions, actionName, dispatch, onPaginationChange]
		);
		const count = data?.count;

		return (
			<div className={style.container}>
				<Header />
				<div className={style.container__content}>
					{data?.items?.map((item: ICompany) => (
						<CompanyCard
							key={item.id}
							onRemovePerson={onRemovePerson}
							trackingListId={trackingListId}
							userId={userId}
							data={item}
						/>
					))}

					{!!count && displayedTrialPlaceholder && <HideContent />}
					{!count && <EmptyData minHeight="60vh" loading={loading} />}
				</div>

				{loading && (
					<CircularProgress
						color="#000"
						size={50}
						position="center"
						colorBg="rgba(0,0,0,0.03)"
						height="100%"
					/>
				)}

				{(!displayedTrialPlaceholder ||
					(displayedTrialPlaceholder && (subscribe.isActive || isAdmin))) && (
					<Footer
						pagination={{ ...pagination, total: pagination.total || count }}
						paginationChange={handleChangePagination}
					/>
				)}
			</div>
		);
	}
);
