import React from "react";

import qs from "query-string";

import PATHS from "PATHS";
import { Nullable } from "app/types";
import { SuccessType } from "app/screens/account/subscriptionSuccess/type";

import PriceListReports from "./PriceListReports";
import { fetchActiveSubscription } from "./utils";
import { ISubscriptionPrice } from "./PriceListReports/type";

interface Props {
	price?: Nullable<ISubscriptionPrice>;
}

const ConfigurationStepper: React.FC<Props> = ({ price }) => {
	const handleSubmitStart = async () => {
		let redirect: string = qs.stringifyUrl({
			url: `${window.location.origin}${PATHS.account.subscriptionSuccess}`,
			query: {
				type: SuccessType.onboarding
			}
		});

		await fetchActiveSubscription(redirect);
	};

	return <PriceListReports onSubmit={handleSubmitStart} data={price} />;
};

export default ConfigurationStepper;
