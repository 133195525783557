import React, { memo, useCallback, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";

import Modal from "app/components/modal";
import action from "app/store/general/actions";
import { handlerErrors } from "app/helper/handlerErrors";
import Error from "app/uikit/error";
import Input from "app/uikit/input";
import { RootState } from "app/store/rootReducer";

import validationSchema from "./validationSchema";

type IinitialValues = {
	name: string;
	email: string;
	comment: string;
};

interface props {
	onClose: () => void;
	open: boolean;
}

const ContactModalStep: React.FC<props> = memo(({ onClose, open }) => {
	const { error, isLoading } = useSelector((state: RootState) => state.general);
	const { user } = useSelector((state: RootState) => state.user);

	const dispatch = useDispatch();

	const hanldeChange = useCallback(
		(values: IinitialValues) => {
			const visitortime = new Date();
			const visitortimezone = "GMT " + -visitortime.getTimezoneOffset() / 60;
			dispatch(
				action.fetchSupport(
					{
						...values,
						locale: navigator.language,
						gmt: visitortimezone,
						form_name: "BOX support"
					},
					onClose
				)
			);
		},
		[dispatch, onClose]
	);

	const formik = useFormik<IinitialValues>({
		initialValues: {
			name: user?.name,
			email: user?.email,
			comment: ""
		},
		validationSchema: validationSchema,
		onSubmit: hanldeChange
	});

	useEffect(() => {
		handlerErrors(error.support, formik.setErrors);
	}, [error.support, formik.setErrors]);

	return (
		<Modal
			title="Ask support a question"
			onClose={onClose}
			open={open}
			resetForm={formik.resetForm}
			footer
			secondBtnloading={isLoading.support}
			secondBtnAction={formik.handleSubmit}
		>
			<form onSubmit={formik.handleSubmit} noValidate>
				<Input fullWidth disabled name="name" label="Name" form={formik} required />

				<Input
					fullWidth
					disabled
					name="email"
					label="Email"
					form={formik}
					required
				/>

				<Input
					multiline
					rows={5}
					fullWidth
					name="comment"
					label="Describe your question"
					form={formik}
					required
				/>

				<Error error={error.support} />
			</form>
		</Modal>
	);
});

export default ContactModalStep;
