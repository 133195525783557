import React, { memo } from "react";

import { Container, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import classNames from "classnames/bind";

import Logo from "app/components/logo";
import Menu from "app/components/menu";
import actions from "app/store/auth/actions";
import Avatar from "app/components/avatar";
import Button from "app/uikit/button/active-button";
import { DownArrow, LeftArrow, NotificationErrorIcon } from "app/icons";
import { RootState } from "app/store/rootReducer";

import menuHeader from "./menu";
import style from "./style.module.scss";

interface Props {
	userHeader?: boolean;
	className?: string;
	headerBackLink?: string;
	headerBackLinkTitle?: string;
}

const Header: React.FC<Props> = ({
	userHeader,
	className,
	headerBackLink,
	headerBackLinkTitle
}) => {
	const { settings } = useSelector((state: RootState) => state.appSettings);
	const {
		user: { name, id, avatar }
	} = useSelector((state: RootState) => state.user);

	const cx = classNames.bind(style);
	const dispatch = useDispatch();

	const logOut = () => {
		dispatch(actions.fetchLogOutRequest());
	};

	return (
		<header className={cx("header", className)}>
			<Container maxWidth={false}>
				{userHeader ? (
					<Grid container justify="space-between" wrap="nowrap" alignItems="center">
						{headerBackLink && (
							<div className="header-back">
								<Link
									to={headerBackLink}
									type="button"
									className="button button_borderless button_medium with-icon animate-left"
								>
									<LeftArrow /> Back to {headerBackLinkTitle}
								</Link>
							</div>
						)}
						{settings?.is_show_technical_works_notification && (
							<div className={style.alert_notification}>
								<NotificationErrorIcon color="#fff" />
								<p>{settings?.technical_works_notification}</p>
							</div>
						)}

						<div className="header-user-box">
							<Menu
								hover
								control={
									<div className="user">
										<Button type="button" className="user-button">
											<Avatar name={name} id={id} src={avatar?.url} />
											{name || `user-${id}`}
											<DownArrow />
										</Button>
									</div>
								}
								menuItem={menuHeader(logOut)}
								placement="bottom-end"
							/>
						</div>
					</Grid>
				) : (
					<Grid container justify="center" alignItems="center">
						<Logo />
					</Grid>
				)}
			</Container>
		</header>
	);
};

export default memo(Header);
