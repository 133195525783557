import { useState } from "react";

import Collapse from "@material-ui/core/Collapse";

import Typography from "app/uikit-V2/Typography";
import { IChildren } from "app/types";

import styles from "./style.module.scss";
import { IconArrow } from "./icons";

interface Props {
	icon: JSX.Element;
	title: String | JSX.Element;
	children: IChildren;
	isNew?: boolean;
	triggerNewStyle?: {
		background: string;
		border: string;
	};
}

const CollapseInfoItem: React.FC<Props> = ({
	triggerNewStyle,
	icon,
	title,
	children,
	isNew
}) => {
	const [checked, setChecked] = useState<boolean>(false);

	const handleChange = () => {
		setChecked(prev => !prev);
	};

	return (
		<div className={styles.block}>
			<div className={styles.header} onClick={handleChange}>
				<div className={styles.info}>
					{icon}
					<Typography className={styles.title}>{title}</Typography>
				</div>

				<div className={styles.marker}>
					{checked ? "Hide details" : "Show details"}
					<IconArrow open={checked} />
				</div>

				{isNew && (
					<div className={styles.header__marker_new} style={triggerNewStyle}>
						New
					</div>
				)}
			</div>

			<Collapse in={checked}>
				<div className={styles.body}>{children}</div>
			</Collapse>
		</div>
	);
};

export default CollapseInfoItem;
