import Loadable from "app/utils/Loadable";
import UserLayout from "app/components/includes/UserLayout";

const InfoDashBoard = Loadable({
	loader: () => import("./InfoDashboard")
});

const PageInfoDashBoard = () => {
	return (
		<UserLayout>
			<InfoDashBoard />
		</UserLayout>
	);
};

export default PageInfoDashBoard;
