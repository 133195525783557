// @ts-nocheck

import cl from "classnames";

import CircularProgress from "app/uikit/CircularProgress";

import Button, { BUTTON_COLOR_VARIANT, BUTTON_SIZE } from "./index";

const ButtonActive = (props: any) => {
	const { loading, disabled, classNameWrapper, ...rest } = props;
	return (
		<div
			className={cl("relative d-inline-block", classNameWrapper, {
				"d-block": props.fullWidth
			})}
		>
			<Button disabled={loading || disabled} {...rest}>
				{props.children}
			</Button>

			{loading && <CircularProgress delayLoader={0} size={24} />}
		</div>
	);
};

export default ButtonActive;
export { BUTTON_COLOR_VARIANT, BUTTON_SIZE };
