import { memo, useCallback, useMemo, useRef, useState } from "react";

import { useSelector } from "react-redux";
import cn from "classnames";
import { FormikHelpers, useFormik } from "formik";
import { Prompt } from "react-router-dom";
import { Box, IconButton } from "@material-ui/core";
import { isEqual } from "lodash";
import isEqualCompare from "react-fast-compare";

import { CloseIcon, DeleteIcon, Edit, SingleCheckIcon } from "app/icons";
import Button from "app/uikit-V2/Button";
import FormField from "app/uikit-V2/Form/FormField";
import Typography from "app/uikit-V2/Typography";
import { configSelect } from "app/store/select";
import { KeywordsKeys, KeywordsKeysId } from "app/types/tracking";
import { IFile, Nullable } from "app/types";
import KeyWorksModal from "app/components/Tracking/TrackingNewLists/SelectTrigger/KeyWorksModal";
import Loader from "app/components/loaderV2";
import { PALETTE } from "app/theme";
import { IResponseCompanyDetails } from "app/screens/cabinet/tracking/types";

import { IMatchedData } from "../TrackingNewLists/ProspectsUpload/type";

import styles from "./style.module.scss";
import { preparedTriggersList } from "./TrackingEditList.utils";
import TrackingAddProspects from "./AddProspects";
import { TAddProspects } from "./AddProspects/types";
import validationSchema from "./validationShema";

export interface FromValues {
	name: string;
	triggers: Record<string, boolean>;
	keywords: {
		mention: string[];
		vacancy: string[];
	};
}

interface Props {
	data: Nullable<IResponseCompanyDetails>;
	onSubmit: ({
		values,
		newProspects,
		actions: { setSubmitting, resetForm },
		cbSuccess
	}: {
		values: FromValues;
		newProspects: TAddProspects[];
		actions: FormikHelpers<FromValues>;
		cbSuccess: () => void;
	}) => Promise<void>;
	onMatchesColumns: (
		data: {
			file: IFile;
		},
		prospects: number
	) => Promise<IMatchedData>;
}

const TrackingEditList: React.FC<Props> = ({
	data,
	onSubmit,
	onMatchesColumns
}) => {
	const [isEdit, setIsEdit] = useState(false);
	const [currentKeyWord, setCurrentKeyWord] = useState<
		Nullable<[string, string | KeywordsKeysId]>
	>();
	const { lead_observation_events } = useSelector(configSelect.handBooks);
	const [isAddProspects, setIsAddProspects] = useState(false);
	const [newProspects, setNewProspects] = useState<TAddProspects[]>([]);
	const initialNameRef = useRef("");

	const detailsFormatted = useMemo(
		() => ({
			name: data?.name || "",
			triggers: data?.event_ids?.reduce(
				(acc, item) => ({ ...acc, [item]: true }),
				{}
			),
			keywords: {
				mention: data?.keywords || [],
				vacancy: data?.vacancy_keywords || []
			}
		}),
		[data]
	);

	const handleSaveName = (e: React.MouseEvent) => {
		e.stopPropagation();
		onToggleEdit();
	};

	const onToggleEdit = () => {
		setIsEdit(state => !state);
	};

	const handleSubmitForm = async (
		values: FromValues,
		actions: FormikHelpers<FromValues>
	) => {
		actions.setSubmitting(true);
		await onSubmit({
			values,
			newProspects,
			actions,
			cbSuccess: () => {
				setNewProspects([]);
			}
		});
		actions.setSubmitting(false);
	};

	const formik = useFormik<FromValues>({
		initialValues: detailsFormatted,
		enableReinitialize: true,
		validationSchema: validationSchema,
		onSubmit: handleSubmitForm
	});

	const onEdit = () => {
		initialNameRef.current = formik.values.name;
		onToggleEdit();
	};

	const onCancelEditName = () => {
		formik.setFieldValue("name", initialNameRef.current);
		onToggleEdit();
	};

	const handleChangeTrigger = useCallback((name: string, checked: boolean) => {
		const isKeyword = Object.entries(KeywordsKeysId).find(
			([_, value]) => Number(name) === value
		);

		if (isKeyword) {
			if (checked) {
				setCurrentKeyWord(isKeyword);
			}
		}
	}, []);

	const preparedList = useMemo(
		() =>
			preparedTriggersList({
				list: lead_observation_events,
				handleChangeTrigger: handleChangeTrigger,
				keyWord: {
					action: (
						e: React.MouseEvent<HTMLButtonElement>,
						keyword: [string, string | KeywordsKeysId]
					) => {
						e.stopPropagation();
						setCurrentKeyWord(keyword);
					},
					data: formik.values.keywords
				},
				triggers: formik.values.triggers
			}),
		[
			lead_observation_events,
			handleChangeTrigger,
			formik.values.triggers,
			formik.values.keywords
		]
	);

	const onSetKeywords = (values: string[]) => {
		formik.setFieldValue(`keywords.${currentKeyWord[0]}`, values);
	};

	const onCloseModalKeyWords = (save?: boolean) => {
		if (
			!save &&
			!formik.values.keywords[currentKeyWord[0] as KeywordsKeys]?.length
		) {
			formik.setFieldValue("triggers", {
				...formik.values.triggers,
				[currentKeyWord[1]]: false
			});
		}
		setCurrentKeyWord(null);
	};

	const currentKeyWordsData = useMemo(
		() => formik.values.keywords?.[currentKeyWord?.[0] as KeywordsKeys],
		[currentKeyWord, formik.values.keywords]
	);

	const handleAddProspects = () => {
		setIsAddProspects(true);
	};

	const handleCancelAddProspects = () => {
		setIsAddProspects(false);
	};

	const handleAddNewProspects = (data: TAddProspects) => {
		handleCancelAddProspects();
		setNewProspects(state => [...state, data]);
	};

	const handleSubmit = () => {
		formik.handleSubmit();
	};

	const onRemovePRospects = (id: number) => {
		setNewProspects(state => state.filter((_, i) => i !== id));
	};

	const onClearTriggers = () => {
		formik.setFieldValue("triggers", detailsFormatted.triggers);
		formik.setFieldValue("keywords", detailsFormatted.keywords);
	};

	const totalProspects = useMemo(
		() => newProspects.reduce((acc, item) => acc + item.prospects, 0),
		[newProspects]
	);

	const isLoading = !lead_observation_events?.length || data === null;
	const haveChanges = formik.dirty || !!newProspects.length;
	const submitDisabled =
		!formik.isValid || (!formik.dirty && !newProspects.length);

	const canClearTriggers = useMemo(
		() =>
			!(
				isEqual(detailsFormatted.triggers, formik.values.triggers) &&
				isEqual(detailsFormatted.keywords, formik.values.keywords)
			),
		[detailsFormatted, formik.values]
	);

	return (
		<Loader loading={isLoading}>
			<Prompt when={haveChanges} message="" />
			<div className={styles.block_name}>
				{(isEdit && (
					<>
						<FormField
							autoFocus
							name="name"
							form={formik}
							wrapperClassName={cn(styles.input_name, "no-margin")}
						/>
						<div className={styles.block_name__controls}>
							<Button
								className={styles.btn_edit}
								variant="text"
								onClick={onCancelEditName}
							>
								<CloseIcon width={16} height={16} />
							</Button>
							<Button
								onClick={handleSaveName}
								disabled={!formik.values.name}
								className={styles.btn_edit}
								variant="text"
							>
								<SingleCheckIcon width={16} height={16} color="#EB7D68" />
							</Button>
						</div>
					</>
				)) || (
					<>
						<Typography component="h3" marginBottom={0}>
							{formik.values.name}
						</Typography>
						<Button className={styles.btn_edit} onClick={onEdit} variant="text">
							<Edit color="#EB7D68" />
						</Button>
					</>
				)}
			</div>

			<div className={styles.box}>
				<div className={styles.block}>
					<Box
						marginBottom={3}
						display="flex"
						alignItems="center"
						justifyContent="space-between"
					>
						<Typography variant="subtitle1">Trigger list of tracking</Typography>

						{canClearTriggers && (
							<Button variant="text" onClick={onClearTriggers}>
								Clear
							</Button>
						)}
					</Box>

					<FormField
						type="checkbox"
						canDisplayError={false}
						form={formik}
						list={preparedList}
						name="triggers"
					/>

					<KeyWorksModal
						setKeyWords={onSetKeywords}
						onClose={onCloseModalKeyWords}
						keyWords={currentKeyWordsData}
						open={!!currentKeyWord}
					/>
				</div>
				<div className={styles.block}>
					<Typography variant="subtitle1" marginBottom={3}>
						Add prospects for tracking
					</Typography>
					{(isAddProspects && (
						<TrackingAddProspects
							withNameField={false}
							onMatchesColumns={onMatchesColumns}
							prospects={totalProspects}
							onCancel={handleCancelAddProspects}
							onSubmit={handleAddNewProspects}
						/>
					)) ||
						(!!newProspects.length &&
							newProspects.map((item, i) => (
								<div className={styles.newProspectItem} key={i}>
									<div>
										<span className={styles.newProspectItem__value}>
											{item.prospects}
										</span>
										prospects added
									</div>
									<IconButton
										onClick={() => {
											onRemovePRospects(i);
										}}
									>
										<DeleteIcon color={PALETTE.primary} />
									</IconButton>
								</div>
							)))}
					{!isAddProspects && (
						<div className={styles.footer}>
							<Button variant="secondaryBordered" onClick={handleAddProspects}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="14"
									height="18"
									viewBox="0 0 14 18"
									fill="none"
									style={{ marginRight: 8 }}
								>
									<path
										d="M3.99329 7.47796V13.4725H10.0067V7.47796H14L7 0.5L0 7.47796H3.99329Z"
										fill="#939BAB"
									/>
									<path d="M14 17.5V15.4862H0V17.5H14Z" fill="#939BAB" />
								</svg>
								Add new prospects
							</Button>
						</div>
					)}
				</div>
				<div className={styles.block}>
					<Button
						loading={formik.isSubmitting}
						disabled={submitDisabled}
						onClick={handleSubmit}
					>
						Save Changes
					</Button>
				</div>
			</div>
		</Loader>
	);
};

export default memo(TrackingEditList, isEqualCompare);
