import { useCallback, useLayoutEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import { FormikHelpers, FormikState } from "formik";

import { IResponseCompanyDetails } from "app/screens/cabinet/tracking/types";
import UserProfileWrapper from "app/components/UserProfileLayout";
import Button from "app/uikit-V2/Button";
import PATHS from "PATHS";
import { API } from "app/api";
import { ApiUrls } from "app/api/apiUrls";
import TrackingEditList, {
	FromValues
} from "app/components/Tracking/TrackingEditList";
import { TAddProspects } from "app/components/Tracking/TrackingEditList/AddProspects/types";
import { KeywordsKeysId } from "app/types/tracking";
import toastify from "app/utils/toastify";
import { IFile } from "app/types";

import {
	getMatchesAdditionalColumns,
	getUserDetailsTracking
} from "./EditList.utils";
import styles from "./style.module.scss";

type UserLeadsParams = {
	id: string;
	companyId: string;
	trackingId: string;
};

const UserTrackingCompanyDetails = () => {
	const { id, trackingId } = useParams<UserLeadsParams>();
	const [data, setData] = useState<IResponseCompanyDetails>(null);

	const history = useHistory();

	const onBack = useCallback(() => {
		history.push(PATHS.adminPanel.users.tracking.url(id));
	}, [history, id]);

	const onDetails = useCallback(
		() => getUserDetailsTracking(id, trackingId, setData),
		[id, trackingId]
	);

	useLayoutEffect(() => {
		onDetails();
	}, [onDetails]);

	const onSubmit = useCallback(
		async ({
			values,
			newProspects,
			actions: { resetForm },
			cbSuccess
		}: {
			values: FromValues;
			newProspects: TAddProspects[];
			actions: FormikHelpers<FromValues>;
			cbSuccess: () => void;
		}) => {
			try {
				if (newProspects.length) {
					await API.post(
						ApiUrls.admin.users.leadObservation.companies.list(id, trackingId),
						{
							files: newProspects.map(({ columns, file_id }) => ({ file_id, columns }))
						}
					);
				}

				const res = await API.put<{ message: string }>(
					ApiUrls.admin.users.leadObservation.companiesSets.details(id, trackingId),
					{
						name: values.name,
						event_ids: Object.entries(values.triggers).reduce((acc, [key, value]) => {
							if (value) {
								return [...acc, key];
							}
							return acc;
						}, []),
						keywords: values.triggers[KeywordsKeysId.mention]
							? values.keywords.mention
							: [],
						vacancy_keywords: values.triggers[KeywordsKeysId.vacancy]
							? values.keywords.vacancy
							: []
					}
				);

				resetForm({ values } as Partial<FormikState<FromValues>>);
				cbSuccess();
				setTimeout(() => {
					toastify.success(res?.data?.message);
					onBack();
				}, 200);

				return;
			} catch (err) {
				console.log(err);
				toastify.error(err?.response?.data?.message || err.message);
			}
		},
		[id, trackingId, onBack]
	);

	const onMatchesColumns = useCallback(
		async (data: { file: IFile }, prospects: number) => {
			const obj: { file: IFile; previous_people_count?: number } = data;
			if (prospects) {
				obj.previous_people_count = prospects;
			}
			return getMatchesAdditionalColumns(id, trackingId, obj);
		},

		[id, trackingId]
	);

	return (
		<UserProfileWrapper>
			<div className={styles.controls}>
				<Button size="medium" variant="secondary" onClick={onBack}>
					Go to tracking
				</Button>
			</div>
			<TrackingEditList
				data={data}
				onSubmit={onSubmit}
				onMatchesColumns={onMatchesColumns}
			/>
		</UserProfileWrapper>
	);
};

export default UserTrackingCompanyDetails;
