// @ts-nocheck
import types from "./types";

const actions = {
	getInfo: (data?: {
		cb?: () => void;
		cbSuccess?: () => void;
		cbError?: () => void;
	}) => ({
		type: types.GET_INFO_USER_REQUEST,
		cb: data?.cb,
		cbSuccess: data?.cbSuccess,
		cbError: data?.cbError
	}),
	getInfoSuccess: data => ({
		type: types.GET_INFO_USER_SUCCESS,
		data
	}),
	getInfoFailure: error => ({
		type: types.GET_INFO_USER_FAILURE,
		error
	}),
	resetInfo: () => ({
		type: types.RESET_INFO_USER
	}),
	changeInfo: data => ({
		type: types.CHANGE_INFO_USER_REQUEST,
		data
	}),
	changeSuccess: data => ({
		type: types.CHANGE_INFO_USER_SUCCESS,
		data
	}),
	changeFailure: error => ({
		type: types.CHANGE_INFO_USER_FAILURE,
		error
	}),
	trainingPassed: () => ({
		type: types.TRAINING_PASSED
	}),
	firstLoginPassed: () => ({
		type: types.FIRST_LOGIN_PASSED
	}),
	fetchAppSumoKey: (key: string) => ({
		type: types.FETCH_APP_SUMO_KEY_REQUEST,
		key
	}),
	cancelSubscription: () => ({
		type: types.CANCEL_SUBSCRIPTION_REQUEST
	}),
	cancelSubscriptionSuccess: () => ({
		type: types.CANCEL_SUBSCRIPTION_SUCCESS
	}),
	cancelSubscriptionFailure: () => ({
		type: types.CANCEL_SUBSCRIPTION_FAILURE
	})
};
export default actions;
