import { memo } from "react";

import { Chip as ChipMUI } from "@material-ui/core";
import cn from "classnames";
import isEqual from "react-fast-compare";

import { IOption } from "app/types";

import { DeleteIcon, ResetIcon } from "./Icons";
import useStyles from "./styles";

interface Props {
	list?: IOption | IOption[] | string[];
	id?: string | number;
	onDelete?: (index: string | number, id?: string | number) => void;
	onReset?: () => void;
	label?: string | IOption;
	variant?: "bordered";
	className?: string;
	classNameItem?: string;
	renderStyleItem?: (value: IOption) => {};
	styleItem?: object;
	getStyleItem?: (value: IOption) => any;
}

const Chip: React.FC<Props> = ({
	list,
	onDelete,
	onReset,
	variant,
	className,
	classNameItem,
	getStyleItem,
	styleItem,
	id,
	label
}: any) => {
	const classes = useStyles();

	if (!list) {
		return (
			<ChipMUI
				label={label?.name || label}
				className={cn(classes.root, { [classes.with_remove]: !!onDelete })}
				deleteIcon={DeleteIcon}
				onDelete={() => {
					if (onDelete) {
						onDelete(id);
					}
				}}
			/>
		);
	}

	return (
		<div
			className={cn(
				variant === "bordered" ? classes.container : classes.container_ghost,
				className,
				"container-chip"
			)}
		>
			{Array.isArray(list) &&
				list.map((el, i) => {
					const handleDelete = onDelete ? onDelete(i, el.id) : undefined;
					const elIsString = typeof el === "string";
					const label = elIsString ? el : el?.name;
					const id = elIsString ? i : el.id;

					return (
						<ChipMUI
							key={`${label}-${id}`}
							label={label}
							style={styleItem || getStyleItem?.(el)}
							data-id={id}
							className={cn(
								classes.root,
								{ [classes.with_remove]: !!handleDelete },
								classNameItem
							)}
							deleteIcon={DeleteIcon}
							onDelete={handleDelete}
						/>
					);
				})}
			{onReset && !!list.length && (
				<button type="button" onClick={onReset}>
					Reset selection {ResetIcon}
				</button>
			)}
		</div>
	);
};
export default memo(Chip, isEqual);
