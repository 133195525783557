import React, { useCallback, useState } from "react";

import { IFile } from "app/types";
import AsideTrackingNewList from "app/components/Tracking/TrackingAddNewListFlow/aside";

import { IMatchedData } from "../../TrackingNewLists/ProspectsUpload/type";

import TrackingAddProspectsStepper from "./Stepper";
import { TAddProspects } from "./types";
import styles from "./style.module.scss";

interface Props {
	withNameField?: boolean;
	onCancel: () => void;
	onSubmit: (data: TAddProspects) => void;
	prospects?: number;
	onMatchesColumns: (
		data: {
			file: IFile;
		},
		prospects: number
	) => Promise<IMatchedData>;
}

export const STEP_INFO = [
	{
		value: 0,
		text: "Prospects uploading"
	},
	{
		value: 1,
		text: "Creating a list"
	}
];

const TrackingAddProspects: React.FC<Props> = ({
	onCancel,
	onSubmit,
	withNameField,
	prospects,
	onMatchesColumns
}) => {
	const [step, setStep] = useState(0);

	const fetchMatchesColumns = useCallback(
		async (data: { file: IFile }) => onMatchesColumns(data, prospects),
		[onMatchesColumns, prospects]
	);

	return (
		<div className={styles.container}>
			<div className={styles.aside}>
				<p className={styles.aside__title}>STEPS</p>
				<AsideTrackingNewList list={STEP_INFO} step={step} setStep={setStep} />
			</div>
			<div className={styles.content}>
				<TrackingAddProspectsStepper
					withNameField={withNameField}
					fetchMatchesColumns={fetchMatchesColumns}
					prospects={prospects}
					onSubmit={onSubmit}
					onCancel={onCancel}
					step={step}
					setStep={setStep}
				/>
			</div>
		</div>
	);
};

export default TrackingAddProspects;
