import Loadable from "app/utils/Loadable";
import UserLayout from "app/components/includes/UserLayout";

const Users = Loadable({
	loader: () => import("./Users")
});

const ScreenUsers = () => {
	return (
		<UserLayout>
			<Users />
		</UserLayout>
	);
};

export default ScreenUsers;
