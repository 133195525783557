import React from "react";

import {
	NotificationErrorIcon,
	NotificationInfoIcon,
	NotificationSuccessIcon,
	NotificationWarnIcon
} from "../../icons";

interface props {
	variant?: string;
	title?: string;
	children?: React.ReactNode;
}

const showNotifIcon = (variant?: string): JSX.Element | string => {
	switch (variant) {
		case "success":
			return <NotificationSuccessIcon />;
		case "error":
			return <NotificationErrorIcon />;
		case "warn":
			return <NotificationWarnIcon />;
		case "info":
			return <NotificationInfoIcon />;
		default:
			return "";
	}
};

const CustomNotification: React.FC<props> = ({ variant, title, children }) => (
	<div className={`notification notification_${variant}`}>
		<div className="notification-icon">{showNotifIcon(variant)}</div>
		<div className="notification-text">
			{title && (
				<p className="notification-title main-text main-text_bold">{title}</p>
			)}
			{children && <p className="notification-text small-text">{children}</p>}
		</div>
	</div>
);

export default CustomNotification;
