// @ts-nocheck
import { takeEvery, put, all, call, select } from "redux-saga/effects";
import { push } from "connected-react-router";

import toastify from "app/utils/toastify";
import { serializedData } from "app/helper";
import { ITrendDataBaseValue } from "app/screens/cabinet/trends/trendsDatabase/components/Filter";
import PATHS from "PATHS";

import { errorsHandler } from "../helper";
import { TSagaProps } from "../type";

import services from "./services";
import types from "./types";
import actions from "./actions";
import { ITrandsDatabaseActionNames } from "./type";

type TID = string | number;

function* getTrendsDatabase({
	actionName,
	isAdmin
}: TSagaProps<{
	actionName: ITrandsDatabaseActionNames;
	isAdmin?: boolean;
}>) {
	try {
		const {
			trendsDatabase: { pagination, filter }
		} = yield select();
		const filters = { ...filter[actionName] };
		delete filters.range;

		let reqCall = services.fetchTrends;

		if (isAdmin) {
			reqCall = services.fetchTrendsAdmin;
		}

		const response = yield call(reqCall, {
			...pagination[actionName],
			...serializedData(filters)
		});

		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.getTrendsDatabaseSuccess(actionName, response?.data));
		}
	} catch (error) {
		yield errorsHandler(actions.getTrendsDatabaseFailure, error, actionName);
	}
}

function* getTrendDetailsDatabase({
	id,
	cb,
	actionName
}: TSagaProps<{
	id: number;
	cb: () => void;
	actionName: ITrandsDatabaseActionNames;
}>) {
	try {
		const response = yield call(services.fetchTrendDetails, id);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.getTrendDatabaseSuccess(actionName, response?.data));
			cb && cb();
		}
	} catch (error) {
		yield errorsHandler(actions.getTrendDatabaseFailure, error, actionName);
		if (error?.response?.status === 403) {
			yield put(push(PATHS.pageNotFound));
		}
	}
}

function* fetchCreateTrendDatabase({
	data,
	cb,
	actionName
}: TSagaProps<{
	data: ITrendDataBaseValue;
	cb: () => void;
	actionName: ITrandsDatabaseActionNames;
}>) {
	try {
		const response = yield call(services.fetchTrendCreate, data);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(
				actions.fetchCreateTrendDatabaseSuccess(actionName, response?.data)
			);
			toastify.success(response?.data?.message);
			cb && cb();
		}
	} catch (error) {
		yield errorsHandler(
			actions.fetchCreateTrendDatabaseFailure,
			error,
			actionName
		);
	}
}

function* fetchUpdateTrendDatabase({
	data,
	id,
	cb,
	actionName
}: TSagaProps<{
	data: ITrendDataBaseValue;
	id: TID;
	cb: () => void;
	actionName: ITrandsDatabaseActionNames;
}>) {
	try {
		const response = yield call(services.fetchTrendUpdate, id, data);

		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(
				actions.fetchUpdateTrendDatabaseSuccess(actionName, response?.data)
			);
			toastify.success(response?.data?.message);
			cb && cb();
		}
	} catch (error) {
		yield errorsHandler(
			actions.fetchUpdateTrendDatabaseFailure,
			error,
			actionName
		);
	}
}

function* fetchDeleteTrendDatabase({
	id,
	cb,
	actionName
}: TSagaProps<{
	id: TID;
	cb: () => void;
	actionName: ITrandsDatabaseActionNames;
}>) {
	try {
		const response = yield call(services.fetchTrendDelete, id);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(
				actions.fetchDeleteTrendDatabaseSuccess(actionName, response?.data)
			);
			toastify.success(response?.data?.message);
			cb && cb();
			yield put(actions.getTrendsDatabase());
		}
	} catch (error) {
		yield errorsHandler(
			actions.fetchDeleteTrendDatabaseFailure,
			error,
			actionName
		);
	}
}

function* fetchPaginationDatabase({
	data,
	actionName
}: TSagaProps<{
	data: any;
	actionName: ITrandsDatabaseActionNames;
}>) {
	try {
		let params = data;
		if (data.limit) params.page = 1;
		yield put(actions.fetchPaginationSuccess(data, actionName));
		yield put(actions.getTrendsDatabase());
	} catch (error) {
		toastify.error(error?.response?.data?.message || error.message);
	}
}

function* fetchFilterDatabase({
	data,
	actionName
}: TSagaProps<{
	data: any;
	actionName: ITrandsDatabaseActionNames;
}>) {
	try {
		yield put(actions.fetchFilterSuccess(data, actionName));
		yield put(actions.getTrendsDatabase());
	} catch (error) {
		toastify.error(error?.response?.data?.message || error.message);
	}
}

export default function* root() {
	yield all([takeEvery(types.GET_TRENDS_DATABASE_REQUEST, getTrendsDatabase)]);
	yield all([
		takeEvery(types.GET_TREND_DATABASE_REQUEST, getTrendDetailsDatabase)
	]);
	yield all([
		takeEvery(types.FETCH_TREND_CREATE_DATABASE_REQUEST, fetchCreateTrendDatabase)
	]);
	yield all([
		takeEvery(types.FETCH_TREND_EDIT_DATABASE_REQUEST, fetchUpdateTrendDatabase)
	]);
	yield all([
		takeEvery(types.FETCH_TREND_DELETE_DATABASE_REQUEST, fetchDeleteTrendDatabase)
	]);
	yield all([
		takeEvery(
			types.FETCH_PAGINATION_TRENDS_DATABASE_REQUEST,
			fetchPaginationDatabase
		)
	]);
	yield all([
		takeEvery(types.FETCH_FILTER_TRENDS_DATABASE_REQUEST, fetchFilterDatabase)
	]);
}
