import { memo } from "react";

import isEqual from "react-fast-compare";

import style from "./style.module.scss";

const HEADER_ITEMS_NAMES: string[] = [
	"Name of list",
	"Prospects",
	"New events",
	"Last update",
	"Next update",
	"Tracking",
	""
];

const TrackingListHeader = () => (
	<div className={style.header}>
		{HEADER_ITEMS_NAMES.map((name: string) => (
			<div key={name}>{name}</div>
		))}
	</div>
);

export default memo(TrackingListHeader, isEqual);
