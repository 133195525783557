import React, { memo } from "react";

import isEqual from "react-fast-compare";

import { useIsAdmin } from "app/hooks/useIsAdmin";
import { useSubscribe } from "app/hooks/useSubscribe";
import Typography from "app/uikit-V2/Typography";

import styles from "./style.module.scss";

import Price from ".";

interface Props {
	hideChildren?: boolean;
	children?: any;
}

const PriceLayout: React.FC<Props> = ({ children, hideChildren }) => {
	const { isAdmin, loading } = useIsAdmin();
	const subscription = useSubscribe();

	if (loading) return null;
	if (subscription.isActive || isAdmin) return children;

	return (
		<>
			{!hideChildren && children}

			<div className={styles.wrapper}>
				<div className={styles.wrapper_inner}>
					<div className={styles.wrapper_inner_header}>
						<Typography variant="h2" marginBottom="5px">
							Your subscription has ended
						</Typography>
						<Typography variant="subtitle1" marginBottom="40px">
							Choose a suitable subscription plan
						</Typography>
					</div>
					<Price />
				</div>
			</div>
		</>
	);
};

export default memo(PriceLayout, isEqual);
