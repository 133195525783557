export default {
	FETCH_SIGN_IN_REQUEST: "FETCH_SIGN_IN_REQUEST",
	FETCH_SIGN_IN_SUCCESS: "FETCH_SIGN_IN_SUCCESS",
	FETCH_SIGN_IN_FAILURE: "FETCH_SIGN_IN_FAILURE",

	FETCH_SIGN_UP_REQUEST: "FETCH_SIGN_UP_REQUEST",
	FETCH_SIGN_UP_SUCCESS: "FETCH_SIGN_UP_SUCCESS",
	FETCH_SIGN_UP_FAILURE: "FETCH_SIGN_UP_FAILURE",

	FETCH_LOGOUT_REQUEST: "FETCH_LOGOUT_REQUEST",
	FETCH_LOGOUT_SUCCESS: "FETCH_LOGOUT_SUCCESS",
	FETCH_LOGOUT_FAILURE: "FETCH_LOGOUT_FAILURE",

	FETCH_PASSWORD_RECOVERY_REQUEST: "FETCH_PASSWORD_RECOVERY_REQUEST",
	FETCH_PASSWORD_RECOVERY_SUCCESS: "FETCH_PASSWORD_RECOVERY_SUCCESS",
	FETCH_PASSWORD_RECOVERY_FAILURE: "FETCH_PASSWORD_RECOVERY_FAILURE",

	FETCH_NEW_PASSWORD_REQUEST: "FETCH_NEW_PASSWORD_REQUEST",
	FETCH_NEW_PASSWORD_SUCCESS: "FETCH_NEW_PASSWORD_SUCCESS",
	FETCH_NEW_PASSWORD_FAILURE: "FETCH_NEW_PASSWORD_FAILURE",
	FETCH_RESET_ACTION_STATE: "FETCH_RESET_ACTION_STATE"
};
