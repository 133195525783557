// @ts-nocheck

import { takeEvery, put, all, call, select } from "redux-saga/effects";

import toastify from "app/utils/toastify";
import directory from "app/directory/errors";

import actionsUser from "../user/actions";

import services from "./services";
import types from "./types";
import actions from "./actions";

const dir = directory.en;

function* getEmployees() {
	try {
		const {
			employees: { pagination, filter }
		} = yield select();
		const response = yield call(services.getEmployees, {
			...pagination,
			...filter
		});
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(
				actions.getEmployeesSuccess({
					count: response.data.count,
					items: response.data.items
				})
			);
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			return yield put(
				actions.getEmployeesFailure(error?.response?.data?.errors || "")
			);
		} else if (error?.response?.status === 403) {
			return yield put(
				actions.getEmployeesFailure(error?.response?.data?.message)
			);
		} else {
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.getEmployeesFailure(""));
		}
	}
}

function* deleteItems({ ids, cb }) {
	try {
		const response = yield call(services.deleteEmployees, ids);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			const arrIds = ids?.split(",");
			yield put(actions.deleteItemsSuccess(arrIds?.length));
			cb && cb();
			toastify.success(response?.data?.message);
			yield put(actions.getEmployees());
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.deleteItemsFailure(""));
	}
}

function* fetchNewEmployee({ data }) {
	try {
		const response = yield call(services.fetchNewEmployees, data);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.fetchNewEmployeeSuccess());
			toastify.success(response.data.message);
			yield put(actions.getEmployees());
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			return yield put(
				actions.fetchNewEmployeeFailure(error?.response?.data?.errors || "")
			);
		} else if (error?.response?.status === 403) {
			return yield put(
				actions.fetchNewEmployeeFailure(error?.response?.data?.message)
			);
		} else {
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.fetchNewEmployeeFailure(""));
		}
	}
}

function* fetchEditEmployee({ data, id }) {
	try {
		const response = yield call(services.fetchEditEmployee, { data, id });
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.fetchEditEmployeeSuccess());
			toastify.success(response.data.message);
			yield put(actions.getEmployees());
			yield put(actionsUser.getInfo());
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			return yield put(
				actions.fetchEditEmployeeFailure(error?.response?.data?.errors || "")
			);
		} else if (error?.response?.status === 403) {
			return yield put(
				actions.fetchEditEmployeeFailure(error?.response?.data?.message)
			);
		} else {
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.fetchEditEmployeeFailure(""));
		}
	}
}

function* fetchPagination({ data }) {
	try {
		let params = data;
		if (data.limit) params.page = 1;
		yield put(actions.fetchPaginationSuccess(params));
		yield put(actions.getEmployees());
	} catch (error) {
		toastify.error(error?.response?.data?.message || error.message);
	}
}

export default function* root() {
	yield all([takeEvery(types.GET_EMPLOYEES_REQUEST, getEmployees)]);
	yield all([takeEvery(types.DELETE_EMPLOYEES_REQUEST, deleteItems)]);
	yield all([takeEvery(types.FETCH_NEW_EMPLOYEE_REQUEST, fetchNewEmployee)]);
	yield all([takeEvery(types.FETCH_EDIT_EMPLOYEE_REQUEST, fetchEditEmployee)]);
	yield all([
		takeEvery(types.FETCH_PAGINATION_EMPLOYEE_REQUEST, fetchPagination)
	]);
}
