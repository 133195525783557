import classNames from "classnames/bind";

import style from "./style.module.scss";

const FooterLight = (props: any) => {
	const cx = classNames.bind(style);
	return (
		<footer className={cx("footer", props.className)}>{props.children}</footer>
	);
};

export default FooterLight;
