// @ts-nocheck
import { makeStyles } from "@material-ui/core/styles";
import { FormHelperText } from "@material-ui/core";
import cl from "classnames";

import { InputLabel, FormControl } from "../form-components";

import Select from "./select-base";

const useStyles = makeStyles({
	formControl: {
		"& .MuiInputBase-input": {
			color: "#3E4555"
		},
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#E8EAEF",
			transition: "border-color .3s"
		},
		"& .MuiOutlinedInput-root:hover": {
			"& .MuiOutlinedInput-notchedOutline": {
				borderColor: "#CFD3DE"
			}
		},
		"& legend": {
			maxWidth: "0"
		},
		"& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
			transform: "none"
		}
	},
	error: {
		"&.Mui-error": {
			color: "#EF5E39",
			fontSize: "16px"
		}
	}
});

export default function SelectBase(props) {
	const {
		name,
		info,
		labelClassName,
		label,
		error,
		variant,
		errorText,
		infoPlacement,
		fullWidth,
		wrapClassName,
		required,
		...rest
	} = props;
	const classes = useStyles({ fullWidth });
	return (
		<FormControl
			variant={variant}
			fullWidth={fullWidth}
			className={cl(classes.formControl, wrapClassName)}
		>
			<InputLabel
				name={name}
				info={info}
				infoPlacement={infoPlacement}
				required={required}
				label={label}
				className={labelClassName}
			/>

			<Select error={error} name={name} {...rest} />
			{error && Boolean(error) && (
				<FormHelperText error={true}>{errorText}</FormHelperText>
			)}
		</FormControl>
	);
}

SelectBase.defaultProps = {
	list: [],
	label: ""
};
