import { memo } from "react";

import {
	FormControl as FormControlBase,
	FormControlProps
} from "@material-ui/core";
import cn from "classnames";
import isEqual from "react-fast-compare";

import useStyles from "./style";

const FormControl: React.FC<FormControlProps> = ({
	fullWidth,
	children,
	className,
	...rest
}) => {
	const classes = useStyles({ fullWidth });
	return (
		<FormControlBase className={cn(classes.formControl, className)} {...rest}>
			{children}
		</FormControlBase>
	);
};

export default memo(FormControl, isEqual);
