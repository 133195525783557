import { useRef, useState } from "react";

import { withStyles, Theme } from "@material-ui/core/styles";
import TooltipMUI from "@material-ui/core/Tooltip";

const LightTooltip = withStyles((theme: Theme) => ({
	tooltip: {
		backgroundColor: "#fff !important",
		color: "rgba(0, 0, 0, 0.87)",
		boxShadow: theme.shadows[1],
		fontSize: 11,
		border: "1px solid #D9D9D9"
	}
}))(TooltipMUI);

interface Props {
	title: string;
	children: JSX.Element;
	onClick: () => void;
}

const ToolTip: React.FC<Props> = ({ title, children, onClick }) => {
	const timer = useRef<null | ReturnType<typeof setTimeout>>(null);
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		onClick();
		setOpen(true);
		if (timer.current) {
			clearTimeout(timer.current);
		}
		timer.current = setTimeout(() => {
			setOpen(false);
			timer.current = null;
		}, 1000);
	};

	return (
		<LightTooltip
			open={open}
			onClick={handleOpen}
			disableFocusListener
			disableHoverListener
			disableTouchListener
			title={title}
		>
			{children}
		</LightTooltip>
	);
};

export default ToolTip;
