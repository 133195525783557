import { AxiosResponse } from "axios";

import { API } from "app/api";
import { ApiUrls } from "app/api/apiUrls";
import { IMatchedData } from "app/components/Tracking/TrackingNewLists/ProspectsUpload/type";
import { globalHandlerError } from "app/helper/handlerErrors";
import { IFile } from "app/types";
import { IResponseCompanyDetails } from "app/screens/cabinet/tracking/types";

export const getUserDetailsTracking = async (
	userId: string,
	trackingId: string,
	cb?: React.Dispatch<React.SetStateAction<IResponseCompanyDetails>>
): Promise<IResponseCompanyDetails> => {
	try {
		const response = await API.get(
			ApiUrls.admin.users.leadObservation.companiesSets.details(userId, trackingId)
		);
		const data = response?.data?.companies_set;

		if (cb && data) {
			cb(data);
		}

		return data;
	} catch (err) {
		globalHandlerError(err);
	}
};

export const getMatchesAdditionalColumns = async (
	userId: string,
	trackingId: string,
	data: {
		file: IFile;
	}
): Promise<IMatchedData> => {
	try {
		const response: AxiosResponse<IMatchedData> = await API.post(
			ApiUrls.admin.users.leadObservation.companiesSets.detailsMatch(
				userId,
				trackingId
			),
			data
		);

		return response.data;
	} catch (err) {
		globalHandlerError(err);
		throw new Error(err);
	}
};
