import { makeStyles } from "@material-ui/core/styles";

import { IStyleProps } from "./type";

export const useStyles = makeStyles({
	wrapper: {
		position: (props: IStyleProps) =>
			props.position === "center" ? "static" : props.position || "relative",
		height: (props: IStyleProps) => props.height || props.size,
		backgroundColor: (props: IStyleProps) => props.colorBg || "transparent",
		animation: (props: IStyleProps) =>
			`fade-in 0s linear backwards infinite ${
				props.delayLoader !== undefined ? props.delayLoader : 0.25
			}s`,
		"& .MuiCircularProgress-indeterminate": {
			animation: (props: IStyleProps) =>
				`MuiCircularProgress-keyframes-circular-rotate 1.4s linear infinite ${
					props.delayLoader !== undefined ? props.delayLoader : 0.25
				}s`
		},
		"& .MuiCircularProgress-svg": {
			animation: (props: IStyleProps) =>
				`fade-in 0s linear backwards infinite ${
					props.delayLoader !== undefined ? props.delayLoader : 0.25
				}s`
		}
	},
	wrapperCenter: {
		position: "absolute !important" as any,
		zIndex: 500,
		top: 0,
		left: 0,
		width: "100%",
		height: "100%"
	},
	buttonProgress: {
		color: (props: IStyleProps) => props.color || "#fff",
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: (props: IStyleProps) => -(props.size / 2) || -12,
		marginLeft: (props: IStyleProps) => -(props.size / 2) || -12
	}
});

export const getVariant = (variant: string) => {
	switch (variant) {
		case "black":
			return {
				color: "#000",
				colorBg: "rgba(0,0,0,0.03)"
			};
		default:
			return {};
	}
};
