import React, { MouseEvent, memo, useCallback } from "react";

import cn from "classnames";
import isEqual from "react-fast-compare";
import { Button as ButtonMUI } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import CircularProgress from "app/uikit-V2/CircularProgress";
import { TEST_IDS } from "app/test/test-ids";

import styles from "./style.module.scss";
import {
	getButtonSize,
	getButtonVariant,
	getColorLoaderByVariant,
	useStyle
} from "./Button.utils";
import { IPropsButton } from "./types";

const Button: React.FC<IPropsButton> = ({
	children,
	className,
	variant = "primary",
	size = "large",
	disabled,
	loading,
	withIcon,
	color,
	to,
	href,
	onClick,
	...rest
}) => {
	const classed = useStyle({ color });
	const history = useHistory();
	const classes = cn(
		styles.root,
		getButtonVariant(variant),
		getButtonSize(size),
		{ [styles.withIcon]: withIcon },
		classed.color,
		className
	);

	const isDisabled: boolean = loading || disabled;
	const colorLoader: string = getColorLoaderByVariant(variant);

	const handleClick = useCallback(
		(e: MouseEvent<HTMLButtonElement>) => {
			if (to) {
				e.preventDefault();
				history.push(to);
			}

			if (onClick) {
				onClick(e);
			}
		},
		[onClick, history, to]
	);

	return (
		<ButtonMUI
			data-testid={TEST_IDS.button}
			disabled={isDisabled}
			className={classes}
			href={href || to}
			onClick={handleClick}
			{...rest}
		>
			{children}
			{loading && (
				<CircularProgress
					variant="center-absolute"
					height="100%"
					width="100%"
					color={colorLoader}
				/>
			)}
		</ButtonMUI>
	);
};

export default memo(Button, isEqual);
