import React, { memo } from "react";

import isEqual from "react-fast-compare";
import cn from "classnames";

import style from "./style.module.scss";
import { MarkerIcon } from "./icons";
import { STEP_INFO } from "./aside.config";

interface Props {
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	list?: typeof STEP_INFO;
}

const AsideTrackingNewList: React.FC<Props> = ({
	step,
	setStep,
	list = STEP_INFO
}) => (
	<div className={style.nav}>
		<ul className={style.nav__list}>
			{list.map(({ text, value }) => {
				const isActive = value === step;
				const isPassed = value < step;

				const markerColor = isPassed ? "#34BD41" : isActive ? "#EB7D68" : "#CFD3DE";

				return (
					<li
						key={value}
						className={cn(style.nav__list_item, {
							[style["nav__list_item--passed"]]: isPassed,
							[style["nav__list_item--active"]]: isActive
						})}
						onClick={() => {
							if (isPassed) {
								setStep(value);
							}
						}}
					>
						<span className={style.nav__list_item_marker}>
							<MarkerIcon color={markerColor} />
						</span>
						{text}
					</li>
				);
			})}
		</ul>
	</div>
);

export default memo(AsideTrackingNewList, isEqual);
