// import TestButtons from "./TestButtons";
// import TestCircularProgress from "./TestCircularProgress";
// import TestCheckbox from "./TestCheckbox";
// import TestSelectChip from "./TestSelectChip";
// import TestInputs from "./TestInputs";
// import TestPaletteTriggers from "./TestPalitreTriggers";
import TestSelect from "./TestSelect";
import styles from "./style.module.scss";

const TestScreen = () => {
	return (
		<div className={styles.container}>
			{/* <TestButtons />
			<TestCircularProgress /> */}
			{/* <TestCheckbox /> */}
			{/* <TestSelectChip /> */}
			{/* <TestInputs /> */}
			{/* <TestPaletteTriggers /> */}
			<TestSelect />
		</div>
	);
};

export default TestScreen;
