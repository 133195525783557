import { Edit } from "app/icons";
import { PALETTE } from "app/theme";
import { KeywordsKeys, KeywordsKeysId } from "app/types/tracking";
import { ILeadObservationEvent } from "app/store/handBooks/reducer";

import styles from "./style.module.scss";

type TChangedTrigger = (name: string, checked: boolean) => void;

interface ITriggersList {
	value: string | number;
	name: string;
	label: JSX.Element | string;
	labelClassName?: string;
	onChangedTrigger?: TChangedTrigger;
}

interface IPreparedTriggersList {
	list: ILeadObservationEvent[];
	handleChangeTrigger: TChangedTrigger;
	keyWord: {
		action: (
			e: React.MouseEvent<HTMLButtonElement>,
			keyword: [string, string | KeywordsKeysId]
		) => void;
		data: Record<KeywordsKeys, string[]>;
	};
	triggers: Record<number, boolean>;
}

export const preparedTriggersList = ({
	list,
	handleChangeTrigger,
	keyWord,
	triggers
}: IPreparedTriggersList): ITriggersList[] =>
	list.map(item => {
		const isKeyword = Object.entries(KeywordsKeysId).find(
			([_, value]) => item.id === value
		);

		const keywordsKey = isKeyword?.[0] as KeywordsKeys;
		const keywordsKeyId = isKeyword?.[1] as KeywordsKeysId;

		const isEditKeyword =
			!!keyWord?.data?.[keywordsKey]?.length && triggers[keywordsKeyId];

		return {
			value: item.id,
			name: String(item.id),
			labelClassName: styles.checkbox,
			onChangedTrigger: handleChangeTrigger,
			label: (
				<>
					<span className={styles.checkbox__title}>{item.name}</span>
					{isEditKeyword && (
						<button
							className={styles.btn_edit_keyWord}
							onClick={e => {
								keyWord.action?.(e, isKeyword);
							}}
						>
							<Edit color={PALETTE.primary} />
						</button>
					)}
				</>
			)
		};
	});
