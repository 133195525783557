import { AxiosResponse } from "axios";

import { globalHandlerError } from "app/helper/handlerErrors";
import { ApiUrls } from "app/api/apiUrls";
import { API } from "app/api";

import { IResponseCompanies, IResponseCompanyDetails } from "../types";

export const getListTracking = async (
	id: string,
	data?: {
		params: {
			pagination: {
				limit?: number;
				offset?: number;
				total?: number;
				page: number;
			};
			filter: {
				[x: string]: any;
			};
		};
	},
	cb?: React.Dispatch<React.SetStateAction<IResponseCompanies>>
): Promise<IResponseCompanies> => {
	try {
		if (!id) return;

		const response: AxiosResponse<IResponseCompanies> = await API.get(
			ApiUrls.account.leadObservation.companiesSets.companies.list(id),
			data
		);
		const resData = response?.data;

		if (cb) {
			cb(resData);
		}

		return resData;
	} catch (err) {
		globalHandlerError(err);
	}
};

export const getDetailsTracking = async (
	id: string,
	cb?: React.Dispatch<React.SetStateAction<IResponseCompanyDetails>>
): Promise<IResponseCompanyDetails> => {
	try {
		const response = await API.get(
			ApiUrls.account.leadObservation.companiesSets.details(id)
		);
		const data = response?.data?.companies_set;

		if (cb && data) {
			cb(data);
		}

		return data;
	} catch (err) {
		globalHandlerError(err);
	}
};

export const getPersonDetailsTracking = async (
	id: string,
	companyId: string,
	personUuid: string,
	cb?: React.Dispatch<React.SetStateAction<IResponseCompanyDetails>>
): Promise<IResponseCompanyDetails> => {
	try {
		const response = await API.get(
			ApiUrls.account.leadObservation.companiesSets.companies.person.details(
				id,
				companyId,
				personUuid
			)
		);
		const data = response?.data?.companies_set;

		if (cb && data) {
			cb(data);
		}

		return data;
	} catch (err) {
		globalHandlerError(err);
	}
};
