import { API } from "app/api";
import { ApiUrls } from "app/api/apiUrls";
import { globalHandlerError } from "app/helper/handlerErrors";

import { canResumeSubscriptionStatuses } from "./Subscriptions.config";

export const fetchActiveSubscription: {
	(params?: { success_url: string; tariff_plan_id?: number }): Promise<void>;
	(params: { success_url: string; tariff_plan_id?: number }): Promise<void>;
	(params: { success_url?: string; tariff_plan_id: number }): Promise<void>;
	(params: { success_url?: string; tariff_plan_id?: number }): Promise<void>;
} = async ({ success_url, tariff_plan_id } = {}) => {
	try {
		const response = await API.get(ApiUrls.account.onboard.subscriptionLink, {
			params: { success_url, tariff_plan_id }
		});
		window.location.href = response?.data?.link;
	} catch (err) {
		globalHandlerError(err);
		throw new Error(err);
	}
};

export const fetchResumeSubscription = async () => {
	try {
		await API.patch(ApiUrls.account.user.resumeSubscription);
	} catch (err) {
		globalHandlerError(err);
		throw new Error(err);
	}
};

export const canResumeSubscription = (status?: number) => {
	if (status) return false;
	return canResumeSubscriptionStatuses.includes(status);
};

export const fetchChangeSubscriptionPlan = async (tariffPlan: number) => {
	const body = {
		tariff_plan_id: tariffPlan
	};
	const {
		data: { isCan }
	} = await API.get<{
		isCan: boolean;
		message: string;
	}>(ApiUrls.account.user.subscription.tariffPlan.canChange, {
		params: body
	});

	return isCan;
};
