export const IconCheck = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
	>
		<g clipPath="url(#clip0_1_649)">
			<path
				d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12Z"
				stroke="#EB7D68"
				strokeWidth="2"
			/>
			<path
				d="M15.5 10L10.5 14.9998L8 12.5"
				stroke="#EB7D68"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1_649">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
