export const IconLocation = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.06772 8.82711C4.06772 4.73405 7.61458 1.42365 12 1.42365C16.3854 1.42365 19.9323 4.73405 19.9323 8.82711C19.9323 14.3797 12 22.5764 12 22.5764C12 22.5764 4.06772 14.3797 4.06772 8.82711ZM12 12.8814C13.9471 12.8814 15.5255 11.303 15.5255 9.35593C15.5255 7.40887 13.9471 5.83047 12 5.83047C10.053 5.83047 8.47456 7.40887 8.47456 9.35593C8.47456 11.303 10.053 12.8814 12 12.8814Z"
			fill="#D4D7DF"
		/>
	</svg>
);

export const IconPerson = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			d="M9.5 11.5C11.8481 11.5 13.75 9.59812 13.75 7.25C13.75 4.90188 11.8481 3 9.5 3C7.15188 3 5.25 4.90188 5.25 7.25C5.25 9.59812 7.15188 11.5 9.5 11.5ZM9.5 13.625C6.66313 13.625 1 15.0487 1 17.875V18.9375C1 19.5219 1.47812 20 2.0625 20H16.9375C17.5219 20 18 19.5219 18 18.9375V17.875C18 15.0487 12.3369 13.625 9.5 13.625Z"
			fill="#D4D7DF"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M23 7.5L20 3L17 7.5H19L19 20L21 20L21 7.5H23Z"
			fill="#D4D7DF"
		/>
	</svg>
);

export const IconKey = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M22.847 7.55996C22.847 11.1829 19.898 14.1199 16.2601 14.1199C15.5963 14.1199 14.0849 13.9673 13.3496 13.3571L12.4305 14.2725C11.8901 14.8107 12.036 14.9691 12.2764 15.2301C12.3767 15.3391 12.4935 15.4658 12.5837 15.6455C12.5837 15.6455 13.3496 16.7134 12.5837 17.7813C12.1241 18.3915 10.8373 19.2458 9.36684 17.7813L9.06047 18.0864C9.06047 18.0864 9.97957 19.1543 9.21365 20.2222C8.7541 20.8324 7.52865 21.4426 6.45636 20.3748L5.38409 21.4426C4.64881 22.1749 3.75015 21.7477 3.39274 21.4426L2.47365 20.5272C1.61582 19.6729 2.11622 18.7474 2.47365 18.3915L10.4392 10.4586C10.4392 10.4586 9.67325 9.23807 9.67325 7.55996C9.67325 3.93699 12.6223 1 16.2601 1C19.898 1 22.847 3.93699 22.847 7.55996ZM16.2604 9.84839C17.5293 9.84839 18.558 8.82388 18.558 7.56005C18.558 6.29622 17.5293 5.27169 16.2604 5.27169C14.9913 5.27169 13.9626 6.29622 13.9626 7.56005C13.9626 8.82388 14.9913 9.84839 16.2604 9.84839Z"
			fill="#D4D7DF"
		/>
	</svg>
);

export const IconPersonLocation = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
	>
		<rect x="1" y="7" width="22" height="15" rx="4" fill="#D4D7DF" />
		<path
			d="M7 6C7 4.34315 8.34315 3 10 3H14C15.6569 3 17 4.34315 17 6V10H7V6Z"
			stroke="#D4D7DF"
			stroke-width="2"
		/>
	</svg>
);
