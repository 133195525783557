// @ts-nocheck
import { takeEvery, put, all, call } from "redux-saga/effects";
import qs from "query-string";
import { push, replace } from "connected-react-router";

import toastify from "app/utils/toastify";
import { setInfo } from "app/helper";
import directory from "app/directory/errors";
import { SuccessType } from "app/screens/account/subscriptionSuccess/type";
import PATHS from "PATHS";
import config from "app/config";

import services from "./services";
import types from "./types";
import actions from "./actions";

const dir = directory.en;

function* getInfo({ cb, cbSuccess, cbError }) {
	try {
		const response = yield call(services.getInfo);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			const data = response?.data;
			yield put(actions.getInfoSuccess(data));
			setInfo(data);
			cbSuccess?.();
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.getInfoFailure(""));
		cbError?.();
	} finally {
		if (cb) {
			cb();
		}
	}
}

function* changeInfo({ data }) {
	try {
		const response = yield call(services.changeInfo, data);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.changeSuccess(response?.data?.user));
			toastify.success(response?.data?.message);
			setInfo(response?.data?.user);
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			return yield put(actions.changeFailure(error?.response?.data?.errors || ""));
		} else if (error?.response?.status === 403) {
			return yield put(actions.changeFailure(error?.response?.data?.message));
		}
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.changeFailure(""));
	}
}

function* cancelSubscription() {
	try {
		const response = yield services.cancelSubscription();
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			response?.data?.message && toastify.success(response?.data?.message);
			yield put(actions.getInfo());
			yield put(actions.cancelSubscriptionSuccess());
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.cancelSubscriptionFailure(""));
	}
}

function* fetchAppSumoKey({ key }) {
	try {
		const response = yield services.fetchAppSumoKey({
			appsumo_licence_key: key
		});
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(
				replace(
					qs.stringifyUrl({
						url: PATHS.account.subscriptionSuccess,
						query: {
							type: SuccessType.appsumo
						}
					})
				)
			);
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(replace(config.defaultPage.link));
	}
}

export default function* root() {
	yield all([takeEvery(types.GET_INFO_USER_REQUEST, getInfo)]);
	yield all([takeEvery(types.CHANGE_INFO_USER_REQUEST, changeInfo)]);
	yield all([takeEvery(types.CANCEL_SUBSCRIPTION_REQUEST, cancelSubscription)]);
	yield all([takeEvery(types.FETCH_APP_SUMO_KEY_REQUEST, fetchAppSumoKey)]);
}
