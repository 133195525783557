import { ITriggerStyle } from "app/types";

export const mapPalette: Record<string, ITriggerStyle> = {
	"1": {
		background: "rgba(255, 234, 233, 0.5)",
		border: "1px solid rgba(242, 185, 183, 0.5)"
	},
	"2": {
		background: "rgba(237, 242, 255, 0.5)",
		border: "1px solid rgba(185, 200, 239, 0.5)"
	},
	"3": {
		background: "rgba(251, 230, 199, 0.5)",
		border: "1px solid #FAE2BC"
	},
	"4": {
		background: "rgba(214, 244, 210, 0.5)",
		border: "1px solid #CBEDC6"
	},
	"5": {
		background: "rgba(241, 237, 255, 0.5)",
		border: "1px solid rgba(199, 185, 239, 0.5)"
	},
	"6": {
		background: "rgba(237, 252, 255, 0.5)",
		border: "1px solid rgba(185, 220, 239, 0.5)"
	},
	"7": {
		background: "rgba(204, 204, 0, 0.1)",
		border: "1px solid rgba(204, 204, 0, 0.3)"
	},
	"8": {
		background: "rgba(255, 0, 0, 0.1)",
		border: "1px solid rgba(255, 0, 0, 0.3)"
	},
	"9": {
		background: "rgba(153, 51, 255, 0.1)",
		border: "1px solid rgba(153, 51, 255, 0.3)"
	},
	"10": {
		background: "rgba(0, 204, 204, 0.1)",
		border: "1px solid rgba(0, 204, 204, 0.3)"
	},
	"11": {
		background: "rgba(102, 255, 102, 0.1)",
		border: "1px solid rgba(102, 255, 102, 0.3)"
	},
	"12": {
		background: "rgba(255, 204, 51, 0.1)",
		border: "1px solid rgba(255, 204, 51, 0.3)"
	},
	"13": {
		background: "rgba(153, 0, 51, 0.1)",
		border: "1px solid rgba(153, 0, 51, 0.3)"
	},
	"14": {
		background: "rgba(0, 204, 255, 0.1)",
		border: "1px solid rgba(0, 204, 255, 0.3)"
	},
	"15": {
		background: "rgba(102, 204, 204, 0.1)",
		border: "1px solid rgba(102, 204, 204, 0.3)"
	},
	"16": {
		background: "rgba(51, 255, 0, 0.1)",
		border: "1px solid rgba(51, 255, 0, 0.3)"
	},
	"17": {
		background: "rgba(51, 0, 255, 0.1)",
		border: "1px solid rgba(51, 0, 255, 0.3)"
	},
	"18": {
		background: "rgba(255, 0, 102, 0.1)",
		border: "1px solid rgba(255, 0, 102, 0.3)"
	},
	"19": {
		background: "rgba(0, 51, 255, 0.1)",
		border: "1px solid rgba(0, 51, 255, 0.3)"
	},
	"20": {
		background: "rgba(51, 102, 102, 0.1)",
		border: "1px solid rgba(51, 102, 102, 0.3)"
	},
	"21": {
		background: "rgba(153, 255, 51, 0.1)",
		border: "1px solid rgba(153, 255, 51, 0.3)"
	},
	"22": {
		background: "rgba(255, 102, 0, 0.1)",
		border: "1px solid rgba(255, 102, 0, 0.3)"
	},
	"23": {
		background: "rgba(255, 102, 204, 0.1)",
		border: "1px solid rgba(255, 102, 204, 0.3)"
	},
	"24": {
		background: "rgba(51, 153, 255, 0.1)",
		border: "1px solid rgba(51, 153, 255, 0.3)"
	},
	"25": {
		background: "rgba(0, 204, 153, 0.1)",
		border: "1px solid rgba(0, 204, 153, 0.3)"
	},
	"27": {
		background: "rgba(153, 204, 0, 0.1)",
		border: "1px solid rgba(153, 204, 0, 0.3)"
	},
	"28": {
		background: "rgba(204, 51, 0, 0.1)",
		border: "1px solid rgba(204, 51, 0, 0.3)"
	},
	"29": {
		background: "rgba(255, 0, 255, 0.1)",
		border: "1px solid rgba(255, 0, 255, 0.3)"
	},
	"30": {
		background: "rgba(204, 102, 0, 0.1)",
		border: "1px solid rgba(204, 102, 0, 0.3)"
	},
	"31": {
		background: "rgba(51, 204, 102, 0.1)",
		border: "1px solid rgba(51, 204, 102, 0.3)"
	},
	"32": {
		background: "rgba(204, 255, 0, 0.1)",
		border: "1px solid rgba(193, 219, 89, 0.3)"
	}
};

export const copyContent = async (text: string) => {
	const el = document.createElement("textarea");
	el.value = text;
	el.setAttribute("readonly", "");
	el.style.position = "absolute";
	el.style.left = "-9999px";
	document.body.appendChild(el);
	el.select();
	document.execCommand("copy");
	document.body.removeChild(el);
};

export const getTriggerColors = (id: number) =>
	mapPalette[String(id)] || mapPalette["1"];
