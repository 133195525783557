import React, {
	memo,
	SyntheticEvent,
	useEffect,
	useRef,
	useMemo,
	useState
} from "react";

import { FixedSizeList } from "react-window";
import { ButtonBase, ClickAwayListener, MenuItem } from "@material-ui/core";
import Popper from "@material-ui/core/Popper";

import { IOption } from "app/types";

import style from "./style.module.scss";

type IValue = string | number | IOption | IOption[];

const ITEM_SIZE = 42;

export interface CellConfig<T> {
	key: keyof T;
	label: string;
	widthPx?: number;
	component?: (data: any) => JSX.Element;
}
interface RowProps<T> {
	index: number;
	style: React.CSSProperties;
	data: T[];
	cells: CellConfig<T>[];
}

interface props {
	disabled?: boolean;
	disablePortal?: boolean;
	name?: string;
	options: IOption[];
	renderItem?: (item: IOption, value?: IValue) => JSX.Element;
	disableCloseOnSelect?: boolean;
	renderComponent: JSX.Element;
	contentHeader?: string | JSX.Element;
	contentFooter?: string | JSX.Element;
	onChange: (item: IOption) => void;
	value: IValue;
	initialOpenMenu?: boolean;
	itemSize?: number;
}

export const CustomSelect: React.FC<props> = memo(
	({
		disabled,
		name,
		disablePortal,
		contentHeader,
		contentFooter,
		options = [],
		itemSize = ITEM_SIZE,
		renderItem,
		disableCloseOnSelect = false,
		renderComponent,
		initialOpenMenu = false,
		value,
		onChange
	}) => {
		const [open, setOpen] = useState(initialOpenMenu);
		const [anchorEl, setAnchorEl] = useState<Element | null>(null);

		const ref = useRef(null);

		const clickAwayHandler = () => {
			setAnchorEl(null);
			setOpen(false);
		};

		const handleClick = (event: SyntheticEvent) => {
			setAnchorEl(event.currentTarget);
			setOpen(true);
		};

		const handleChange = (item: IOption) => {
			if (!disableCloseOnSelect) {
				setOpen(false);
			}
			onChange(item);
		};

		useEffect(() => {
			if (initialOpenMenu && ref) {
				setAnchorEl(ref.current);
			}
		}, [initialOpenMenu, setAnchorEl, ref]);

		const dataLength = options?.length;

		const renderRow = <T,>(props: RowProps<T>) => {
			const { index, style: itemStyle } = props;
			const option = options[index];
			if (option) {
				return (
					<MenuItem
						style={itemStyle}
						onClick={() => {
							handleChange(option);
						}}
					>
						{(renderItem && renderItem(option, value)) || option.name}
					</MenuItem>
				);
			}
			return (
				<MenuItem style={itemStyle} value={index} disabled={true}>
					<div>
						<span className="main-text main-text_bold">Loading...</span>
					</div>
				</MenuItem>
			);
		};

		const getHeight = useMemo(() => {
			let height = 16;
			if (dataLength === 0) return 0;
			if (dataLength >= 6) {
				height = itemSize * 6 + 12;
			} else if (dataLength > 0 && dataLength < 6) {
				height = itemSize * dataLength + 12;
			}
			return height;
		}, [dataLength, itemSize]);

		const minWidthPopper = useMemo(() => {
			const widthPopper = anchorEl?.clientWidth;
			if (!widthPopper) return 0;
			const lenArr = options.map(item => item.name.length);
			const maxLen = Math.max(...lenArr);
			const countValue = maxLen * 10.5 + 20;
			if (widthPopper > countValue) {
				return widthPopper;
			} else if (countValue < 500) {
				return countValue;
			} else {
				return 500;
			}
		}, [options, anchorEl]);

		return (
			<>
				<ButtonBase
					ref={ref}
					disabled={disabled}
					disableRipple
					onClick={handleClick}
				>
					{renderComponent}
				</ButtonBase>
				<ClickAwayListener
					mouseEvent="onMouseDown"
					touchEvent="onTouchStart"
					onClickAway={clickAwayHandler}
				>
					<Popper
						disablePortal={disablePortal}
						id={`${name}-popper`}
						open={open}
						anchorEl={anchorEl}
						style={{
							minWidth: minWidthPopper,
							zIndex: 1000
						}}
						modifiers={{
							flip: {
								enabled: false
							}
						}}
						placement="bottom-start"
					>
						<div className={style.menu}>
							{contentHeader && (
								<div className={style.menu__header}>{contentHeader}</div>
							)}
							<FixedSizeList
								itemCount={dataLength}
								height={getHeight}
								width="100%"
								itemSize={itemSize}
								overscanCount={6}
								style={{ position: "relative" }}
								innerElementType="ul"
								className={style.menu__body}
							>
								{renderRow}
							</FixedSizeList>
							{contentFooter && (
								<div className={style.menu__footer}>{contentFooter}</div>
							)}
						</div>
					</Popper>
				</ClickAwayListener>
			</>
		);
	}
);
