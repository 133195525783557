//@ts-nocheck
const filterMenuItem = (token: any, permissions: any, permission: any) => {
	return token && (permissions[permission] || permission === undefined);
};

// фиксануть фильтрует только напервом уровне
export const filterMenuList = (list, token, permissions) => {
	return list.filter(menuItem => {
		if (menuItem.subItems) {
			return filterMenuList(menuItem.subItems, token, permissions);
		}
		return filterMenuItem(token, permissions, menuItem.permission);
	});
};

export const matchPathByNames = (match, location, names) => {
	let matched = false;

	if (Array.isArray(names)) {
		matched = names.some(el => location.pathname === el);
	}

	if (!match && !matched) return false;

	return true;
};

export const setMenuItemId = (array, suffix) => {
	if (!array) return null;
	return array.map((item, i) => {
		const id = (suffix && `${suffix}.${i + 1}`) || i + 1;
		return {
			...item,
			id: id,
			parentId: suffix,
			subItems: setMenuItemId(item.subItems, id)
		};
	});
};

/**
 * Return array links
 * @param  {object} array  structure
 *
 */

const getArrayLinks = array => {
	if (!array) return [];
	return array.flatMap(el => {
		if (el.subItems) {
			return getArrayLinks(el.subItems);
		}
		const names = el?.names || [];
		return [el.link, ...names];
	});
};

export const isActiveSubItemPage = (array, path) => {
	const allLinsArray = getArrayLinks(array);
	return allLinsArray.includes(path);
};
