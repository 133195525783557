import { memo } from "react";

import CircularProgressMUI, {
	CircularProgressProps
} from "@material-ui/core/CircularProgress";
import classNames from "classnames/bind";
import isEqual from "react-fast-compare";

import { TEST_IDS } from "app/test/test-ids";

import { useStyles } from "./CircularProgress.utils";
import {
	TypeCircularProgressPosition,
	TypeCircularProgressVariant
} from "./type";

export interface PropsCircularProgress
	extends Omit<CircularProgressProps, "color" | "variant"> {
	height?: number | string;
	width?: number | string;
	color?: string;
	colorBg?: string;
	size?: number;
	wrapper?: boolean;
	position?: TypeCircularProgressPosition;
	delay?: number;
	variant?: TypeCircularProgressVariant;
	loading?: boolean;
}

const CircularProgress: React.FC<PropsCircularProgress> = ({
	height,
	width,
	color,
	colorBg,
	size = 24,
	delay = 0,
	position = "static",
	variant,
	loading,
	...rest
}) => {
	const classes = useStyles({
		height,
		color,
		colorBg,
		position,
		width,
		delay,
		size
	});
	const cx = classNames.bind(classes);

	const classesName = cx("wrapper", {
		center: variant === "center",
		centerAbsolute: variant === "center-absolute"
	});

	if (loading !== undefined && !loading) {
		return null;
	}

	return (
		<div className={classesName}>
			<CircularProgressMUI
				data-testid={TEST_IDS.circularProgress}
				disableShrink
				size={size}
				className={classes.circle}
				{...rest}
			/>
		</div>
	);
};

export default memo(CircularProgress, isEqual);
