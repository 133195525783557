import { InputLabel as InputLabelBase } from "@material-ui/core";
import cl from "classnames";

import Info from "app/components/info";

import useStyles from "./style";

export default function InputLabel({
	label,
	labelContent,
	required,
	name,
	info,
	infoPlacement,
	className,
	...rest
}: any) {
	const classes = useStyles();
	if (!label) return null;
	return (
		<InputLabelBase
			id={name}
			htmlFor={name}
			className={cl(classes.inputLabel, className)}
			{...rest}
		>
			{
				<>
					{label}

					{required && <span className="required">&nbsp;*</span>}
				</>
			}
			{labelContent}

			{info && <Info info={info} placement={infoPlacement} />}
		</InputLabelBase>
	);
}
