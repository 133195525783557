export default {
	successRegistration: {
		event: "success_registration",
		label: "user has successfully signed up"
	},
	successLogin: {
		event: "success_login",
		label: "user has successfully logged in"
	},
	successPassOnBoarding: {
		event: "success_pass_onboarding",
		label: "user has successfully passed onboarding"
	},
	successSetKeyAppSumo: {
		event: "success_set_key_appsumo",
		label: "user has successfully passed key appsumo"
	},
	successPayment: {
		event: "success_payment",
		label: "payment has successfully"
	},

	setToolsOnboarding: {
		event: "select_tool_onboarding",
		label: "selected "
	}
};
