// @ts-nocheck
import { useEffect } from "react";

import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import Loadable from "app/utils/Loadable";
import UserProfileWrapper from "app/components/UserProfileLayout";
import actions from "app/store/users/actions";

const UserAccountProfile = Loadable({
	loader: () => import("./AccountProfile")
});

const PageUserAccountProfile = props => {
	const {
		match: { params }
	} = props;

	const { user } = useSelector(state => state.users);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(actions.getUser(params.id));
	}, [dispatch, params.id]);

	return (
		<UserProfileWrapper date={user?.created_at}>
			<UserAccountProfile {...props} />
		</UserProfileWrapper>
	);
};

export default withRouter(PageUserAccountProfile);
