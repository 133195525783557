import types from "./types";

export enum ACTIONS_NAMES {
	registrations = "registrations",
	userRegistrations = "userRegistrations",
	userTrials = "userTrials",
	activeSubscriptions = "activeSubscriptions"
}

type IData = Record<string, any>;
type IError = Record<string, any> | string;

export type IDashBoardActionNames = keyof typeof ACTIONS_NAMES;

const actions = {
	getStatisticRegistrations: (
		data: IData,
		actionName?: IDashBoardActionNames
	) => ({
		type: types.GET_STATISTIC_REGISTRATIONS_REQUEST,
		data,
		actionName: actionName || ACTIONS_NAMES.registrations
	}),
	getStatisticRegistrationsSuccess: (
		actionName: IDashBoardActionNames,
		data: IData
	) => ({
		type: types.GET_STATISTIC_REGISTRATIONS_SUCCESS,
		actionName,
		data
	}),
	getStatisticRegistrationsFailure: (
		actionName: IDashBoardActionNames,
		error: IError
	) => ({
		type: types.GET_STATISTIC_REGISTRATIONS_FAILURE,
		actionName,
		error
	}),

	getStatisticUserRegistrations: (
		data: IData,
		actionName?: IDashBoardActionNames
	) => ({
		type: types.GET_STATISTIC_USER_REGISTRATIONS_REQUEST,
		data,
		actionName: actionName || ACTIONS_NAMES.userRegistrations
	}),
	getStatisticUserRegistrationsSuccess: (
		actionName: IDashBoardActionNames,
		data: IData
	) => ({
		type: types.GET_STATISTIC_USER_REGISTRATIONS_SUCCESS,
		actionName,
		data
	}),
	getStatisticUserRegistrationsFailure: (
		actionName: IDashBoardActionNames,
		error: IError
	) => ({
		type: types.GET_STATISTIC_USER_REGISTRATIONS_FAILURE,
		actionName,
		error
	}),

	getStatisticUserTrials: (data: IData, actionName?: IDashBoardActionNames) => ({
		type: types.GET_STATISTIC_USER_TRIALS_REQUEST,
		data,
		actionName: actionName || ACTIONS_NAMES.userTrials
	}),
	getStatisticUserTrialsSuccess: (
		actionName: IDashBoardActionNames,
		data?: IData
	) => ({
		type: types.GET_STATISTIC_USER_TRIALS_SUCCESS,
		actionName,
		data
	}),
	getStatisticUserTrialsFailure: (
		actionName: IDashBoardActionNames,
		error: IError
	) => ({
		type: types.GET_STATISTIC_USER_TRIALS_FAILURE,
		actionName,
		error
	}),

	getStatisticUserActiveSubscriptions: (
		data: IData,
		actionName?: IDashBoardActionNames
	) => ({
		type: types.GET_STATISTIC_USER_ACTIVE_SUBSCRIPTIONS_REQUEST,
		data,
		actionName: actionName || ACTIONS_NAMES.activeSubscriptions
	}),
	getStatisticUserActiveSubscriptionsSuccess: (
		actionName: IDashBoardActionNames,
		data: IData
	) => ({
		type: types.GET_STATISTIC_USER_ACTIVE_SUBSCRIPTIONS_SUCCESS,
		actionName,
		data
	}),
	getStatisticUserActiveSubscriptionsFailure: (
		actionName: IDashBoardActionNames,
		error: IError
	) => ({
		type: types.GET_STATISTIC_USER_ACTIVE_SUBSCRIPTIONS_FAILURE,
		actionName,
		error
	}),

	resetStatistics: () => ({
		type: types.RESET_STATISTICS
	})
};
export default actions;
