import { removeEmptyValues } from "app/helper";

interface IPrepareLabelProps {
	label: string;
	initialLabel: string;
	labelEnding: string;
}

interface IEventTrackProps {
	category: string;
	action: string;
	label: string;
	value?: string;
	nonInteraction?: boolean;
}

export const prepareLabel = ({
	label,
	initialLabel,
	labelEnding
}: IPrepareLabelProps) => {
	let newLabel = label;

	if (!newLabel) {
		newLabel = initialLabel;
	}

	if (labelEnding) {
		newLabel = `${newLabel} ${labelEnding}`;
	}

	return newLabel;
};

export const prepareActions = (
	category: string,
	events: Record<
		string,
		{
			event: string;
			label: string;
			nonInteraction?: boolean;
			value?: string;
		}
	>
) => {
	const actions: Record<
		string,
		(props?: {
			action: string;
			labelEnding?: string;
			label?: string;
			value?: string;
			nonInteraction?: boolean;
		}) => IEventTrackProps
	> = {};

	Object.entries(events).forEach(([item, valueItem]) => {
		actions[item] = (props: {
			action: string;
			labelEnding?: string;
			label?: string;
			value?: string;
			nonInteraction?: boolean;
		}): IEventTrackProps => {
			const { label, labelEnding, value, nonInteraction } = props || {};
			const newLabel = prepareLabel({
				label,
				initialLabel: valueItem.label,
				labelEnding
			});

			const obj = {
				category,
				action: valueItem.event,
				nonInteraction: nonInteraction || valueItem.nonInteraction,
				value: value || valueItem.value,
				label: newLabel
			};

			return removeEmptyValues(obj) as IEventTrackProps;
		};
	});

	return actions;
};
