import React from "react";

import { Button as ButtonDefault } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import { CircleWarningIcon } from "app/icons";
import { IChildren } from "app/types";

const useStyles = makeStyles(() => ({
	root: {
		backgroundImage: "linear-gradient(260deg, #EB7D68 0%, #F3B078 100%);",
		backgroundSize: "150%, 100%",
		backgroundPositionX: "30%",
		borderRadius: 4,
		fontWeight: 600,
		fontSize: 16,
		lineHeight: "58px",
		padding: "0px 30px",
		color: "#fff",
		boxShadow: "0px 4px 20px rgba(235, 125, 104, 0.5) !important",
		transition: ".5s",
		textTransform: "none",
		fontFamily: "Rubik, sans-serif",

		"&:hover": {
			backgroundPositionX: "100%"
		},

		"&:disabled": {
			cursor: "not-allowed",
			opacity: "0.8",
			pointerEvents: "auto",
			color: "rgba(255,255,255,0.5)"
		},

		"&:disabled ~ div": {
			cursor: "not-allowed"
		}
	}
}));

export enum BUTTON_COLOR_VARIANT {
	danger = "danger",
	borderless = "borderless",
	gradient = "gradient",
	ghost = "ghost",
	blue = "blue",
	error = "error",
	text = "text",
	textOrange = "textOrange",
	square = "square"
}

export enum BUTTON_COLOR_VARIANT_CLASSES {
	danger = "button_danger",
	borderless = "button_borderless",
	gradient = "button_gradient",
	ghost = "button_ghost",
	blue = "button_blue",
	error = "button_error",
	text = "button_text",
	textOrange = "button_text button_text_orange",
	square = "button_square"
}

export enum BUTTON_SIZE {
	large = "large",
	medium = "medium",
	small = "small"
}

export enum BUTTON_SIZE_CLASSSES {
	large = "button_large",
	medium = "button_medium",
	small = "button_small"
}

const getButtonVariant = (variant: keyof typeof BUTTON_COLOR_VARIANT) =>
	BUTTON_COLOR_VARIANT_CLASSES[variant] || variant || "";

const getButtonSize = (size: keyof typeof BUTTON_SIZE) =>
	BUTTON_SIZE_CLASSSES[size] || size || "";

interface props {
	className?: string;
	variant?: "text" | "contained" | "outlined";
	colorVariant?: keyof typeof BUTTON_COLOR_VARIANT;
	size?: keyof typeof BUTTON_SIZE;
	onClick?: (
		event?: React.MouseEvent<HTMLButtonElement>
	) =>
		| void
		| (() => void)
		| (() => Promise<void>)
		| Promise<void>
		| ((e: React.MouseEvent) => Promise<void>);
	disabled?: boolean;
	children?: IChildren;
	fullWidth?: boolean;
	loading?: boolean;
	download?: boolean;
	href?: string;
	target?: string;
}

const Button: React.FC<props> = ({
	className,
	variant,
	colorVariant,
	size,
	disabled,
	onClick,
	children,
	...rest
}) => {
	const classes = useStyles();

	return (
		<ButtonDefault
			variant={variant}
			className={classNames(
				"button",
				classes.root,
				className,
				getButtonVariant(colorVariant),
				getButtonSize(size)
			)}
			disabled={disabled}
			onClick={onClick}
			{...rest}
		>
			{(colorVariant === "error" && <CircleWarningIcon />) || null}
			{children}
		</ButtonDefault>
	);
};

Button.defaultProps = {
	className: "",
	variant: "contained",
	disabled: false,
	size: "large",
	onClick: () => {}
};

export default Button;
