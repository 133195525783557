// @ts-nocheck

import types from "./types";

const actions = {
	getHandBooks: () => ({
		type: types.GET_HANDBOOKS_REQUEST
	}),
	getHandBooksSuccess: data => ({
		type: types.GET_HANDBOOKS_SUCCESS,
		data
	}),
	getHandBooksFailure: error => ({
		type: types.GET_HANDBOOKS_FAILURE,
		error
	})
};
export default actions;
