import { memo } from "react";

import { useIsAdmin } from "app/hooks/useIsAdmin";
import HideContent from "app/components/hideContent";

import CompanyCard from "./CompanyCard";

const mokeCompains = {
	id: "6293cbce373c7f6ce96a5489",
	name: "Company",
	country: {
		id: 1,
		name: "United States"
	},
	industries: [
		{
			id: 1,
			name: "Database"
		},
		{
			id: 2,
			name: "Information Technology"
		}
	],
	website_url: "https://company.com",
	employees_count: "11-50",
	total_funding: 17056813,
	linked_in_url: "https://www.linkedin.com/",
	headquarter_location: "Test, Test, Test Test",
	description: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
	persons: [
		{
			first_name: "Test",
			last_name: "Test",
			position_name: "Test",
			email: "test@test.com",
			linked_in_url: "https://www.linkedin.com/"
		},
		{
			first_name: "Test",
			last_name: "Test",
			position_name: "Test",
			email: "test@test.com",
			linked_in_url: "https://www.linkedin.com/"
		},
		{
			first_name: "Test",
			last_name: "Test",
			position_name: "Test",
			email: "test@test.com",
			linked_in_url: "https://www.linkedin.com/"
		}
	]
};

const data = new Array(3).fill(mokeCompains);

const HideContentSignals = () => {
	const { isAdmin, loading } = useIsAdmin();
	if (isAdmin || loading) return null;
	return (
		<HideContent
			data={data?.map((compain, _i) => (
				<CompanyCard key={_i} data={compain} />
			))}
		/>
	);
};

export default memo(HideContentSignals);
