export default {
	GET_USERS_REQUEST: "GET_USERS_REQUEST",
	GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
	GET_USERS_FAILURE: "GET_USERS_FAILURE",

	GET_USER_REQUEST: "GET_USER_REQUEST",
	GET_USER_SUCCESS: "GET_USER_SUCCESS",
	GET_USER_FAILURE: "GET_USER_FAILURE",

	GET_USER_DATA_REQUEST: "GET_USER_DATA_REQUEST",
	GET_USER_DATA_SUCCESS: "GET_USER_DATA_SUCCESS",
	GET_USER_DATA_FAILURE: "GET_USER_DATA_FAILURE",

	DELETE_USERS_REQUEST: "DELETE_USERS_REQUEST",
	DELETE_USERS_SUCCESS: "DELETE_USERS_SUCCESS",
	DELETE_USERS_FAILURE: "DELETE_USERS_FAILURE",

	GET_USERS_STATISTIC: "GET_USERS_STATISTIC",
	GET_USERS_STATISTIC_SUCCESS: "GET_USERS_STATISTIC_SUCCESS",

	GET_USER_INTEGRAIONS_REQUEST: "GET_USER_INTEGRAIONS_REQUEST",
	GET_USER_INTEGRAIONS_SUCCESS: "GET_USER_INTEGRAIONS_SUCCESS",
	GET_USER_INTEGRAIONS_FAILURE: "GET_USER_INTEGRAIONS_FAILURE",

	FETCH_NEW_USER_REQUEST: "FETCH_NEW_USER_REQUEST",
	FETCH_NEW_USER_SUCCESS: "FETCH_NEW_USER_SUCCESS",
	FETCH_NEW_USER_FAILURE: "FETCH_NEW_USER_FAILURE",

	FETCH_EDIT_USER_REQUEST: "FETCH_EDIT_USER_REQUEST",
	FETCH_EDIT_USER_SUCCESS: "FETCH_EDIT_USER_SUCCESS",
	FETCH_EDIT_USER_FAILURE: "FETCH_EDIT_USER_FAILURE",

	FETCH_RESET_PASSWORD_USER_REQUEST: "FETCH_RESET_PASSWORD_USER_REQUEST",
	FETCH_RESET_PASSWORD_USER_SUCCESS: "FETCH_RESET_PASSWORD_USER_SUCCESS",
	FETCH_RESET_PASSWORD_USER_FAILURE: "FETCH_RESET_PASSWORD_USER_FAILURE",

	FETCH_USER_SHARE_AUDIENCE_REQUEST: "FETCH_USER_SHARE_AUDIENCE_REQUEST",
	FETCH_USER_SHARE_AUDIENCE_SUCCESS: "FETCH_USER_SHARE_AUDIENCE_SUCCESS",
	FETCH_USER_SHARE_AUDIENCE_FAILURE: "FETCH_USER_SHARE_AUDIENCE_FAILURE",

	FETCH_USER_SHARE_CREATIVE_REQUEST: "FETCH_USER_SHARE_CREATIVE_REQUEST",
	FETCH_USER_SHARE_CREATIVE_SUCCESS: "FETCH_USER_SHARE_CREATIVE_SUCCESS",
	FETCH_USER_SHARE_CREATIVE_FAILURE: "FETCH_USER_SHARE_CREATIVE_FAILURE",

	FETCH_FILTER_USERS_REQUEST: "FETCH_FILTER_USERS_REQUEST",
	FETCH_FILTER_USERS_SUCCESS: "FETCH_FILTER_USERS_SUCCESS",

	FETCH_FILTER_USER_REQUEST: "FETCH_FILTER_USER_REQUEST",
	FETCH_FILTER_USER_SUCCESS: "FETCH_FILTER_USER_SUCCESS",

	FETCH_PAGINATION_USERS_REQUEST: "FETCH_PAGINATION_USERS_REQUEST",
	FETCH_PAGINATION_USERS_SUCCESS: "FETCH_PAGINATION_USERS_SUCCESS",

	FETCH_PAGINATION_USER_REQUEST: "FETCH_PAGINATION_USER_REQUEST",
	FETCH_PAGINATION_USER_SUCCESS: "FETCH_PAGINATION_USER_SUCCESS",

	FETCH_GET_USERS_EXPORT_REQUEST: "FETCH_GET_USERS_EXPORT_REQUEST",
	FETCH_GET_USERS_EXPORT_PROGRESS_REQUEST: "FETCH_GET_USERS_EXPORT_PROGRESS_REQUEST",

	RESET_USERS_TABLE_PARAMS: "RESET_USERS_TABLE_PARAMS",
	RESET_USER_TABLE_DATA_PARAMS: "RESET_USER_TABLE_DATA_PARAMS",
};
