import { memo } from "react";

import { Field, FieldProps } from "formik";
import isEqual from "react-fast-compare";

import { getPropsForm, getPropsInput } from "../Form.utils";

import SelectBase from "./selectBase";
import { ISelectProps } from "./type";

const Select: React.FC<ISelectProps> = props => {
	const { form, onChange, validateField } = props;

	const handleChangeField = async (
		event: React.ChangeEvent<{
			name?: string;
			value: unknown;
		}>,
		child: React.ReactNode
	) => {
		const name = event.target.name;

		if (onChange) {
			onChange(event, child);
		} else if (form) {
			await form.setFieldValue(name, event.target.value);
		}

		if (form && validateField) {
			await form.validateField(name);
		}
	};

	const propsForm = getPropsForm(props, { onChange: handleChangeField });
	const propsInput = getPropsInput(props);

	return <SelectBase {...propsInput} {...propsForm} />;
};

const FormikSelectBase: React.FC<ISelectProps> = ({ name, ...rest }) => (
	<Field name={name}>
		{({ field, meta }: FieldProps) => {
			const error = meta.touched ? meta.error : "";
			return (
				<Select
					value={field.value}
					name={name}
					{...rest}
					onChange={field.onChange}
					onBlur={field.onBlur}
					error={error}
				/>
			);
		}}
	</Field>
);

export const FormikFormSelect = memo(FormikSelectBase, isEqual);

export default memo(Select, isEqual);
