import { AxiosResponse } from "axios";

import { API } from "app/api";
import { ApiUrls } from "app/api/apiUrls";
import { globalHandlerError } from "app/helper/handlerErrors";

import { ISubscriptionPrice } from "./type";

export const getSubscriptionPrice = async (): Promise<ISubscriptionPrice> => {
	try {
		const response: AxiosResponse<ISubscriptionPrice> = await API.get(
			ApiUrls.account.onboard.subscriptionPrice
		);

		return response.data;
	} catch (err) {
		globalHandlerError(err);
		throw new Error(err);
	}
};
