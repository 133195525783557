import React, { useState } from "react";

import { useFormik } from "formik";
import cn from "classnames";

import DropZone from "app/components/dropzone";
import Button from "app/uikit-V2/Button";
import { Download } from "app/icons";
//@ts-expect-error
import file from "assets/Signum.AI_Prospects_Template_signumAI.csv";
import { PALETTE } from "app/theme";
import { getPreparedDataFile } from "app/helper/helper.files";
import { IFile, Nullable } from "app/types";
import config from "app/config/config";
import Input from "app/uikit/input";

import ButtonsControls from "../controls/ButtonsControls";

import style from "./style.module.scss";
import validationSchema from "./validationSchema";
import MatchModal from "./MartchModal";
import {
	IMatchedData,
	IPrespectUploadValues,
	TypeProspectData,
	TypeRequestFile
} from "./type";
import { getMatchesColumns } from "./ProspectsUpload.utils";

interface Props {
	data: Nullable<TypeProspectData>;
	onSubmit: (
		values: TypeRequestFile,
		file: File[],
		matched?: IMatchedData
	) => Promise<void>;
	onNext: (nextStep?: number) => void;
	onPrev?: () => void;
	fetchMatchesColumns?: (data: { file: IFile }) => Promise<IMatchedData>;
	withNameField?: boolean;
	isShowHelpBlock?: boolean;
	isPositionCenter?: boolean;
	title?: React.ReactNode;
	subTitle?: React.ReactNode;
	prevBtnContent?: React.ReactNode;
}

const ProspectsUpload: React.FC<Props> = ({
	onPrev,
	onSubmit,
	onNext,
	isShowHelpBlock,
	isPositionCenter,
	withNameField,
	fetchMatchesColumns,
	data,
	prevBtnContent,
	title = "Upload prospects",
	subTitle = "Upload a CSV file containing LinkedIn URLs and company websites of prospects you want to track"
}) => {
	const [tempData, setTempData] = useState<IMatchedData>();
	const [loadingMatching, setLoadingMatching] = useState(false);
	const [openMatchModal, setOpenMatchModal] = useState(false);

	const onSubmitMatch = async (values: IPrespectUploadValues) => {
		try {
			const _valueFile = values?.file?.[0];
			const _dataFile = data?.file?.[0];

			if (
				_valueFile?.name === _dataFile?.name &&
				_valueFile?.size === _dataFile?.size &&
				(withNameField ? values?.name === data?.name : true)
			) {
				onNext();
				return;
			}
			setLoadingMatching(true);
			const file = values.file[0];
			const name = values.name || file.name;
			const requestData = await getPreparedDataFile(file, name);
			const fetchColumns = fetchMatchesColumns || getMatchesColumns;
			const columns = await fetchColumns({ file: requestData });

			setTempData(columns);
			setOpenMatchModal(true);
		} catch (err) {
			console.log(err);
		} finally {
			setLoadingMatching(false);
		}
	};

	const formik = useFormik<IPrespectUploadValues>({
		initialValues: {
			name: withNameField ? data?.name || "" : undefined,
			file: data?.file
		},
		validationSchema: validationSchema(withNameField),
		onSubmit: onSubmitMatch
	});

	const onClose = () => {
		setOpenMatchModal(false);
	};

	const handleSubmit = async (values: TypeRequestFile) => {
		formik.setSubmitting(true);
		await onSubmit(
			{
				...values,
				name: (withNameField && formik.values.name) || values.name
			},
			formik.values.file,
			tempData
		);
		formik.setSubmitting(false);
	};

	return (
		<section className={style.section}>
			<div
				className={cn({
					[style.content]: isPositionCenter
				})}
			>
				<h1 className={style.title}>{title}</h1>
				<p className={style.text}>{subTitle}</p>
				<div className={style.wrapFields}>
					{withNameField && (
						<Input
							fullWidth
							name="name"
							form={formik}
							label="Name the sheet"
							required
						/>
					)}
					<DropZone
						showInfoTextType={!formik.values.file?.length}
						fullWidth
						name="file"
						type="csv"
						label={withNameField && "File"}
						form={formik}
					/>
				</div>
				<br />
				<Button
					download
					href={file}
					size="small"
					variant="text"
					color={PALETTE.primary}
				>
					Download the CSV spreadsheet template
					<Download style={{ marginLeft: "5px" }} />
				</Button>

				{isShowHelpBlock && (
					<div className={style.help_block}>
						<p className={style.assist_text}>No sheet? Call us for help!</p>
						<Button
							variant="secondary"
							href={config.artemLinkCalendly}
							target="_blank"
							className={style.btn_assist}
							size="small"
						>
							Book a quick call
						</Button>
					</div>
				)}
			</div>
			<ButtonsControls
				prevBtnContent={prevBtnContent}
				onPrev={onPrev}
				onNext={formik.handleSubmit}
				loadingNextBtn={loadingMatching}
			/>
			<MatchModal
				onClose={onClose}
				open={openMatchModal}
				data={tempData}
				loading={formik.isSubmitting}
				file={formik.values.file?.[0]}
				onSubmit={handleSubmit}
			/>
		</section>
	);
};

export default ProspectsUpload;
