import { memo } from "react";

import CircularProgressMUI, {
	CircularProgressProps
} from "@material-ui/core/CircularProgress";
import classNames from "classnames/bind";
import isEqual from "react-fast-compare";

import { getVariant, useStyles } from "./CircularProgress.utils";

export interface PropsCircularProgress
	extends Omit<CircularProgressProps, "color" | "variant"> {
	height?: number | string;
	color?: string;
	colorBg?: string;
	size?: number;
	wrapper?: boolean;
	position?: "absolute-center" | "center" | "relative" | "absolute" | "static";
	delayLoader?: number;
	variant?: string;
	loading?: boolean;
}

const CircularProgress: React.FC<PropsCircularProgress> = ({
	height,
	color,
	colorBg,
	size = 24,
	wrapper,
	position,
	delayLoader,
	variant,
	loading,
	...rest
}) => {
	const colorVariant = getVariant(variant);
	const classes = useStyles({
		height,
		color: colorVariant.color || color,
		colorBg: colorVariant.colorBg || colorBg,
		position,
		delayLoader,
		size
	});
	const cx = classNames.bind(classes);

	if (loading !== undefined && !loading) {
		return null;
	}
	if (wrapper || height) {
		return (
			<div className={cx("wrapper", { wrapperCenter: position === "center" })}>
				<CircularProgressMUI
					disableShrink
					size={size}
					className={classes.buttonProgress}
					{...rest}
				/>
			</div>
		);
	} else {
		return (
			<CircularProgressMUI
				disableShrink
				size={size}
				className={classes.buttonProgress}
				{...rest}
			/>
		);
	}
};

export default memo(CircularProgress, isEqual);
