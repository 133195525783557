// @ts-nocheck
import { takeEvery, put, all, call } from "redux-saga/effects";

import toastify from "app/utils/toastify";
import directory from "app/directory/errors";

import services from "./services";
import types from "./types";
import actions from "./actions";

const dir = directory.en;

function* getStatisticRegistrations({ actionName, data }: any) {
	try {
		const response = yield call(services.getStatisticRegistrations, data);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(
				actions.getStatisticRegistrationsSuccess(
					actionName,
					response.data.chart || []
				)
			);
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.getStatisticRegistrationsFailure(actionName, ""));
	}
}

function* getStatisticUserRegistrations({ actionName, data }: any) {
	try {
		const response = yield call(services.getStatisticUserRegistrations, data);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(
				actions.getStatisticUserRegistrationsSuccess(
					actionName,
					response.data.chart || []
				)
			);
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.getStatisticUserRegistrationsFailure(actionName, ""));
	}
}

function* getStatisticUserTrials({ actionName, data }: any) {
	try {
		const response = yield call(services.getStatisticUserTrials, data);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(
				actions.getStatisticUserTrialsSuccess(actionName, response.data.chart || [])
			);
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.getStatisticUserTrialsFailure(actionName, ""));
	}
}

function* getStatisticActiveSubscriptions({ actionName, data }: any) {
	try {
		const response = yield call(
			services.getStatisticUserActiveSubscriptions,
			data
		);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(
				actions.getStatisticUserActiveSubscriptionsSuccess(
					actionName,
					response.data.chart || []
				)
			);
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.getStatisticUserActiveSubscriptionsFailure(actionName, ""));
	}
}

export default function* root() {
	yield all([
		takeEvery(
			types.GET_STATISTIC_REGISTRATIONS_REQUEST,
			getStatisticRegistrations
		)
	]);
	yield all([
		takeEvery(
			types.GET_STATISTIC_USER_REGISTRATIONS_REQUEST,
			getStatisticUserRegistrations
		)
	]);
	yield all([
		takeEvery(types.GET_STATISTIC_USER_TRIALS_REQUEST, getStatisticUserTrials)
	]);

	yield all([
		takeEvery(
			types.GET_STATISTIC_USER_ACTIVE_SUBSCRIPTIONS_REQUEST,
			getStatisticActiveSubscriptions
		)
	]);
}
