import { IPagination } from "app/types";
import { ITrendValuePrepare } from "app/screens/cabinet/trends/trendsDatabase/components/createOrEdit/types";

import types from "./types";
import { ITrandsDatabaseActionNames, TFiltersParams } from "./type";

export const ACTIONS_NAMES = {
	trends: "trends",
	trendDetails: "trendDetails",
	trendCreate: "trendCreate",
	trendUpdate: "trendUpdate",
	trendDelete: "trendDelete"
};

const actions = {
	getTrendsDatabase: (isAdmin: boolean = false) => ({
		type: types.GET_TRENDS_DATABASE_REQUEST,
		actionName: ACTIONS_NAMES.trends,
		isAdmin
	}),
	getTrendsDatabaseSuccess: (
		actionName: ITrandsDatabaseActionNames,
		data: any
	) => ({
		type: types.GET_TRENDS_DATABASE_SUCCESS,
		actionName,
		data
	}),
	getTrendsDatabaseFailure: (
		actionName: ITrandsDatabaseActionNames,
		error: any
	) => ({
		type: types.GET_TRENDS_DATABASE_FAILURE,
		actionName,
		error
	}),

	getTrendDatabase: (id: number | string, cb?: () => void) => ({
		type: types.GET_TREND_DATABASE_REQUEST,
		id,
		cb,
		actionName: ACTIONS_NAMES.trendDetails
	}),
	getTrendDatabaseSuccess: (
		actionName: ITrandsDatabaseActionNames,
		data: any
	) => ({
		type: types.GET_TREND_DATABASE_SUCCESS,
		actionName,
		data
	}),
	getTrendDatabaseFailure: (
		actionName: ITrandsDatabaseActionNames,
		error: any
	) => ({
		type: types.GET_TREND_DATABASE_FAILURE,
		actionName,
		error
	}),

	fetchCreateTrendDatabase: (data: ITrendValuePrepare, cb?: () => void) => ({
		type: types.FETCH_TREND_CREATE_DATABASE_REQUEST,
		data,
		cb,
		actionName: ACTIONS_NAMES.trendCreate
	}),
	fetchCreateTrendDatabaseSuccess: (
		actionName: ITrandsDatabaseActionNames,
		data: any
	) => ({
		type: types.FETCH_TREND_CREATE_DATABASE_SUCCESS,
		actionName,
		data
	}),
	fetchCreateTrendDatabaseFailure: (
		actionName: ITrandsDatabaseActionNames,
		error: any
	) => ({
		type: types.FETCH_TREND_CREATE_DATABASE_FAILURE,
		actionName,
		error
	}),

	fetchUpdateTrendDatabase: (
		id: number | string,
		data: ITrendValuePrepare,
		cb?: () => void
	) => ({
		type: types.FETCH_TREND_EDIT_DATABASE_REQUEST,
		id,
		data,
		cb,
		actionName: ACTIONS_NAMES.trendUpdate
	}),
	fetchUpdateTrendDatabaseSuccess: (
		actionName: ITrandsDatabaseActionNames,
		data: any
	) => ({
		type: types.FETCH_TREND_EDIT_DATABASE_SUCCESS,
		actionName,
		data
	}),
	fetchUpdateTrendDatabaseFailure: (
		actionName: ITrandsDatabaseActionNames,
		error: any
	) => ({
		type: types.FETCH_TREND_EDIT_DATABASE_FAILURE,
		actionName,
		error
	}),

	fetchDeleteTrendDatabase: (id: number, cb?: () => void) => ({
		type: types.FETCH_TREND_DELETE_DATABASE_REQUEST,
		id,
		cb,
		actionName: ACTIONS_NAMES.trendDelete
	}),

	fetchDeleteTrendDatabaseSuccess: (
		actionName: ITrandsDatabaseActionNames,
		data: any
	) => ({
		type: types.FETCH_TREND_DELETE_DATABASE_SUCCESS,
		actionName,
		data
	}),
	fetchDeleteTrendDatabaseFailure: (
		actionName: ITrandsDatabaseActionNames,
		error: any
	) => ({
		type: types.FETCH_TREND_DELETE_DATABASE_FAILURE,
		actionName,
		error
	}),

	fetchPagination: (
		data: IPagination,
		actionName?: ITrandsDatabaseActionNames
	) => ({
		type: types.FETCH_PAGINATION_TRENDS_DATABASE_REQUEST,
		actionName: actionName || ACTIONS_NAMES.trends,
		data
	}),

	fetchPaginationSuccess: (
		data: IPagination,
		actionName: ITrandsDatabaseActionNames
	) => ({
		type: types.FETCH_PAGINATION_TRENDS_DATABASE_SUCCESS,
		data,
		actionName
	}),

	fetchFilter: (
		data: TFiltersParams,
		actionName?: ITrandsDatabaseActionNames
	) => ({
		type: types.FETCH_FILTER_TRENDS_DATABASE_REQUEST,
		data,
		actionName: ACTIONS_NAMES.trends || actionName
	}),
	fetchFilterSuccess: (
		data: TFiltersParams,
		actionName: ITrandsDatabaseActionNames
	) => ({
		type: types.FETCH_FILTER_TRENDS_DATABASE_SUCCESS,
		data,
		actionName
	}),

	resetTrendDatabaseDetails: () => ({
		type: types.RESET_TRENDS_DATABASE_DETAILS
	}),

	resetTrendsDatabase: () => ({
		type: types.RESET_TRENDS_DATABASE
	})
};

export default actions;
