import React, { useEffect, useRef } from "react";

export const useDidUpdateEffect = (
	fn: React.EffectCallback,
	inputs: React.DependencyList
) => {
	const didMountRef = useRef(false);

	useEffect(() => {
		if (didMountRef.current) {
			return fn();
		}
		didMountRef.current = true;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, inputs);
};
