import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import Loadable from "app/utils/Loadable";
import Layout from "app/components/includes/layout";
import action from "app/store/auth/actions";
import { configSelect } from "app/store/select";

const Success = Loadable({
	loader: () => import("app/components/auth/emailVerification")
});

const Registration = Loadable({
	loader: () => import("app/components/auth/registration")
});

const NewPassword = () => {
	const { countRegistered } = useSelector(configSelect.auth);

	const dispatch = useDispatch();

	useEffect(() => {
		return () => {
			dispatch(action.fetchResetActionState());
		};
	}, [dispatch]);

	return <Layout>{(countRegistered && <Success />) || <Registration />}</Layout>;
};

export default NewPassword;
