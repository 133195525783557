import ReactDOM from "react-dom";

let _node: HTMLDivElement | null = null;

const renderInfoModal = (children: JSX.Element) => {
	if (_node && children) {
		ReactDOM.render(children, _node);
	}
};

const createInfoModal = () => {
	const hasElem: HTMLDivElement | null = document.querySelector("#modal-info");
	let element: HTMLDivElement | null = hasElem;

	if (!hasElem) {
		const elem = document.createElement("div");
		elem.id = "modal-info";
		document.body.appendChild(elem);
		element = elem;
	}

	_node = element;

	return renderInfoModal;
};

export const renderModal = createInfoModal();
