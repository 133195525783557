import api from "app/api";

const API = api();

const getStatisticRegistrations = async (params: any) => {
	try {
		return await API.get("/admin/statistic/registrations", { params: params });
	} catch (error) {
		return error;
	}
};

const getStatisticUserRegistrations = async (params: any) => {
	try {
		return await API.get("/admin/statistic/users/registrations-summary", {
			params: params
		});
	} catch (error) {
		return error;
	}
};

const getStatisticUserTrials = async (params: any) => {
	try {
		return await API.get("/admin/statistic/users/trials", {
			params: params
		});
	} catch (error) {
		return error;
	}
};

const getStatisticUserActiveSubscriptions = async (params: any) => {
	try {
		return await API.get("/admin/statistic/users/active-subscriptions", {
			params: params
		});
	} catch (error) {
		return error;
	}
};

export default {
	getStatisticRegistrations,
	getStatisticUserRegistrations,
	getStatisticUserTrials,
	getStatisticUserActiveSubscriptions
};
