import { ChangeEvent } from "react";

import CheckboxBase from "./checkbox-base";
import CheckboxLabel from "./checkbox-label";

const Checkbox = (props: any) => {
	const {
		label,
		name,
		form,
		formCount,
		handleChange,
		validateField,
		...rest
	} = props;
	const handleChangeField = async (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.checked;
		if (handleChange) {
			await handleChange(value, name, e);
		} else {
			await form.setFieldValue(name, value);
		}
		if (validateField) {
			await form.validateField(name);
		}
		form.setStatus({
			...form.status,
			dirtyFields: {
				...form.status?.dirtyFields,
				[name]: true
			}
		});
	};
	let propsForm = {
		name: name,
		checked: !!form?.values[name],
		onChange: handleChangeField,
		error: form?.touched[name] && Boolean(form?.errors[name]),
		helperText: form?.touched[name] && form?.errors[name]
	};

	if (label && form) {
		return <CheckboxLabel name={name} label={label} {...propsForm} {...rest} />;
	} else if (form) {
		return <CheckboxBase {...propsForm} {...rest} />;
	} else if (label) {
		return <CheckboxLabel name={name} label={label} {...rest} />;
	} else {
		return <CheckboxBase name={name} {...rest} />;
	}
};

export default Checkbox;
