import React, { memo } from "react";

import isEqual from "react-fast-compare";
import { Link } from "react-router-dom";

import { DeleteIcon, LogoSIcon } from "app/icons";
import { copyContent, getTriggerColors } from "app/helper/tracking.helper";
import Button from "app/uikit-V2/Button";

import { EmailIcon, LinkedinIcon } from "./icons";
import ToolTip from "./tooltip";
import styles from "./style.module.scss";

interface Props {
	name?: string;
	position?: string;
	email?: string;
	linkedInUrl?: string;
	viewLink?: string;
	events?: { id: number }[];
	onDelete?: () => void;
}

const CardPerson: React.FC<Props> = ({
	name,
	position,
	events,
	viewLink,
	email,
	linkedInUrl,
	onDelete
}) => (
	<div className={styles.card_user}>
		<div className={styles.card_user__body}>
			<p className={styles.card_user__title}>{name}</p>
			{position && <p className={styles.card_user__position}>{position}</p>}
			<div className={styles.card_user__wrap_links}>
				<div className={styles.card_user__wrap_media}>
					{email && (
						<ToolTip
							onClick={() => {
								copyContent(email);
							}}
							title="Email copied to clipboard"
						>
							<span className={styles.card_user__icon}>
								<EmailIcon />
							</span>
						</ToolTip>
					)}
					{linkedInUrl && (
						<a
							target="_blank"
							rel="noreferrer"
							href={linkedInUrl}
							className={styles.card_user__icon}
						>
							<LinkedinIcon />
						</a>
					)}
				</div>
				{viewLink && (
					<Link className={styles.card_user__details} to={viewLink}>
						<LogoSIcon /> View details
					</Link>
				)}
			</div>
			<div className={styles.card_user__right_corner}>
				{events && (
					<ul className={styles.card_user__trigger_list}>
						{events?.map(item => (
							<li style={getTriggerColors(item.id)} key={item.id}></li>
						))}
					</ul>
				)}
				{onDelete && (
					<Button
						size="medium"
						variant="text"
						className={styles.card_user__delete}
						onClick={onDelete}
					>
						<DeleteIcon color="#EB7D68" />
					</Button>
				)}
			</div>
		</div>
	</div>
);

export default memo(CardPerson, isEqual);
