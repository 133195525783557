import { memo } from "react";

import isEqual from "react-fast-compare";
import {
	Input as MUIInput,
	InputAdornment,
	FormHelperText
} from "@material-ui/core";
import cn from "classnames";

import Alert from "app/uikit/alert";

import { getFomComponentProps } from "../FormComponents/FormComponents.utils";
import FormComponent from "../FormComponents";

import useStyles from "./style";
import { IInputProps } from "./type";

const Input: React.FC<IInputProps> = props => {
	const {
		className,
		error,
		name,
		icon,
		iconPosition,
		adornmentActions,
		warning,
		reference,
		...rest
	} = props;

	const classes = useStyles();
	const fomComponentProps = getFomComponentProps(props);

	const Adornment = icon ? (
		<InputAdornment
			className={classes.iconStyles}
			position={iconPosition}
			{...adornmentActions}
		>
			{icon}
		</InputAdornment>
	) : undefined;

	return (
		<FormComponent {...fomComponentProps}>
			<MUIInput
				id={name}
				name={name}
				ref={reference}
				className={cn(classes.input, { [classes.input_error]: error }, className)}
				endAdornment={iconPosition === "end" && Adornment}
				startAdornment={iconPosition === "start" && Adornment}
				inputProps={{}}
				{...rest}
			/>

			{/* TODO: REFACTOR TO V2  */}
			{!(error && Boolean(error)) && warning && (
				<FormHelperText error className={classes.warning} component="div">
					{!(error && Boolean(error)) && warning && (
						<Alert type="warning" text={warning} />
					)}
				</FormHelperText>
			)}
		</FormComponent>
	);
};

Input.defaultProps = {
	iconPosition: "end"
};

export default memo(Input, isEqual);
