import { AxiosResponse } from "axios";

import { globalHandlerError } from "app/helper/handlerErrors";
import { ApiUrls } from "app/api/apiUrls";
import { API } from "app/api";

import { ICompany, IResponseTriggerCompanyDetails } from "../types";

export const getCompanyDetails = async (
	id: string,
	companyId: string,
	cb?: React.Dispatch<React.SetStateAction<ICompany>>
): Promise<IResponseTriggerCompanyDetails | ICompany> => {
	try {
		if (!id) return;
		const response: AxiosResponse<IResponseTriggerCompanyDetails> = await API.get(
			ApiUrls.account.leadObservation.companiesSets.companies.details(
				id,
				companyId
			)
		);
		if (cb) {
			cb(response?.data?.company);
		}

		return response.data;
	} catch (err) {
		globalHandlerError(err);
	}
};
