import { memo, useState } from "react";

import isEqual from "react-fast-compare";
import { useSelector } from "react-redux";

import { configSelect } from "app/store/select";

import PriceCard from "./PriceCard";
import { PRICES_INCLUDES } from "./Price.config";
import styles from "./style.module.scss";

const Price = () => {
	const [show, setShow] = useState(false);
	const { tariff_plans } = useSelector(configSelect.handBooks);

	const onShow = () => {
		setShow(value => !value);
	};

	return (
		<div className={styles.container}>
			{tariff_plans?.map((item, i) => (
				<PriceCard
					id={item.id}
					title={item.name}
					value={item.price}
					includes={PRICES_INCLUDES[i]}
					showDetails={show}
					onShow={onShow}
				/>
			))}
		</div>
	);
};

export default memo(Price, isEqual);
