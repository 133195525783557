import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@material-ui/core/styles";
import ReactGA from "react-ga4";

import theme from "app/theme/theme";
import { initSentry } from "app/utils/sentry";
import { GOOGLE_KEY, IS_DEV_ENV } from "app/constants";
import { isDev } from "app/utils/isDev";

import Routers from "./app/routers/routers";
import store from "./app/store";

import "style/scss/index.scss";

if (!isDev && !IS_DEV_ENV) {
	initSentry();
	ReactGA.initialize(GOOGLE_KEY);
}

ReactDOM.render(
	<Provider store={store}>
		<ThemeProvider theme={theme}>
			<ToastContainer />
			<Routers />
		</ThemeProvider>
	</Provider>,
	document.getElementById("root")
);
