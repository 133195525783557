import React, { useEffect, useMemo, useState } from "react";

import { useParams } from "react-router-dom";

import Loadable from "app/utils/Loadable";
import UserLayout from "app/components/includes/UserLayout";
import PATHS from "PATHS";
import useQuery from "app/hooks/useQuery";
import { Nullable } from "app/types";

import { ICompany, IResponseCompanyDetails } from "../types";
import { getDetailsTracking } from "../details/TrackingDetails.utils";
import { PropsTriggerDetails } from "../triggerDetails";

import { getCompanyDetails } from "./CompanyDetails.utils";

const TriggerDetails = Loadable<PropsTriggerDetails>({
	loader: () => import("../triggerDetails")
});

const PageScreenTrackingCompanyDetails: React.FC = () => {
	const { id, companyId } = useParams<Record<string, string>>();
	const [data, setData] = useState<IResponseCompanyDetails>(null);
	const [details, setDetails] = useState<Nullable<ICompany>>(null);
	const query = useQuery();
	const prevTrackingList = query.get("prev-tracking-list");

	const currentId = prevTrackingList || id;

	useEffect(() => {
		(async () => {
			await getDetailsTracking(currentId, setData);
		})();
	}, [currentId, setData]);

	useEffect(() => {
		getCompanyDetails(id, companyId, setDetails);
	}, [id, companyId, setDetails]);

	const triggerDetails = useMemo(() => {
		if (!details) return details;

		const { ...rest } = details;

		return {
			...rest
		};
	}, [details]);

	return (
		<UserLayout
			headerBackLink={PATHS.cabinet.tracking.details.url(currentId)}
			headerBackLinkTitle={data ? `a list ${data.name}` : ""}
		>
			<TriggerDetails
				isCompany
				title="Company Details"
				trackingId={id}
				companyId={companyId}
				details={triggerDetails}
			/>
		</UserLayout>
	);
};

export default PageScreenTrackingCompanyDetails;
