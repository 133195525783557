import React, { useState } from "react";

import ButtonsControls from "app/components/Tracking/TrackingNewLists/controls/ButtonsControls";
import Alert from "app/uikit/alert";

import style from "./style.module.scss";
import { IPriceData } from "./types";

interface Props {
	data: IPriceData;
	onPrev: () => void;
	onSubmit: () => Promise<void>;
	showTariffPlan?: boolean;
	submitBtnContent?: string;
}

const PriceList: React.FC<Props> = ({
	data: {
		totalProspects,
		newProspects,
		currencyCode,
		price,
		is_change_tariff_plan
	} = {},
	onPrev,
	onSubmit,
	showTariffPlan = true,
	submitBtnContent = "Create new list"
}) => {
	const [loadingSession, setLoadingSession] = useState(false);

	const handleSubmit = async () => {
		try {
			setLoadingSession(true);
			await onSubmit();
		} catch (err) {
			console.log(err);
		} finally {
			setLoadingSession(false);
		}
	};

	return (
		<section className={style.section}>
			<div>
				<h1 className={style.title}>Creating a new tracking list</h1>

				<div>
					<div className={style.item}>
						<p className={style.item__text}>Added new prospects:</p>
						<div className={style.item__info}>
							<p className={style.item__value}>{newProspects}</p>
						</div>
					</div>

					<div className={style.item}>
						<p className={style.item__text}>
							Total number of prospects for tracking:
						</p>
						<div className={style.item__info}>
							<p className={style.item__value}>{totalProspects}</p>
						</div>
					</div>

					{is_change_tariff_plan && showTariffPlan && (
						<>
							<div className={style.item}>
								<p className={style.item__text}>
									Updated subscription price per month:
								</p>
								<div className={style.item__info}>
									<p className={style.item__value}>
										{currencyCode}
										{price}
									</p>
								</div>
							</div>
							<Alert text="There will be no withdrawal now. First withdrawal after 30 days" />
						</>
					)}
				</div>
			</div>
			<ButtonsControls
				onPrev={onPrev}
				onNext={handleSubmit}
				nextBtnContent={submitBtnContent}
				loadingNextBtn={loadingSession}
			/>
		</section>
	);
};

export default PriceList;
