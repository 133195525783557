import { memo } from "react";

import isEqual from "react-fast-compare";

import Typography from "app/uikit-V2/Typography";
import Chip from "app/uikit-V2/Chip";
import { ITriggerStyle } from "app/types";

import { IconLink, IconTime } from "./Icons";
import styles from "./style.module.scss";

type TypeKeyword = {
	id: number;
	name: string;
};

export interface KeywordsViewItemProps {
	time: string;
	source: string;
	keywords: TypeKeyword[];
	triggerStyle?: ITriggerStyle;
}

const KeywordsViewItem: React.FC<KeywordsViewItemProps> = ({
	source,
	time,
	keywords,
	triggerStyle
}) => (
	<div className={styles.keywords_view}>
		<Typography className={styles.keywords_view__title}>
			Keywords was mentioned
		</Typography>

		<Chip
			list={keywords}
			className={styles.chips}
			classNameItem={styles.chips_item}
			styleItem={triggerStyle}
		/>

		<div className={styles.keywords_view__footer}>
			<div className={styles.keywords_view__source}>
				Source:{" "}
				<a href={source} target="_blank" rel="noreferrer">
					{source}
				</a>
				<a
					className={styles.link_icon}
					href={source}
					target="_blank"
					rel="noreferrer"
				>
					<IconLink />
				</a>
			</div>

			<div className={styles.keywords_view__time}>
				Date of capturing the update:{" "}
				<span>
					<IconTime /> {time}
				</span>
			</div>
		</div>
	</div>
);

export default memo(KeywordsViewItem, isEqual);
