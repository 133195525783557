// @ts-nocheck
import { useMemo, useState, useCallback, memo, useEffect } from "react";

import debounce from "lodash/debounce";
import { MenuItem } from "@material-ui/core";
import { ClickAwayListener } from "@material-ui/core";
import Popper from "@material-ui/core/Popper";
import ButtonBase from "@material-ui/core/ButtonBase";
import InputBase from "@material-ui/core/InputBase";
import { InputAdornment } from "@material-ui/core";
import { FixedSizeList } from "react-window";
import cl from "classnames/bind";

import Input from "app/uikit/input";

import { DownArrow, Search } from "../../icons";
import Checkbox from "../checkbox";
import Chip from "../chip";

import useStyles from "./style";

const ITEM_SIZE = 40;

function filter(val: any, list: any) {
	let matched_terms = [];
	list.forEach(item => {
		if (item.name.toLowerCase().indexOf(val.toLowerCase()) !== -1) {
			matched_terms.push(item);
		}
	});
	return matched_terms;
}

const AutocompleteWindow = ({
	name,
	error,
	searchPlaceholder,
	placeholder,
	value,
	options,
	itemSize = ITEM_SIZE,
	minWidth = 100,
	noOptionsText,
	disablePortal,
	onChange,
	fullWidth,
	multiple,
	disabled
}) => {
	const classes = useStyles();
	const cx = cl.bind(classes);
	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [pendingValue, setPendingValue] = useState(null);
	const [list, setList] = useState([]);
	const [initialList, setInitialList] = useState([]);

	const onChoose = useCallback(
		option => {
			onChange && onChange(option);
			setOpen(false);
		},
		[onChange, setOpen]
	);

	const delayed = useCallback(
		debounce(v => {
			setList(filter(v, initialList));
		}, 500),
		[debounce, initialList]
	);

	useEffect(() => {
		if (options && pendingValue === null) {
			setInitialList(options);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options]);

	const listOptins = list?.length || pendingValue !== null ? list : initialList;

	const dataLength = listOptins?.length;

	const renderRow = props => {
		const { index, style } = props;
		if (listOptins?.[index]) {
			const option = listOptins[index];
			const { name } = option;
			const isActive = (option as any).id === value?.id;

			return (
				<MenuItem
					className={cx("optionItem", { optionItemActive: isActive })}
					onClick={() => onChoose(option)}
					style={{ ...style }}
					value={index}
				>
					{(multiple && (
						<div className={classes.text}>
							<Checkbox checked={isActive} />

							<span className="main-text main-text_bold">{(option as any).name}</span>
						</div>
					)) || (
						<div className={cx("text", "menuItemBlock")}>
							<div className={cx("innerOptionItem")}>
								<span className={cl(classes.menuItemText, "main-text main-text_bold")}>
									{name}
								</span>
							</div>
						</div>
					)}
				</MenuItem>
			);
		}
		return (
			<MenuItem
				className={classes.optionItem}
				style={style}
				value={index}
				disabled={true}
			>
				<div className={classes.text}>
					<span className="main-text main-text_bold">Loading...</span>
				</div>
			</MenuItem>
		);
	};

	const handleChangeSearch = e => {
		const value = e.target.value;
		setPendingValue(value);
		delayed(value);
	};

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
	};

	const clickAwayHandler = () => {
		setOpen(false);
	};

	const getHeight = useMemo(() => {
		let height = 16;
		if (dataLength === 0) return 0;
		if (dataLength >= 6) {
			height = itemSize * 6 + 16;
		} else if (dataLength > 0 && dataLength < 6) {
			height = itemSize * dataLength + 16;
		}
		return height;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataLength]);

	const popperWidth =
		(anchorEl as any)?.clientWidth < minWidth
			? minWidth
			: (anchorEl as any)?.clientWidth;

	const onDelete = index => {
		const mewArr = (pendingValue && [...pendingValue]) || [];
		mewArr.splice(+index, 1);
		setPendingValue(mewArr);
		onChange(mewArr);
	};

	return (
		<>
			<ButtonBase
				disabled={disabled}
				disableRipple
				className={cx("button", { error: error })}
				onClick={handleClick}
			>
				{(multiple && (
					<Chip
						className={classes.chipContainer}
						variant="bordered"
						list={pendingValue || []}
						onDelete={onDelete}
					/>
				)) || (
					<Input
						name={name}
						className={cx("input")}
						id={name}
						disabled={disabled}
						readOnly={true}
						value={value?.name || ""}
						fullWidth
						iconPosition="end"
						placeholder={placeholder}
						icon={<DownArrow />}
					/>
				)}
			</ButtonBase>

			<ClickAwayListener
				mouseEvent="onMouseDown"
				touchEvent="onTouchStart"
				onClickAway={clickAwayHandler}
			>
				<Popper
					disablePortal={!!disablePortal}
					id={`${name}-popper`}
					open={open}
					style={{
						width: popperWidth
					}}
					anchorEl={anchorEl}
					modifiers={{
						flip: {
							enabled: false
						}
					}}
					placement="bottom-start"
					className={classes.popper}
				>
					<InputBase
						autoFocus
						fullWidth
						className={cl(classes.inputSearchSecond, classes.inputSearch)}
						placeholder={searchPlaceholder}
						value={pendingValue || ""}
						onChange={handleChangeSearch}
						startAdornment={
							<InputAdornment position="start">
								<Search />
							</InputAdornment>
						}
					/>

					<FixedSizeList
						itemCount={dataLength}
						height={getHeight}
						width="100%"
						itemSize={itemSize}
						overscanCount={5}
						style={{ position: "relative" }}
						innerElementType="ul"
						className={cx("infiniteBlock", {
							infiniteBlockEmpty: dataLength === 0
						})}
					>
						{renderRow}
					</FixedSizeList>
					{!dataLength && (
						<p className={classes.noOptions}>
							{noOptionsText || "No items have been found by your request"}
						</p>
					)}
				</Popper>
			</ClickAwayListener>
		</>
	);
};
export default memo(AutocompleteWindow);
