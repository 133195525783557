import React from "react";

import { EmptyPlaceholder } from "app/icons";

interface Props {
	text?: React.ReactNode;
	content?: React.ReactNode;
}

const NoData: React.FC<Props> = ({ text, content }) => (
	<div
		style={{
			padding: "35px",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center"
		}}
	>
		<div
			style={{
				maxWidth: "411px",
				marginBottom: "25px"
			}}
		>
			<EmptyPlaceholder />
		</div>

		<p className="title-h4">{text || "No data are available"}</p>
		{content}
	</div>
);

export default NoData;
