// @ts-nocheck
import directory from "app/directory/errors";
import toastify from "app/utils/toastify";

const dir = directory.en;

export const handlerErrors = (
	backendErrors: Error | string | string[],
	setErrors: any,
	keys?: any
) => {
	if (!backendErrors) return;

	if (Array.isArray(backendErrors)) {
		return backendErrors;
	}

	let newError = { ...backendErrors };

	let errors = {};
	if (keys) {
		for (let key in newError) {
			const find = Object.keys(keys).find(elf => elf === key);
			if (!find) {
				delete newError[key];
			}
		}
	}
	for (let key in newError) {
		errors[key] = newError[key][0];
	}

	setErrors(errors);
};

export const globalHandlerError = (error: Error, tost = true) => {
	const _message = error?.response?.data?.message;
	const message = _message || dir.serverNotWorking;

	if (process?.env?.NODE_ENV === "development") {
		console.log(error);
		console.error(error);
	}

	if (tost) {
		toastify.error(message);
	}

	return message;
};

export const handlerFormErrors = (
	error: Error,
	actions: FormikHelpers<any>
) => {
	const status = error?.response?.status;
	const _errors = error?.response?.data?.errors;
	const _message = error?.response?.data?.message;

	switch (status) {
		case 422:
			return handlerErrors(_errors, actions.setErrors);
		case 403:
			return _message;
		default:
			toastify.error(_message || dir.serverNotWorking);
			return;
	}
};

export const getErrorsKeys = obj => {
	if (!obj) return;
	let newObj = {};
	for (let mark in obj) {
		const key = mark.split(".");
		newObj[key[key.length - 1]] = obj[mark];
	}
	return newObj;
};
