import React, { memo, useState } from "react";

import { useSelector } from "react-redux";
import qs from "query-string";

import Button from "app/uikit-V2/Button";
import { IPropsButton } from "app/uikit-V2/Button/types";
import { configSelect } from "app/store/select";
import PATHS from "PATHS";
import { globalHandlerError } from "app/helper/handlerErrors";

import SubscriptionSelectCardModal from "../SubscriptionSelectCardModal";
import {
	canResumeSubscription,
	fetchActiveSubscription,
	fetchChangeSubscriptionPlan
} from "../Subscription.utils";
import CantSelectSubscription from "../CantSelectSubscription";

interface Props extends IPropsButton {
	tariffPlan?: number;
}

const ActivateSubscriptionButton: React.FC<Props> = ({
	children,
	tariffPlan,
	...props
}) => {
	const [cantSelectPlan, setCantSelectPlan] = useState(false);
	const { subscription } = useSelector(configSelect.selectUser);
	const [selectCard, setSelectCard] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleActivateSubscription = async () => {
		try {
			setLoading(true);
			if (tariffPlan) {
				const isCan = await fetchChangeSubscriptionPlan(tariffPlan);
				if (!isCan) {
					setCantSelectPlan(true);
					return;
				}
			}
			const redirect: string = qs.stringifyUrl({
				url: `${window.location.origin}${PATHS.account.subscriptionSuccess}`
			});
			await fetchActiveSubscription({
				tariff_plan_id: tariffPlan,
				success_url: redirect
			});
		} catch (err) {
			console.log(err);
			globalHandlerError(err);
		} finally {
			setLoading(false);
		}
	};

	const handleClick = () => {
		const canResume = canResumeSubscription(subscription?.status?.id);
		if (subscription?.bank_card && canResume) {
			setSelectCard(true);
		} else {
			handleActivateSubscription();
		}
	};

	return (
		<>
			<Button onClick={handleClick} loading={loading} {...props}>
				{children}
			</Button>
			<SubscriptionSelectCardModal
				open={selectCard}
				tariffPlan={tariffPlan}
				onClose={() => {
					setSelectCard(false);
				}}
			/>
			<CantSelectSubscription
				open={cantSelectPlan}
				onClose={() => {
					setCantSelectPlan(false);
				}}
			/>
		</>
	);
};

export default memo(ActivateSubscriptionButton);
