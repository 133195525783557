import Loadable from "app/utils/Loadable";
import Layout from "app/components/includes/layout";

const ErrorServerUnavailable = Loadable({
	loader: () => import("./ErrorServerUnavailable")
});

const PageErrorServerUnavailable = () => {
	return (
		<Layout>
			<ErrorServerUnavailable />
		</Layout>
	);
};

export default PageErrorServerUnavailable;
