import React, { memo } from "react";

import { FormHelperText } from "@material-ui/core";
import isEqual from "react-fast-compare";

export interface IPropsFormError {
	className?: string;
	error?: boolean | string;
	text?: string;
}

const FormError: React.FC<IPropsFormError> = ({ error, text, className }) => {
	const errorText: string =
		text || typeof error === "string" ? (error as string) : "";

	return (
		<>
			{!!error && errorText && (
				<FormHelperText error className={className}>
					{errorText}
				</FormHelperText>
			)}
		</>
	);
};
export default memo(FormError, isEqual);
