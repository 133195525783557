import { memo } from "react";

import isEqual from "react-fast-compare";
import cn from "classnames";

import ActivateSubscriptionButton from "../Subscription/ButtonsSubscription/ActivateSubscriptionButton";

import styles from "./style.module.scss";

interface Props {
	title: string;
	value: number;
	onShow: () => void;
	showDetails?: boolean;
	includes?: { disabled?: boolean; title: string; text: string }[];
	id: number;
}

const PriceCard: React.FC<Props> = ({
	title,
	value,
	showDetails,
	includes,
	id,
	onShow
}) => (
	<div className={styles.priceCard}>
		<div className={styles.priceCard__header}>
			<h3 className={styles.priceCard__title}>{title}</h3>
			<p className={styles.priceCard__price}>
				<span className={styles.priceCard__price_currency}>$</span>{" "}
				<span className={styles.priceCard__price_value}>{value}</span>{" "}
				<span className={styles.priceCard__price_period}>\ mo</span>
			</p>
		</div>
		<button onClick={onShow} className={styles.priceCard__show_details}>
			{showDetails ? "Hide details" : "Show details"}
			<svg
				width="16"
				height="10"
				viewBox="0 0 16 10"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				style={{ transform: showDetails ? `rotate(180deg)` : "" }}
			>
				<path d="M1 1L8 8L15 1" stroke="#38465D" stroke-width="1.5" />
			</svg>
		</button>

		{showDetails && (
			<div className={styles.priceCard__body}>
				{includes.map(({ title, text, disabled }) => (
					<>
						<p className={styles.priceCard__sub_title}> {title}</p>
						<ul className={styles.priceCard__list}>
							<li className={cn({ [styles.priceCard__list_disable]: disabled })}>
								{text}
							</li>
						</ul>
					</>
				))}
			</div>
		)}

		<div className={styles.priceCard__footer}>
			<ActivateSubscriptionButton tariffPlan={id} fullWidth>
				Try now
			</ActivateSubscriptionButton>
		</div>
	</div>
);

export default memo(PriceCard, isEqual);
