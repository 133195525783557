// @ts-nocheck
import { takeEvery, put, all, call, select } from "redux-saga/effects";
import { push } from "connected-react-router";

import toastify from "app/utils/toastify";
import { serializedData } from "app/helper";
import { ITrendValue } from "app/screens/cabinet/trends/reports/components/createOrEdit/types";
import PATHS from "PATHS";

import { errorsHandler } from "../helper";

import services from "./services";
import types from "./types";
import actions, { ITrandsActionNames } from "./actions";

function* getTrends({
	actionName,
	isAdmin
}: {
	actionName: ITrandsActionNames;
}) {
	try {
		const {
			trends: { pagination, filter }
		} = yield select();
		const filters = { ...filter[actionName] };
		delete filters.range;

		let reqCall = services.fetchTrends;

		if (isAdmin) {
			reqCall = services.fetchTrendsAdmin;
		}

		const response = yield call(reqCall, {
			...pagination[actionName],
			...serializedData(filters)
		});
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.getTrendsSuccess(actionName, response?.data));
		}
	} catch (error) {
		yield errorsHandler(actions.getTrendsFailure, error, actionName);
	}
}

function* getTrendDetails({
	id,
	cb,
	actionName
}: {
	id: number;
	cb: () => void;
	actionName: ITrandsActionNames;
}) {
	try {
		const response = yield call(services.fetchTrendDetails, id);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.getTrendSuccess(actionName, response?.data));
			cb && cb();
		}
	} catch (error) {
		yield errorsHandler(actions.getTrendFailure, error, actionName);
		if (error?.response?.status === 403) {
			yield put(push(PATHS.pageNotFound));
		}
	}
}

function* fetchCreateTrend({
	data,
	cb,
	actionName
}: {
	data: ITrendValue;
	cb: () => void;
	actionName: ITrandsActionNames;
}) {
	try {
		const response = yield call(services.fetchTrendCreate, data);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.fetchCreateTrendSuccess(actionName, response?.data));
			toastify.success(response?.data?.message);
			cb && cb();
		}
	} catch (error) {
		yield errorsHandler(actions.fetchCreateTrendFailure, error, actionName);
	}
}

function* fetchUpdateTrend({
	data,
	id,
	cb,
	actionName
}: {
	data: ITrendValue;
	cb: () => void;
	actionName: ITrandsActionNames;
}) {
	try {
		const response = yield call(services.fetchTrendUpdate, id, data);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.fetchUpdateTrendSuccess(actionName, response?.data));
			toastify.success(response?.data?.message);
			cb && cb();
		}
	} catch (error) {
		yield errorsHandler(actions.fetchUpdateTrendFailure, error, actionName);
	}
}

function* fetchDeleteTrend({
	id,
	cb,
	actionName
}: {
	data: ITrendValue;
	cb: () => void;
	actionName: ITrandsActionNames;
}) {
	try {
		const response = yield call(services.fetchTrendDelete, id);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.fetchDeleteTrendSuccess(actionName, response?.data));
			toastify.success(response?.data?.message);
			cb && cb();
			yield put(actions.getTrends());
		}
	} catch (error) {
		yield errorsHandler(actions.fetchDeleteTrendFailure, error, actionName);
	}
}

function* fetchPagination({ data, actionName }) {
	try {
		let params = data;
		if (data.limit) params.page = 1;
		yield put(actions.fetchPaginationSuccess(data, actionName));
		yield put(actions.getTrends());
	} catch (error) {
		toastify.error(error?.response?.data?.message || error.message);
	}
}

function* fetchFilter({ data, actionName }) {
	try {
		yield put(actions.fetchFilterSuccess(data, actionName));
		yield put(actions.getTrends());
	} catch (error) {
		toastify.error(error?.response?.data?.message || error.message);
	}
}

export default function* root() {
	yield all([takeEvery(types.GET_TRENDS_REQUEST, getTrends)]);
	yield all([takeEvery(types.GET_TREND_REQUEST, getTrendDetails)]);
	yield all([takeEvery(types.FETCH_TREND_CREATE_REQUEST, fetchCreateTrend)]);
	yield all([takeEvery(types.FETCH_TREND_EDIT_REQUEST, fetchUpdateTrend)]);
	yield all([takeEvery(types.FETCH_TREND_DELETE_REQUEST, fetchDeleteTrend)]);
	yield all([takeEvery(types.FETCH_PAGINATION_TRENDS_REQUEST, fetchPagination)]);
	yield all([takeEvery(types.FETCH_FILTER_TRENDS_REQUEST, fetchFilter)]);
}
