import { makeStyles } from "@material-ui/core/styles";

import { PALETTE } from "app/theme";

const useStyles = makeStyles({
	block: {
		border: "1px solid #E8EAEF",
		minWidth: 222,
		display: "flex",
		flexWrap: "wrap",
		borderRadius: 6,
		minHeight: 50,
		padding: "6px 14px",
		transition: "0.2s",
		color: PALETTE.black,
		fontSize: "16px",
		alignContent: "flex-start",
		alignItems: "flex-start",

		"&.Mui-focused": {
			borderColor: PALETTE.mischka
		},
		"&.MuiInput-underline:before,&.MuiInput-underline:after": {
			content: "none"
		},
		"& .MuiInput-input": {
			padding: 0,
			color: PALETTE.black
		},
		"& .MuiInputBase-inputMultiline": {
			paddingTop: 6,
			paddingBottom: 6,
			resize: "vertical",
			lineHeight: "26px"
		}
	},
	input_wrapper: {
		margin: "2px 2px 2px 0",
		minHeight: 32,
		flexGrow: 1,
		width: 0,
		minWidth: 30,
		textOverflow: "ellipsis",
		alignSelf: "stretch",

		"&::placeholder": {
			fontSize: "16px",
			fontFamily: "Rubik, sans-serif",
			opacity: 0.5,
			color: PALETTE.black
		}
	},
	chip: {
		maxWidth: "100%"
	},
	error: {
		borderColor: PALETTE.error
	}
});

export default useStyles;
