import { AnyAction } from "redux";

import { stateRequest } from "../helper";

import { ACTIONS_NAMES } from "./actions";
import types from "./types";

export const initialState = {
	registrations: {},
	userRegistrations: {},
	userTrials: {},
	activeSubscriptions: {},
	isLoading: {},
	error: {}
};

const reducer = function (state = initialState, action: AnyAction) {
	const actionName = action.actionName;

	switch (action.type) {
		case types.GET_STATISTIC_REGISTRATIONS_REQUEST:
		case types.GET_STATISTIC_USER_REGISTRATIONS_REQUEST:
		case types.GET_STATISTIC_USER_TRIALS_REQUEST:
		case types.GET_STATISTIC_USER_ACTIVE_SUBSCRIPTIONS_REQUEST:
			return {
				...state,
				...stateRequest(state, action, "request")
			};
		case types.GET_STATISTIC_REGISTRATIONS_FAILURE:
		case types.GET_STATISTIC_USER_REGISTRATIONS_FAILURE:
		case types.GET_STATISTIC_USER_TRIALS_FAILURE:
		case types.GET_STATISTIC_USER_ACTIVE_SUBSCRIPTIONS_FAILURE:
			return {
				...state,
				...stateRequest(state, action, "failure")
			};
		case types.GET_STATISTIC_REGISTRATIONS_SUCCESS:
		case types.GET_STATISTIC_USER_REGISTRATIONS_SUCCESS:
		case types.GET_STATISTIC_USER_TRIALS_SUCCESS:
		case types.GET_STATISTIC_USER_ACTIVE_SUBSCRIPTIONS_SUCCESS:
			let tempState = {
				...state,
				...stateRequest(state, action, "success")
			};

			switch (actionName) {
				case ACTIONS_NAMES.registrations:
					tempState.registrations = action.data;
					break;
				case ACTIONS_NAMES.userRegistrations:
					tempState.userRegistrations = action.data;
					break;
				case ACTIONS_NAMES.userTrials:
					tempState.userTrials = action.data;
					break;
				case ACTIONS_NAMES.activeSubscriptions:
					tempState.activeSubscriptions = action.data;
					break;
				default:
					break;
			}
			return tempState;
		case types.RESET_STATISTICS:
			return {
				...state,
				registrations: initialState.registrations,
				userRegistrations: initialState.userRegistrations,
				userTrials: initialState.userTrials,
				activeSubscriptions: initialState.activeSubscriptions
			};
		default:
			return state;
	}
};

export default reducer;
