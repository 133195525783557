import { AxiosResponse } from "axios";

import { globalHandlerError } from "app/helper/handlerErrors";
import { ApiUrls } from "app/api/apiUrls";
import { API } from "app/api";
import {
	ICompany,
	IResponseTriggerCompanyDetails
} from "app/screens/cabinet/tracking/types";

export const getCompanyDetails = async (
	userId: string,
	trackingId: string,
	companyId: string,
	cb?: React.Dispatch<React.SetStateAction<ICompany>>
): Promise<IResponseTriggerCompanyDetails | ICompany> => {
	try {
		if (!userId) return;
		const response: AxiosResponse<IResponseTriggerCompanyDetails> = await API.get(
			ApiUrls.admin.users.leadObservation.companies.details(
				userId,
				trackingId,
				companyId
			)
		);
		if (cb) {
			cb(response?.data?.company);
		}

		return response.data;
	} catch (err) {
		globalHandlerError(err);
	}
};
