import React, { ReactNode, memo } from "react";

import cl from "classnames";

import Button, {
	BUTTON_SIZE,
	BUTTON_COLOR_VARIANT
} from "app/uikit/button/active-button";

import style from "./style.module.scss";

interface props {
	title?: string;
	subtitle?: ReactNode;
	content?: JSX.Element;
	buttonText?: string;
	showAddingContent?: boolean;
	showButton?: boolean;
	titleAddingContent?: string;
	subtitleAddingContent?: JSX.Element;
	buttonAction?: () => void;
	buttonDisabled?: boolean;
	buttonLoading?: boolean;
	className?: string;
}

const PageTitle: React.FC<props> = ({
	title,
	subtitle,
	content,
	buttonText,
	showAddingContent,
	showButton,
	titleAddingContent,
	subtitleAddingContent,
	buttonAction,
	buttonDisabled,
	buttonLoading,
	className
}) => {
	return (
		<div className={style.wrapper}>
			<div className={style.container}>
				<h2 className={cl(style.title, "title-h2")}>{title}</h2>
				{titleAddingContent}
			</div>
			{(subtitle || showButton || showAddingContent) && (
				<div className={style.blockSubTitle}>
					<p className={cl(style.subTitle, "main-text")}>{subtitle}</p>

					<div className={style.addingContent}>
						{showButton && (
							<Button
								size={BUTTON_SIZE.large}
								colorVariant={BUTTON_COLOR_VARIANT.gradient}
								className={cl("m-l--12", className)}
								onClick={buttonAction}
								disabled={buttonDisabled}
								loading={buttonLoading}
							>
								{buttonText || "Buttton"}
							</Button>
						)}
						{showAddingContent && subtitleAddingContent}
					</div>
				</div>
			)}
			{content && content}
		</div>
	);
};

export default memo(PageTitle);
