import { AnyAction } from "redux";

import types from "./types";

export type IHandBookItem = { id: number; name: string };

export type ILeadObservationEvent = IHandBookItem & {
	description: string;
	is_enabled: boolean;
};

export type ITariffPlans = IHandBookItem & {
	appsumo_name: string;
	credits_count: number;
	custom_credit_price: number;
	duration: string;
	price: number;
};

export interface IHandbookState {
	isSuccessLoaded: boolean;
	roles?: IHandBookItem[];
	employee_roles?: IHandBookItem[];
	user_roles?: IHandBookItem[];
	user_statuses?: IHandBookItem[];
	integration_types?: IHandBookItem[];
	creative_template_types?: IHandBookItem[];
	ad_account_activation_statuses?: IHandBookItem[];
	ad_account_statuses?: IHandBookItem[];
	audience_column_types?: IHandBookItem[];
	contact_column_types?: IHandBookItem[];
	campaign_statuses?: IHandBookItem[];
	facebook_ads_date_presets?: IHandBookItem[];
	campaign_budget_types?: IHandBookItem[];
	facebook_standard_conversions?: IHandBookItem[];
	campaign_targets?: IHandBookItem[];
	campaign_conversion_types?: IHandBookItem[];
	countries?: IHandBookItem[];
	gender_types?: IHandBookItem[];
	tariff_plans?: ITariffPlans[];
	user_subscription_statuses?: IHandBookItem[];
	languages?: IHandBookItem[];
	lead_locations?: IHandBookItem[];
	lead_categories?: IHandBookItem[];
	lead_last_funding_dates?: IHandBookItem[];
	lead_last_funding_types?: IHandBookItem[];
	lead_positions?: IHandBookItem[];
	lead_intent_vendors?: IHandBookItem[];
	lead_vendors?: IHandBookItem[];
	lead_locations_mattermark?: IHandBookItem[];
	lead_categories_mattermark?: IHandBookItem[];
	lead_last_funding_types_mattermark?: IHandBookItem[];
	lead_last_funding_dates_mattermark?: IHandBookItem[];
	lead_positions_mattermark?: IHandBookItem[];
	lead_locations_apollo?: IHandBookItem[];
	lead_categories_apollo?: IHandBookItem[];
	lead_last_funding_types_apollo?: IHandBookItem[];
	lead_positions_apollo?: IHandBookItem[];
	data_vendor_types?: IHandBookItem[];
	data_vendor_countries?: IHandBookItem[];
	data_vendor_attribute_groups?: IHandBookItem[];
	company_resume_categories?: IHandBookItem[];
	company_resume_sizes?: IHandBookItem[];
	company_resume_industries?: IHandBookItem[];
	company_resume_positions?: IHandBookItem[];
	lead_observation_vacancies_count?: IHandBookItem[];
	lead_observation_industries?: IHandBookItem[];
	lead_observation_funding_types?: IHandBookItem[];
	lead_observation_events?: ILeadObservationEvent[];
	lead_observation_notification_periods?: IHandBookItem[];
	lead_observation_new_vacancies?: IHandBookItem[];
	lead_observation_marketing_budgets?: IHandBookItem[];
	lead_observation_employees_counts?: IHandBookItem[];
	user_lead_observation_statuses?: IHandBookItem[];
	trend_markets?: IHandBookItem[];
}

export const initialState: IHandbookState = {
	roles: [],
	employee_roles: [],
	user_roles: [],
	user_statuses: [],
	integration_types: [],
	creative_template_types: [],
	ad_account_activation_statuses: [],
	ad_account_statuses: [],
	audience_column_types: [],
	contact_column_types: [],
	campaign_statuses: [],
	facebook_ads_date_presets: [],
	campaign_budget_types: [],
	facebook_standard_conversions: [],
	campaign_targets: [],
	campaign_conversion_types: [],
	countries: [],
	gender_types: [],
	tariff_plans: [],
	user_subscription_statuses: [],
	languages: [],
	lead_locations: [],
	lead_categories: [],
	lead_last_funding_dates: [],
	lead_last_funding_types: [],
	lead_positions: [],
	lead_intent_vendors: [],
	lead_vendors: [],
	lead_locations_mattermark: [],
	lead_categories_mattermark: [],
	lead_last_funding_types_mattermark: [],
	lead_last_funding_dates_mattermark: [],
	lead_positions_mattermark: [],
	lead_locations_apollo: [],
	lead_categories_apollo: [],
	lead_last_funding_types_apollo: [],
	lead_positions_apollo: [],
	data_vendor_types: [],
	data_vendor_countries: [],
	data_vendor_attribute_groups: [],
	company_resume_categories: [],
	company_resume_sizes: [],
	company_resume_industries: [],
	company_resume_positions: [],
	lead_observation_vacancies_count: [],
	lead_observation_industries: [],
	lead_observation_funding_types: [],
	lead_observation_events: [],
	lead_observation_notification_periods: [],
	lead_observation_new_vacancies: [],
	lead_observation_marketing_budgets: [],
	lead_observation_employees_counts: [],
	user_lead_observation_statuses: [],
	isSuccessLoaded: false
};

const reducer = function (
	state: IHandbookState = initialState,
	action: AnyAction
) {
	switch (action.type) {
		case types.GET_HANDBOOKS_SUCCESS:
			return {
				...state,
				...action.data,
				isSuccessLoaded: true
			};
		default:
			return state;
	}
};

export default reducer;
