import { memo } from "react";

import isEqual from "react-fast-compare";
import { Field, FieldProps } from "formik";

import { getPropsForm, getPropsInput } from "../Form.utils";

import ChipSelectBase from "./ChipSelectBase";
import { ISelectChipProps } from "./types";

const SelectChip: React.FC<ISelectChipProps> = props => {
	const { form, onChange, validateField } = props;

	const handleChangeField = async (
		valueInput?: string[] | [],
		name?: string
	) => {
		if (onChange) {
			await onChange(valueInput, name);
		} else if (form) {
			await form.setFieldValue(name, valueInput);
		}

		if (form && validateField) {
			await form.validateField(name);
		}
	};

	const propsForm = getPropsForm(props, { onChange: handleChangeField });
	const propsInput = getPropsInput(props);

	return <ChipSelectBase {...propsForm} {...propsInput} />;
};

const FormikSelectChipBase: React.FC<ISelectChipProps> = ({
	name,
	...rest
}) => (
	<Field name={name}>
		{({ field, meta, form }: FieldProps) => {
			const error = meta.touched ? meta.error : "";

			const onChange = (value: string[] | []) => {
				form.setFieldValue(name, value);
			};

			const name = field.name;

			return (
				<SelectChip
					value={field.value}
					name={name}
					{...rest}
					onChange={onChange}
					error={error}
				/>
			);
		}}
	</Field>
);

SelectChip.defaultProps = {
	validateField: true
};

export const FormikSelectChip = memo(FormikSelectChipBase, isEqual);

export default memo(SelectChip, isEqual);
