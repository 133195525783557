import { useCallback } from "react";

import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import UserProfileWrapper from "app/components/UserProfileLayout";
import { RootState } from "app/store/rootReducer";
import Button from "app/uikit-V2/Button";
import PATHS from "PATHS";
import { IFile } from "app/types";
import { TypeRequestFile } from "app/components/Tracking/TrackingNewLists/ProspectsUpload/type";
import TrackingAddNewList from "app/components/Tracking/TrackingAddNewListFlow";

import useGetUserDetails from "../../hooks/useGetUserDetails";

import { getMatchesColumns, sendMatchedColumns } from "./AddNewList.utils";

const UserLeadsAddNewList = () => {
	const { id } = useParams<{
		id: string;
	}>();

	const {
		user: { created_at }
	} = useSelector((state: RootState) => state.users);
	const history = useHistory();

	useGetUserDetails();

	const fetchMatchesColumns = useCallback(
		async (data: { file: IFile }) => getMatchesColumns(id, data),
		[id]
	);
	const fetchMatchedColumns = useCallback(
		async (data: TypeRequestFile) => sendMatchedColumns(id, data),
		[id]
	);

	const onSuccessCreateList = useCallback(() => {
		history.push(PATHS.adminPanel.users.tracking.url(id));
	}, [id, history]);

	return (
		<UserProfileWrapper>
			<Button
				className="m-b--16"
				size="medium"
				onClick={() => {
					history.push(PATHS.adminPanel.users.tracking.url(id));
				}}
			>
				Go to user leads
			</Button>

			<TrackingAddNewList
				fetchMatchesColumns={fetchMatchesColumns}
				fetchMatchedColumns={fetchMatchedColumns}
				onSuccessCreateList={onSuccessCreateList}
			/>
		</UserProfileWrapper>
	);
};

export default UserLeadsAddNewList;
