export enum KeywordsKeys {
	mention = "mention",
	vacancy = "vacancy"
}

export enum KeywordsKeysId {
	mention = 12,
	vacancy = 14
}

export enum KeywordsSubmitKeys {
	mention = "keywords",
	vacancy = "vacancy_keywords"
}
