// @ts-nocheck

import { IFile } from "app/types";

export const getBase64Content = (file: any): Promise<string> =>
	new Promise((resolve, reject) => {
		const based = toBase64(file);
		based
			.then(res => {
				const arr = (res as any).split("base64,");
				resolve(arr[1]);
			})
			.catch(e => {
				reject(e);
			});
	});

export const toBase64 = file =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});

const loadImage = url => {
	return new Promise((resolve, reg) => {
		const image = new Image();
		image.crossOrigin = "anonymous";
		image.addEventListener("load", () => {
			resolve(image);
		});
		image.addEventListener("error", e => {
			reg(e);
		});
		image.src = url;
	});
};

export const getParamsImage = async file => {
	var reader = new FileReader();
	reader.readAsDataURL(file);
	const res = await new Promise(res => {
		reader.onloadend = async () => {
			let img = await loadImage(reader.result);
			res(img);
		};
	});
	const height = (res as any).naturalHeight;
	const width = (res as any).naturalWidth;
	return {
		height,
		width,
		ratio: width / height
	};
};

export const urlfileToObject = async (
	url: string,
	name: string,
	mime?: string
) => {
	try {
		const options = {
			method: "get",
			headers: {
				// "Content-Type": mime,
			}
		};

		const response = await fetch(url, options);
		const blob = await response.blob();
		return new File([blob], name, {
			type: blob.type
		});
	} catch (e) {
		console.warn(e);
		return e;
	}
};

export const getPreparedDataFile = async (file: File, name?: string): IFile => {
	const content = await getBase64Content(file);

	return {
		name: name || file.name,
		content,
		mime: file.type
	};
};
