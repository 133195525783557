import React, { memo } from "react";

import { DeleteCampaignIcon } from "app/icons";

import Modal from "./";

interface Props {
	onClose?: any;
	showCloseIcon?: boolean;
	loadingSubAgree?: boolean;
	onAgree?: any;
	onSubAgree?: any;
	open?: boolean;
	text?: React.ReactNode;
	loading?: boolean;
	icon?: any;
	title?: string;
	textAgree?: string;
	textClose?: string;
}

const ConfirmModal: React.FC<Props> = ({
	onClose,
	showCloseIcon,
	loadingSubAgree,
	onAgree,
	onSubAgree,
	open,
	text,
	loading,
	icon,
	title,
	textAgree,
	textClose
}) => {
	return (
		<Modal
			showCloseIcon={showCloseIcon}
			onClose={onClose}
			open={open}
			footer
			footerJustify="center"
			firstBtnText={textClose || "No, decline"}
			firstBtnloading={!!loadingSubAgree}
			firstBtnAction={onSubAgree || onClose}
			secondBtnText={textAgree || "Yes, delete"}
			secondBtnloading={!!loading}
			secondBtnAction={onAgree}
		>
			<div className="text-centered">
				<div className="verification-icon m-y--16">
					{icon || <DeleteCampaignIcon />}
				</div>
				<h2 className="title-subheading title-subheading_bold m-b--8">
					{title || "Delete this item"}
				</h2>
				<p className="small-text">
					{text || "Are you sure you want to delete this?"}
				</p>
			</div>
		</Modal>
	);
};

export default memo(ConfirmModal);
